import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { VehicleListItem } from '../../models/business/shifts/vehicle-list-item.model';

export class ShiftVehiclesAction {
    static readonly type = '[ShiftVehicles Page] GetShiftVehicles';

    constructor(public payload: { workingAreaUid: string }) { }
}

export class ShiftVehiclesSuccessAction {
    static readonly type = '[ShiftVehicles API] GetShiftVehicles Success';

    constructor(public payload: VehicleListItem[]) { }
}

export class ShiftVehiclesFailAction {
    static readonly type = '[ShiftVehicles API] GetShiftVehicles Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftVehiclesResetAction {
    static readonly type = '[ShiftVehicles Page] GetShiftVehicles Reset';
}
