import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigCreateUpdateRequest } from '../../models/business/drilling-rig/drilling-rig-create-update-request.model';

import { DrillingRigCreateAction, DrillingRigCreateResetAction } from './drilling-rig-create.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigCreateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigCreateDispatchers');
    }

    public dispatchDrillingRigCreateAction(request: DrillingRigCreateUpdateRequest) {
        this.store.dispatch(new DrillingRigCreateAction({ request }));
    }

    public dispatchDrillingRigCreateResetAction() {
        this.store.dispatch(new DrillingRigCreateResetAction());
    }
}
