import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WorkingAreaListGetFilterData } from '../../models/filters/working-area-list-get-filter.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

export class EmployeeScheduleWorkingAreaAction {
    static readonly type = '[EmployeeScheduleWorkingArea Page] GetEmployeeScheduleWorkingArea';

    constructor(public payload: WorkingAreaListGetFilterData) { }
}

export class EmployeeScheduleWorkingAreaSuccessAction {
    static readonly type = '[EmployeeScheduleWorkingArea API] GetEmployeeScheduleWorkingArea Success';

    constructor(public payload: PaginationResult<WorkingAreaListItem>) { }
}

export class EmployeeScheduleWorkingAreaFailAction {
    static readonly type = '[EmployeeScheduleWorkingArea API] GetEmployeeScheduleWorkingArea Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeScheduleWorkingAreaResetAction {
    static readonly type = '[EmployeeScheduleWorkingArea Page] GetEmployeeScheduleWorkingArea Reset';
}
