import { DrillingRigTransferListGetFilterData } from '../../models/filters/drilling-rig-transfer-list-get-filter.model';
import { DrillingRigTransferListItem } from '../../models/business/drilling-rig-transfer/drilling-rig-transfer-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class DrillingRigTransfersAction {
    static readonly type = '[DrillingRigTransfers Page] GetDrillingRigTransfers';

    constructor(public payload: PaginationRequest) { }
}

export class DrillingRigTransfersSuccessAction {
    static readonly type = '[DrillingRigTransfers API] GetDrillingRigTransfers Success';

    constructor(public payload: { list: PaginationResult<DrillingRigTransferListItem>, pagination: PaginationRequest }) { }
}

export class DrillingRigTransfersFailAction {
    static readonly type = '[DrillingRigTransfers API] GetDrillingRigTransfers Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigTransfersResetAction {
    static readonly type = '[DrillingRigTransfers Page] GetDrillingRigTransfers Reset';
}

export class DrillingRigTransfersSetFilterAction {
    static readonly type = '[DrillingRigTransfers API] SetFilter';

    constructor(public payload: DrillingRigTransferListGetFilterData) { }
}
