import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigTransfersService } from '../../services/drilling-rig-transfers.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigTransferDeleteAction, DrillingRigTransferDeleteFailAction, DrillingRigTransferDeleteResetAction, DrillingRigTransferDeleteSuccessAction } from './drilling-rig-transfer-delete.actions';

export interface DrillingRigTransferDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<DrillingRigTransferDeleteStateModel>({
    name: 'drillingRigTransferDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class DrillingRigTransferDeleteState {
    constructor(private drillingRigTransfersService: DrillingRigTransfersService) { }

    @Action(DrillingRigTransferDeleteAction) async drillingRigTransferDelete(ctx: StateContext<DrillingRigTransferDeleteStateModel>, action: DrillingRigTransferDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.drillingRigTransfersService.deleteDrillingRigTransfer(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigTransferDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new DrillingRigTransferDeleteFailAction(error)), 0));
    }

    @Action(DrillingRigTransferDeleteSuccessAction) drillingRigTransferDeleteSuccess(ctx: StateContext<DrillingRigTransferDeleteStateModel>, _: DrillingRigTransferDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(DrillingRigTransferDeleteFailAction) drillingRigTransferDeleteFail(ctx: StateContext<DrillingRigTransferDeleteStateModel>, action: DrillingRigTransferDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigTransferDeleteResetAction) drillingRigTransferDeleteReset(ctx: StateContext<DrillingRigTransferDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
