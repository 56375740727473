import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';
import { WorkingAreaListGetFilter } from '../models/filters/working-area-list-get-filter.model';
import { WorkingAreaListItem } from '../models/business/working-area/working-area-list-item.model';

@Injectable({
    providedIn: 'root'
})
export class WorkingAreaService extends BaseSingletonService {

    private settings: {
        service: {
            getWorkingAreas: string;
        }
    };

    constructor(private http: HttpService) {
        super('WorkingAreaService');
        this.settings = {
            service: {
                getWorkingAreas: '/drilling/v1/working-areas?term={0}&countryCode={1}&termField={2}&sortField={3}&sortOrder={4}&pageIndex={5}&pageSize={6}&companyUid={7}',
            }
        };
    }

    public async getWorkingAreas(filter: WorkingAreaListGetFilter): Promise<PaginationResult<WorkingAreaListItem>> {
        return this.http.get<PaginationResult<WorkingAreaListItem>>(String.format(this.settings.service.getWorkingAreas, filter.term, filter.countryCode, filter.termField, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.companyUid))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }
}
