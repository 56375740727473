export class TransportWorkbookGetFilter {
    public workingArea?: { uid: string, title: string};
    public dateFrom?: number;
    public dateTo?: number;

    constructor(args?: { filterData?: TransportWorkbookGetFilterData }) {
        this.workingArea = (args && args.filterData && args.filterData.workingArea != null) ? args.filterData.workingArea : null;
        this.dateFrom = (args && args.filterData && args.filterData.dateFrom != null) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData && args.filterData.dateTo != null) ? args.filterData.dateTo : null;
    }
}

export class TransportWorkbookGetFilterData {
    public workingArea?: { uid: string, title: string};
    public dateFrom?: number;
    public dateTo?: number;
}
