import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { ActivityBalanceWithPeriod } from '../models/business/analytics/activity-balance-with-period.model';
import { ActivityBalance } from '../models/business/analytics/activity-balance.model';
import { ActivityHours } from '../models/business/analytics/activity-hours.model';
import { AnalyticGetFilter } from '../models/filters/analytic-get-filter.model';
import { DateActivityHours } from '../models/business/analytics/date-activity-hours.model';
import { DrillSpeedChangeListItem } from '../models/business/analytics/drill-speed-change-list-item.model';
import { LossOfProfitListItem } from '../models/business/analytics/loss-of-profit-list-item.model';
import { ExgTranslateService } from '../../burns-ui-framework/shared/services/common/exg-translate.service';

import { String } from '../../burns-ui-framework/shared/utils/string';
import { AnalyticsWorkbookGetFilter } from '../models/filters/analytics-workbook-get-filter.model';
import { DateUtils } from '../../burns-ui-framework/shared/utils/date-utils';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService extends BaseSingletonService {

    private settings: {
        service: {
            getLossOfProfit: string;
            getDrillSpeedChangeDynamic: string;
            getActivityBalance: string;
            getActivityBalanceSummary: string;
            getProductiveBalance: string;
            getProductiveBalanceSummary: string;
            getDrillingRigRepairBalance: string;
            getDrillingRigRepairBalanceSummary: string;
            getCausedByCustomerStoppageBalance: string;
            getCausedByCustomerStoppageBalanceSummary: string;
            getCausedByContractorStoppageBalance: string;
            getCausedByContractorStoppageBalanceSummary: string;
            getWorkbook: string;
        }
    };

    constructor(private http: HttpService, private translate: ExgTranslateService) {
        super('AnalyticsService');
        this.settings = {
            service: {
                getLossOfProfit: '/drilling/v1/analytics/loss-of-profit?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getDrillSpeedChangeDynamic: '/drilling/v1/analytics/drill-speed-change-dynamic?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getActivityBalance: '/drilling/v1/analytics/activity-balance?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getActivityBalanceSummary: '/drilling/v1/analytics/activity-balance/summary?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getProductiveBalance: '/drilling/v1/analytics/productive-balance?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getProductiveBalanceSummary: '/drilling/v1/analytics/productive-balance/summary?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getDrillingRigRepairBalance: '/drilling/v1/analytics/drilling-rig-repair-balance?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getDrillingRigRepairBalanceSummary: '/drilling/v1/analytics/drilling-rig-repair-balance/summary?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getCausedByCustomerStoppageBalance: '/drilling/v1/analytics/caused-by-customer-stoppage-balance?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getCausedByCustomerStoppageBalanceSummary: '/drilling/v1/analytics/caused-by-customer-stoppage-balance/summary?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getCausedByContractorStoppageBalance: '/drilling/v1/analytics/caused-by-contractor-stoppage-balance?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getCausedByContractorStoppageBalanceSummary: '/drilling/v1/analytics/caused-by-contractor-stoppage-balance/summary?countryCode={0}&workingAreaUid={1}&drillingRigUid={2}&dateFrom={3}&dateTo={4}',
                getWorkbook: '/drilling/v1/analytics/workbook?workingAreaUid={0}&DateFrom={1}&DateTo={2}',
            }
        };
    }

    public async getLossOfProfit(filter: AnalyticGetFilter): Promise<LossOfProfitListItem[]> {
        return this.http.get<{ list: LossOfProfitListItem[] }>(String.format(this.settings.service.getLossOfProfit, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.list);
    }

    public async getDrillSpeedChangeDynamic(filter: AnalyticGetFilter): Promise<DrillSpeedChangeListItem[]> {
        return this.http.get<{ list: DrillSpeedChangeListItem[] }>(String.format(this.settings.service.getDrillSpeedChangeDynamic, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.list);
    }

    public async getActivityBalance(filter: AnalyticGetFilter): Promise<ActivityBalanceWithPeriod[]> {
        return this.http.get<{ list: ActivityBalanceWithPeriod[] }>(String.format(this.settings.service.getActivityBalance, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.list);
    }

    public async getActivityBalanceSummary(filter: AnalyticGetFilter): Promise<ActivityBalance> {
        return this.http.get<{ data: ActivityBalance }>(String.format(this.settings.service.getActivityBalanceSummary, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.data);
    }

    public async getProductiveBalance(filter: AnalyticGetFilter): Promise<DateActivityHours[]> {
        return this.http.get<{ list: DateActivityHours[] }>(String.format(this.settings.service.getProductiveBalance, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.list);
    }

    public async getProductiveBalanceSummary(filter: AnalyticGetFilter): Promise<ActivityHours[]> {
        return this.http.get<{ data: ActivityHours[] }>(String.format(this.settings.service.getProductiveBalanceSummary, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.data);
    }

    public async getDrillingRigRepairBalance(filter: AnalyticGetFilter): Promise<DateActivityHours[]> {
        return this.http.get<{ list: DateActivityHours[] }>(String.format(this.settings.service.getDrillingRigRepairBalance, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.list);
    }

    public async getDrillingRigRepairBalanceSummary(filter: AnalyticGetFilter): Promise<ActivityHours[]> {
        return this.http.get<{ data: ActivityHours[] }>(String.format(this.settings.service.getDrillingRigRepairBalanceSummary, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.data);
    }

    public async getCausedByCustomerStoppageBalance(filter: AnalyticGetFilter): Promise<DateActivityHours[]> {
        return this.http.get<{ list: DateActivityHours[] }>(String.format(this.settings.service.getCausedByCustomerStoppageBalance, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.list);
    }

    public async getCausedByCustomerStoppageBalanceSummary(filter: AnalyticGetFilter): Promise<ActivityHours[]> {
        return this.http.get<{ data: ActivityHours[] }>(String.format(this.settings.service.getCausedByCustomerStoppageBalanceSummary, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.data);
    }

    public async getCausedByContractorStoppageBalance(filter: AnalyticGetFilter): Promise<DateActivityHours[]> {
        return this.http.get<{ list: DateActivityHours[] }>(String.format(this.settings.service.getCausedByContractorStoppageBalance, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.list);
    }

    public async getCausedByContractorStoppageBalanceSummary(filter: AnalyticGetFilter): Promise<ActivityHours[]> {
        return this.http.get<{ data: ActivityHours[] }>(String.format(this.settings.service.getCausedByContractorStoppageBalanceSummary, filter.countryCode, filter.workingAreaUid, filter.drillingRigUid, filter.dateFrom, filter.dateTo))
            .then(res => res.data);
    }

    public async getWorkbook(filter: AnalyticsWorkbookGetFilter): Promise<Blob> {
        const workbookName = `Аналитика - ${filter.workingArea ? filter.workingArea?.title : ''} - ${ DateUtils.formatEpocToString(filter.dateFrom, this.translate.getCurrentLang(), 'yy')}.xlsx`;
        return this.http.download(String.format(this.settings.service.getWorkbook, filter.workingArea?.uid, filter.dateFrom, filter.dateTo), workbookName);
    }
}
