import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { DrillingRigListGetFilter, DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigListItem } from '../../models/business/drilling-rig/drilling-rig-list-item.model';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { DrillingRigsAction, DrillingRigsFailAction, DrillingRigsResetAction, DrillingRigsSetFilterAction, DrillingRigsSuccessAction } from './drilling-rigs.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface DrillingRigsStateModel extends PaginationStateModel<DrillingRigListItem> {
    uids: string[];
    entities: { [uid: string]: DrillingRigListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: DrillingRigListGetFilterData;
    total: number;
}

@State<DrillingRigsStateModel>({
    name: 'drillingRigs',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class DrillingRigsState extends PaginationStateBase {
    constructor(private drillingRigsService: DrillingRigsService) {
        super();
    }

    @Action([DrillingRigsAction]) drillingRigsGet(ctx: StateContext<DrillingRigsStateModel>, action: DrillingRigsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new DrillingRigListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.drillingRigsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.drillingRigsService.getDrillingRigList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigsFailAction(err)), 0));
    }

    @Action(DrillingRigsSuccessAction) drillingRigsGetSuccess(ctx: StateContext<DrillingRigsStateModel>, action: DrillingRigsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.drillingRigsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(DrillingRigsFailAction) drillingRigsGetFail(ctx: StateContext<DrillingRigsStateModel>, action: DrillingRigsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigsResetAction) drillingRigsGetReset(ctx: StateContext<DrillingRigsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(DrillingRigsSetFilterAction) drillingRigsSetFilter(ctx: StateContext<DrillingRigsStateModel>, action: DrillingRigsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new DrillingRigsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
