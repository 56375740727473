import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { JobPositionCreateOrUpdateRequest } from '../../models/business/job-positions/job-position-create-or-update-request.model';

export class DictionaryJobPositionsUpdateAction {
    static readonly type = '[DictionaryJobPositionsUpdate Page] DictionaryJobPositionsUpdate';

    constructor(public payload: { request: JobPositionCreateOrUpdateRequest[] }) { }
}

export class DictionaryJobPositionsUpdateSuccessAction {
    static readonly type = '[DictionaryJobPositionsUpdate API] DictionaryJobPositionsUpdate Success';
}

export class DictionaryJobPositionsUpdateFailAction {
    static readonly type = '[DictionaryJobPositionsUpdate API] DictionaryJobPositionsUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryJobPositionsUpdateResetAction {
    static readonly type = '[DictionaryJobPositionsUpdate Page] DictionaryJobPositionsUpdate Reset';
}
