import { DrillingRig } from '../../models/business/drilling-rig/drilling-rig.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigAction {
    static readonly type = '[DrillingRig Page] GetDrillingRig';

    constructor(public payload: { uid: string}) { }
}

export class DrillingRigSuccessAction {
    static readonly type = '[DrillingRig API] GetDrillingRig Success';

    constructor(public payload: DrillingRig) { }
}

export class DrillingRigFailAction {
    static readonly type = '[DrillingRig API] GetDrillingRig Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigResetAction {
    static readonly type = '[DrillingRig Page] GetDrillingRig Reset';
}
