import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsService } from '../../services/analytics.service';

import { AnalyticsWorkbookGetFilter } from '../../models/filters/analytics-workbook-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { AnalyticsWorkbookExportAction, AnalyticsWorkbookExportFailAction, AnalyticsWorkbookExportResetAction, AnalyticsWorkbookExportSuccessAction } from './analytics-workbook-export.actions';

export interface AnalyticsWorkbookExportStateModel {
    loaded: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<AnalyticsWorkbookExportStateModel>({
    name: 'analyticsWorkbookExport',
    defaults: { pending: false, loaded: false, error: null }
})
@Injectable()
export class AnalyticsWorkbookExportState {
    constructor(private analyticsService: AnalyticsService) {}

    @Action(AnalyticsWorkbookExportAction) analyticsWorkbookExportGet(ctx: StateContext<AnalyticsWorkbookExportStateModel>, action: AnalyticsWorkbookExportAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new AnalyticsWorkbookGetFilter({ filterData: action.payload.filter });
        return this.analyticsService.getWorkbook(filter)
            .then(() => setTimeout(() => ctx.dispatch(new AnalyticsWorkbookExportSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsWorkbookExportFailAction(err)), 0));
    }

    @Action(AnalyticsWorkbookExportSuccessAction) analyticsWorkbookExportGetSuccess(ctx: StateContext<AnalyticsWorkbookExportStateModel>, _: AnalyticsWorkbookExportSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: true, error: null });
    }

    @Action(AnalyticsWorkbookExportFailAction) analyticsWorkbookExportGetFail(ctx: StateContext<AnalyticsWorkbookExportStateModel>, action: AnalyticsWorkbookExportFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsWorkbookExportResetAction) analyticsWorkbookExportGetReset(ctx: StateContext<AnalyticsWorkbookExportStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: false, error: null });
    }
}
