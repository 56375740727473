import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeCreateState, EmployeeCreateStateModel } from './employee-create.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeCreateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeeCreateState], (state: EmployeeCreateStateModel) => state.pending);

    static readonly getUpdated = createSelector([EmployeeCreateState], (state: EmployeeCreateStateModel) => state.created);

    static readonly getError = createSelector([EmployeeCreateState], (state: EmployeeCreateStateModel) => state.error);

    @Select(EmployeeCreateSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeeCreateSelectors.getUpdated) created$: Observable<{ uid: string }>;

    @Select(EmployeeCreateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('EmployeeCreateSelectors');
    }
}
