import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigUpdateAction, DrillingRigUpdateFailAction, DrillingRigUpdateResetAction, DrillingRigUpdateSuccessAction } from './drilling-rig-update.actions';

export interface DrillingRigUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<DrillingRigUpdateStateModel>({
    name: 'drillingRigUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class DrillingRigUpdateState {
    constructor(private drillingRigsService: DrillingRigsService) { }

    @Action(DrillingRigUpdateAction) async drillingRigUpdate(ctx: StateContext<DrillingRigUpdateStateModel>, action: DrillingRigUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.drillingRigsService.updateDrillingRig(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigUpdateFailAction(err)), 0));
    }

    @Action(DrillingRigUpdateSuccessAction) drillingRigUpdateSuccess(ctx: StateContext<DrillingRigUpdateStateModel>, _: DrillingRigUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(DrillingRigUpdateFailAction) drillingRigUpdateFail(ctx: StateContext<DrillingRigUpdateStateModel>, action: DrillingRigUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(DrillingRigUpdateResetAction) drillingRigUpdateReset(ctx: StateContext<DrillingRigUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
