import { VehicleTransferListGetFilterData } from '../../models/filters/vehicle-transfer-list-get-filter.model';
import { VehicleTransferListItem } from '../../models/business/vehicle-transfer/vehicle-transfer-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class VehicleTransfersAction {
    static readonly type = '[VehicleTransfers Page] GetVehicleTransfers';

    constructor(public payload: PaginationRequest) { }
}

export class VehicleTransfersSuccessAction {
    static readonly type = '[VehicleTransfers API] GetVehicleTransfers Success';

    constructor(public payload: { list: PaginationResult<VehicleTransferListItem>, pagination: PaginationRequest }) { }
}

export class VehicleTransfersFailAction {
    static readonly type = '[VehicleTransfers API] GetVehicleTransfers Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleTransfersResetAction {
    static readonly type = '[VehicleTransfers Page] GetVehicleTransfers Reset';
}

export class VehicleTransfersSetFilterAction {
    static readonly type = '[VehicleTransfers API] SetFilter';

    constructor(public payload: VehicleTransferListGetFilterData) { }
}
