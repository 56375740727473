import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CompaniesSearchAction, CompaniesSearchFailAction, CompaniesSearchResetAction, CompaniesSearchSuccessAction } from './companies-search.actions';

import { CompaniesService } from '../../services/companies.service';
import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { CompaniesListFilter } from '../../models/filters/companies-list-filter.model';
import { CompanyListItem } from '../../models/business/company/company-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { ExgParamsConfig } from '../../exg-params.config';

export interface CompaniesSearchStateModel extends PaginationStateModel<CompanyListItem> {
    uids: string[];
    entities: { [uid: string]: CompanyListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<CompaniesSearchStateModel>({
    name: 'companiesSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class CompaniesSearchState extends PaginationStateBase {
    constructor(private companiesService: CompaniesService) {
        super();
    }

    @Action([CompaniesSearchAction]) companiesGet(ctx: StateContext<CompaniesSearchStateModel>, action: CompaniesSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CompaniesListFilter({ pageIndex: state.index, filterData: { ...action.payload } });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.companiesService.getCompanyList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CompaniesSearchSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CompaniesSearchFailAction(err)), 0));
    }

    @Action(CompaniesSearchSuccessAction) companiesGetSuccess(ctx: StateContext<CompaniesSearchStateModel>, action: CompaniesSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.companiesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CompaniesSearchFailAction) companiesGetFail(ctx: StateContext<CompaniesSearchStateModel>, action: CompaniesSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CompaniesSearchResetAction) companiesGetReset(ctx: StateContext<CompaniesSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
