import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class ShiftDrillingRigsAction {
    static readonly type = '[ShiftDrillingRigs Page] GetShiftDrillingRigs';

    constructor(public payload: { workingAreaUid: string }) { }
}

export class ShiftDrillingRigsSuccessAction {
    static readonly type = '[ShiftDrillingRigs API] GetShiftDrillingRigs Success';

    constructor(public payload: DictionaryEntryListItem[]) { }
}

export class ShiftDrillingRigsFailAction {
    static readonly type = '[ShiftDrillingRigs API] GetShiftDrillingRigs Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftDrillingRigsResetAction {
    static readonly type = '[ShiftDrillingRigs Page] GetShiftDrillingRigs Reset';
}
