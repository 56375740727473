import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../common/base-singleton.service';
import { CurrencyService } from './currency-service.service';
import { ExgTranslateService } from '../common/exg-translate.service';
import { LanguageService } from '../common/language-service.service';
import { LocalStorageService } from '../common/local-storage.service';

import { ExgBaseParamsConfig } from '../../exg-params.config';
import { JwtUtils } from '../../utils/jwt-utils';

@Injectable({
    providedIn: 'root'
})
export class TokenService extends BaseSingletonService {

    constructor(private languageService: LanguageService, private currencyService: CurrencyService, private localStorage: LocalStorageService, private translate: ExgTranslateService) {
        super('TokenService');
    }

    /**
     * Reset auth tokens
     */
    public resetAuthToken() {
        this.setAuth(null, null);
    }

    /**
     * Get authentication token
     */
    public getAuthToken(): string {
        return this.localStorage.getItem(ExgBaseParamsConfig.storageKeys.storageTokenKey);
    }

    public getRefreshToken(): string {
        return this.localStorage.getItem(ExgBaseParamsConfig.storageKeys.storageRefreshTokenKey);
    }

    public setAuth(token: string, refreshToken: string) {
        if (!token) {
            this.localStorage.removeItem(ExgBaseParamsConfig.storageKeys.storageTokenKey);
            this.localStorage.removeItem(ExgBaseParamsConfig.storageKeys.storageRefreshTokenKey);
            this.languageService.resetLanguage();
            this.currencyService.resetCurrency();
        } else {
            this.localStorage.setItem(ExgBaseParamsConfig.storageKeys.storageTokenKey, token);
            this.localStorage.setItem(ExgBaseParamsConfig.storageKeys.storageRefreshTokenKey, refreshToken);

            const culture = JwtUtils.decode<{ culture_name: string }>(token).culture_name;
            this.languageService.storeLanguage(culture);
        }
        this.translate.apply();
    }
}
