import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { RouteListItem } from '../../models/business/route/route-list-item.model';

export class RoutesAction {
    static readonly type = '[RoutesAll Page] GetRoutes';

    constructor(public payload: { employeeUid: string }) { }
}

export class RoutesSuccessAction {
    static readonly type = '[RoutesAll API] GetRoutes Success';

    constructor(public payload: { list: PaginationResult<RouteListItem>, pagination: PaginationRequest }) { }
}

export class RoutesFailAction {
    static readonly type = '[RoutesAll API] GetRoutes Fail';

    constructor(public payload: ErrorObject) { }
}

export class RoutesResetAction {
    static readonly type = '[RoutesAll Page] GetRoutes Reset';
}
