<div class="root" *ngFor="let dataItem of data">
    <div *ngTemplateOutlet="nodeTemplate; context: { $implicit: dataItem }"></div>
</div>

<ng-template #nodeTemplate let-node>
    
    <div class="tree-item">
        <exg-checkbox class="exg-checkbox" [value]="node.checked" [indeterminate]="node.indeterminate" (valueChange)="setAll(node, $event)"></exg-checkbox>
        <exg-button styleType="custom" (btnClick)="onNodeTitleClick(node)" [ngClass]="{'arrow-section': node?.childrens?.length}">
            <exg-icon *ngIf="node?.childrens?.length" class="arrow-icon" [class.expanded]="!node.showChilds" iconName="arrow-down1"></exg-icon>
        </exg-button>
        <exg-button [ngClass]="{'tree-item__bold': node?.childrens?.length}" class="tree-item__title" styleType="custom" (btnClick)="onNodeTitleClick(node)">{{ node.name }}</exg-button>
    </div>

    <div class="tree-items" *ngIf="node.showChilds">
        <ng-container *ngFor="let child of node.childrens" [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{ $implicit: child }"></ng-container>
    </div>

</ng-template>
