import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DrillingRigHeaderRightTabsEnum } from './rbc-drilling-rig-header-tabs.enum';

@Component({
    selector: 'rbc-drilling-rig-right-header',
    templateUrl: './rbc-drilling-rig-right-header.component.html',
    styleUrls: ['./rbc-drilling-rig-right-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcDrillingRigRightHeaderComponent {

    @Input() activeTab = DrillingRigHeaderRightTabsEnum.Transfers;
    @Input() readonly: boolean;

    @Output() readonly selectedTab = new EventEmitter<DrillingRigHeaderRightTabsEnum>();
    @Output() readonly addDrillingRigTransfer = new EventEmitter();
    @Output() readonly addDrillingRigBreakdown = new EventEmitter();

    public headerTabs = DrillingRigHeaderRightTabsEnum;

    public onClickTab(type: DrillingRigHeaderRightTabsEnum): void {
       this.selectedTab.emit(type);
    }

    public onBreakClick(): void {
        this.addDrillingRigBreakdown.emit();
    }

    public onMoveClick(): void {
        this.addDrillingRigTransfer.emit();
    }
}
