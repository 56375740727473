import { DateUtils } from "../burns-ui-framework/shared/utils/date-utils";

export class ExgParamsConfig {
    public static readonly logos = {
        emptyUserAvatar: '/assets/images/no_photo.png',
        emptyProductPhoto: '/assets/images/no_photo.png'
    };

    public static readonly photosUpload = {
        avatarSize: 400,
    };

    public static readonly signalR = {
        identity: {
            OnNameChanged: { hub: '/identity/identityhub', method: 'OnNameChanged' },
            OnEmailConfirmed: { hub: '/identity/identityhub', method: 'OnEmailConfirmed' },
            OnGroupMemberAdded: { hub: '/identity/identityhub', method: 'OnGroupMemberAdded' }
        }
    };

    public static readonly pagination = {
        defaultPageSize: 20,
        usersAllListPageSize: 25,
        employeesAllListPageSize: 20,
        companiesAllListPageSize: 20,
        workingAreaAllListPageSize: 20,
        routesAllListPageSize: 20,
        contractsAllListPageSize: 20,
        transportsAllListPageSize: 20,
        vehicleTransfersAllListPageSize: 20,
        transportBreakdownsAllListPageSize: 20,
        workReportAllListPageSize: 20,
        transportDrillingRigsAllListPageSize: 20,
        shiftsAllListPageSize: 20,
        drillingRigsAllListPageSize: 20,
        drillingRigTransfersAllListPageSize: 20,
        drillingRigBreakdownsAllListPageSize: 20
    };

    public static readonly rbcSettings = {
        rbcCompany: { uid: '32B09F70-675B-42F6-A52A-B3C92127F118', name: 'ООО "РБК"' },
        minCalendarDate: DateUtils.convertStringToEpoc('2013-01-01T00:00:00'),
    };
}
