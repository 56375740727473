import { Routes } from '@angular/router';
import { RoutingConfig } from './routing.config';

export const routes: Routes = [
    { path: '', redirectTo: RoutingConfig.routes.objects.route, pathMatch: 'full' },
    { path: RoutingConfig.routes.home.route, redirectTo: RoutingConfig.routes.objects.route },
    { path: RoutingConfig.routes.objects.route, loadChildren: () => import('../objects/objects.module').then(m => m.ObjectsModule) },
    { path: RoutingConfig.routes.auth.route, loadChildren: () => import('../burns-ui-framework/auth/auth.module').then(m => m.AuthModule) },
    { path: RoutingConfig.routes.companies.route, loadChildren: () => import('../companies/companies.module').then(m => m.CompaniesModule) },
    { path: RoutingConfig.routes.personnel.route, loadChildren: () => import('../personnel/personnel.module').then(m => m.PersonnelModule) },
    { path: RoutingConfig.routes.transport.route, loadChildren: () => import('../transport/transport.module').then(m => m.TransportModule) },
    { path: RoutingConfig.routes.analytics.route, loadChildren: () => import('../analytics/analytics.module').then(m => m.AnalyticsModule) },
    { path: RoutingConfig.routes.drillingRig.route, loadChildren: () => import('../drilling-rig/drilling-rig.module').then(m => m.DrillingRigModule) },
    { path: RoutingConfig.routes.version.route, loadChildren: () => import('../burns-ui-framework/version/version.module').then(m => m.VersionModule) },
    { path: RoutingConfig.routes.settings.route, loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule) },
    { path: RoutingConfig.routes.errors.route, loadChildren: () => import('../burns-ui-framework/errors/errors.module').then(m => m.ErrorsModule) }
];
