import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsCausedByCustomerStoppageBalanceSummarySuccessAction {
    static readonly type = '[AnalyticsCausedByCustomerStoppageBalanceSummary API] GetAnalyticsCausedByCustomerStoppageBalanceSummary Success';

    constructor(public payload: ActivityHours[]) { }
}

export class AnalyticsCausedByCustomerStoppageBalanceSummaryFailAction {
    static readonly type = '[AnalyticsCausedByCustomerStoppageBalanceSummary API] GetAnalyticsCausedByCustomerStoppageBalanceSummary Fail';

    constructor(public payload: ErrorObject) { }
}
