import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Route } from '../../models/business/route/route.model';

export class RouteAction {
    static readonly type = '[Route Page] GetRoute';

    constructor(public payload: { uid: string}) { }
}

export class RouteSuccessAction {
    static readonly type = '[Route API] GetRoute Success';

    constructor(public payload: Route) { }
}

export class RouteFailAction {
    static readonly type = '[Route API] GetRoute Fail';

    constructor(public payload: ErrorObject) { }
}

export class RouteResetAction {
    static readonly type = '[Route Page] GetRoute Reset';
}
