import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { WorkTypesUpdateAction, WorkTypesUpdateFailAction, WorkTypesUpdateResetAction, WorkTypesUpdateSuccessAction } from './work-types-update.actions';

export interface WorkTypesUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<WorkTypesUpdateStateModel>({
    name: 'WorkTypesUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class WorkTypesUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(WorkTypesUpdateAction) async workTypes(ctx: StateContext<WorkTypesUpdateStateModel>, action: WorkTypesUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateWorkTypes(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new WorkTypesUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WorkTypesUpdateFailAction(err)), 0));
    }

    @Action(WorkTypesUpdateSuccessAction) workTypesSuccess(ctx: StateContext<WorkTypesUpdateStateModel>, _: WorkTypesUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(WorkTypesUpdateFailAction) workTypesFail(ctx: StateContext<WorkTypesUpdateStateModel>, action: WorkTypesUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WorkTypesUpdateResetAction) workTypesReset(ctx: StateContext<WorkTypesUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
