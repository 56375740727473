import { CreateOrUpdateVehicleTransferAggregationRequest } from '../../models/business/vehicle-transfer/create-or-update-vehicleTransfer-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class VehicleTransferUpdateAction {
    static readonly type = '[VehicleTransfer Page] UpdateVehicleTransfer';

    constructor(public payload: { uid: string, request: CreateOrUpdateVehicleTransferAggregationRequest }) { }
}

export class VehicleTransferUpdateSuccessAction {
    static readonly type = '[VehicleTransfer API] UpdateVehicleTransfer Success';

    constructor(public payload: { uid: string, request: CreateOrUpdateVehicleTransferAggregationRequest }) { }
}

export class VehicleTransferUpdateFailAction {
    static readonly type = '[VehicleTransfer API] UpdateVehicleTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleTransferUpdateResetAction {
    static readonly type = '[VehicleTransfer Page] UpdateVehicleTransfer Reset';
}
