import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { ShiftCreateOrUpdateRequest } from '../../models/business/shifts/shift-create-or-update-request.model';
import { ShiftType } from '../../models/business/shifts/shift-type.enum';
import { Shift } from '../../models/business/shifts/shift.model';

export class ShiftCreateAction {
    static readonly type = '[Shifts Page] CreateShift';

    constructor(public payload: { date: number, shiftType: ShiftType, workingAreaUid: string, drillingRigUid: string, request: ShiftCreateOrUpdateRequest }) { }
}

export class ShiftCreateSuccessAction {
    static readonly type = '[Shift API] CreateShift Success';

    constructor(public payload: Shift) { }
}

export class ShiftCreateFailAction {
    static readonly type = '[Shift API] CreateShift Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftCreateResetAction {
    static readonly type = '[Shifts Page] CreateShift Reset';
}
