import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigListItem } from '../../models/business/drilling-rig/drilling-rig-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigsState, DrillingRigsStateModel } from './drilling-rigs.reducer';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigsSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([DrillingRigsState], (state: DrillingRigsStateModel) => state.pending);

    static readonly getDrillingRigs = createSelector([DrillingRigsState], (state: DrillingRigsStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([DrillingRigsState], (state: DrillingRigsStateModel) => state.retrieved);

    static readonly getError = createSelector([DrillingRigsState], (state: DrillingRigsStateModel) => state.error);

    static readonly getAllDrillingRigsLoaded = createSelector([DrillingRigsState], (state: DrillingRigsStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([DrillingRigsState], (state: DrillingRigsStateModel) => state.filter);

    static readonly getTotal = createSelector([DrillingRigsState], (state: DrillingRigsStateModel) => state.total);

    @Select(DrillingRigsSelectors.getPending) pending$: Observable<boolean>;

    @Select(DrillingRigsSelectors.getDrillingRigs) drillingRigs$: Observable<DrillingRigListItem[]>;

    @Select(DrillingRigsSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(DrillingRigsSelectors.getError) error$: Observable<ErrorObject>;

    @Select(DrillingRigsSelectors.getAllDrillingRigsLoaded) allDrillingRigsLoaded$: Observable<boolean>;

    @Select(DrillingRigsSelectors.getFilter) filter$: Observable<DrillingRigListGetFilterData>;

    @Select(DrillingRigsSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('DrillingRigsSelectors');
    }
}
