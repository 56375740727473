import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { SuggestionService } from '../../services/suggestion.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { FullnamePart } from '../../models/business/suggestions/fullname-part.enum';
import { SuggestionFullName } from '../../models/business/suggestions/suggestion-full-name.model';

import { FullNameSuggestionFailAction, FullNameSuggestionGetAction, FullNameSuggestionGetSuccessAction, FullNameSuggestionResetAction } from './fullname-suggestion.actions';

export interface FullNameSuggestionStateModel {
    entities: SuggestionFullName[];
    firstNames: SuggestionFullName[];
    secondNames: SuggestionFullName[];
    lastNames: SuggestionFullName[];
    pending: boolean;
    error: ErrorObject;
}

@State<FullNameSuggestionStateModel>({
    name: 'fullNameSuggestion',
    defaults: { pending: false, entities: null, firstNames: null, secondNames: null, lastNames: null, error: null }
})
@Injectable()
export class FullNameSuggestionState {
    constructor(private suggestionService: SuggestionService) { }

    @Action(FullNameSuggestionGetAction) contragentSuggestionGet(ctx: StateContext<FullNameSuggestionStateModel>, action: FullNameSuggestionGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.suggestionService.getFullName(action.payload.term, action.payload.parts)
            .then(resp => setTimeout(() => ctx.dispatch(new FullNameSuggestionGetSuccessAction({ list: resp, parts: action.payload.parts })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new FullNameSuggestionFailAction(err)), 0));
    }

    @Action(FullNameSuggestionGetSuccessAction) contragentSuggestionGetSuccess(ctx: StateContext<FullNameSuggestionStateModel>, action: FullNameSuggestionGetSuccessAction) {
        const state = ctx.getState();
        const firstNames = [];
        const secondNames = [];
        const lastNames = [];
        if ((action.payload.parts || []).some(x => x === FullnamePart.NAME)) {
            firstNames.push(...action.payload.list);
        }

        if ((action.payload.parts || []).some(x => x === FullnamePart.SURNAME)) {
            secondNames.push(...action.payload.list);
        }

        if ((action.payload.parts || []).some(x => x === FullnamePart.PATRONYMIC)) {
            lastNames.push(...action.payload.list);
        }

        ctx.setState({ ...state, pending: false, entities: action.payload.list, firstNames, secondNames, lastNames, error: null });
    }

    @Action(FullNameSuggestionFailAction) contragentSuggestionGetFail(ctx: StateContext<FullNameSuggestionStateModel>, action: FullNameSuggestionFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(FullNameSuggestionResetAction) contragentSuggestionGetReset(ctx: StateContext<FullNameSuggestionStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, firstNames: null, secondNames: null, lastNames: null, error: null });
    }
}
