<div class="container" [class.short-table]="hideFullName" (scroll)="onScroll($event)">
    <section class="left-column">
        <div>
            <div class="header left-columns" *ngIf="scheduleItems && scheduleItems[0]" >
                <div class="full-name">
                    <span title="{{'Full Name1' | translate}}">{{'Full Name1' | translate}}</span>
                </div>
                <div class="job-position">
                    <span title="{{'Job position' | translate}}">{{'Job position' | translate}}</span>
                </div>
                <div class="working-area">
                    <span title="{{'Working area' | translate}}">{{'Working area' | translate}}</span>
                </div>
            </div>
            <button class="employee_row left-columns btn" type="button" *ngFor="let scheduleItem of scheduleItems; trackBy: trackByEmployees" (click)="onOpenEmployee(scheduleItem.uid)">
                <div class="full-name">
                    <span title="{{scheduleItem.lastName}} {{scheduleItem.firstName}} {{scheduleItem.middleName}}">{{scheduleItem.firstName | rbcFullName: scheduleItem.lastName:scheduleItem.middleName}}</span>
                </div>
                <div class="job-position">
                    <span title="{{scheduleItem.jobPosition?.title}}">{{scheduleItem.jobPosition?.title}}</span>
                </div>
                <div class="working-area">
                    <span title="{{scheduleItem.workingArea?.title}}">{{scheduleItem.workingArea?.title}}</span>
                </div>
            </button>
        </div>
    </section>
    
    <exg-button class="nav-btn btn-left" [style.height.px]="getClientHeight()" styleType="custom" (btnClick)="scrollLeft()"></exg-button>
    <div #scheduleWrapper class="exg-wrapper" (swipeLeft)="scrollRight()" (swipeRight)="scrollLeft()">
        <div #scheduleContainer class="exg-container">
            <div class="header" *ngIf="scheduleItems && scheduleItems[0]">
                <div *ngFor="let monthToDisplay of monthsToDisplay;" class="date" [style.width.px]="baseWidthPx">
                    <span>{{monthToDisplay | exgDate:'MMM'}}</span>
                </div>
            </div>
            
            <rbc-employee-schedules-list-item *ngFor="let scheduleItem of scheduleItems; trackBy: trackByEmployees" class="employee_row" [scheduleItem]="scheduleItem" [monthsToDisplay]="monthsToDisplay" [baseWidthPx]="baseWidthPx" (openEmployeeRoute)="onOpenEmployeeRoute($event)"></rbc-employee-schedules-list-item>
        </div>     
    </div>
    <exg-button class="nav-btn btn-right" [style.height.px]="getClientHeight()" styleType="custom" (btnClick)="scrollRight()"></exg-button>
</div>
