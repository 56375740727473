import { SearchFilterData } from '../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../burns-ui-framework/shared/models/filters/sort-order.model';

export class DrillingRigListGetFilter extends SearchPaginationFilter {
    public sortField: string;
    public sortOrder: SortOrder;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: DrillingRigListGetFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.sortField = (args && args.filterData && args.filterData.sortField != null) ? args.filterData.sortField : 'category';
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
    }
}

export class DrillingRigListGetFilterData extends SearchFilterData {
    public sortField?: string;
    public sortOrder?: SortOrder;
}
