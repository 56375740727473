import { TransportBreakdownListGetFilterData } from '../../models/filters/transport-breakdown-list-get-filter.model';
import { TransportBreakdownListItem } from '../../models/business/transport-breakdown/transport-breakdown-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class TransportBreakdownsAction {
    static readonly type = '[TransportBreakdowns Page] GetTransportBreakdowns';

    constructor(public payload: PaginationRequest) { }
}

export class TransportBreakdownsSuccessAction {
    static readonly type = '[TransportBreakdowns API] GetTransportBreakdowns Success';

    constructor(public payload: { list: PaginationResult<TransportBreakdownListItem>, pagination: PaginationRequest }) { }
}

export class TransportBreakdownsFailAction {
    static readonly type = '[TransportBreakdowns API] GetTransportBreakdowns Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportBreakdownsResetAction {
    static readonly type = '[TransportBreakdowns Page] GetTransportBreakdowns Reset';
}

export class TransportBreakdownsSetFilterAction {
    static readonly type = '[TransportBreakdowns API] SetFilter';

    constructor(public payload: TransportBreakdownListGetFilterData) { }
}
