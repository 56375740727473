import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeCreateAction, EmployeeCreateFailAction, EmployeeCreateResetAction, EmployeeCreateSuccessAction } from './employee-create.actions';

export interface EmployeeCreateStateModel {
    pending: boolean;
    created: { uid: string };
    error: ErrorObject;
}

@State<EmployeeCreateStateModel>({
    name: 'employeeCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class EmployeeCreateState {
    constructor(private employeesService: EmployeesService) { }

    @Action(EmployeeCreateAction) async employeeCreate(ctx: StateContext<EmployeeCreateStateModel>, action: EmployeeCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.employeesService.createEmployee(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new EmployeeCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeeCreateFailAction(err)), 0));
    }

    @Action(EmployeeCreateSuccessAction) employeeCreateSuccess(ctx: StateContext<EmployeeCreateStateModel>, action: EmployeeCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(EmployeeCreateFailAction) employeeCreateFail(ctx: StateContext<EmployeeCreateStateModel>, action: EmployeeCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(EmployeeCreateResetAction) employeeCreateReset(ctx: StateContext<EmployeeCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
