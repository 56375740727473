import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigDeleteAction, DrillingRigDeleteResetAction } from './drilling-rig-delete.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigDeleteDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigDeleteDispatchers');
    }

    public dispatchDrillingRigDeleteAction(uid: string) {
        this.store.dispatch(new DrillingRigDeleteAction({ uid }));
    }

    public dispatchDrillingRigDeleteResetAction() {
        this.store.dispatch(new DrillingRigDeleteResetAction());
    }
}
