import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { VehicleTransfersService } from '../../services/vehicle-transfers.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { VehicleTransferUpdateAction, VehicleTransferUpdateFailAction, VehicleTransferUpdateResetAction, VehicleTransferUpdateSuccessAction } from './vehicle-transfer-update.actions';

export interface VehicleTransferUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<VehicleTransferUpdateStateModel>({
    name: 'vehicleTransferUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class VehicleTransferUpdateState {
    constructor(private vehicleTransfersService: VehicleTransfersService) { }

    @Action(VehicleTransferUpdateAction) async vehicleTransferUpdate(ctx: StateContext<VehicleTransferUpdateStateModel>, action: VehicleTransferUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.vehicleTransfersService.updateVehicleTransfer(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new VehicleTransferUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VehicleTransferUpdateFailAction(err)), 0));
    }

    @Action(VehicleTransferUpdateSuccessAction) vehicleTransferUpdateSuccess(ctx: StateContext<VehicleTransferUpdateStateModel>, _: VehicleTransferUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(VehicleTransferUpdateFailAction) vehicleTransferUpdateFail(ctx: StateContext<VehicleTransferUpdateStateModel>, action: VehicleTransferUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(VehicleTransferUpdateResetAction) vehicleTransferUpdateReset(ctx: StateContext<VehicleTransferUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
