import { Employee } from '../../models/business/employee/employee.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class SelfEmployeeAction {
    static readonly type = '[SelfEmployee Page] GetEmployee';

    constructor(public payload: { uid: string}) { }
}

export class SelfEmployeeSuccessAction {
    static readonly type = '[SelfEmployee API] GetEmployee Success';

    constructor(public payload: Employee) { }
}

export class SelfEmployeeFailAction {
    static readonly type = '[SelfEmployee API] GetEmployee Fail';

    constructor(public payload: ErrorObject) { }
}

export class SelfEmployeeResetAction {
    static readonly type = '[SelfEmployee Page] GetEmployee Reset';
}
