import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeAction, EmployeeResetAction } from './employee.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeDispatchers');
    }

    public dispatchEmployeeAction(uid: string) {
        this.store.dispatch(new EmployeeAction({ uid }));
    }

    public dispatchEmployeeResetAction() {
        this.store.dispatch(new EmployeeResetAction());
    }
}
