import { CreateOrUpdateTransportBreakdownAggregationRequest } from '../../models/business/transport-breakdown/create-or-update-transport-breakdown-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransportBreakdownCreateAction {
    static readonly type = '[TransportBreakdowns Page] CreateTransportBreakdown';

    constructor(public payload: { request: CreateOrUpdateTransportBreakdownAggregationRequest }) { }
}

export class TransportBreakdownCreateSuccessAction {
    static readonly type = '[TransportBreakdown API] CreateTransportBreakdown Success';

    constructor(public payload: { uid: string }) { }
}

export class TransportBreakdownCreateFailAction {
    static readonly type = '[TransportBreakdown API] CreateTransportBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportBreakdownCreateResetAction {
    static readonly type = '[TransportBreakdowns Page] CreateTransportBreakdown Reset';
}
