import { TransportListGetFilterData } from '../../models/filters/transport-list-get-filter.model';
import { TransportListItem } from '../../models/business/transport/transport-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class TransportsAction {
    static readonly type = '[Transports Page] GetTransports';

    constructor(public payload: PaginationRequest) { }
}

export class TransportsSuccessAction {
    static readonly type = '[Transports API] GetTransports Success';

    constructor(public payload: { list: PaginationResult<TransportListItem>, pagination: PaginationRequest }) { }
}

export class TransportsFailAction {
    static readonly type = '[Transports API] GetTransports Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportsResetAction {
    static readonly type = '[Transports Page] GetTransports Reset';
}

export class TransportsSetFilterAction {
    static readonly type = '[Transports API] SetFilter';

    constructor(public payload: TransportListGetFilterData) { }
}
