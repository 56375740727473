import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { VehicleTransfersService } from '../../services/vehicle-transfers.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { VehicleTransferCreateAction, VehicleTransferCreateFailAction, VehicleTransferCreateResetAction, VehicleTransferCreateSuccessAction } from './vehicle-transfer-create.actions';

export interface VehicleTransferCreateStateModel {
    pending: boolean;
    created: { uid: string };
    error: ErrorObject;
}

@State<VehicleTransferCreateStateModel>({
    name: 'vehicleTransferCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class VehicleTransferCreateState {
    constructor(private vehicleTransfersService: VehicleTransfersService) { }

    @Action(VehicleTransferCreateAction) async vehicleTransferCreate(ctx: StateContext<VehicleTransferCreateStateModel>, action: VehicleTransferCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.vehicleTransfersService.createVehicleTransfer(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new VehicleTransferCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VehicleTransferCreateFailAction(err)), 0));
    }

    @Action(VehicleTransferCreateSuccessAction) vehicleTransferCreateSuccess(ctx: StateContext<VehicleTransferCreateStateModel>, action: VehicleTransferCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(VehicleTransferCreateFailAction) vehicleTransferCreateFail(ctx: StateContext<VehicleTransferCreateStateModel>, action: VehicleTransferCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(VehicleTransferCreateResetAction) vehicleTransferCreateReset(ctx: StateContext<VehicleTransferCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
