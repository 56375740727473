import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DictionaryVehicleCategoriesAction {
    static readonly type = '[DictionaryVehicleCategories Page] GetDictionaryVehicleCategories';
}

export class DictionaryVehicleCategoriesSuccessAction {
    static readonly type = '[DictionaryVehicleCategories API] GetDictionaryVehicleCategories Success';

    constructor(public payload: DictionaryEntryListItem[]) { }
}

export class DictionaryVehicleCategoriesFailAction {
    static readonly type = '[DictionaryVehicleCategories API] GetDictionaryVehicleCategories Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryVehicleCategoriesResetAction {
    static readonly type = '[DictionaryVehicleCategories Page] GetDictionaryVehicleCategories Reset';
}
