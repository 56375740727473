import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DictionaryCasingTypesAction {
    static readonly type = '[DictionaryCasingTypes Page] GetDictionaryCasingTypes';
}

export class DictionaryCasingTypesSuccessAction {
    static readonly type = '[DictionaryCasingTypes API] GetDictionaryCasingTypes Success';

    constructor(public payload: DictionaryEntryListItem[]) { }
}

export class DictionaryCasingTypesFailAction {
    static readonly type = '[DictionaryCasingTypes API] GetDictionaryCasingTypes Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryCasingTypesResetAction {
    static readonly type = '[DictionaryCasingTypes Page] GetDictionaryCasingTypes Reset';
}
