import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { RoutingConfig } from '../../routing.config';

import { ExgDialogMode } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-mode.model';

import { TransportHeaderTabsEnum } from './transport-header-tabs.enum';
import { DialogResult } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { DrillingRigWorkbookGetFilterData } from '../../models/filters/drilling-rig-workbook-get-filter.model';
import { RbcExportComponent } from '../rbc-export/rbc-export.component';
import { Profile } from '../../../burns-ui-framework/shared/models/business/user/profile.model';

@Component({
    selector: 'rbc-transport-header',
    templateUrl: './rbc-transport-header.component.html',
    styleUrls: ['./rbc-transport-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RbcTransportHeaderComponent {
    @Input() profile: Profile;
    @Input() filter: { term?: string };
    @Input() readonly: boolean;
    @Input() pending: boolean;

    @Output() readonly search = new EventEmitter<{ term: string }>();
    @Output() readonly export = new EventEmitter<DrillingRigWorkbookGetFilterData>();
    @Output() readonly onClickButton = new EventEmitter();

    public routes = RoutingConfig.routes;
    public dialogMode = ExgDialogMode;
    public tabsEnum = TransportHeaderTabsEnum;
    public currentTab: TransportHeaderTabsEnum = TransportHeaderTabsEnum.Table;
    public componentData: { component: any, inputs: any };
    public showDialog: boolean;

    constructor(private router: Router) {
        this.setCurrentTab();
    }

    public onValueChange($event: string) {
        const term = $event.trim();
        if (term.length > 2 || this.filter.term) {
            this.search.emit({ term });
        }
    }

    public onExportClick() {
        this.componentData = { component: RbcExportComponent, inputs: { title: 'Transport report' } };
        this.showDialog = true;
    }

    public onDialogClose(event) {
        this.showDialog = false;
        if (event.dialogResult === DialogResult.Ok) {
            this.export.emit({
                workingArea: event.dataFromComponent.workingArea,
                dateFrom: event.dataFromComponent.dateFrom,
                dateTo: event.dataFromComponent.dateTo
            });
        }
    }

    public addClickButton(): void {
        this.onClickButton.emit();
    }

    private setCurrentTab(): void {
        switch (this.router.url) {
            case this.routes.transport.table.fullUrl: {
                this.currentTab = this.tabsEnum.Table;
                break;
            }
            case this.routes.transport.workReport.fullUrl: {
                this.currentTab = this.tabsEnum.WorkReport;
                break;
            }
        }
    }
}
