import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeUpdateState, EmployeeUpdateStateModel } from './employee-update.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeUpdateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeeUpdateState], (state: EmployeeUpdateStateModel) => state.pending);

    static readonly getUpdated = createSelector([EmployeeUpdateState], (state: EmployeeUpdateStateModel) => state.updated);

    static readonly getError = createSelector([EmployeeUpdateState], (state: EmployeeUpdateStateModel) => state.error);

    @Select(EmployeeUpdateSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeeUpdateSelectors.getUpdated) updated$: Observable<boolean>;

    @Select(EmployeeUpdateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('EmployeeUpdateSelectors');
    }
}
