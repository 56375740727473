import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Geocoding } from '../../models/business/geocoding/geocoding.model';

import { YandexGeocodingState, YandexGeocodingStateModel } from './yandex-geocoding.reducer';

@Injectable({
    providedIn: 'root'
})
export class YandexGeocodingSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([YandexGeocodingState], (state: YandexGeocodingStateModel) => state.pending);

    static readonly getPlaces = createSelector([YandexGeocodingState], (state: YandexGeocodingStateModel) => state.entities);

    static readonly getRetrieved = createSelector([YandexGeocodingState], (state: YandexGeocodingStateModel) => state.retrieved);

    static readonly getError = createSelector([YandexGeocodingState], (state: YandexGeocodingStateModel) => state.error);

    @Select(YandexGeocodingSelectors.getPending) pending$: Observable<boolean>;

    @Select(YandexGeocodingSelectors.getPlaces) places$: Observable<Geocoding[]>;

    @Select(YandexGeocodingSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(YandexGeocodingSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('YandexGeocodingSelectors');
    }
}
