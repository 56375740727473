import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { routes } from './shared-rbc/routes';

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',

        // initialNavigation should be enabled to make SSR flickering issue less annoying (although not fixing completely!!!)
        // https://github.com/angular/universal/issues/1184
        // https://github.com/angular/angular/issues/23427
        initialNavigation: 'enabledBlocking'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
