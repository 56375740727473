import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigTransfersService } from '../../services/drilling-rig-transfers.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigTransferUpdateAction, DrillingRigTransferUpdateFailAction, DrillingRigTransferUpdateResetAction, DrillingRigTransferUpdateSuccessAction } from './drilling-rig-transfer-update.actions';

export interface DrillingRigTransferUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<DrillingRigTransferUpdateStateModel>({
    name: 'drillingRigTransferUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class DrillingRigTransferUpdateState {
    constructor(private drillingRigTransfersService: DrillingRigTransfersService) { }

    @Action(DrillingRigTransferUpdateAction) async drillingRigTransferUpdate(ctx: StateContext<DrillingRigTransferUpdateStateModel>, action: DrillingRigTransferUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.drillingRigTransfersService.updateDrillingRigTransfer(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigTransferUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigTransferUpdateFailAction(err)), 0));
    }

    @Action(DrillingRigTransferUpdateSuccessAction) drillingRigTransferUpdateSuccess(ctx: StateContext<DrillingRigTransferUpdateStateModel>, _: DrillingRigTransferUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(DrillingRigTransferUpdateFailAction) drillingRigTransferUpdateFail(ctx: StateContext<DrillingRigTransferUpdateStateModel>, action: DrillingRigTransferUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(DrillingRigTransferUpdateResetAction) drillingRigTransferUpdateReset(ctx: StateContext<DrillingRigTransferUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
