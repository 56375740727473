import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { DialogResult } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { DrillingRigHeaderTabsEnum } from './drilling-rig-header-tabs.enum';
import { ExgDialogMode } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/exg-dialog-mode.model';
import { Profile } from '../../../burns-ui-framework/shared/models/business/user/profile.model';
import { RbcExportComponent } from '../rbc-export/rbc-export.component';
import { TransportWorkbookGetFilterData } from '../../models/filters/transport-workbook-get-filter.model';
import { Group } from '../../models/business/user/user-group.enum';

import { RoutingConfig } from '../../routing.config';

@Component({
    selector: 'rbc-drilling-rig-header',
    templateUrl: './rbc-drilling-rig-header.component.html',
    styleUrls: ['./rbc-drilling-rig-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RbcDrillingRigHeaderComponent implements OnChanges {
    @Input() profile: Profile;
    @Input() filter: { term?: string };
    @Input() pending: boolean;
    @Input() readonly: boolean;

    @Output() readonly search = new EventEmitter<{ term: string }>();
    @Output() readonly export = new EventEmitter<TransportWorkbookGetFilterData>();
    @Output() readonly onClickButton = new EventEmitter();

    public routes = RoutingConfig.routes;
    public dialogMode = ExgDialogMode;
    public tabsEnum = DrillingRigHeaderTabsEnum;
    public currentTab: DrillingRigHeaderTabsEnum = DrillingRigHeaderTabsEnum.Table;
    public currentUrl: string;
    public componentData: { component: any, inputs: any };
    public showDialog: boolean;
    public isAdmin$ = new BehaviorSubject<boolean>(false);

    constructor(private router: Router) {
        this.setCurrentTab();
        this.currentUrl = this.router.url;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.profile && this.profile) {
            this.isAdmin$.next(this.profile.user.groups.some(g => +g === +Group.Administrator || +g === +Group.Superadmin));
        }
    }

    public onValueChange($event: string) {
        const term = $event.trim();
        if (term.length > 2 || this.filter.term) {
            this.search.emit({ term });
        }
    }

    public addClickButton(): void {
        this.onClickButton.emit();
    }

    public onExportClick() {
        this.componentData = { component: RbcExportComponent, inputs: { title: 'Drilling rig report' } };
        this.showDialog = true;
    }

    public onDialogClose(event) {
        this.showDialog = false;
        if (event.dialogResult === DialogResult.Ok) {
            this.export.emit({
                workingArea: event.dataFromComponent.workingArea,
                dateFrom: event.dataFromComponent.dateFrom,
                dateTo: event.dataFromComponent.dateTo
            });
        }
    }

    private setCurrentTab(): void {
        switch (this.router.url) {
            case this.routes.drillingRig.table.fullUrl: {
                this.currentTab = this.tabsEnum.Table;
                break;
            }
            // case this.routes.drillingRig.workReport.fullUrl: {
            //     this.currentTab = this.tabsEnum.WorkReport;
            //     break;
            // }
            // case this.routes.drillingRig.drillingRigs.fullUrl: {
            //     this.currentTab = this.tabsEnum.DrillInstallations;
            //     break;
            // }
        }
    }
}
