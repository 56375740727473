import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class VehicleTransferDeleteAction {
    static readonly type = '[My VehicleTransfer Page] DeleteVehicleTransfer';

    constructor(public payload: { uid: string }) { }
}

export class VehicleTransferDeleteSuccessAction {
    static readonly type = '[My VehicleTransfers API] DeleteVehicleTransfer Success';

    constructor(public payload: { uid: string }) { }
}

export class VehicleTransferDeleteFailAction {
    static readonly type = '[My VehicleTransfers API] DeleteVehicleTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleTransferDeleteResetAction {
    static readonly type = '[My VehicleTransfer Page] DeleteVehicleTransfer Reset';
}
