import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigTransferDeleteAction {
    static readonly type = '[My DrillingRigTransfer Page] DeleteDrillingRigTransfer';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigTransferDeleteSuccessAction {
    static readonly type = '[My DrillingRigTransfers API] DeleteDrillingRigTransfer Success';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigTransferDeleteFailAction {
    static readonly type = '[My DrillingRigTransfers API] DeleteDrillingRigTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigTransferDeleteResetAction {
    static readonly type = '[My DrillingRigTransfer Page] DeleteDrillingRigTransfer Reset';
}
