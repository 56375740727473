import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { BaseSingletonService } from './base-singleton.service';

import { DialogResult } from '../../components/common/exg-dialog/shared/dialog-result.model';
import { ErrorObject } from '../../models/common/error-object.model';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService extends BaseSingletonService {

    constructor(public snackBar: MatSnackBar) {
        super('SnackbarService');
    }

    public async showInfo(message: string, duration?: number): Promise<DialogResult> {
        return Promise.resolve(null);
    }

    public async showWarning(message: string, duration?: number): Promise<DialogResult> {
        return Promise.resolve(null);
    }

    public async showError(message: string | ErrorObject | object, duration?: number): Promise<DialogResult> {
        return Promise.resolve(null);
    }

    public async showDelete(message: string, duration?: number): Promise<DialogResult> {
        return Promise.resolve(null);
    }
}
