import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { CreateOrUpdateEmployeeAggregationRequest } from '../../models/business/employee/create-or-update-employee-aggregation-request.model';

import { EmployeeUpdateAction, EmployeeUpdateResetAction } from './employee-update.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeUpdateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeUpdateDispatchers');
    }

    public dispatchEmployeeUpdateAction(uid: string, request: CreateOrUpdateEmployeeAggregationRequest) {
        this.store.dispatch(new EmployeeUpdateAction({ uid, request }));
    }

    public dispatchEmployeeUpdateResetAction() {
        this.store.dispatch(new EmployeeUpdateResetAction());
    }
}
