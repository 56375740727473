import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { DictionaryEntryCreateOrUpdate } from '../../models/business/dictionaries/dictionary-entry-create-or-update.model';

export class WorkTypesUpdateAction {
    static readonly type = '[WorkTypesUpdate Page] WorkTypesUpdate';

    constructor(public payload: { request: DictionaryEntryCreateOrUpdate[] }) { }
}

export class WorkTypesUpdateSuccessAction {
    static readonly type = '[WorkTypesUpdate API] WorkTypesUpdate Success';
}

export class WorkTypesUpdateFailAction {
    static readonly type = '[WorkTypesUpdate API] WorkTypesUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class WorkTypesUpdateResetAction {
    static readonly type = '[WorkTypesUpdate Page] WorkTypesUpdate Reset';
}
