import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { DrillSpeedChangeListItem } from '../../models/business/analytics/drill-speed-change-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsDetailsDrillSpeedChangeDynamicAction {
    static readonly type = '[AnalyticsDetailsDrillSpeedChangeDynamic Page] GetAnalyticsDetailsDrillSpeedChangeDynamic Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsDrillSpeedChangeDynamicSuccessAction {
    static readonly type = '[AnalyticsDetailsDrillSpeedChangeDynamic API] GetAnalyticsDetailsDrillSpeedChangeDynamic Success';

    constructor(public payload: DrillSpeedChangeListItem[]) { }
}

export class AnalyticsDetailsDrillSpeedChangeDynamicFailAction {
    static readonly type = '[AnalyticsDetailsDrillSpeedChangeDynamic API] GetAnalyticsDetailsDrillSpeedChangeDynamic Fail';

    constructor(public payload: ErrorObject) { }
}
