import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { YandexGeocodingAction, YandexGeocodingResetAction } from './yandex-geocoding.actions';

@Injectable({
    providedIn: 'root'
})
export class YandexGeocodingDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('YandexGeocodingDispatchers');
    }

    public dispatchGeocodingAction(geocode: string ) {
        this.store.dispatch(new YandexGeocodingAction({ geocode }));
    }

    public dispatchGeocodingResetAction() {
        this.store.dispatch(new YandexGeocodingResetAction());
    }
}
