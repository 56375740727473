import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { CreateOrUpdateTransportBreakdownAggregationRequest } from '../models/business/transport-breakdown/create-or-update-transport-breakdown-aggregation-request.model';
import { TransportBreakdownListGetFilter } from '../models/filters/transport-breakdown-list-get-filter.model';
import { TransportBreakdownListItem } from '../models/business/transport-breakdown/transport-breakdown-list-item.model';
import { TransportBreakdown } from '../models/business/transport-breakdown/transport-breakdown.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';


@Injectable({
    providedIn: 'root'
})
export class TransportBreakdownsService extends BaseSingletonService {

    private settings: {
        service: {
            getTransportBreakdown: string;
            getTransportBreakdowns: string;
            createTransportBreakdown: string;
            updateTransportBreakdown: string;
            deleteTransportBreakdown: string;
        }
    };

    constructor(private http: HttpService) {
        super('TransportBreakdownsService');
        this.settings = {
            service: {
                getTransportBreakdown: '/drilling/v1/vehicle-breakdowns/{0}',
                getTransportBreakdowns: '/drilling/v1/vehicle-breakdowns?term={0}&vehicleUid={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                createTransportBreakdown: '/drilling/v1/vehicle-breakdowns',
                updateTransportBreakdown: '/drilling/v1/vehicle-breakdowns/{0}',
                deleteTransportBreakdown: '/drilling/v1/vehicle-breakdowns/{0}'
            }
        };
    }

    public async getTransportBreakdown(uid: string): Promise<TransportBreakdown> {
        return this.http.get<{ data: TransportBreakdown }>(String.format(this.settings.service.getTransportBreakdown, uid))
            .then(res => res.data);
    }

    public async getTransportBreakdowns(filter: TransportBreakdownListGetFilter): Promise<PaginationResult<TransportBreakdownListItem>> {
        return this.http.get<PaginationResult<TransportBreakdownListItem>>(String.format(this.settings.service.getTransportBreakdowns, filter.term, filter.vehicleUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createTransportBreakdown(request: CreateOrUpdateTransportBreakdownAggregationRequest): Promise<{ uid: string }> {
        return this.http.post<{ data: string }>(String.format(this.settings.service.createTransportBreakdown), request)
            .then(res => ({ uid: res.data }));
    }

    public async updateTransportBreakdown(uid: string, request: CreateOrUpdateTransportBreakdownAggregationRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateTransportBreakdown, uid), request);
    }

    public async deleteTransportBreakdown(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteTransportBreakdown, uid));
    }
}
