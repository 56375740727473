import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftsService } from '../../services/shifts.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Shift } from '../../models/business/shifts/shift.model';

import { ShiftCreateAction, ShiftCreateFailAction, ShiftCreateResetAction, ShiftCreateSuccessAction } from './shift-create.actions';

export interface ShiftCreateStateModel {
    pending: boolean;
    created: Shift;
    error: ErrorObject;
}

@State<ShiftCreateStateModel>({
    name: 'shiftCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class ShiftCreateState {
    constructor(private shiftsService: ShiftsService) { }

    @Action(ShiftCreateAction) async shiftCreate(ctx: StateContext<ShiftCreateStateModel>, action: ShiftCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.shiftsService.createShift(action.payload.date, action.payload.shiftType, action.payload.workingAreaUid, action.payload.drillingRigUid, action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new ShiftCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftCreateFailAction(err)), 0));
    }

    @Action(ShiftCreateSuccessAction) shiftCreateSuccess(ctx: StateContext<ShiftCreateStateModel>, action: ShiftCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(ShiftCreateFailAction) shiftCreateFail(ctx: StateContext<ShiftCreateStateModel>, action: ShiftCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(ShiftCreateResetAction) shiftCreateReset(ctx: StateContext<ShiftCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
