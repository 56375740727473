import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { LossOfProfitListItem } from '../../models/business/analytics/loss-of-profit-list-item.model';

export class AnalyticsLossOfProfitSuccessAction {
    static readonly type = '[AnalyticsLossOfProfit API] GetAnalyticsLossOfProfit Success';

    constructor(public payload: LossOfProfitListItem[]) { }
}

export class AnalyticsLossOfProfitFailAction {
    static readonly type = '[AnalyticsLossOfProfit API] GetAnalyticsLossOfProfit Fail';

    constructor(public payload: ErrorObject) { }
}
