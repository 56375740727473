import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeUpdateAction, EmployeeUpdateFailAction, EmployeeUpdateResetAction, EmployeeUpdateSuccessAction } from './employee-update.actions';

export interface EmployeeUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<EmployeeUpdateStateModel>({
    name: 'employeeUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class EmployeeUpdateState {
    constructor(private employeesService: EmployeesService) { }

    @Action(EmployeeUpdateAction) async employeeUpdate(ctx: StateContext<EmployeeUpdateStateModel>, action: EmployeeUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.employeesService.updateEmployee(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new EmployeeUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeeUpdateFailAction(err)), 0));
    }

    @Action(EmployeeUpdateSuccessAction) employeeUpdateSuccess(ctx: StateContext<EmployeeUpdateStateModel>, _: EmployeeUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(EmployeeUpdateFailAction) employeeUpdateFail(ctx: StateContext<EmployeeUpdateStateModel>, action: EmployeeUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(EmployeeUpdateResetAction) employeeUpdateReset(ctx: StateContext<EmployeeUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
