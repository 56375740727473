import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';

import { Employee } from '../../../shared-rbc/models/business/employee/employee.model';

import { ExgParamsConfig } from '../../exg-params.config';
import { RoutingConfig } from '../../../shared-rbc/routing.config';

@Component({
    selector: 'rbc-personnel-right-header',
    templateUrl: './rbc-personnel-right-header.component.html',
    styleUrls: ['./rbc-personnel-right-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcPersonnelRightHeaderComponent implements OnChanges, OnDestroy {

    @Input() employee: Employee;
    @Input() filter: { dateFrom?: number, dateTo?: number };

    @Output() readonly filterChanged = new EventEmitter<{ dateFrom: number, dateTo: number }>();
    @Output() readonly backClick = new EventEmitter<void>();

    public formPath: string[];
    public routePath: string[];
    public schedulePath: string[];

    public datepickerData: { begin: number, end: number };

    public routingData: { isForm: boolean, rotues: boolean, schedule: boolean };
    public readonly minCalendarDate = ExgParamsConfig.rbcSettings.minCalendarDate;

    private unsubscribe$ = new Subject();

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data: { isForm: boolean, rotues: boolean, schedule: boolean }) => {
            this.routingData = data;
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.employee && this.employee) {
            this.formPath = [RoutingConfig.routes.personnel.edit.fullUrl, this.employee.uid];
            this.routePath = RoutingConfig.dynamicRoutes.personnelRoutes.baseUrl(this.employee.uid);
            this.schedulePath = RoutingConfig.dynamicRoutes.personnelSchedule.baseUrl(this.employee.uid);
        }

        if (changes.filter && this.filter) {
            this.datepickerData = { begin: this.filter.dateFrom, end: this.filter.dateTo };
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    public onDatesRangeChanged($event: { begin: number, end: number }) {
        if ($event.end < $event.begin) {
            this.datepickerData = { begin: $event.begin, end: $event.begin };
            return;
        }

        this.filterChanged.emit({ ...this.filter, dateFrom: $event.begin, dateTo: $event.end });
    }

    public onBackClick() {
        this.backClick.emit();
    }
}
