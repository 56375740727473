import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftsService } from '../../services/shifts.service';

import { CountryShiftsSummaryListGetFilter } from '../../models/filters/country-shifts-summary-list-get-filter.model';
import { CountryShiftsSummaryListItem } from '../../models/business/shifts/country-shifts-summary-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WorkingAreaShiftsSummaryListGetFilter, WorkingAreaShiftsSummaryListGetFilterData } from '../../models/filters/working-area-shifts-summary-list-get-filter.model';
import { WorkingAreaShiftsSummaryListItem } from '../../models/business/shifts/working-area-shifts-summary-list-item.model';

import { ShiftsCountriesAction, ShiftsCountriesExpandAction, ShiftsCountriesFailAction, ShiftsCountriesResetAction, ShiftsCountriesSetFilterAction, ShiftsCountriesSuccessAction, ShiftsWorkingAreasAction, ShiftsWorkingAreasSuccessAction } from './shifts-countries.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface ShiftsCountriesStateModel {
    entities: CountryShiftsSummaryListItem[];
    totalEntity: CountryShiftsSummaryListItem;
    subList: { [code: string]: WorkingAreaShiftsSummaryListItem[] };
    subListPending: { [code: string]: boolean };
    subListRetrieved: { [code: string]: boolean };
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: WorkingAreaShiftsSummaryListGetFilterData;
    expanded: { [code: string]: boolean; };
}

@State<ShiftsCountriesStateModel>({
    name: 'ShiftsCountries',
    defaults: { pending: false, entities: [], totalEntity: null, subList: {}, subListPending: {}, subListRetrieved: {}, retrieved: false, error: null, filter: {}, expanded: {} }
})
@Injectable()
export class ShiftsCountriesState {
    constructor(private shiftsService: ShiftsService) { }

    @Action([ShiftsCountriesAction]) shiftsGet(ctx: StateContext<ShiftsCountriesStateModel>, _: ShiftsCountriesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CountryShiftsSummaryListGetFilter({ pageIndex: 0, pageSize: 9999, filterData: state.filter });

        return this.shiftsService.getShiftsCountries(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftsCountriesSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftsCountriesFailAction(err)), 0));
    }

    @Action(ShiftsCountriesSuccessAction) shiftsGetSuccess(ctx: StateContext<ShiftsCountriesStateModel>, action: ShiftsCountriesSuccessAction) {
        const state = ctx.getState();
        const indexOfTotal = action.payload.list.findIndex(x => !x.country);
        const entities = indexOfTotal > -1 ? action.payload.list.filter((_, index) => index !== indexOfTotal) : action.payload.list;
        const totalEntity = indexOfTotal > -1 ? action.payload.list[indexOfTotal] : null;
        const countryCodes = entities.map(e => e?.country?.code).filter(code => code);

        ctx.setState({ ...state, pending: false, entities, totalEntity, retrieved: true, error: null });
        if (countryCodes.length > 0) {
            countryCodes.forEach(countryCode => {
                setTimeout(() => ctx.dispatch(new ShiftsWorkingAreasAction({ countryCode })), 0);
            });
        }
    }

    @Action([ShiftsWorkingAreasAction]) shiftsWorkAreasGet(ctx: StateContext<ShiftsCountriesStateModel>, action: ShiftsWorkingAreasAction) {
        const state = ctx.getState();
        const subListPending = { ...state.subListPending };
        subListPending[action.payload.countryCode] = true;
        ctx.setState({ ...state, error: null, subListPending });

        const filter = new WorkingAreaShiftsSummaryListGetFilter({ pageIndex: 0, pageSize: 9999, filterData: { ...state.filter, countryCode: action.payload.countryCode } });

        return this.shiftsService.getShiftsWorkingAreas(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftsWorkingAreasSuccessAction({ list: resp, countryCode: action.payload.countryCode })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftsCountriesFailAction(err)), 0));
    }

    @Action(ShiftsWorkingAreasSuccessAction) shiftsWorkAreasGetSuccess(ctx: StateContext<ShiftsCountriesStateModel>, action: ShiftsWorkingAreasSuccessAction) {
        const state = ctx.getState();
        const expanded = { ...state.expanded };
        const subList = { ...state.subList };
        const subListPending = { ...state.subListPending };
        const subListRetrieved = { ...state.subListRetrieved };
        subList[action.payload.countryCode] = action.payload.list;
        expanded[action.payload.countryCode] = true;
        subListPending[action.payload.countryCode] = false;
        subListRetrieved[action.payload.countryCode] = true;

        ctx.setState({ ...state, subList, subListPending, subListRetrieved, expanded });
    }

    @Action(ShiftsCountriesFailAction) shiftsGetFail(ctx: StateContext<ShiftsCountriesStateModel>, action: ShiftsCountriesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftsCountriesResetAction) shiftsGetReset(ctx: StateContext<ShiftsCountriesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], subList: {}, subListPending: {}, subListRetrieved: {}, retrieved: false, error: null, filter: {} });
    }

    @Action(ShiftsCountriesExpandAction) shiftsExpand(ctx: StateContext<ShiftsCountriesStateModel>, action: ShiftsCountriesExpandAction) {
        const state = ctx.getState();

        const expanded = { ...state.expanded };
        expanded[action.payload.code] = !expanded[action.payload.code];
        ctx.setState({ ...state, expanded });
    }

    @Action(ShiftsCountriesSetFilterAction) shiftsSetFilter(ctx: StateContext<ShiftsCountriesStateModel>, action: ShiftsCountriesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter });

            setTimeout(() => ctx.dispatch(new ShiftsCountriesAction()), 0);

            for (const countryCode of Object.keys(state.subList)) {
                setTimeout(() => ctx.dispatch(new ShiftsWorkingAreasAction({ countryCode })), 0);
            }
        }
    }

    private updateExpandedState(state, entities) {
        const expanded = { ...state.expanded };
        entities.forEach(v => {
            const code = v?.country?.code;
            if (code) {
                expanded[code] = true;
            }
        });
        return expanded;
    }
}
