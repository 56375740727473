import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { SelfEmployeeAction, SelfEmployeeResetAction } from './self-employee.actions';

@Injectable({
    providedIn: 'root'
})
export class SelfEmployeeDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('SelfEmployeeDispatchers');
    }

    public dispatchEmployeeAction(uid: string) {
        this.store.dispatch(new SelfEmployeeAction({ uid }));
    }

    public dispatchEmployeeResetAction() {
        this.store.dispatch(new SelfEmployeeResetAction());
    }
}
