import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransportBreakdownsService } from '../../services/transport-breakdowns.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportBreakdownDeleteAction, TransportBreakdownDeleteFailAction, TransportBreakdownDeleteResetAction, TransportBreakdownDeleteSuccessAction } from './transport-breakdown-delete.actions';

export interface TransportBreakdownDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<TransportBreakdownDeleteStateModel>({
    name: 'transportBreakdownDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class TransportBreakdownDeleteState {
    constructor(private transportBreakdownsService: TransportBreakdownsService) { }

    @Action(TransportBreakdownDeleteAction) async transportBreakdownDelete(ctx: StateContext<TransportBreakdownDeleteStateModel>, action: TransportBreakdownDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.transportBreakdownsService.deleteTransportBreakdown(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new TransportBreakdownDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new TransportBreakdownDeleteFailAction(error)), 0));
    }

    @Action(TransportBreakdownDeleteSuccessAction) transportBreakdownDeleteSuccess(ctx: StateContext<TransportBreakdownDeleteStateModel>, _: TransportBreakdownDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(TransportBreakdownDeleteFailAction) transportBreakdownDeleteFail(ctx: StateContext<TransportBreakdownDeleteStateModel>, action: TransportBreakdownDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransportBreakdownDeleteResetAction) transportBreakdownDeleteReset(ctx: StateContext<TransportBreakdownDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
