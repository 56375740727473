import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsCausedByContractorStoppageBalanceSummarySuccessAction {
    static readonly type = '[AnalyticsCausedByContractorStoppageBalanceSummary API] GetAnalyticsCausedByContractorStoppageBalanceSummary Success';

    constructor(public payload: ActivityHours[]) { }
}

export class AnalyticsCausedByContractorStoppageBalanceSummaryFailAction {
    static readonly type = '[AnalyticsCausedByContractorStoppageBalanceSummary API] GetAnalyticsCausedByContractorStoppageBalanceSummary Fail';

    constructor(public payload: ErrorObject) { }
}
