import { ContractListItem } from '../../models/business/contract/contract-list-item.model';
import { ContractsListFilterData } from '../../models/filters/contracts-list-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';


export class CompanyContractsAction {
    static readonly type = '[CompanyContracts Page] GetCompanyContracts';

    constructor(public payload: PaginationRequest) { }
}

export class CompanyContractsSuccessAction {
    static readonly type = '[CompanyContracts API] GetCompanyContracts Success';

    constructor(public payload: { list: PaginationResult<ContractListItem>, pagination: PaginationRequest }) { }
}

export class CompanyContractsFailAction {
    static readonly type = '[CompanyContracts API] GetCompanyContracts Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompanyContractsResetAction {
    static readonly type = '[CompanyContracts Page] GetCompanyContracts Reset';
}

export class CompanyContractsSetFilterAction {
    static readonly type = '[CompanyContracts API] SetFilter';

    constructor(public payload: ContractsListFilterData) { }
}
