import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { DrillingRigWorkbookGetFilterData } from '../../models/filters/drilling-rig-workbook-get-filter.model';

export class DrillingRigWorkbookExportAction {
    static readonly type = '[DrillingRigWorkbookExport Page] GetDrillingRigWorkbookExport';

    constructor(public payload: { filter: DrillingRigWorkbookGetFilterData }) { }
}

export class DrillingRigWorkbookExportSuccessAction {
    static readonly type = '[DrillingRigWorkbookExport API] GetDrillingRigWorkbookExport Success';
}

export class DrillingRigWorkbookExportFailAction {
    static readonly type = '[DrillingRigWorkbookExport API] GetDrillingRigWorkbookExport Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigWorkbookExportResetAction {
    static readonly type = '[DrillingRigWorkbookExport Page] GetDrillingRigWorkbookExport Reset';
}
