import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransportsService } from '../../services/transports.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportUpdateAction, TransportUpdateFailAction, TransportUpdateResetAction, TransportUpdateSuccessAction } from './transport-update.actions';

export interface TransportUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<TransportUpdateStateModel>({
    name: 'transportUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class TransportUpdateState {
    constructor(private transportsService: TransportsService) { }

    @Action(TransportUpdateAction) async transportUpdate(ctx: StateContext<TransportUpdateStateModel>, action: TransportUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.transportsService.updateTransport(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new TransportUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportUpdateFailAction(err)), 0));
    }

    @Action(TransportUpdateSuccessAction) transportUpdateSuccess(ctx: StateContext<TransportUpdateStateModel>, _: TransportUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(TransportUpdateFailAction) transportUpdateFail(ctx: StateContext<TransportUpdateStateModel>, action: TransportUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(TransportUpdateResetAction) transportUpdateReset(ctx: StateContext<TransportUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
