import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RoutesService } from '../../services/routes.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { RouteUpdateAction, RouteUpdateFailAction, RouteUpdateResetAction, RouteUpdateSuccessAction } from './route-update.actions';

export interface RouteUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<RouteUpdateStateModel>({
    name: 'routeUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class RouteUpdateState {
    constructor(private routesService: RoutesService) { }

    @Action(RouteUpdateAction) async routeUpdate(ctx: StateContext<RouteUpdateStateModel>, action: RouteUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.routesService.updateRoute(action.payload.uid, action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new RouteUpdateSuccessAction({ uid: action.payload.uid, request: res })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RouteUpdateFailAction(err)), 0));
    }

    @Action(RouteUpdateSuccessAction) routeUpdateSuccess(ctx: StateContext<RouteUpdateStateModel>, _: RouteUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(RouteUpdateFailAction) routeUpdateFail(ctx: StateContext<RouteUpdateStateModel>, action: RouteUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(RouteUpdateResetAction) routeUpdateReset(ctx: StateContext<RouteUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
