import { SearchFilterData } from '../../../burns-ui-framework/shared/models/filters/search-filter-data.model';
import { SearchPaginationFilter } from '../../../burns-ui-framework/shared/models/filters/search-pagination-filter.model';
import { SortOrder } from '../../../burns-ui-framework/shared/models/filters/sort-order.model';
import { UserFilterTypes } from '../business/user/user-filter-types.model';
import { Group } from '../business/user/user-group.enum';
import { UserSortingFields } from '../business/user/user-sorting-fields.model';

export class UserFilter extends SearchPaginationFilter {
    public startDate: number;
    public endDate: number;
    public termField: string;
    public sortField: string;
    public sortOrder: SortOrder;
    public groups: Group[];
    public isFired?: boolean;

    constructor(args?: { pageIndex?: number, pageSize?: number, filterData?: UserFilterData }) {
        super(args ? args.pageIndex : null, args ? args.pageSize : null, (args && args.filterData) ? args.filterData.term : null);
        this.startDate = (args && args.filterData && args.filterData.startDate != null) ? args.filterData.startDate : null;
        this.endDate = (args && args.filterData && args.filterData.endDate != null) ? args.filterData.endDate : null;
        this.termField = (args && args.filterData && args.filterData.termField) ? args.filterData.termField : UserFilterTypes.FullName;
        this.sortField = (args && args.filterData && args.filterData.sortField  != null) ? args.filterData.sortField : UserSortingFields.FullName;
        this.sortOrder = (args && args.filterData && args.filterData.sortOrder != null) ? args.filterData.sortOrder : SortOrder.Asc;
        this.groups = (args && args.filterData && args.filterData.groups != null) ? args.filterData.groups : [];
        this.isFired = (args && args.filterData && args.filterData.isFired != null) ? args.filterData.isFired : false;
    }
}

export class UserFilterData extends SearchFilterData {
    public startDate?: number;
    public endDate?: number;
    public termField?: string;
    public sortField?: string;
    public sortOrder?: SortOrder;
    public groups?: Group[];
    public isFired?: boolean;
}
