import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DictionaryVehicleTypesAction, DictionaryVehicleTypesResetAction } from './dictionary-vehicle-types.actions';

@Injectable({
    providedIn: 'root'
})
export class DictionaryVehicleTypesDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DictionaryVehicleTypesDispatchers');
    }

    public dispatchDictionaryVehicleTypesAction() {
        this.store.dispatch(new DictionaryVehicleTypesAction());
    }

    public dispatchDictionaryVehicleTypesResetAction() {
        this.store.dispatch(new DictionaryVehicleTypesResetAction());
    }
}
