import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

export class AnalyticsWorkingAreaSuccessAction {
    static readonly type = '[AnalyticsWorkingArea API] GetAnalyticsWorkingArea Success';

    constructor(public payload: PaginationResult<WorkingAreaListItem>) { }
}

export class AnalyticsWorkingAreaFailAction {
    static readonly type = '[AnalyticsWorkingArea API] GetAnalyticsWorkingArea Fail';

    constructor(public payload: ErrorObject) { }
}
