import { Directive, OnDestroy } from '@angular/core';

@Directive()
export class BaseSingletonService implements OnDestroy {

    public static initialized = {};

    constructor(protected serviceName: string) {
        if (BaseSingletonService.initialized[this.serviceName]) {
            if (!global['__SERVER__']) {
                throw new Error(`Service already initialized! (${this.serviceName})`);
            }
        }
        BaseSingletonService.initialized[serviceName || this.constructor.name] = true;
    }

    public ngOnDestroy() {
        BaseSingletonService.initialized[this.serviceName] = false;
    }
}
