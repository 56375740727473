import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsProductiveBalanceSummarySuccessAction {
    static readonly type = '[AnalyticsProductiveBalanceSummary API] GetAnalyticsProductiveBalanceSummary Success';

    constructor(public payload: ActivityHours[]) { }
}

export class AnalyticsProductiveBalanceSummaryFailAction {
    static readonly type = '[AnalyticsProductiveBalanceSummary API] GetAnalyticsProductiveBalanceSummary Fail';

    constructor(public payload: ErrorObject) { }
}
