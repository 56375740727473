import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRig } from '../../models/business/drilling-rig/drilling-rig.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigState, DrillingRigStateModel } from './drilling-rig.reducer';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([DrillingRigState], (state: DrillingRigStateModel) => state.pending);

    static readonly getDrillingRig = createSelector([DrillingRigState], (state: DrillingRigStateModel) => state.entity);

    static readonly getError = createSelector([DrillingRigState], (state: DrillingRigStateModel) => state.error);

    @Select(DrillingRigSelectors.getPending) pending$: Observable<boolean>;

    @Select(DrillingRigSelectors.getDrillingRig) drillingRig$: Observable<DrillingRig>;

    @Select(DrillingRigSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('DrillingRigSelectors');
    }
}
