<a exgRipple class="menu_el header" [routerLink]="['/']" routerLinkActive="active" >
    <exg-icon class="logo-icon" [class.logo-square]="headerSettings?.logoSquare" [class.logo-vertical]="headerSettings?.logoVertical" iconName="logo2"></exg-icon>
</a>
<a exgRipple class="menu_el" *ngFor="let menuItem of menu; trackBy: trackByMenu" [routerLink]="[menuItem.route]" [class.bottom]="menuItem.bottom" routerLinkActive="active" >
    <exg-icon class="menu_icon icon_stroke" [iconName]="menuItem.icon"></exg-icon>
    <span>{{menuItem.title | translate}}</span>
</a>

<a exgRipple class="profile" [class.disable-margin]="disableMargin" *ngIf="profile" (click)="onProfileClick()">
    <exg-image class="profile-avatar" [image]="profile.user.avatarCroppedUrl" [alternativeImages]="[profile.user.avatarUrl, emptyAvatar]" [isRound]="true"></exg-image>
    <div class="profile-info">
        <span class="profile-name">{{'Profile' | translate}}</span>
    </div>
<a>