import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsService } from '../../services/analytics.service';

import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilter, AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { AnalyticsCausedByCustomerStoppageBalanceSummaryFailAction, AnalyticsCausedByCustomerStoppageBalanceSummarySuccessAction } from './analytics-caused-by-customer-stoppage-balance-summary.actions';
import { AnalyticsGetAction, AnalyticsResetAction } from './analytics.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface AnalyticsCausedByCustomerStoppageBalanceSummaryStateModel {
    entities: ActivityHours[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: AnalyticGetFilterData;
}

@State<AnalyticsCausedByCustomerStoppageBalanceSummaryStateModel>({
    name: 'AnalyticsCausedByCustomerStoppageBalanceSummary',
    defaults: { pending: false, entities: [], retrieved: false, error: null, filter: {} }
})
@Injectable()
export class AnalyticsCausedByCustomerStoppageBalanceSummaryState {
    constructor(private analyticsService: AnalyticsService) { }

    @Action(AnalyticsGetAction) analyticsSetFilter(ctx: StateContext<AnalyticsCausedByCustomerStoppageBalanceSummaryStateModel>, action: AnalyticsGetAction) {
        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            ctx.setState({ ...state, filter: filterData, retrieved: false });

            const filter = new AnalyticGetFilter({ filterData });

            return this.analyticsService.getCausedByCustomerStoppageBalanceSummary(filter)
                .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsCausedByCustomerStoppageBalanceSummarySuccessAction(resp)), 0))
                .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsCausedByCustomerStoppageBalanceSummaryFailAction(err)), 0));
        }
    }

    @Action(AnalyticsCausedByCustomerStoppageBalanceSummarySuccessAction) lossOfProfitGetSuccess(ctx: StateContext<AnalyticsCausedByCustomerStoppageBalanceSummaryStateModel>, action: AnalyticsCausedByCustomerStoppageBalanceSummarySuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(AnalyticsCausedByCustomerStoppageBalanceSummaryFailAction) lossOfProfitGetFail(ctx: StateContext<AnalyticsCausedByCustomerStoppageBalanceSummaryStateModel>, action: AnalyticsCausedByCustomerStoppageBalanceSummaryFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsResetAction) analyticsGetReset(ctx: StateContext<AnalyticsCausedByCustomerStoppageBalanceSummaryStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
