import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { Employee } from '../../models/business/employee/employee.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Group } from '../../models/business/user/user-group.enum';

import { ProfileSuccessAction } from '../../../burns-ui-framework/shared/store/profile/profile.actions';

import { SelfEmployeeAction, SelfEmployeeFailAction, SelfEmployeeResetAction, SelfEmployeeSuccessAction } from './self-employee.actions';

export interface SelfEmployeeStateModel {
    entity: Employee;
    pending: boolean;
    readonly: boolean;
    user: boolean;
    viewer: boolean;
    error: ErrorObject;
}

@State<SelfEmployeeStateModel>({
    name: 'SelfEmployee',
    defaults: { pending: false, readonly: false, user: false, viewer: false, entity: null, error: null }
})
@Injectable()
export class SelfEmployeeState {
    constructor(private employeesService: EmployeesService) {}


    @Action(SelfEmployeeAction) employeeGet(ctx: StateContext<SelfEmployeeStateModel>, action: SelfEmployeeAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, readonly: false, error: null });

        return this.employeesService.getEmploye(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new SelfEmployeeSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new SelfEmployeeFailAction(err)), 0));
    }

    @Action(SelfEmployeeSuccessAction) employeeGetSuccess(ctx: StateContext<SelfEmployeeStateModel>, action: SelfEmployeeSuccessAction) {
        const state = ctx.getState();
        const readonly = (action.payload.group === Group.User) || (action.payload.group === Group.Viewer);
        const user = action.payload.group === Group.User;
        const viewer = action.payload.group === Group.Viewer;
        ctx.setState({ ...state, pending: false, readonly, user, viewer, entity: action.payload, error: null });
    }

    @Action(SelfEmployeeFailAction) employeeGetFail(ctx: StateContext<SelfEmployeeStateModel>, action: SelfEmployeeFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, readonly: false, error: action.payload });
    }

    @Action(SelfEmployeeResetAction) employeeGetReset(ctx: StateContext<SelfEmployeeStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, readonly: false, entity: null, error: null });
    }

    @Action([ProfileSuccessAction]) profileGetSuccess(ctx: StateContext<SelfEmployeeStateModel>, action: ProfileSuccessAction) {
        setTimeout(() => ctx.dispatch(new SelfEmployeeAction({ uid: action.payload.user.uid })), 0);
    }
}
