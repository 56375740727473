import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { DialogService } from '../../../burns-ui-framework/shared/services/common/dialog.service';

import { DialogResult } from '../../../burns-ui-framework/shared/components/common/exg-dialog/shared/dialog-result.model';
import { IMenuItem } from '../../../burns-ui-framework/shared/components/common/exg-menu/exg-menu.component';
import { Profile } from '../../../burns-ui-framework/shared/models/business/user/profile.model';
import { IHeaderMenuItem } from '../rbc-left-menu/rbc-left-menu.component';

import { ExgParamsConfig } from '../../exg-params.config';

export interface IMenuItemWithRoute extends IMenuItem {
    route: string;
}

export interface IHeaderMobileMenuItem {
    bottom: IHeaderMenuItem[];
    dropdown: IMenuItemWithRoute[];
}

@Component({
    selector: 'rbc-mobile-menu',
    templateUrl: './rbc-mobile-menu.component.html',
    styleUrls: ['./rbc-mobile-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcMobileMenuComponent {

    @Input() profile: Profile;
    @Input() menu: IHeaderMobileMenuItem;

    @Output() readonly logout = new EventEmitter<void>();

    public emptyAvatar = ExgParamsConfig.logos.emptyUserAvatar;

    constructor(private dialogService: DialogService, private router: Router) { }

    public async onProfileClick() {
        if (await this.dialogService.showConfirm({ description: 'Are you sure you want to log out of your account?' }) === DialogResult.Confirm) {
            this.logout.emit();
        }
    }

    public onMenuItemClick(event: IMenuItemWithRoute) {
        switch (event.id) {
            case 'logout':
                this.onProfileClick();
                break;
            default:
                this.router.navigate([event.route]);
                break;
        }
    }
}
