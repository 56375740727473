import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Geocoding } from '../../models/business/geocoding/geocoding.model';

export class YandexGeocodingAction {
    static readonly type = '[Yandex Geocoding API] Get Geocoding';

    constructor(public payload: { geocode: string }) { }
}

export class YandexGeocodingSuccessAction {
    static readonly type = '[Yandex Geocoding API] Get Geocoding Success';

    constructor(public payload: Geocoding[]) { }
}

export class YandexGeocodingFailAction {
    static readonly type = '[Yandex Geocoding API] Get Geocoding Fail';

    constructor(public payload: ErrorObject) { }
}

export class YandexGeocodingResetAction {
    static readonly type = '[Yandex Geocoding API] Geocoding Reset';
}
