export enum DialogResult {
    Ok,
    Cancel,
    Yes,
    No,
    Close,
    Save,
    Delete,
    Confirm,
    Other
}
