import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeesState, EmployeesStateModel } from './employees.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeesSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeesState], (state: EmployeesStateModel) => state.pending);

    static readonly getEmployees = createSelector([EmployeesState], (state: EmployeesStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([EmployeesState], (state: EmployeesStateModel) => state.retrieved);

    static readonly getError = createSelector([EmployeesState], (state: EmployeesStateModel) => state.error);

    static readonly getAllEmployeesLoaded = createSelector([EmployeesState], (state: EmployeesStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([EmployeesState], (state: EmployeesStateModel) => state.filter);

    static readonly getTotal = createSelector([EmployeesState], (state: EmployeesStateModel) => state.total);

    @Select(EmployeesSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeesSelectors.getEmployees) employees$: Observable<EmployeeListItem[]>;

    @Select(EmployeesSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(EmployeesSelectors.getError) error$: Observable<ErrorObject>;

    @Select(EmployeesSelectors.getAllEmployeesLoaded) allEmployeesLoaded$: Observable<boolean>;

    @Select(EmployeesSelectors.getFilter) filter$: Observable<EmployeeListGetFilterData>;

    @Select(EmployeesSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('EmployeesSelectors');
    }
}
