import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { WorkingAreaListGetFilterData } from '../../models/filters/working-area-list-get-filter.model';

import { EmployeeScheduleWorkingAreaAction, EmployeeScheduleWorkingAreaResetAction } from './employee-schedule-working-area.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeScheduleWorkingAreaDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeScheduleWorkingAreaDispatchers');
    }

    public dispatchEmployeeScheduleWorkingAreaAction(request: WorkingAreaListGetFilterData) {
        this.store.dispatch(new EmployeeScheduleWorkingAreaAction(request));
    }

    public dispatchEmployeeScheduleWorkingAreaResetAction() {
        this.store.dispatch(new EmployeeScheduleWorkingAreaResetAction());
    }
}
