import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ExgParamsConfig } from '../../exg-params.config';
import { CompaniesAction, CompaniesFailAction, CompaniesResetAction, CompaniesSetFilterAction, CompaniesSuccessAction } from './companies.actions';
import { CompanyCreateSuccessAction } from './company-create.actions';
import { CompanyDeleteSuccessAction } from './company-delete.actions';
import { CompanyUpdateSuccessAction } from './company-update.actions';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

import { CompaniesService } from '../../services/companies.service';

import { CompaniesListFilter, CompaniesListFilterData } from '../../models/filters/companies-list-filter.model';
import { CompanyListItem } from '../../models/business/company/company-list-item.model';

export interface CompaniesStateModel extends PaginationStateModel<CompanyListItem> {
    uids: string[];
    entities: { [uid: string]: CompanyListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: CompaniesListFilterData;
    total: number;
}

@State<CompaniesStateModel>({
    name: 'companies',
    defaults: {
        pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {}
    }
})
@Injectable()
export class CompaniesState extends PaginationStateBase {
    constructor(private companiesService: CompaniesService) {
        super();
    }

    @Action([CompaniesAction]) companiesGet(ctx: StateContext<CompaniesStateModel>, action: CompaniesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new CompaniesListFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.companiesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.companiesService.getCompanyList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new CompaniesSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CompaniesFailAction(err)), 0));
    }

    @Action(CompaniesSuccessAction) companiesGetSuccess(ctx: StateContext<CompaniesStateModel>, action: CompaniesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, total, index } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.companiesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(CompaniesFailAction) companiesGetFail(ctx: StateContext<CompaniesStateModel>, action: CompaniesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CompaniesResetAction) companiesGetReset(ctx: StateContext<CompaniesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, total: 0, index: 0, filter: {} });
    }

    @Action(CompaniesSetFilterAction) usersSetFilter(ctx: StateContext<CompaniesStateModel>, action: CompaniesSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, index: 0 });
            setTimeout(() => ctx.dispatch(new CompaniesAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }

    @Action(CompanyCreateSuccessAction) companyCreateSuccess(ctx: StateContext<CompaniesStateModel>, action: CompanyCreateSuccessAction) {
        const state = ctx.getState();
        const uids = [...state.uids, action.payload.uid];
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload };
        ctx.setState({ ...state, uids, entities });
    }

    @Action(CompanyUpdateSuccessAction) companyUpdateSuccess(ctx: StateContext<CompaniesStateModel>, action: CompanyUpdateSuccessAction) {
        const state = ctx.getState();
        const entities = { ...state.entities };
        entities[action.payload.uid] = { ...entities[action.payload.uid], ...action.payload.request };
        ctx.setState({ ...state, entities });
    }

    @Action(CompanyDeleteSuccessAction) companyDeleteSuccess(ctx: StateContext<CompaniesStateModel>, action: CompanyDeleteSuccessAction) {
        const state = ctx.getState();

        const uids = state.uids.filter(x => x !== action.payload.uid);
        const entities = { ...state.entities };
        // tslint:disable-next-line:no-dynamic-delete
        delete entities[action.payload.uid];
        ctx.setState({ ...state, uids, entities });
    }
}
