import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftResourcesService } from '../../services/shift-resources.service';

import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { ShiftDrillingRigsAction, ShiftDrillingRigsFailAction, ShiftDrillingRigsResetAction, ShiftDrillingRigsSuccessAction } from './shift-drilling-rigs.actions';

export interface ShiftDrillingRigsStateModel {
    entity: DictionaryEntryListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<ShiftDrillingRigsStateModel>({
    name: 'ShiftDrillingRigs',
    defaults: { pending: false, entity: [], error: null }
})
@Injectable()
export class ShiftDrillingRigsState {
    constructor(private shiftResourcesService: ShiftResourcesService) {}


    @Action(ShiftDrillingRigsAction) drillingRigsGet(ctx: StateContext<ShiftDrillingRigsStateModel>, action: ShiftDrillingRigsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.shiftResourcesService.getDrillingRigs(action.payload.workingAreaUid)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftDrillingRigsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftDrillingRigsFailAction(err)), 0));
    }

    @Action(ShiftDrillingRigsSuccessAction) drillingRigsGetSuccess(ctx: StateContext<ShiftDrillingRigsStateModel>, action: ShiftDrillingRigsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ShiftDrillingRigsFailAction) drillingRigsGetFail(ctx: StateContext<ShiftDrillingRigsStateModel>, action: ShiftDrillingRigsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftDrillingRigsResetAction) drillingRigsGetReset(ctx: StateContext<ShiftDrillingRigsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: [], error: null });
    }
}
