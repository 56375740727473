import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryDrillingRigsAction, DictionaryDrillingRigsFailAction, DictionaryDrillingRigsResetAction, DictionaryDrillingRigsSuccessAction } from './dictionary-drilling-rigs.actions';

export interface DictionaryDrillingRigsStateModel {
    entities: DictionaryEntryListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<DictionaryDrillingRigsStateModel>({
    name: 'DictionaryDrillingRigs',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class DictionaryDrillingRigsState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action([DictionaryDrillingRigsAction]) drillingRigsGet(ctx: StateContext<DictionaryDrillingRigsStateModel>, _: DictionaryDrillingRigsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.dictionariesService.getDrillingRigs()
            .then(resp => setTimeout(() => ctx.dispatch(new DictionaryDrillingRigsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryDrillingRigsFailAction(err)), 0));
    }

    @Action(DictionaryDrillingRigsSuccessAction) drillingRigsGetSuccess(ctx: StateContext<DictionaryDrillingRigsStateModel>, action: DictionaryDrillingRigsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(DictionaryDrillingRigsFailAction) drillingRigsGetFail(ctx: StateContext<DictionaryDrillingRigsStateModel>, action: DictionaryDrillingRigsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryDrillingRigsResetAction) drillingRigsGetReset(ctx: StateContext<DictionaryDrillingRigsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
