import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';
import { RouteCreateOrUpdateRequest } from '../models/business/route/route-create-or-update-request.model';
import { RouteListGetFilter } from '../models/filters/route-list-get-filter.model';
import { RouteListItem } from '../models/business/route/route-list-item.model';
import { Route } from '../models/business/route/route.model';

import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class RoutesService extends BaseSingletonService {

    private settings: {
        service: {
            getRoute: string;
            getRoutes: string;
            createRoute: string;
            updateRoute: string;
            deleteRoute: string;
        }
    };

    constructor(private http: HttpService) {
        super('RoutesService');
        this.settings = {
            service: {
                getRoute: '/personnel/v1/routes/{0}',
                getRoutes: '/personnel/v1/routes?term={0}&employeeUid={1}&dateFrom={2}&dateTo={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                createRoute: '/personnel/v1/routes',
                updateRoute: '/personnel/v1/routes/{0}',
                deleteRoute: '/personnel/v1/routes/{0}'
            }
        };
    }

    public async getRoute(uid: string): Promise<Route> {
        return this.http.get<{ data: Route }>(String.format(this.settings.service.getRoute, uid))
            .then(res => res.data);
    }

    public async getRoutes(filter: RouteListGetFilter): Promise<PaginationResult<RouteListItem>> {
        return this.http.get<PaginationResult<RouteListItem>>(String.format(this.settings.service.getRoutes, filter.term, filter.employeeUid, filter.dateFrom, filter.dateTo, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createRoute(request: RouteCreateOrUpdateRequest): Promise<Route> {
        return this.http.post<{ data: Route }>(String.format(this.settings.service.createRoute), request)
            .then(res => res.data);
    }

    public async updateRoute(uid: string, request: RouteCreateOrUpdateRequest): Promise<Route> {
        return this.http.put<{ data: Route }>(String.format(this.settings.service.updateRoute, uid), request)
            .then(res => res.data);
    }

    public async deleteRoute(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteRoute, uid));
    }
}
