import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { CreateOrUpdateVehicleTransferAggregationRequest } from '../models/business/vehicle-transfer/create-or-update-vehicleTransfer-aggregation-request.model';
import { VehicleTransferListGetFilter } from '../models/filters/vehicle-transfer-list-get-filter.model';
import { VehicleTransferListItem } from '../models/business/vehicle-transfer/vehicle-transfer-list-item.model';
import { VehicleTransfer } from '../models/business/vehicle-transfer/vehicle-transfer.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';


@Injectable({
    providedIn: 'root'
})
export class VehicleTransfersService extends BaseSingletonService {

    private settings: {
        service: {
            getVehicleTransfer: string;
            getVehicleTransfers: string;
            getSchedule: string;
            getWorkbook: string;
            createVehicleTransfer: string;
            updateVehicleTransfer: string;
            deleteVehicleTransfer: string;
        }
    };

    constructor(private http: HttpService) {
        super('VehicleTransfersService');
        this.settings = {
            service: {
                getVehicleTransfer: '/drilling/v1/vehicle-transfers/{0}',
                getVehicleTransfers: '/drilling/v1/vehicle-transfers?term={0}&vehicleUid={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                getSchedule: '/drilling/v1/vehicle-transfers/schedule?term={0}&workingAreaUid={1}&dateFrom={2}&dateTo={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                getWorkbook: '/drilling/v1/vehicle-transfers/workbook?workingAreaUid={0}&DateFrom={1}&DateTo={2}',
                createVehicleTransfer: '/drilling/v1/vehicle-transfers',
                updateVehicleTransfer: '/drilling/v1/vehicle-transfers/{0}',
                deleteVehicleTransfer: '/drilling/v1/vehicle-transfers/{0}'
            }
        };
    }

    public async getVehicleTransfer(uid: string): Promise<VehicleTransfer> {
        return this.http.get<{ data: VehicleTransfer }>(String.format(this.settings.service.getVehicleTransfer, uid))
            .then(res => res.data);
    }

    public async getVehicleTransfers(filter: VehicleTransferListGetFilter): Promise<PaginationResult<VehicleTransferListItem>> {
        return this.http.get<PaginationResult<VehicleTransferListItem>>(String.format(this.settings.service.getVehicleTransfers, filter.term, filter.vehicleUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createVehicleTransfer(request: CreateOrUpdateVehicleTransferAggregationRequest): Promise<{ uid: string }> {
        return this.http.post<{ data: string }>(String.format(this.settings.service.createVehicleTransfer), request)
            .then(res => ({ uid: res.data }));
    }

    public async updateVehicleTransfer(uid: string, request: CreateOrUpdateVehicleTransferAggregationRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateVehicleTransfer, uid), request);
    }

    public async deleteVehicleTransfer(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteVehicleTransfer, uid));
    }
}
