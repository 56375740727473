import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeChangePasswordState, EmployeeChangePasswordStateModel } from './employee-change-password.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeChangePasswordSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeeChangePasswordState], (state: EmployeeChangePasswordStateModel) => state.pending);

    static readonly getChanged = createSelector([EmployeeChangePasswordState], (state: EmployeeChangePasswordStateModel) => state.changed);

    static readonly getError = createSelector([EmployeeChangePasswordState], (state: EmployeeChangePasswordStateModel) => state.error);

    @Select(EmployeeChangePasswordSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeeChangePasswordSelectors.getChanged) changed$: Observable<boolean>;

    @Select(EmployeeChangePasswordSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('EmployeeChangePasswordSelectors');
    }
}
