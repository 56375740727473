import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class VehicleDictionaryAction {
    static readonly type = '[VehicleDictionary Page] GetWorkingAreaSearch';

    constructor() { }
}

export class VehicleDictionarySuccessAction {
    static readonly type = '[VehicleDictionary API] GetVehicleDictionary Success';

    constructor(public payload: any) { }
}

export class VehicleDictionaryFailAction {
    static readonly type = '[VehicleDictionary API] GetVehicleDictionary Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleDictionaryResetAction {
    static readonly type = '[VehicleDictionary Page] GetVehicleDictionary Reset';
}
