import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { DateActivityHours } from '../../models/business/analytics/date-activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsDetailsCausedByCustomerStoppageBalanceGetAction {
    static readonly type = '[AnalyticsDetailsCausedByCustomerStoppageBalance Page] GetAnalyticsDetailsCausedByCustomerStoppageBalance Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsCausedByCustomerStoppageBalanceSuccessAction {
    static readonly type = '[AnalyticsDetailsCausedByCustomerStoppageBalance API] GetAnalyticsCausedByCustomerStoppageBalance Success';

    constructor(public payload: { list: DateActivityHours[], summary: ActivityHours[] }) { }
}

export class AnalyticsDetailsCausedByCustomerStoppageBalanceFailAction {
    static readonly type = '[AnalyticsDetailsCausedByCustomerStoppageBalance API] GetAnalyticsCausedByCustomerStoppageBalance Fail';

    constructor(public payload: ErrorObject) { }
}
