import { CompaniesListFilterData } from '../../models/filters/companies-list-filter.model';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { CompanyListItem } from '../../models/business/company/company-list-item.model';


export class CompaniesAction {
    static readonly type = '[Companies Page] GetCompanies';

    constructor(public payload: PaginationRequest) { }
}

export class CompaniesSuccessAction {
    static readonly type = '[Companies API] GetCompanies Success';

    constructor(public payload: { list: PaginationResult<CompanyListItem>, pagination: PaginationRequest }) { }
}

export class CompaniesFailAction {
    static readonly type = '[Companies API] GetCompanies Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompaniesResetAction {
    static readonly type = '[Companies Page] GetCompanies Reset';
}

export class CompaniesSetFilterAction {
    static readonly type = '[Companies API] SetFilter';

    constructor(public payload: CompaniesListFilterData) { }
}
