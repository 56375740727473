import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

import { EmployeeScheduleWorkingAreaState, EmployeeScheduleWorkingAreaStateModel } from './employee-schedule-working-area.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeScheduleWorkingAreaSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeeScheduleWorkingAreaState], (state: EmployeeScheduleWorkingAreaStateModel) => state.pending);

    static readonly getEmployeeScheduleWorkingArea = createSelector([EmployeeScheduleWorkingAreaState], (state: EmployeeScheduleWorkingAreaStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([EmployeeScheduleWorkingAreaState], (state: EmployeeScheduleWorkingAreaStateModel) => state.retrieved);

    static readonly getError = createSelector([EmployeeScheduleWorkingAreaState], (state: EmployeeScheduleWorkingAreaStateModel) => state.error);

    static readonly getAllEmployeeScheduleWorkingAreaLoaded = createSelector([EmployeeScheduleWorkingAreaState], (state: EmployeeScheduleWorkingAreaStateModel) => state.allItemsLoaded);

    @Select(EmployeeScheduleWorkingAreaSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeeScheduleWorkingAreaSelectors.getEmployeeScheduleWorkingArea) workingAreas$: Observable<WorkingAreaListItem[]>;

    @Select(EmployeeScheduleWorkingAreaSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(EmployeeScheduleWorkingAreaSelectors.getError) error$: Observable<ErrorObject>;

    @Select(EmployeeScheduleWorkingAreaSelectors.getAllEmployeeScheduleWorkingAreaLoaded) allEmployeeScheduleWorkingAreaLoaded$: Observable<boolean>;

    constructor() {
        super('EmployeeScheduleWorkingAreaSelectors');
    }
}
