export enum Group {
    /// <summary>
    /// Superadmin
    /// </summary>
    Superadmin = 1,

    /// <summary>
    /// Administrator
    /// </summary>
    Administrator = 100,

    /// <summary>
    /// User
    /// </summary>
    User = 101,

    /// <summary>
    /// Employee
    /// </summary>
    Employee = 102,

    /// <summary>
    /// Viewer
    /// </summary>
    Viewer = 103
}
