import { EmployeeScheduleItem } from '../models/business/employee/employee-schedule-item.model';
import { LinkedRoute } from '../models/business/employee/linked-route.model';

import { DateUtils } from '../../burns-ui-framework/shared/utils/date-utils';
import { GuidUtils } from '../../burns-ui-framework/shared/utils/guid-utils';

export class RoutesUtils {
    /**
     * Added moq datato routes list
     */
    public static addMoqRoutes(routes: LinkedRoute[], skip: boolean = false): LinkedRoute[] {
        if (!!skip) {
            return routes;
        }

        let newRoutes = [];
        let lastRouteDate = DateUtils.startOf(DateUtils.currentDateTime, 'year');
        let isNotRoute = true;

        if (!!routes && routes.length > 0) {
            newRoutes = [...routes];
            const lastRoute = [...routes].sort((a, b) => b.endDate - a.endDate)[0];
            lastRouteDate = DateUtils.convertEpocToString(lastRoute.endDate);
            isNotRoute = lastRoute.isNotRoute;
        }

        const dateEnd = DateUtils.add(lastRouteDate, 1, 'years');

        let curNextItervalStart = lastRouteDate;

        while (DateUtils.compareDates(curNextItervalStart, dateEnd)) {
            const intervalEnd = DateUtils.add(curNextItervalStart, 60, 'days');

            newRoutes.push({
                uid: null,
                workingArea: null,
                brigadeNumber: null,
                jobPosition: null,
                startDate: DateUtils.convertStringToEpoc(curNextItervalStart),
                endDate: DateUtils.convertStringToEpoc(intervalEnd),
                isNotRoute: !isNotRoute
            });

            isNotRoute = !isNotRoute;
            curNextItervalStart = intervalEnd;
        }

        return newRoutes;
    }

    public static getMoqScheduleItems(): EmployeeScheduleItem[] {
        return [{
            uid: GuidUtils.newGuid,
            firstName: null,
            lastName: null,
            middleName: null,
            workingArea: null,
            jobPosition: null,
            routes: []
        }];
    }
}
