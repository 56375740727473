import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { DrillingRigsAction, DrillingRigsResetAction, DrillingRigsSetFilterAction } from './drilling-rigs.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigsDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigsDispatchers');
    }

    public dispatchDrillingRigsAction(request: PaginationRequest) {
        this.store.dispatch(new DrillingRigsAction(request));
    }

    public dispatchDrillingRigsResetAction() {
        this.store.dispatch(new DrillingRigsResetAction());
    }

    public dispatchDrillingRigsSetFilterAction(filterData: DrillingRigListGetFilterData) {
        this.store.dispatch(new DrillingRigsSetFilterAction(filterData));
    }
}
