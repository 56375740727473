import { ActivityBalanceWithPeriod } from '../../models/business/analytics/activity-balance-with-period.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsActivityBalanceSuccessAction {
    static readonly type = '[AnalyticsActivityBalance API] GetAnalyticsActivityBalance Success';

    constructor(public payload: ActivityBalanceWithPeriod[]) { }
}

export class AnalyticsActivityBalanceFailAction {
    static readonly type = '[AnalyticsActivityBalance API] GetAnalyticsActivityBalance Fail';

    constructor(public payload: ErrorObject) { }
}
