import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryVehicleCategoriesAction, DictionaryVehicleCategoriesFailAction, DictionaryVehicleCategoriesResetAction, DictionaryVehicleCategoriesSuccessAction } from './dictionary-vehicle-categories.actions';

export interface DictionaryVehicleCategoriesStateModel {
    entities: DictionaryEntryListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<DictionaryVehicleCategoriesStateModel>({
    name: 'DictionaryVehicleCategories',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class DictionaryVehicleCategoriesState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action([DictionaryVehicleCategoriesAction]) VehicleCategoriesGet(ctx: StateContext<DictionaryVehicleCategoriesStateModel>, _: DictionaryVehicleCategoriesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.dictionariesService.getVehicleCategories()
            .then(resp => setTimeout(() => ctx.dispatch(new DictionaryVehicleCategoriesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryVehicleCategoriesFailAction(err)), 0));
    }

    @Action(DictionaryVehicleCategoriesSuccessAction) VehicleCategoriesGetSuccess(ctx: StateContext<DictionaryVehicleCategoriesStateModel>, action: DictionaryVehicleCategoriesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(DictionaryVehicleCategoriesFailAction) VehicleCategoriesGetFail(ctx: StateContext<DictionaryVehicleCategoriesStateModel>, action: DictionaryVehicleCategoriesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryVehicleCategoriesResetAction) VehicleCategoriesGetReset(ctx: StateContext<DictionaryVehicleCategoriesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
