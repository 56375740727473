import { WorkingAreaShiftsSummaryListGetFilterData} from '../../models/filters/working-area-shifts-summary-list-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class ShiftsWorkbookExportAction {
    static readonly type = '[ShiftsWorkbookExport Page] GetShiftsWorkbookExport';

    constructor(public payload: { filter: WorkingAreaShiftsSummaryListGetFilterData }) { }
}

export class ShiftsWorkbookExportSuccessAction {
    static readonly type = '[ShiftsWorkbookExport API] GetShiftsWorkbookExport Success';
}

export class ShiftsWorkbookExportFailAction {
    static readonly type = '[ShiftsWorkbookExport API] GetShiftsWorkbookExport Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftsWorkbookExportResetAction {
    static readonly type = '[ShiftsWorkbookExport Page] GetShiftsWorkbookExport Reset';
}
