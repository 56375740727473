import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { CreateOrUpdateEmployeeAggregationRequest } from '../../models/business/employee/create-or-update-employee-aggregation-request.model';

import { EmployeeCreateAction, EmployeeCreateResetAction } from './employee-create.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeCreateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeCreateDispatchers');
    }

    public dispatchEmployeeCreateAction(request: CreateOrUpdateEmployeeAggregationRequest) {
        this.store.dispatch(new EmployeeCreateAction({ request }));
    }

    public dispatchEmployeeCreateResetAction() {
        this.store.dispatch(new EmployeeCreateResetAction());
    }
}
