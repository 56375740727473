import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { VehicleTransfersService } from '../../services/vehicle-transfers.service';

import { VehicleTransferListGetFilter, VehicleTransferListGetFilterData } from '../../models/filters/vehicle-transfer-list-get-filter.model';
import { VehicleTransferListItem } from '../../models/business/vehicle-transfer/vehicle-transfer-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { VehicleTransfersAction, VehicleTransfersFailAction, VehicleTransfersResetAction, VehicleTransfersSetFilterAction, VehicleTransfersSuccessAction } from './vehicle-transfers.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface VehicleTransfersStateModel extends PaginationStateModel<VehicleTransferListItem> {
    uids: string[];
    entities: { [uid: string]: VehicleTransferListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: VehicleTransferListGetFilterData;
    total: number;
}

@State<VehicleTransfersStateModel>({
    name: 'vehicleTransfers',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {vehicleUid: null} }
})
@Injectable()
export class VehicleTransfersState extends PaginationStateBase {
    constructor(private vehicleTransfersService: VehicleTransfersService) {
        super();
    }

    @Action([VehicleTransfersAction]) vehicleTransfersGet(ctx: StateContext<VehicleTransfersStateModel>, action: VehicleTransfersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new VehicleTransferListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.vehicleTransfersAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.vehicleTransfersService.getVehicleTransfers(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new VehicleTransfersSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VehicleTransfersFailAction(err)), 0));
    }

    @Action(VehicleTransfersSuccessAction) vehicleTransfersGetSuccess(ctx: StateContext<VehicleTransfersStateModel>, action: VehicleTransfersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.vehicleTransfersAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(VehicleTransfersFailAction) vehicleTransfersGetFail(ctx: StateContext<VehicleTransfersStateModel>, action: VehicleTransfersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(VehicleTransfersResetAction) vehicleTransfersGetReset(ctx: StateContext<VehicleTransfersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {vehicleUid: null} });
    }

    @Action(VehicleTransfersSetFilterAction) vehicleTransfersSetFilter(ctx: StateContext<VehicleTransfersStateModel>, action: VehicleTransfersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new VehicleTransfersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
