import { DrillingRigCreateUpdateRequest } from '../../models/business/drilling-rig/drilling-rig-create-update-request.model';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigUpdateAction {
    static readonly type = '[DrillingRig Page] UpdateDrillingRig';

    constructor(public payload: { uid: string, request: DrillingRigCreateUpdateRequest }) { }
}

export class DrillingRigUpdateSuccessAction {
    static readonly type = '[DrillingRig API] UpdateDrillingRig Success';

    constructor(public payload: { uid: string, request: DrillingRigCreateUpdateRequest }) { }
}

export class DrillingRigUpdateFailAction {
    static readonly type = '[DrillingRig API] UpdateDrillingRig Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigUpdateResetAction {
    static readonly type = '[DrillingRig Page] UpdateDrillingRig Reset';
}
