import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransportsService } from '../../services/transports.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportCreateAction, TransportCreateFailAction, TransportCreateResetAction, TransportCreateSuccessAction } from './transport-create.actions';

export interface TransportCreateStateModel {
    pending: boolean;
    created: { uid: string };
    error: ErrorObject;
}

@State<TransportCreateStateModel>({
    name: 'transportCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class TransportCreateState {
    constructor(private transportsService: TransportsService) { }

    @Action(TransportCreateAction) async transportCreate(ctx: StateContext<TransportCreateStateModel>, action: TransportCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.transportsService.createTransport(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new TransportCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportCreateFailAction(err)), 0));
    }

    @Action(TransportCreateSuccessAction) transportCreateSuccess(ctx: StateContext<TransportCreateStateModel>, action: TransportCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(TransportCreateFailAction) transportCreateFail(ctx: StateContext<TransportCreateStateModel>, action: TransportCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(TransportCreateResetAction) transportCreateReset(ctx: StateContext<TransportCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
