import { PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { ExgTranslateService } from '../../services/common/exg-translate.service';

@Pipe({ name: 'exgPercent' })
export class ExgPercentPipe implements PipeTransform {
    constructor(private translate: ExgTranslateService) { }

    public transform(value: any, digits?: string, previousValue?: number, emptyDisplayValue?: string): string {
        const displayValue = !!previousValue && previousValue !== 0
            ? (100 - (value / previousValue) * 100)
            : value;

        if (!!+displayValue || displayValue === '0' || displayValue === 0) {
            return new PercentPipe(this.translate.getCurrentLang()).transform(+displayValue / 100, digits || '1.2-2');
        }

        return emptyDisplayValue || '';
    }
}
