<section class="header">
    <div class="title-search-actions">
        <div class="title">{{ 'Transport' | translate }}</div>
        <div class="search_area">
            <exg-icon class="search_icon" iconName="search"></exg-icon>

            <exg-textbox
                class="search_input"
                type="text"
                [value]="filter?.term"
                styleType="empty"
                placeholder="{{
                    (currentTab === tabsEnum.DrillInstallations ? 'Search by brand name or brigade number' : 'Search by brand name or state number') | translate
                }}"
                (valueChange)="onValueChange($event)"></exg-textbox>
        </div>
        <div [ngSwitch]="currentTab" class="actions">
            
            <ng-container *ngSwitchCase="tabsEnum.Table">
                <exg-button class="btn cancel" [disabled]="pending" styleType="outlined" (btnClick)="onExportClick()">
                    <exg-icon class="btn-icon" iconName="export"></exg-icon>
                    {{ 'Transport report' | translate }}
                </exg-button>
                <exg-button [disabled]="readonly" class="btn submit" (btnClick)="addClickButton()">
                    <exg-icon class="btn-icon add" iconName="add_square"></exg-icon>
                    {{ 'Add transport' | translate }}
                </exg-button>
            </ng-container>
            <ng-container *ngSwitchCase="tabsEnum.WorkReport">
                <!-- <exg-button [disabled]="true" class="btn submit" (btnClick)="addClickButton()">
                    <exg-icon class="btn-icon add" iconName="export"></exg-icon>
                    {{ 'Create report' | translate }}
                </exg-button> -->
            </ng-container>
            <ng-container *ngSwitchCase="tabsEnum.DrillInstallations">
                <exg-button [disabled]="true" class="btn submit" (btnClick)="addClickButton()">
                    <exg-icon class="btn-icon add" iconName="export"></exg-icon>
                    {{ 'Download report' | translate }}
                </exg-button>
            </ng-container>
        </div>
    </div>
    <div class="navigation">
        <exg-button class="tab_btn" styleType="custom" [routerLink]="[routes.transport.table.fullUrl]" routerLinkActive="active">
            <exg-icon class="btn-icon tab_btn-icon" iconName="list-check"></exg-icon>
            {{ 'Table' | translate }}
        </exg-button>
        <exg-button class="tab_btn schedule" styleType="custom" [routerLink]="[routes.transport.workReport.fullUrl]" routerLinkActive="active">
            <exg-icon class="btn-icon tab_btn-icon" iconName="export"></exg-icon>
            {{ 'Working report' | translate }}
        </exg-button>
    </div>
</section>

<exg-dialog [componentData]="componentData"
            [dialogMode]="dialogMode.NoButtons"
            [mobileFullScreen]="true"
            [showDialog]="showDialog"
            (dialogClose)="onDialogClose($event)"></exg-dialog>
