import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { CompanyUpdateSuccessAction } from './company-update.actions';
import { CompanyFailAction, CompanyGetAction, CompanyGetSuccessAction, CompanyResetAction } from './company.actions';
import { CompaniesService } from '../../services/companies.service';
import { Company } from '../../models/business/company/company.model';

export interface CompanyStateModel {
    entity: Company;
    pending: boolean;
    error: ErrorObject;
}

@State<CompanyStateModel>({
    name: 'company',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class CompanyState {
    constructor(private companiesService: CompaniesService) { }

    @Action(CompanyGetAction) companyGet(ctx: StateContext<CompanyStateModel>, action: CompanyGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.companiesService.getCompany(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new CompanyGetSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CompanyFailAction(err)), 0));
    }

    @Action(CompanyGetSuccessAction) companyGetSuccess(ctx: StateContext<CompanyStateModel>, action: CompanyGetSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(CompanyFailAction) companyGetFail(ctx: StateContext<CompanyStateModel>, action: CompanyFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CompanyResetAction) companyGetReset(ctx: StateContext<CompanyStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(CompanyUpdateSuccessAction) companyUpdateSuccess(ctx: StateContext<CompanyStateModel>, action: CompanyUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = { ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
