import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { EmployeesService } from '../../services/employees.service';

import { EmployeeScheduleGetFilter, EmployeeScheduleGetFilterData } from '../../models/filters/employee-schedule-get-filter.model';
import { EmployeeScheduleItem } from '../../models/business/employee/employee-schedule-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { EmployeeScheduleAction, EmployeeScheduleFailAction, EmployeeScheduleResetAction, EmployeeScheduleSetFilterAction, EmployeeScheduleSuccessAction } from './employee-schedule.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface EmployeeScheduleStateModel extends PaginationStateModel<EmployeeScheduleItem> {
    uids: string[];
    entities: { [uid: string]: EmployeeScheduleItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: EmployeeScheduleGetFilterData;
    total: number;
}

@State<EmployeeScheduleStateModel>({
    name: 'employeeSchedule',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class EmployeeScheduleState extends PaginationStateBase {
    constructor(private employeesService: EmployeesService) {
        super();
    }

    @Action([EmployeeScheduleAction]) employeesGet(ctx: StateContext<EmployeeScheduleStateModel>, action: EmployeeScheduleAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new EmployeeScheduleGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.employeesAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.employeesService.getSchedule(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new EmployeeScheduleSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeeScheduleFailAction(err)), 0));
    }

    @Action(EmployeeScheduleSuccessAction) employeesGetSuccess(ctx: StateContext<EmployeeScheduleStateModel>, action: EmployeeScheduleSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.employeesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(EmployeeScheduleFailAction) employeesGetFail(ctx: StateContext<EmployeeScheduleStateModel>, action: EmployeeScheduleFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(EmployeeScheduleResetAction) employeesGetReset(ctx: StateContext<EmployeeScheduleStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(EmployeeScheduleSetFilterAction) employeesSetFilter(ctx: StateContext<EmployeeScheduleStateModel>, action: EmployeeScheduleSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new EmployeeScheduleAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
