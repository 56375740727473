import { EmployeeWorkbookGetFilterData } from '../../models/filters/employee-workbook-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class WorkbookExportAction {
    static readonly type = '[WorkbookExport Page] GetWorkbookExport';

    constructor(public payload: { filter: EmployeeWorkbookGetFilterData }) { }
}

export class WorkbookExportSuccessAction {
    static readonly type = '[WorkbookExport API] GetWorkbookExport Success';
}

export class WorkbookExportFailAction {
    static readonly type = '[WorkbookExport API] GetWorkbookExport Fail';

    constructor(public payload: ErrorObject) { }
}

export class WorkbookExportResetAction {
    static readonly type = '[WorkbookExport Page] GetWorkbookExport Reset';
}
