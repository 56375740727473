import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DictionaryDrillingRigsAction {
    static readonly type = '[DictionaryDrillingRigs Page] GetDictionaryDrillingRigs';
}

export class DictionaryDrillingRigsSuccessAction {
    static readonly type = '[DictionaryDrillingRigs API] GetDictionaryDrillingRigs Success';

    constructor(public payload: DictionaryEntryListItem[]) { }
}

export class DictionaryDrillingRigsFailAction {
    static readonly type = '[DictionaryDrillingRigs API] GetDictionaryDrillingRigs Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryDrillingRigsResetAction {
    static readonly type = '[DictionaryDrillingRigs Page] GetDictionaryDrillingRigs Reset';
}
