import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsService } from '../../services/analytics.service';

import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilter, AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { DateActivityHours } from '../../models/business/analytics/date-activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { AnalyticsDetailsProductiveBalanceFailAction, AnalyticsDetailsProductiveBalanceGetAction, AnalyticsDetailsProductiveBalanceSuccessAction } from './analytics-details-productive-balance.actions';
import { AnalyticsDetailsResetAction } from './analytics-details.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface AnalyticsDetailsProductiveBalanceStateModel {
    list: DateActivityHours[];
    summary: ActivityHours[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: AnalyticGetFilterData;
}

@State<AnalyticsDetailsProductiveBalanceStateModel>({
    name: 'AnalyticsDetailsProductiveBalance',
    defaults: { pending: false, list: [], summary: [], retrieved: false, error: null, filter: {} }
})
@Injectable()
export class AnalyticsDetailsProductiveBalanceState {
    constructor(private analyticsService: AnalyticsService) { }

    @Action(AnalyticsDetailsProductiveBalanceGetAction) analyticsSetFilter(ctx: StateContext<AnalyticsDetailsProductiveBalanceStateModel>, action: AnalyticsDetailsProductiveBalanceGetAction) {
        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            ctx.setState({ ...state, filter: filterData, retrieved: false });

            const filter = new AnalyticGetFilter({ filterData });

            return Promise.all([
                this.analyticsService.getProductiveBalance(filter),
                this.analyticsService.getProductiveBalanceSummary(filter)
            ])
            .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsDetailsProductiveBalanceSuccessAction({ summary: resp[1], list: resp[0] })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsDetailsProductiveBalanceFailAction(err)), 0));
        }
    }

    @Action(AnalyticsDetailsProductiveBalanceSuccessAction) lossOfProfitGetSuccess(ctx: StateContext<AnalyticsDetailsProductiveBalanceStateModel>, action: AnalyticsDetailsProductiveBalanceSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, list: action.payload.list, summary: action.payload.summary, retrieved: true, error: null });
    }

    @Action(AnalyticsDetailsProductiveBalanceFailAction) lossOfProfitGetFail(ctx: StateContext<AnalyticsDetailsProductiveBalanceStateModel>, action: AnalyticsDetailsProductiveBalanceFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsDetailsResetAction) analyticsGetReset(ctx: StateContext<AnalyticsDetailsProductiveBalanceStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false,  list: [], summary: [], retrieved: false, error: null });
    }
}
