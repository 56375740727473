import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigDeleteAction, DrillingRigDeleteFailAction, DrillingRigDeleteResetAction, DrillingRigDeleteSuccessAction } from './drilling-rig-delete.actions';

export interface DrillingRigDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<DrillingRigDeleteStateModel>({
    name: 'drillingRigDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class DrillingRigDeleteState {
    constructor(private drillingRigsService: DrillingRigsService) { }

    @Action(DrillingRigDeleteAction) async drillingRigDelete(ctx: StateContext<DrillingRigDeleteStateModel>, action: DrillingRigDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.drillingRigsService.deleteDrillingRig(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new DrillingRigDeleteFailAction(error)), 0));
    }

    @Action(DrillingRigDeleteSuccessAction) drillingRigDeleteSuccess(ctx: StateContext<DrillingRigDeleteStateModel>, _: DrillingRigDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(DrillingRigDeleteFailAction) drillingRigDeleteFail(ctx: StateContext<DrillingRigDeleteStateModel>, action: DrillingRigDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigDeleteResetAction) drillingRigDeleteReset(ctx: StateContext<DrillingRigDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
