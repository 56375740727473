import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DictionaryDrillDiametersAction {
    static readonly type = '[DictionaryDrillDiameters Page] GetDictionaryDrillDiameters';
}

export class DictionaryDrillDiametersSuccessAction {
    static readonly type = '[DictionaryDrillDiameters API] GetDictionaryDrillDiameters Success';

    constructor(public payload: DictionaryEntryListItem[]) { }
}

export class DictionaryDrillDiametersFailAction {
    static readonly type = '[DictionaryDrillDiameters API] GetDictionaryDrillDiameters Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryDrillDiametersResetAction {
    static readonly type = '[DictionaryDrillDiameters Page] GetDictionaryDrillDiameters Reset';
}
