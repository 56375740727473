import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigCreateUpdateRequest } from '../../models/business/drilling-rig/drilling-rig-create-update-request.model';

import { DrillingRigUpdateAction, DrillingRigUpdateResetAction } from './drilling-rig-update.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigUpdateDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigUpdateDispatchers');
    }

    public dispatchDrillingRigUpdateAction(uid: string, request: DrillingRigCreateUpdateRequest) {
        this.store.dispatch(new DrillingRigUpdateAction({ uid, request }));
    }

    public dispatchDrillingRigUpdateResetAction() {
        this.store.dispatch(new DrillingRigUpdateResetAction());
    }
}
