import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { CompanyListItem } from '../../models/business/company/company-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { CompaniesSearchState, CompaniesSearchStateModel } from './companies-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class CompaniesSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([CompaniesSearchState], (state: CompaniesSearchStateModel) => state.pending);

    static readonly getCompaniesSearch = createSelector([CompaniesSearchState], (state: CompaniesSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([CompaniesSearchState], (state: CompaniesSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([CompaniesSearchState], (state: CompaniesSearchStateModel) => state.error);

    static readonly getAllCompaniesSearchLoaded = createSelector([CompaniesSearchState], (state: CompaniesSearchStateModel) => state.allItemsLoaded);

    @Select(CompaniesSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(CompaniesSearchSelectors.getCompaniesSearch) companies$: Observable<CompanyListItem[]>;

    @Select(CompaniesSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(CompaniesSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(CompaniesSearchSelectors.getAllCompaniesSearchLoaded) allCompaniesSearchLoaded$: Observable<boolean>;

    constructor() {
        super('CompaniesSearchSelectors');
    }
}
