import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigBreakdownsService } from '../../services/drilling-rig-breakdowns.service';

import { DrillingRigBreakdown } from '../../models/business/drilling-rig-breakdown/drilling-rig-breakdown.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigBreakdownUpdateSuccessAction } from './drilling-rig-breakdown-update.actions';
import { DrillingRigBreakdownAction, DrillingRigBreakdownFailAction, DrillingRigBreakdownResetAction, DrillingRigBreakdownSuccessAction } from './drilling-rig-breakdown.actions';

export interface DrillingRigBreakdownStateModel {
    entity: DrillingRigBreakdown;
    pending: boolean;
    error: ErrorObject;
}

@State<DrillingRigBreakdownStateModel>({
    name: 'drillingRigBreakdown',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class DrillingRigBreakdownState {
    constructor(private drillingRigBreakdownsService: DrillingRigBreakdownsService) {}


    @Action(DrillingRigBreakdownAction) drillingRigBreakdownGet(ctx: StateContext<DrillingRigBreakdownStateModel>, action: DrillingRigBreakdownAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.drillingRigBreakdownsService.getDrillingRigBreakdown(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownFailAction(err)), 0));
    }

    @Action(DrillingRigBreakdownSuccessAction) drillingRigBreakdownGetSuccess(ctx: StateContext<DrillingRigBreakdownStateModel>, action: DrillingRigBreakdownSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(DrillingRigBreakdownFailAction) drillingRigBreakdownGetFail(ctx: StateContext<DrillingRigBreakdownStateModel>, action: DrillingRigBreakdownFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigBreakdownResetAction) drillingRigBreakdownGetReset(ctx: StateContext<DrillingRigBreakdownStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(DrillingRigBreakdownUpdateSuccessAction) drillingRigBreakdownUpdateSuccessAction(ctx: StateContext<DrillingRigBreakdownStateModel>, action: DrillingRigBreakdownUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = <any>{ ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
