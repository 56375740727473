import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { UserFilterData } from '../../models/filters/user-filter.model';
import { UserListItem } from '../../models/business/user/user.model';

export class UsersAction {
    static readonly type = '[Users API] GetUsers';

    constructor(public payload: PaginationRequest) { }
}

export class UsersSuccessAction {
    static readonly type = '[Users API] GetUsers Success';

    constructor(public payload: { users: PaginationResult<UserListItem>, pagination: PaginationRequest }) { }
}

export class UsersFailAction {
    static readonly type = '[Users API] GetUsers Fail';

    constructor(public payload: ErrorObject) { }
}

export class UsersResetAction {
    static readonly type = '[Users API] GetUsers Reset';
}

export class UsersSetFilterAction {
    static readonly type = '[Users API] SetFilter';

    constructor(public payload: UserFilterData) { }
}
