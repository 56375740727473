import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../.../../../burns-ui-framework/shared/services/common/http.service';
import { String } from '../../.../../burns-ui-framework/shared/utils/string';
import { PaginationResult } from '../.../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { ContractCreateOrUpdateRequest } from '../models/business/contract/contract-create-or-update.model';
import { ContractsListFilter } from '../models/filters/contracts-list-filter.model';
import { Contract } from '../models/business/contract/contract.model';
import { ContractSheduleListItem } from '../models/business/contract-shedule/contract-shedule-list-item.model';
import { ContractSheduleListGetFilter } from '../models/filters/contract-shedule-list-get-filter.model';
import { ContractListItem } from '../models/business/contract/contract-list-item.model';

@Injectable({
    providedIn: 'root'
})
export class ContractsService extends BaseSingletonService {

    private settings: {
        service: {
            getContract: string;
            getContractList: string;
            createContract: string;
            updateContract: string;
            deleteContract: string;
            editContractDocument: string;
            getContractShedule: string;
        }
    };

    constructor(private http: HttpService) {
        super('ContractsService');
        this.settings = {
            service: {
                getContract: '/drilling/v1/contracts/{0}',
                getContractList: '/drilling/v1/contracts?term={0}&companyUid={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                createContract: '/drilling/v1/contracts',
                updateContract: '/drilling/v1/contracts/{0}',
                deleteContract: '/drilling/v1/contracts/{0}',
                editContractDocument: '/drilling/v1/contragents/{0}/contracts/{1}/documents',
                getContractShedule: '/drilling/v1/contracts/{0}/schedule?dateFrom={1}&dateTo={2}&sortField={3}&sortOrder={4}',
            }
        };
    }

    public async getContract(uid: string): Promise<Contract> {
        return this.http.get<{ data: Contract }>(String.format(this.settings.service.getContract, uid))
            .then(res => res.data);
    }

    public async getContractList(filter: ContractsListFilter): Promise<PaginationResult<ContractListItem>> {
        return this.http.get<PaginationResult<ContractListItem>>(String.format(this.settings.service.getContractList, filter.term, filter.companyUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createContract(request: ContractCreateOrUpdateRequest): Promise<Contract> {
        return this.http.post<{ data: Contract }>(String.format(this.settings.service.createContract), request)
            .then(res => res.data);
    }

    public async updateContract(uid: string, request: ContractCreateOrUpdateRequest): Promise<Contract> {
        return this.http.put(String.format(this.settings.service.updateContract, uid), request);
    }

    public async deleteContract(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteContract, uid));
    }

    public async editContractDocument(uid: string, contragentUid: string, request: any): Promise<string> {
        const formData = new FormData();

        for (const file of request) {
            formData.append(file.name, file);
        }

        return this.http.upload<{ data: string }>(String.format(this.settings.service.editContractDocument, contragentUid, uid), formData)
            .then(x => x.data);
    }

    public async getContractShedule(filter: ContractSheduleListGetFilter): Promise<{list: ContractSheduleListItem[]}> {
        return this.http.get<{list: ContractSheduleListItem[]}>(String.format(this.settings.service.getContractShedule, filter.uid, filter.dateFrom, filter.dateTo, filter.sortField, filter.sortOrder))
    }
}
