import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { WorkbookExportState, WorkbookExportStateModel } from './workbook-export.reducer';

@Injectable({
    providedIn: 'root'
})
export class WorkbookExportSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([WorkbookExportState], (state: WorkbookExportStateModel) => state.pending);

    static readonly getWorkbookExport = createSelector([WorkbookExportState], (state: WorkbookExportStateModel) => state.loaded);

    static readonly getError = createSelector([WorkbookExportState], (state: WorkbookExportStateModel) => state.error);

    @Select(WorkbookExportSelectors.getPending) pending$: Observable<boolean>;

    @Select(WorkbookExportSelectors.getWorkbookExport) loaded$: Observable<boolean>;

    @Select(WorkbookExportSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('WorkbookExportSelectors');
    }
}
