import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';


import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigWorkbookExportAction, DrillingRigWorkbookExportFailAction, DrillingRigWorkbookExportResetAction, DrillingRigWorkbookExportSuccessAction } from './drilling-rig-workbook-export.actions';
import { DrillingRigWorkbookGetFilter } from '../../models/filters/drilling-rig-workbook-get-filter.model';
import { DrillingRigsService } from '../../services/drilling-rigs.service';

export interface DrillingRigWorkbookExportStateModel {
    loaded: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<DrillingRigWorkbookExportStateModel>({
    name: 'drilingRigWorkbookExport',
    defaults: { pending: false, loaded: false, error: null }
})
@Injectable()
export class DrillingRigWorkbookExportState {
    constructor(private drillingRigService: DrillingRigsService) {}

    @Action(DrillingRigWorkbookExportAction) drilingRigWorkbookExportGet(ctx: StateContext<DrillingRigWorkbookExportStateModel>, action: DrillingRigWorkbookExportAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new DrillingRigWorkbookGetFilter({ filterData: action.payload.filter });
        return this.drillingRigService.getWorkbook(filter)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigWorkbookExportSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigWorkbookExportFailAction(err)), 0));
    }

    @Action(DrillingRigWorkbookExportSuccessAction) drilingRigWorkbookExportGetSuccess(ctx: StateContext<DrillingRigWorkbookExportStateModel>, _: DrillingRigWorkbookExportSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: true, error: null });
    }

    @Action(DrillingRigWorkbookExportFailAction) drilingRigWorkbookExportGetFail(ctx: StateContext<DrillingRigWorkbookExportStateModel>, action: DrillingRigWorkbookExportFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigWorkbookExportResetAction) drilingRigWorkbookExportGetReset(ctx: StateContext<DrillingRigWorkbookExportStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: false, error: null });
    }
}
