import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

import { WorkingAreaSearchState, WorkingAreaSearchStateModel } from './working-area-search.reducer';

@Injectable({
    providedIn: 'root'
})
export class WorkingAreaSearchSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([WorkingAreaSearchState], (state: WorkingAreaSearchStateModel) => state.pending);

    static readonly getWorkingAreaSearch = createSelector([WorkingAreaSearchState], (state: WorkingAreaSearchStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([WorkingAreaSearchState], (state: WorkingAreaSearchStateModel) => state.retrieved);

    static readonly getError = createSelector([WorkingAreaSearchState], (state: WorkingAreaSearchStateModel) => state.error);

    static readonly getAllWorkingAreaSearchLoaded = createSelector([WorkingAreaSearchState], (state: WorkingAreaSearchStateModel) => state.allItemsLoaded);

    @Select(WorkingAreaSearchSelectors.getPending) pending$: Observable<boolean>;

    @Select(WorkingAreaSearchSelectors.getWorkingAreaSearch) workingAreas$: Observable<WorkingAreaListItem[]>;

    @Select(WorkingAreaSearchSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(WorkingAreaSearchSelectors.getError) error$: Observable<ErrorObject>;

    @Select(WorkingAreaSearchSelectors.getAllWorkingAreaSearchLoaded) allWorkingAreaSearchLoaded$: Observable<boolean>;

    constructor() {
        super('WorkingAreaSearchSelectors');
    }
}
