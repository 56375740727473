import { Injectable, NgModule } from '@angular/core';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { RouterStateSerializer } from '@ngxs/router-plugin';

import { NGXS_PLUGINS } from '@ngxs/store';

import { AppModule } from './app.module';

import { AppComponent } from './app.component';

import { PusherFullService } from './burns-ui-framework/shared/services/common/pusher-full.service';
import { PusherService } from './burns-ui-framework/shared/services/common/pusher.service';

import { loggerPlugin } from './burns-ui-framework/shared/store/logger.reducer';

import { CustomRouterStateSerializer } from './burns-ui-framework/shared/store/custom-router-state-serializer';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any> {
        pan: {
            direction: 6
        },
        pinch: { enable: false },
        rotate: { enable: false }
    };
}

@NgModule({
    imports: [
        AppModule,
        HammerModule
    ],
    providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        { provide: NGXS_PLUGINS, useValue: loggerPlugin, multi: true },
        { provide: PusherService, useClass: PusherFullService }
    ],
    bootstrap: [AppComponent]
})
export class AppBrowserModule { }
