import { Employee } from '../../models/business/employee/employee.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class EmployeeAction {
    static readonly type = '[Employee Page] GetEmployee';

    constructor(public payload: { uid: string}) { }
}

export class EmployeeSuccessAction {
    static readonly type = '[Employee API] GetEmployee Success';

    constructor(public payload: Employee) { }
}

export class EmployeeFailAction {
    static readonly type = '[Employee API] GetEmployee Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeResetAction {
    static readonly type = '[Employee Page] GetEmployee Reset';
}
