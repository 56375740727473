import { DrillSpeedChangeListItem } from '../../models/business/analytics/drill-speed-change-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsDrillSpeedChangeDynamicSuccessAction {
    static readonly type = '[AnalyticsDrillSpeedChangeDynamic API] GetAnalyticsDrillSpeedChangeDynamic Success';

    constructor(public payload: DrillSpeedChangeListItem[]) { }
}

export class AnalyticsDrillSpeedChangeDynamicFailAction {
    static readonly type = '[AnalyticsDrillSpeedChangeDynamic API] GetAnalyticsDrillSpeedChangeDynamic Fail';

    constructor(public payload: ErrorObject) { }
}
