<div *ngIf="closeButton" class="close-wrapper" [ngStyle]="{ 'margin-left': width }">
    <exg-button class="btn" styleType="texted" (btnClick)="onClick()">
        <exg-icon class="stub-icon" iconName="close"></exg-icon>
    </exg-button>
</div>
<div class="dialog" [ngStyle]="{ 'min-width': width }">
    <div *ngIf="title" class="dialog-title">{{title | translate}}</div>
    <div class="dialog-content">
        <div class="dialog-component"><div #dynamicComponentContainer></div></div>

        <div *ngIf="!!message" class="dialog-message" [innerHTML]="message | translate"></div>

        <div *ngIf="!!description" [class.message]="!!message" class="dialog-description" [innerHTML]="description | translate"></div>

        <div *ngIf="buttons && buttons[0]" class="dialog-buttons">
            <exg-button *ngFor="let button of buttons; trackBy: trackByButton"
                        class="dialog-button"
                        [color]="button.color"
                        [styleType]="button.styleType"
                        (btnClick)="onClick(button)">
                {{button.text | translate}}
            </exg-button>
        </div>
    </div>
</div>
