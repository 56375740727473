import { LinkedDictionaryEntry } from '../shifts/linked-dictionary-entry.model';
import { LinkedRoute } from './linked-route.model';
import { LinkedWorkingArea } from './linked-working-area.model';

export class EmployeeScheduleItem {
    public uid: string;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public workingArea: LinkedWorkingArea;
    public jobPosition: LinkedDictionaryEntry;
    public routes: LinkedRoute[];
}
