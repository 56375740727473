import { Injectable } from '@angular/core';

import { combineLatest, first } from 'rxjs';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DictionaryJobPositionsDispatchers } from '../../shared-rbc/store/dictionaries/dictionary-job-positions.dispatchers';
import { DictionaryJobPositionsSelectors } from '../../shared-rbc/store/dictionaries/dictionary-job-positions.selectors';
import { EmployeeChangePasswordDispatchers } from '../../shared-rbc/store/employee-change-password/employee-change-password.dispatchers';
import { EmployeeChangePasswordSelectors } from '../../shared-rbc/store/employee-change-password/employee-change-password.selectors';
import { EmployeeCreateDispatchers } from '../../shared-rbc/store/employee/employee-create.dispatchers';
import { EmployeeCreateSelectors } from '../../shared-rbc/store/employee/employee-create.selectors';
import { EmployeeDeleteDispatchers } from '../../shared-rbc/store/employee/employee-delete.dispatchers';
import { EmployeeDeleteSelectors } from '../../shared-rbc/store/employee/employee-delete.selectors';
import { EmployeeScheduleWorkingAreaDispatchers } from '../../shared-rbc/store/employee/employee-schedule-working-area.dispatchers';
import { EmployeeScheduleWorkingAreaSelectors } from '../../shared-rbc/store/employee/employee-schedule-working-area.selectors';
import { EmployeeScheduleDispatchers } from '../../shared-rbc/store/employee/employee-schedule.dispatchers';
import { EmployeeScheduleSelectors } from '../../shared-rbc/store/employee/employee-schedule.selectors';
import { EmployeeUpdateDispatchers } from '../../shared-rbc/store/employee/employee-update.dispatchers';
import { EmployeeUpdateSelectors } from '../../shared-rbc/store/employee/employee-update.selectors';
import { EmployeeDispatchers } from '../../shared-rbc/store/employee/employee.dispatchers';
import { EmployeeSelectors } from '../../shared-rbc/store/employee/employee.selectors';
import { EmployeesDispatchers } from '../../shared-rbc/store/employee/employees.dispatchers';
import { EmployeesSelectors } from '../../shared-rbc/store/employee/employees.selectors';
import { SelfEmployeeSelectors } from '../../shared-rbc/store/user-profile/self-employee.selectors';
import { FullNameSuggestionDispatchers } from '../../shared-rbc/store/suggestions/fullname-suggestion.dispatchers';
import { FullNameSuggestionSelectors } from '../../shared-rbc/store/suggestions/fullname-suggestion.selectors';
import { GeocodingDispatchers } from '../../burns-ui-framework/geocoding/store/geocoding.dispatchers';
import { GeocodingSelectors } from '../../burns-ui-framework/geocoding/store/geocoding.selectors';
import { PhoneCodesDispatchers } from '../../burns-ui-framework/shared/store/phone-codes/phone-codes.dispatchers';
import { PhoneCodesSelectors } from '../../burns-ui-framework/shared/store/phone-codes/phone-codes.selectors';
import { ProfileSelectors } from '../../burns-ui-framework/shared/store/profile/profile.selectors';
import { RoutesDispatchers } from '../../shared-rbc/store/route/routes.dispatchers';
import { RoutesSelectors } from '../../shared-rbc/store/route/routes.selectors';
import { WorkbookExportDispatchers } from '../../shared-rbc/store/employee/workbook-export.dispatchers';
import { WorkbookExportSelectors } from '../../shared-rbc/store/employee/workbook-export.selector';
import { WorkingAreaSearchDispatchers } from '../../shared-rbc/store/working-area/working-area-search.dispatchers';
import { WorkingAreaSearchSelectors } from '../../shared-rbc/store/working-area/working-area-search.selectors';

import { CreateOrUpdateEmployeeAggregationRequest } from '../../shared-rbc/models/business/employee/create-or-update-employee-aggregation-request.model';
import { EmployeeListGetFilterData } from '../../shared-rbc/models/filters/employee-list-get-filter.model';
import { EmployeePasswordUpdateAggregationRequest } from '../../shared-rbc/models/business/employee/employee-password-update-aggregation-request.model';
import { EmployeeScheduleGetFilterData } from '../../shared-rbc/models/filters/employee-schedule-get-filter.model';
import { EmployeeWorkbookGetFilterData } from '../../shared-rbc/models/filters/employee-workbook-get-filter.model';
import { ExgCultureEnum } from '../../burns-ui-framework/shared/models/common/exg-culture.model';
import { FullnamePart } from '../../shared-rbc/models/business/suggestions/fullname-part.enum';
import { PaginationRequest } from '../../burns-ui-framework/shared/models/common/pagination-request.model';
import { RouteListGetFilterData } from '../../shared-rbc/models/filters/route-list-get-filter.model';
import { WorkingAreaListGetFilterData } from '../../shared-rbc/models/filters/working-area-list-get-filter.model';

@Injectable({
    providedIn: 'root'
})
export class PersonnelSandbox extends BaseSingletonService {

    public profile$ = this.profileSelectors.profile$;

    public employees$ = this.employeesSelectors.employees$;
    public pending$ = this.employeesSelectors.pending$;
    public error$ = this.employeesSelectors.error$;
    public allEmployeesLoaded$ = this.employeesSelectors.allEmployeesLoaded$;
    public retrieved$ = this.employeesSelectors.retrieved$;
    public filter$ = this.employeesSelectors.filter$;
    public total$ = this.employeesSelectors.total$;

    public routes$ = this.routesSelectors.routes$;

    public employeeSchedule$ = this.employeeScheduleSelectors.employeeSchedule$;
    public employeeSchedulePending$ = this.employeeScheduleSelectors.pending$;
    public employeeScheduleError$ = this.employeeScheduleSelectors.error$;
    public allEmployeeScheduleLoaded$ = this.employeeScheduleSelectors.allEmployeeScheduleLoaded$;
    public employeeScheduleRetrieved$ = this.employeeScheduleSelectors.retrieved$;
    public employeeScheduleFilter$ = this.employeeScheduleSelectors.filter$;
    public employeeScheduleTotal$ = this.employeeScheduleSelectors.total$;

    public employee$ = this.employeeSelectors.employee$;
    public employeePending$ = this.employeeSelectors.pending$;
    public employeeError$ = this.employeeSelectors.error$;

    public created$ = this.employeeCreateSelectors.created$;
    public createPending$ = this.employeeCreateSelectors.pending$;
    public createError$ = this.employeeCreateSelectors.error$;

    public updated$ = this.employeeUpdateSelectors.updated$;
    public updatePending$ = this.employeeUpdateSelectors.pending$;
    public updateError$ = this.employeeUpdateSelectors.error$;

    public deleted$ = this.employeeDeleteSelectors.deleted$;
    public deletePending$ = this.employeeDeleteSelectors.pending$;
    public deleteError$ = this.employeeDeleteSelectors.error$;

    public selfEmployee$ = this.selfEmployeeSelectors.employee$;
    public selfEmployeePending$ = this.selfEmployeeSelectors.pending$;
    public selfEmployeeReadonly$ = this.selfEmployeeSelectors.readonly$;
    public selfEmployeeViewer$ = this.selfEmployeeSelectors.viewer$;
    public selfEmployeeError$ = this.selfEmployeeSelectors.error$;

    public phoneCodesPending$ = this.phoneCodesSelectors.pending$;
    public phoneCodesError$ = this.phoneCodesSelectors.error$;
    public phoneCodes$ = this.phoneCodesSelectors.phoneCodes$;

    public firstNames$ = this.fullNameSuggestionSelectors.firstNames$;
    public secondNames$ = this.fullNameSuggestionSelectors.secondNames$;
    public lastNames$ = this.fullNameSuggestionSelectors.lastNames$;

    public exportLoaded$ = this.workbookExportSelectors.loaded$;
    public exportPending$ = this.workbookExportSelectors.pending$;
    public exportError$ = this.workbookExportSelectors.error$;

    public changePasswordPending$ = this.employeeChangePasswordSelectors.pending$;
    public changePasswordError$ = this.employeeChangePasswordSelectors.error$;
    public passwordChanged$ = this.employeeChangePasswordSelectors.changed$;

    public places$ = this.geocodingSelectors.places$;

    public workingAreasSearch$ = this.workingAreaSearchSelectors.workingAreas$;

    public employeeWorkingAreas$ = this.employeeScheduleWorkingAreaSelectors.workingAreas$;

    public jobPositions$ = this.jobPositionsSelectors.jobPositions$;
    public jobPositionsPending$ = this.jobPositionsSelectors.pending$;

    constructor(private profileSelectors: ProfileSelectors,
                private employeeChangePasswordDispatchers: EmployeeChangePasswordDispatchers,
                private employeeChangePasswordSelectors: EmployeeChangePasswordSelectors,
                private employeeCreateDispatchers: EmployeeCreateDispatchers,
                private employeeCreateSelectors: EmployeeCreateSelectors,
                private employeeDeleteDispatchers: EmployeeDeleteDispatchers,
                private employeeDeleteSelectors: EmployeeDeleteSelectors,
                private employeeScheduleWorkingAreaDispatchers: EmployeeScheduleWorkingAreaDispatchers,
                private employeeScheduleWorkingAreaSelectors: EmployeeScheduleWorkingAreaSelectors,
                private employeeScheduleDispatchers: EmployeeScheduleDispatchers,
                private employeeScheduleSelectors: EmployeeScheduleSelectors,
                private employeeUpdateDispatchers: EmployeeUpdateDispatchers,
                private employeeUpdateSelectors: EmployeeUpdateSelectors,
                private employeeDispatchers: EmployeeDispatchers,
                private employeeSelectors: EmployeeSelectors,
                private employeesDispatchers: EmployeesDispatchers,
                private employeesSelectors: EmployeesSelectors,
                private selfEmployeeSelectors: SelfEmployeeSelectors,
                private fullNameSuggestionDispatchers: FullNameSuggestionDispatchers,
                private fullNameSuggestionSelectors: FullNameSuggestionSelectors,
                private phoneCodesDispatchers: PhoneCodesDispatchers,
                private phoneCodesSelectors: PhoneCodesSelectors,
                private geocodingDispatchers: GeocodingDispatchers,
                private geocodingSelectors: GeocodingSelectors,
                private routesDispatchers: RoutesDispatchers,
                private routesSelectors: RoutesSelectors,
                private jobPositionsDispatchers: DictionaryJobPositionsDispatchers,
                private jobPositionsSelectors: DictionaryJobPositionsSelectors,
                private workbookExportDispatchers: WorkbookExportDispatchers,
                private workbookExportSelectors: WorkbookExportSelectors,
                private workingAreaSearchDispatchers: WorkingAreaSearchDispatchers,
                private workingAreaSearchSelectors: WorkingAreaSearchSelectors) {
        super('PersonnelSandbox');
    }

    public dispatchPhoneCodesGet(culture: ExgCultureEnum) {
        combineLatest([this.phoneCodes$, this.phoneCodesPending$]).pipe(first()).subscribe((res) => {
            if (res[0].length < 1 && !res[1]) {
                this.phoneCodesDispatchers.dispatchPhoneCodesAction(culture, 'contactsPage');
            }
        });
    }

    public dispatchEmployees(request: PaginationRequest) {
        this.employeesDispatchers.dispatchEmployeesAction(request);
    }

    public dispatchEmployeesReset() {
        this.employeesDispatchers.dispatchEmployeesResetAction();
    }

    public dispatchEmployeesSetFilter(filterData: EmployeeListGetFilterData) {
        this.employeesDispatchers.dispatchEmployeesSetFilterAction(filterData);
    }

    public dispatchEmployeeSchedule(request: PaginationRequest) {
        this.employeeScheduleDispatchers.dispatchEmployeeScheduleAction(request);
    }

    public dispatchEmployeeScheduleReset() {
        this.employeeScheduleDispatchers.dispatchEmployeeScheduleResetAction();
    }

    public dispatchEmployeeScheduleSetFilter(filterData: EmployeeScheduleGetFilterData) {
        this.employeeScheduleDispatchers.dispatchEmployeeScheduleSetFilterAction(filterData);
    }

    public dispatchEmployee(uid: string) {
        this.employeeDispatchers.dispatchEmployeeAction(uid);
    }

    public dispatchEmployeeReset() {
        this.employeeDispatchers.dispatchEmployeeResetAction();
    }

    public dispatchEmployeeCreate(request: CreateOrUpdateEmployeeAggregationRequest) {
        this.employeeCreateDispatchers.dispatchEmployeeCreateAction(request);
    }

    public dispatchEmployeeCreateReset() {
        this.employeeCreateDispatchers.dispatchEmployeeCreateResetAction();
    }

    public dispatchEmployeeUpdate(uid: string, request: CreateOrUpdateEmployeeAggregationRequest) {
        this.employeeUpdateDispatchers.dispatchEmployeeUpdateAction(uid, request);
    }

    public dispatchEmployeeUpdateReset() {
        this.employeeUpdateDispatchers.dispatchEmployeeUpdateResetAction();
    }

    public dispatchEmployeeDelete(uid: string) {
        this.employeeDeleteDispatchers.dispatchEmployeeDeleteAction(uid);
    }

    public dispatchEmployeeDeleteReset() {
        this.employeeDeleteDispatchers.dispatchEmployeeDeleteResetAction();
    }

    public dispatchSuggestionFullName(term: string, parts?: FullnamePart[]) {
        this.fullNameSuggestionDispatchers.dispatchFullNameSuggestionAction(term, parts);
    }

    public dispatchSuggestionFullNameReset() {
        this.fullNameSuggestionDispatchers.dispatchFullNameSuggestionResetAction();
    }

    public dispatchGeocodingAction(countryCode: string, term: string) {
        this.geocodingDispatchers.dispatchGeocodingAction(countryCode, term);
    }

    public dispatchGeocodingResetAction() {
        this.geocodingDispatchers.dispatchGeocodingResetAction();
    }

    public dispatchRoutes(request: PaginationRequest) {
        this.routesDispatchers.dispatchRoutesAction(request);
    }

    public dispatchRoutesReset() {
        this.routesDispatchers.dispatchRoutesResetAction();
    }

    public dispatchRoutesSetFilter(filterData: RouteListGetFilterData) {
        this.routesDispatchers.dispatchRoutesSetFilterAction(filterData);
    }

    public dispatchWorkingAreaSearch(request: WorkingAreaListGetFilterData) {
        this.workingAreaSearchDispatchers.dispatchWorkingAreaSearchAction(request);
    }

    public dispatchWorkingAreaSearchReset() {
        this.workingAreaSearchDispatchers.dispatchWorkingAreaSearchResetAction();
    }

    public dispatchEmployeeScheduleWorkingArea(request: WorkingAreaListGetFilterData) {
        this.employeeScheduleWorkingAreaDispatchers.dispatchEmployeeScheduleWorkingAreaAction(request);
    }

    public dispatchEmployeeScheduleWorkingAreaReset() {
        this.employeeScheduleWorkingAreaDispatchers.dispatchEmployeeScheduleWorkingAreaResetAction();
    }

    public dispatchWorkbookExport(filter: EmployeeWorkbookGetFilterData) {
        this.workbookExportDispatchers.dispatchWorkbookExportAction(filter);
    }

    public dispatchWorkbookExportReset() {
        this.workbookExportDispatchers.dispatchWorkbookExportResetAction();
    }

    public dispatchChangePassword(userUid: string, request: EmployeePasswordUpdateAggregationRequest) {
        this.employeeChangePasswordDispatchers.dispatchEmployeeChangePasswordAction(userUid, request);
    }

    public dispatchChangePasswordReset() {
        this.employeeChangePasswordDispatchers.dispatchEmployeeChangePasswordResetAction();
    }

    public dispatchJobPositions() {
        combineLatest([this.jobPositions$, this.jobPositionsPending$]).pipe(first()).subscribe((res) => {
            if (!res[0] && !res[1]) {
                this.jobPositionsDispatchers.dispatchDictionaryJobPositionsAction();
            }
        });
    }
}
