export enum ComponentType {
    Country = 0,
    Region = 1,
    Province = 2,
    Area = 3,
    Locality = 4,
    District = 5,
    Street = 6,
    House = 7,
    Hydro = 8,
    Station = 9,
    MetroStation = 10,
    RailwayStation = 11,
    Route = 12,
    Vegetation = 13,
    Airport = 14,
    Other = 15,
    Entrance = 16,
    Level = 17,
    Apartment = 18,
    Unknow = 100
}
