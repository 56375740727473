import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class ContractDeleteAction {
    static readonly type = '[My Contract Page] DeleteContract';

    constructor(public payload: { uid: string }) { }
}

export class ContractDeleteSuccessAction {
    static readonly type = '[My Contracts API] DeleteContract Success';

    constructor(public payload: { uid: string }) { }
}

export class ContractDeleteFailAction {
    static readonly type = '[My Contracts API] DeleteContract Fail';

    constructor(public payload: ErrorObject) { }
}

export class ContractDeleteResetAction {
    static readonly type = '[My Contract Page] DeleteContract Reset';
}
