import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransportBreakdownsService } from '../../services/transport-breakdowns.service';

import { TransportBreakdown } from '../../models/business/transport-breakdown/transport-breakdown.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportBreakdownUpdateSuccessAction } from './transport-breakdown-update.actions';
import { TransportBreakdownAction, TransportBreakdownFailAction, TransportBreakdownResetAction, TransportBreakdownSuccessAction } from './transport-breakdown.actions';

export interface TransportBreakdownStateModel {
    entity: TransportBreakdown;
    pending: boolean;
    error: ErrorObject;
}

@State<TransportBreakdownStateModel>({
    name: 'transportBreakdown',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class TransportBreakdownState {
    constructor(private transportBreakdownsService: TransportBreakdownsService) {}


    @Action(TransportBreakdownAction) transportBreakdownGet(ctx: StateContext<TransportBreakdownStateModel>, action: TransportBreakdownAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.transportBreakdownsService.getTransportBreakdown(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new TransportBreakdownSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportBreakdownFailAction(err)), 0));
    }

    @Action(TransportBreakdownSuccessAction) transportBreakdownGetSuccess(ctx: StateContext<TransportBreakdownStateModel>, action: TransportBreakdownSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(TransportBreakdownFailAction) transportBreakdownGetFail(ctx: StateContext<TransportBreakdownStateModel>, action: TransportBreakdownFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransportBreakdownResetAction) transportBreakdownGetReset(ctx: StateContext<TransportBreakdownStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(TransportBreakdownUpdateSuccessAction) transportBreakdownUpdateSuccessAction(ctx: StateContext<TransportBreakdownStateModel>, action: TransportBreakdownUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = <any>{ ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
