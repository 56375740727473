<div class="btn" type="button">
    <div *ngFor="let monthToDisplay of monthsToDisplay;" class="date empty" [style.width.px]="baseWidthPx">
    </div>
</div>

<button class="overflow btn" type="button">
    <div class="dates-container" [style.width.px]="getWidth()">
        <ng-container *ngFor="let scheduleDateItem of scheduleDateItems; trackBy: trackByScheduleDateItem">
            <!-- <div *ngIf="!scheduleDateItem" [style.width.px]="baseWidthPx" class="date item empty">
                <span></span>
            </div> -->
            <div *ngIf="!!scheduleDateItem" exgTooltip="{{scheduleDateItem.workingArea?.title || ((!!scheduleDateItem.isNotRoute ? 'Between routes' : 'Watch') | translate)}} {{scheduleDateItem.brigadeNumber ? '(' + scheduleDateItem.brigadeNumber + ')' : ''}}" [class.actual]="!scheduleDateItem.isNotRoute" [style.width.px]="scheduleDateItem.widthLength * baseWidthPx" [style.left.px]="scheduleDateItem.startOffset * baseWidthPx" class="date item"  (click)="onEmployeeRouteClick(scheduleDateItem)">
                <span class="title">{{scheduleDateItem.workingArea?.title || ((!!scheduleDateItem.isNotRoute ? 'Between routes' : 'Watch') | translate)}}</span>
                <span class="title" *ngIf="scheduleDateItem.brigadeNumber">({{scheduleDateItem.brigadeNumber}})</span>
            </div>
        </ng-container>
    </div>
</button>
