import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransportsService } from '../../services/transports.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportDeleteAction, TransportDeleteFailAction, TransportDeleteResetAction, TransportDeleteSuccessAction } from './transport-delete.actions';

export interface TransportDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<TransportDeleteStateModel>({
    name: 'transportDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class TransportDeleteState {
    constructor(private transportsService: TransportsService) { }

    @Action(TransportDeleteAction) async transportDelete(ctx: StateContext<TransportDeleteStateModel>, action: TransportDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.transportsService.deleteTransport(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new TransportDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new TransportDeleteFailAction(error)), 0));
    }

    @Action(TransportDeleteSuccessAction) transportDeleteSuccess(ctx: StateContext<TransportDeleteStateModel>, _: TransportDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(TransportDeleteFailAction) transportDeleteFail(ctx: StateContext<TransportDeleteStateModel>, action: TransportDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransportDeleteResetAction) transportDeleteReset(ctx: StateContext<TransportDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
