<section class="wrapper">
    <div class="title">{{ title | translate }}</div>

    <exg-autocomplete
        class="control"
        [maxlength]="200"
        [allowNull]="true"
        [readonly]="isUser"
        [data]="workingAreas$ | async"
        [value]="workingArea"
        placeholder="{{ 'Working area' | translate }}"
        [displayValueFunction]="workingAreaDisplayValueFunction"
        (selectionChange)="onWorkingAreaChanged($event)"
        (valueChange)="onWorkingAreaSearch($event)"></exg-autocomplete>

    <ng-container [ngSwitch]="selectionType">
        <div class="column" *ngSwitchCase="selectionTypes.Month">
            <exg-datepicker-month
                placeholder="{{ 'Month for export' | translate }}"
                [minDate]="minCalendarDate"
                [maxDate]="maxDate"
                [value]="dateFrom"
                (valueChange)="onDateChanged($event)"></exg-datepicker-month>
        </div>
        <div class="column" *ngSwitchCase="selectionTypes.Range">
            <exg-datepicker
                class="datepicker"
                placeholder="{{ 'Date of Start' | translate }}"
                [minDate]="minCalendarDate"
                (valueChange)="onStartDateChanged($event)"
                [value]="dateFrom"></exg-datepicker>

            <exg-datepicker
                class="datepicker"
                placeholder="{{ 'Date of End' | translate }}"
                [minDate]="dateFrom"
                (valueChange)="onEndDateChanged($event)"
                [value]="dateTo">
            </exg-datepicker>
        </div>

        <div class="column" *ngSwitchCase="selectionTypes.Year">
            <exg-select 
                [data]="years$ | async" 
                placeholder="{{ 'Year' | translate }}" 
                [value]="selectedYear" 
                (valueChange)="onYearChanged($event)">
            </exg-select>
        </div>

    </ng-container>

    <div class="btns">
        <exg-button class="cancel" styleType="outlined" (btnClick)="onCancelClick()">{{ 'Cancel' | translate }}</exg-button>
        <exg-button class="submit" [disabled]="!workingArea && isWorkingAreaRequired" color="primary" (btnClick)="onExportClick()">
            <exg-icon class="btn-icon" iconName="export"></exg-icon>
            {{ 'Export' | translate }}
        </exg-button>
    </div>
</section>
