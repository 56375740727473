import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SortOrder } from '../../../burns-ui-framework/shared/models/filters/sort-order.model';

@Component({
    selector: 'rbc-filter-field',
    templateUrl: './rbc-filter-field.component.html',
    styleUrls: ['./rbc-filter-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcFilterFieldComponent {
    @Input() name: string;
    @Input() fieldName: string;
    @Input() sortField: string;
    @Input() sortOrder: SortOrder;
    @Input() reverse: boolean = false;
    @Input() isEmpty: boolean = false;

    @Output() readonly sortingChange = new EventEmitter<{ sortOrder: SortOrder, sortField: string }>();

    public sortOrders = SortOrder;

    public onSortingChange() {
        const sortOrder = this.fieldName !== this.sortField || this.sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
        this.sortingChange.emit({ sortOrder, sortField: this.fieldName });
    }
}
