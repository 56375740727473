import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsService } from '../../services/analytics.service';

import { AnalyticGetFilter, AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { DrillSpeedChangeListItem } from '../../models/business/analytics/drill-speed-change-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { AnalyticsDetailsDrillSpeedChangeDynamicAction, AnalyticsDetailsDrillSpeedChangeDynamicFailAction, AnalyticsDetailsDrillSpeedChangeDynamicSuccessAction } from './analytics-details-drill-speed-change-dynamic.actions';
import { AnalyticsDetailsResetAction } from './analytics-details.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface AnalyticsDetailsDrillSpeedChangeDynamicStateModel {
    entities: DrillSpeedChangeListItem[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: AnalyticGetFilterData;
}

@State<AnalyticsDetailsDrillSpeedChangeDynamicStateModel>({
    name: 'AnalyticsDetailsDrillSpeedChangeDynamic',
    defaults: { pending: false, entities: [], retrieved: false, error: null, filter: {} }
})
@Injectable()
export class AnalyticsDetailsDrillSpeedChangeDynamicState {
    constructor(private analyticsService: AnalyticsService) { }

    @Action(AnalyticsDetailsDrillSpeedChangeDynamicAction) analyticsSetFilter(ctx: StateContext<AnalyticsDetailsDrillSpeedChangeDynamicStateModel>, action: AnalyticsDetailsDrillSpeedChangeDynamicAction) {
        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            ctx.setState({ ...state, filter: filterData, retrieved: false });

            const filter = new AnalyticGetFilter({ filterData });

            return this.analyticsService.getDrillSpeedChangeDynamic(filter)
                .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsDetailsDrillSpeedChangeDynamicSuccessAction(resp)), 0))
                .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsDetailsDrillSpeedChangeDynamicFailAction(err)), 0));
        }
    }

    @Action(AnalyticsDetailsDrillSpeedChangeDynamicSuccessAction) lossOfProfitGetSuccess(ctx: StateContext<AnalyticsDetailsDrillSpeedChangeDynamicStateModel>, action: AnalyticsDetailsDrillSpeedChangeDynamicSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(AnalyticsDetailsDrillSpeedChangeDynamicFailAction) lossOfProfitGetFail(ctx: StateContext<AnalyticsDetailsDrillSpeedChangeDynamicStateModel>, action: AnalyticsDetailsDrillSpeedChangeDynamicFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsDetailsResetAction) analyticsGetReset(ctx: StateContext<AnalyticsDetailsDrillSpeedChangeDynamicStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
