import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';

import { fromEvent, Subject, takeUntil } from 'rxjs';

import { EmployeeScheduleGetFilterData } from '../../models/filters/employee-schedule-get-filter.model';
import { EmployeeScheduleItem } from '../../models/business/employee/employee-schedule-item.model';
import { LinkedRoute } from '../../models/business/employee/linked-route.model';

import { DateUtils } from '../../../burns-ui-framework/shared/utils/date-utils';
import { Utils } from '../../../burns-ui-framework/shared/utils/utils';

export class LinkedRouteExtended extends LinkedRoute {
    public colspan: number;
}

@Component({
    selector: 'rbc-employee-schedules',
    templateUrl: './rbc-employee-schedules.component.html',
    styleUrls: ['./rbc-employee-schedules.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcEmployeeSchedulesComponent implements OnChanges, AfterViewChecked, OnDestroy {
    @Input() scheduleItems: EmployeeScheduleItem[];
    @Input() allScheduleLoaded: boolean;
    @Input() retrieved: boolean;
    @Input() filter: EmployeeScheduleGetFilterData;
    @Input() hideFullName: boolean;

    @Output() readonly loadMore = new EventEmitter<void>();
    @Output() readonly sortingChange = new EventEmitter<EmployeeScheduleGetFilterData>();
    @Output() readonly openEmployee = new EventEmitter<{ uid: string }>();
    @Output() readonly openEmployeeRoute = new EventEmitter<{ uid: string, routeUid: string }>();

    @ViewChild('scheduleWrapper', { static: true, read: ElementRef }) public exgWrapper: ElementRef;
    @ViewChild('scheduleContainer', { static: true, read: ElementRef }) public exgContainer: ElementRef;

    public baseWidthPx = 66;

    public monthsToDisplay: string[];

    private unsubscribe$ = new Subject();

    constructor(private cd: ChangeDetectorRef) {
        fromEvent(window, 'resize').pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.calculateDateCellSize());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ((changes.filter || changes.scheduleItems) && !!this.filter && !!this.scheduleItems && !!this.scheduleItems[0]) {
            const dateTo = this.getMaxShcduleRoutesDate();
            const startMonth = DateUtils.startOf(DateUtils.convertEpocToString(this.filter.dateFrom), 'month');
            const monthsCountByDate = DateUtils.getDateDifference(DateUtils.convertEpocToString(this.filter.dateFrom), DateUtils.convertEpocToString(dateTo), 'months');
            const monthsCount = monthsCountByDate >= 11
                ? monthsCountByDate
                : monthsCountByDate < 1
                    ? 0
                    : 11;

            const monthsDaysArray = [];
            for (let index = 0; index <= monthsCount; index++) {
                monthsDaysArray.push(DateUtils.add(startMonth, index, 'months'));
            }

            this.monthsToDisplay = monthsDaysArray;

            const wrapperWidth = this.exgWrapper.nativeElement.clientWidth;
            while (wrapperWidth  > (this.baseWidthPx * this.monthsToDisplay.length)) {
                this.baseWidthPx += 1;
            }
        }
    }

    public ngAfterViewChecked() {
        this.calculateDateCellSize();
    }

    public ngOnDestroy() {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    public trackByEmployees(_, item: EmployeeScheduleItem) {
        return item.uid;
    }

    public trackByDate(_, item: string) {
        return item;
    }

    public scrollLeft(): void {
        this.calculateSrollState('left');
    }

    public scrollRight(): void {
        this.calculateSrollState('right');
    }

    public onOpenEmployee(uid: string) {
        this.openEmployee.emit({ uid });
    }

    public onOpenEmployeeRoute($event: { uid: string, routeUid: string }) {
        this.openEmployeeRoute.emit($event);
    }

    public getClientHeight() {
        return this.exgContainer.nativeElement.clientHeight;
    }

    public onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.loadMore.emit();
        }
    }

    private calculateDateCellSize() {
        const wrapperWidth = this.exgWrapper.nativeElement.clientWidth;
        if (wrapperWidth > 0 && this.monthsToDisplay?.length > 0) {
            while (wrapperWidth  > (this.baseWidthPx * this.monthsToDisplay.length)) {
                this.baseWidthPx += 1;
            }

            this.cd.markForCheck();
        }
    }

    private getMaxShcduleRoutesDate() {
        return Math.max(...this.scheduleItems.filter(sItem => !!sItem.routes && !!sItem.routes[0]).map(x => Math.max(...x.routes.map(r => r.endDate))));
    }

    private calculateSrollState(scrollState: 'left' | 'right') {
        const wrapperWidth = this.exgWrapper.nativeElement.clientWidth;
        const containerWidth = this.exgContainer.nativeElement.clientWidth;

        if (containerWidth <= wrapperWidth) {
            return;
        }

        const transform = Utils.stringToNumber(this.exgContainer.nativeElement.style.transform);
        let translatePosition = transform;
        const translateOffset = this.baseWidthPx;

        if (scrollState === 'left') {
            translatePosition = translatePosition + translateOffset;
            this.exgContainer.nativeElement.style.transform = transform < 0 && translatePosition <= 0 ? `translate(${translatePosition}px)` : `translate(0px)`;
        }

        if (scrollState === 'right') {
            translatePosition = translatePosition - translateOffset;
            this.exgContainer.nativeElement.style.transform = containerWidth - wrapperWidth > Math.abs(translatePosition) ? `translate(${translatePosition}px)` : `translate(${-(containerWidth - wrapperWidth)}px)`;
        }
    }
}
