import { DictionaryEntryCreateOrUpdate } from '../../models/business/dictionaries/dictionary-entry-create-or-update.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DictionaryVehicleTypesUpdateAction {
    static readonly type = '[DictionaryVehicleTypesUpdate Page] DictionaryVehicleTypesUpdate';

    constructor(public payload: { request: DictionaryEntryCreateOrUpdate[] }) { }
}

export class DictionaryVehicleTypesUpdateSuccessAction {
    static readonly type = '[DictionaryVehicleTypesUpdate API] DictionaryVehicleTypesUpdate Success';
}

export class DictionaryVehicleTypesUpdateFailAction {
    static readonly type = '[DictionaryVehicleTypesUpdate API] DictionaryVehicleTypesUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryVehicleTypesUpdateResetAction {
    static readonly type = '[DictionaryVehicleTypesUpdate Page] DictionaryVehicleTypesUpdate Reset';
}
