import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { SuggestionFullName } from '../../models/business/suggestions/suggestion-full-name.model';

import { FullNameSuggestionState, FullNameSuggestionStateModel } from './fullname-suggestion.reducer';

@Injectable({
    providedIn: 'root'
})
export class FullNameSuggestionSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([FullNameSuggestionState], (state: FullNameSuggestionStateModel) => state.pending);

    static readonly getFullNameSuggestion = createSelector([FullNameSuggestionState], (state: FullNameSuggestionStateModel) => state.entities);

    static readonly getFirstNameSuggestion = createSelector([FullNameSuggestionState], (state: FullNameSuggestionStateModel) => state.firstNames);

    static readonly getSecondNameSuggestion = createSelector([FullNameSuggestionState], (state: FullNameSuggestionStateModel) => state.secondNames);

    static readonly getLastNameSuggestion = createSelector([FullNameSuggestionState], (state: FullNameSuggestionStateModel) => state.lastNames);

    static readonly getError = createSelector([FullNameSuggestionState], (state: FullNameSuggestionStateModel) => state.error);

    @Select(FullNameSuggestionSelectors.getPending) pending$: Observable<boolean>;

    @Select(FullNameSuggestionSelectors.getFullNameSuggestion) suggestion$: Observable<SuggestionFullName[]>;

    @Select(FullNameSuggestionSelectors.getFirstNameSuggestion) firstNames$: Observable<SuggestionFullName[]>;

    @Select(FullNameSuggestionSelectors.getSecondNameSuggestion) secondNames$: Observable<SuggestionFullName[]>;

    @Select(FullNameSuggestionSelectors.getLastNameSuggestion) lastNames$: Observable<SuggestionFullName[]>;

    @Select(FullNameSuggestionSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('FullNameSuggestionSelectors');
    }
}
