import { TransportDrillingRigsListGetFilterData } from '../../models/filters/transport-drilling-rigs-list-get-filter.model';
import { TransportDrillingRigsItem } from '../../models/business/transport-drilling-rigs/transport-drilling-rigs-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class TransportDrillingRigsAction {
    static readonly type = '[TransportDrillingRigs Page] GetTransportDrillingRigs';

    constructor(public payload: PaginationRequest) { }
}

export class TransportDrillingRigsSuccessAction {
    static readonly type = '[TransportDrillingRigs API] GetTransportDrillingRigs Success';

    constructor(public payload: { list: PaginationResult<TransportDrillingRigsItem>, pagination: PaginationRequest }) { }
}

export class TransportDrillingRigsFailAction {
    static readonly type = '[TransportDrillingRigs API] GetTransportDrillingRigs Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportDrillingRigsResetAction {
    static readonly type = '[TransportDrillingRigs Page] GetTransportDrillingRigs Reset';
}

export class TransportDrillingRigsSetFilterAction {
    static readonly type = '[TransportDrillingRigs API] SetFilter';

    constructor(public payload: TransportDrillingRigsListGetFilterData) { }
}
