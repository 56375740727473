import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsService } from '../../services/analytics.service';

import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilter, AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { AnalyticsProductiveBalanceSummaryFailAction, AnalyticsProductiveBalanceSummarySuccessAction } from './analytics-productive-balance-summary.actions';
import { AnalyticsGetAction, AnalyticsResetAction } from './analytics.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface AnalyticsProductiveBalanceSummaryStateModel {
    entities: ActivityHours[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: AnalyticGetFilterData;
}

@State<AnalyticsProductiveBalanceSummaryStateModel>({
    name: 'AnalyticsProductiveBalanceSummary',
    defaults: { pending: false, entities: [], retrieved: false, error: null, filter: {} }
})
@Injectable()
export class AnalyticsProductiveBalanceSummaryState {
    constructor(private analyticsService: AnalyticsService) { }

    @Action(AnalyticsGetAction) analyticsSetFilter(ctx: StateContext<AnalyticsProductiveBalanceSummaryStateModel>, action: AnalyticsGetAction) {
        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            ctx.setState({ ...state, filter: filterData, retrieved: false });

            const filter = new AnalyticGetFilter({ filterData });

            return this.analyticsService.getProductiveBalanceSummary(filter)
                .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsProductiveBalanceSummarySuccessAction(resp)), 0))
                .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsProductiveBalanceSummaryFailAction(err)), 0));
        }
    }

    @Action(AnalyticsProductiveBalanceSummarySuccessAction) lossOfProfitGetSuccess(ctx: StateContext<AnalyticsProductiveBalanceSummaryStateModel>, action: AnalyticsProductiveBalanceSummarySuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(AnalyticsProductiveBalanceSummaryFailAction) lossOfProfitGetFail(ctx: StateContext<AnalyticsProductiveBalanceSummaryStateModel>, action: AnalyticsProductiveBalanceSummaryFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsResetAction) analyticsGetReset(ctx: StateContext<AnalyticsProductiveBalanceSummaryStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
