import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from './tree-node.model';

@Component({
    selector: 'exg-tree',
    templateUrl: './exg-tree.component.html',
    styleUrls: ['./exg-tree.component.scss'],
})
export class ExgTree implements OnChanges {
    
    @Input() public value: string[] = [];
    @Input() public data: TreeNode[];

    @Output() readonly valueChange = new EventEmitter<string[]>();

    private showedChilds = {};

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.data && this.data) || (changes.value && this.value)) {
            this.updateCheckedState();
        }
    }

    public setAll(node: TreeNode, completed: boolean): void {
        this.setChildrenCheckedState(node, completed);
        this.updateParentTreeNodeState(node);
        this.emitSelectedUids();
    }

    public findParentTreeNode(nodes: TreeNode[], childToFind: TreeNode): TreeNode | null {
        for (const node of nodes || []) {
            if (node.childrens?.some(child => child.uid === childToFind.uid)) {
                return node;
            }
            const foundInChildren = this.findParentTreeNode(node.childrens, childToFind);
            if (foundInChildren) {
                return foundInChildren;
            }
        }
        return null;
    }

    public onNodeTitleClick(node: TreeNode) {
        const expandState = !node.showChilds;
        node.showChilds = expandState;
        this.showedChilds[node.uid] = expandState;
    }

    private setChildrenCheckedState(node: TreeNode, completed: boolean): void {
        node.checked = completed;
        node.indeterminate = false;
        node.showChilds = !!this.showedChilds[node.uid];
        node.childrens?.forEach(cTreeNode => {
            this.setChildrenCheckedState(cTreeNode, completed);
        });
    }

    private updateParentTreeNodeState(node: TreeNode): void {
        const parentTreeNode = this.findParentTreeNode(this.data, node);
        if (parentTreeNode && parentTreeNode.childrens) {
            const allChecked = parentTreeNode.childrens.every(c => c.checked);
            const allUnchecked = parentTreeNode.childrens.every(c => !c.checked);
            const someChecked = parentTreeNode.childrens.some(c => c.checked);
            const someIndeterminate = parentTreeNode.childrens.some(c => c.indeterminate);
            const everyIndeterminate = parentTreeNode.childrens.every(c => c.indeterminate);
            parentTreeNode.checked = allChecked;
            parentTreeNode.indeterminate = (!allChecked && !allUnchecked && (someChecked || someIndeterminate)) || everyIndeterminate;

            if (parentTreeNode.uid) {
                this.updateParentTreeNodeState(parentTreeNode);
            }
        }
    }

    private updateCheckedState(): void {
        if (this.data && this.value) {
            this.data.forEach(node => {
                this.updateTreeNodeCheckedState(node, this.value.includes(node.uid));
            });
            this.data.forEach(node => {
                if (node.childrens) {
                    this.updateParentTreeNodeState(node);
                }
            });
        }
    }

    private updateTreeNodeCheckedState(node: TreeNode, isSelected: boolean): void {
        if (!node.childrens) {
            node.indeterminate = false;
        }
        node.checked = isSelected;
        node.showChilds = !!this.showedChilds[node.uid];
        if (node.childrens) {
            node.childrens.forEach(child => {
                const childIsSelected = this.value.includes(child.uid);
                this.updateTreeNodeCheckedState(child, childIsSelected);
            });
        }
        node.indeterminate =
            (node.childrens?.some(child => child.indeterminate) ||
                (node.childrens?.some(child => child.checked) && !node.childrens?.every(child => child.checked))) ??
            false;

        this.updateParentTreeNodeState(node);
    }


    

    private getSelectedUids(nodes: TreeNode[]): string[] {
        let value: string[] = [];
        nodes.forEach(node => {
            if (node.checked) {
                value.push(node.uid);
            }
            if (node.childrens) {
                value = value.concat(this.getSelectedUids(node.childrens));
            }
        });
        return value;
    }

    private emitSelectedUids(): void {
        const value = this.getSelectedUids(this.data);
        this.valueChange.emit(value);
    }
}
