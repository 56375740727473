import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { TransportCreateUpdateRequest } from '../models/business/transport/transport-create-update-request.model';
import { TransportListGetFilter } from '../models/filters/transport-list-get-filter.model';
import { TransportListItem } from '../models/business/transport/transport-list-item.model';
import { Transport } from '../models/business/transport/transport.model';
import { ExgTranslateService } from '../../burns-ui-framework/shared/services/common/exg-translate.service';
import { TransportWorkbookGetFilter } from '../models/filters/transport-workbook-get-filter.model';

import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';
import { String } from '../../burns-ui-framework/shared/utils/string';
import { DateUtils } from '../../burns-ui-framework/shared/utils/date-utils';

@Injectable({
    providedIn: 'root'
})
export class TransportsService extends BaseSingletonService {

    private settings: {
        service: {
            getTransport: string;
            getTransports: string;
            createTransport: string;
            updateTransport: string;
            deleteTransport: string;
            getWorkbook: string;
        }
    };

    constructor(private http: HttpService, private translate: ExgTranslateService) {
        super('TransportsService');
        this.settings = {
            service: {
                getTransport: '/drilling/v1/vehicles/{0}',
                getTransports: '/drilling/v1/vehicles?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                createTransport: '/drilling/v1/vehicles',
                updateTransport: '/drilling/v1/vehicles/{0}',
                deleteTransport: '/drilling/v1/vehicles/{0}',
                getWorkbook: '/drilling/v1/vehicles/breakdowns-workbook?workingAreaUid={0}&DateFrom={1}&DateTo={2}',
                
            }
        };
    }

    public async getTransport(uid: string): Promise<Transport> {
        return this.http.get<{ data: Transport }>(String.format(this.settings.service.getTransport, uid))
            .then(res => res.data);
    }

    public async getTransports(filter: TransportListGetFilter): Promise<PaginationResult<TransportListItem>> {
        return this.http.get<PaginationResult<TransportListItem>>(String.format(this.settings.service.getTransports, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createTransport(request: TransportCreateUpdateRequest): Promise<{ uid: string }> {
        return this.http.post<{ data: {uid: string} }>(String.format(this.settings.service.createTransport), request)
            .then(res => ({ uid: res.data.uid }));
    }

    public async updateTransport(uid: string, request: TransportCreateUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateTransport, uid), request);
    }

    public async deleteTransport(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteTransport, uid));
    }

    public async getWorkbook(filter: TransportWorkbookGetFilter): Promise<Blob> {
        const workbookName = `Отчет по технике - ${filter.workingArea ? filter.workingArea?.title : ''} - ${ DateUtils.formatEpocToString(filter.dateFrom, this.translate.getCurrentLang(), 'MMMM yy')}.xlsx`;
        return this.http.download(String.format(this.settings.service.getWorkbook, filter.workingArea?.uid, filter.dateFrom, filter.dateTo), workbookName);
    }
}
