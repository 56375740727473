import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { FullnamePart } from '../../models/business/suggestions/fullname-part.enum';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { FullNameSuggestionGetAction, FullNameSuggestionResetAction } from './fullname-suggestion.actions';

@Injectable({
    providedIn: 'root'
})
export class FullNameSuggestionDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('FullNameSuggestionDispatchers');
    }

    public dispatchFullNameSuggestionAction(term: string, parts: FullnamePart[]) {
        this.store.dispatch(new FullNameSuggestionGetAction({ term, parts }));
    }

    public dispatchFullNameSuggestionResetAction() {
        this.store.dispatch(new FullNameSuggestionResetAction());
    }
}
