import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeScheduleGetFilterData } from '../../models/filters/employee-schedule-get-filter.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { EmployeeScheduleAction, EmployeeScheduleResetAction, EmployeeScheduleSetFilterAction } from './employee-schedule.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeScheduleDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeScheduleDispatchers');
    }

    public dispatchEmployeeScheduleAction(request: PaginationRequest) {
        this.store.dispatch(new EmployeeScheduleAction(request));
    }

    public dispatchEmployeeScheduleResetAction() {
        this.store.dispatch(new EmployeeScheduleResetAction());
    }

    public dispatchEmployeeScheduleSetFilterAction(filterData: EmployeeScheduleGetFilterData) {
        this.store.dispatch(new EmployeeScheduleSetFilterAction(filterData));
    }
}
