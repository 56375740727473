import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { RouteListGetFilterData } from '../../models/filters/route-list-get-filter.model';

import { RoutesAction, RoutesResetAction, RoutesSetFilterAction } from './routes.actions';

@Injectable({
    providedIn: 'root'
})
export class RoutesDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('RoutesDispatchers');
    }

    public dispatchRoutesAction(request: PaginationRequest) {
        this.store.dispatch(new RoutesAction(request));
    }

    public dispatchRoutesResetAction() {
        this.store.dispatch(new RoutesResetAction());
    }

    public dispatchRoutesSetFilterAction(filterData: RouteListGetFilterData) {
        this.store.dispatch(new RoutesSetFilterAction(filterData));
    }
}
