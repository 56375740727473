import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TransportHeaderRightTabsEnum } from './rbc-transport-header-tabs.enum';

@Component({
    selector: 'rbc-transport-right-header',
    templateUrl: './rbc-transport-right-header.component.html',
    styleUrls: ['./rbc-transport-right-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcTransportRightHeaderComponent {

    @Input() activeTab = TransportHeaderRightTabsEnum.Transfers;
    @Input() readonly: boolean;

    @Output() readonly selectedTab = new EventEmitter<TransportHeaderRightTabsEnum>();
    @Output() readonly addTransportTransfer = new EventEmitter();
    @Output() readonly addTransportBreakdown = new EventEmitter();

    public headerTabs = TransportHeaderRightTabsEnum;

    public onClickTab(type: TransportHeaderRightTabsEnum): void {
       this.selectedTab.emit(type);
    }

    public onBreakClick(): void {
        this.addTransportBreakdown.emit();
    }

    public onMoveClick(): void {
        this.addTransportTransfer.emit();
    }
}
