import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { TransportWorkReportListItem } from '../../models/business/work-report/work-report-list-item.model';
import { TransportWorkReportListGetFilterData } from '../../models/filters/work-report-list-get-filter.model';


export class TransportWorkReportAction {
    static readonly type = '[TransportWorkReport Page] GetTransportWorkReport';

    constructor(public payload: PaginationRequest) { }
}

export class TransportWorkReportSuccessAction {
    static readonly type = '[TransportWorkReport API] GetTransportWorkReport Success';

    constructor(public payload: { list: PaginationResult<TransportWorkReportListItem>, pagination: PaginationRequest }) { }
}

export class TransportWorkReportFailAction {
    static readonly type = '[TransportWorkReport API] GetTransportWorkReport Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportWorkReportResetAction {
    static readonly type = '[TransportWorkReport Page] GetTransportWorkReport Reset';
}

export class TransportWorkReportSetFilterAction {
    static readonly type = '[TransportWorkReport API] SetFilter';

    constructor(public payload: TransportWorkReportListGetFilterData) { }
}