import { CreateOrUpdateDrillingRigBreakdownAggregationRequest } from '../../models/business/drilling-rig-breakdown/create-or-update-drilling-rig-breakdown-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigBreakdownCreateAction {
    static readonly type = '[DrillingRigBreakdowns Page] CreateDrillingRigBreakdown';

    constructor(public payload: { request: CreateOrUpdateDrillingRigBreakdownAggregationRequest }) { }
}

export class DrillingRigBreakdownCreateSuccessAction {
    static readonly type = '[DrillingRigBreakdown API] CreateDrillingRigBreakdown Success';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigBreakdownCreateFailAction {
    static readonly type = '[DrillingRigBreakdown API] CreateDrillingRigBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigBreakdownCreateResetAction {
    static readonly type = '[DrillingRigBreakdowns Page] CreateDrillingRigBreakdown Reset';
}
