import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeePasswordUpdateAggregationRequest } from '../../models/business/employee/employee-password-update-aggregation-request.model';

import { EmployeeChangePasswordAction, EmployeeChangePasswordResetAction } from './employee-change-password.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeChangePasswordDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeChangePasswordDispatchers');
    }

    public dispatchEmployeeChangePasswordAction(userUid: string, request: EmployeePasswordUpdateAggregationRequest) {
        this.store.dispatch(new EmployeeChangePasswordAction({ userUid, request }));
    }

    public dispatchEmployeeChangePasswordResetAction() {
        this.store.dispatch(new EmployeeChangePasswordResetAction());
    }
}
