<section class="header">
    <div class="tabs">
        <exg-button (btnClick)="onClickTab(headerTabs.Transfers)" class="tab_btn schedule" [ngClass]="{ 'active': activeTab === headerTabs.Transfers}" styleType="custom">
            <exg-icon class="btn-icon tab_btn-icon" iconName="route"></exg-icon>
            {{'Moving' | translate}}
        </exg-button>       
        <exg-button (btnClick)="onClickTab(headerTabs.Breakdowns)" class="tab_btn" [ngClass]="{ 'active': activeTab === headerTabs.Breakdowns}" styleType="custom">
            <exg-icon class="btn-icon tab_btn-icon" iconName="wrench"></exg-icon>
            {{'Brokens' | translate}}
        </exg-button>
    </div>
    <div class="actions">
        <exg-button class="btn cancel" styleType="outlined" [disabled]="readonly" (btnClick)="onBreakClick()">
            <exg-icon class="btn-icon" iconName="add"></exg-icon>
            <exg-icon class="btn-icon" iconName="wrench"></exg-icon>
            {{'Breaking' | translate}}
        </exg-button>
        <exg-button class="btn cancel" styleType="outlined" [disabled]="readonly" (btnClick)="onMoveClick()">
            <exg-icon class="btn-icon" iconName="add"></exg-icon>
            <exg-icon class="btn-icon" iconName="route"></exg-icon>
            {{ 'Route' | translate }}
        </exg-button>
    </div>
</section>