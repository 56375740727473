import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { TransportBreakdownsService } from '../../services/transport-breakdowns.service';

import { TransportBreakdownListGetFilter, TransportBreakdownListGetFilterData } from '../../models/filters/transport-breakdown-list-get-filter.model';
import { TransportBreakdownListItem } from '../../models/business/transport-breakdown/transport-breakdown-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { TransportBreakdownsAction, TransportBreakdownsFailAction, TransportBreakdownsResetAction, TransportBreakdownsSetFilterAction, TransportBreakdownsSuccessAction } from './transport-breakdowns.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface TransportBreakdownsStateModel extends PaginationStateModel<TransportBreakdownListItem> {
    uids: string[];
    entities: { [uid: string]: TransportBreakdownListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: TransportBreakdownListGetFilterData;
    total: number;
}

@State<TransportBreakdownsStateModel>({
    name: 'transportBreakdowns',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {vehicleUid: null} }
})
@Injectable()
export class TransportBreakdownsState extends PaginationStateBase {
    constructor(private transportBreakdownsService: TransportBreakdownsService) {
        super();
    }

    @Action([TransportBreakdownsAction]) transportBreakdownsGet(ctx: StateContext<TransportBreakdownsStateModel>, action: TransportBreakdownsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new TransportBreakdownListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.transportBreakdownsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.transportBreakdownsService.getTransportBreakdowns(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new TransportBreakdownsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportBreakdownsFailAction(err)), 0));
    }

    @Action(TransportBreakdownsSuccessAction) transportBreakdownsGetSuccess(ctx: StateContext<TransportBreakdownsStateModel>, action: TransportBreakdownsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.transportBreakdownsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(TransportBreakdownsFailAction) transportBreakdownsGetFail(ctx: StateContext<TransportBreakdownsStateModel>, action: TransportBreakdownsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransportBreakdownsResetAction) transportBreakdownsGetReset(ctx: StateContext<TransportBreakdownsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {vehicleUid: null} });
    }

    @Action(TransportBreakdownsSetFilterAction) transportBreakdownsSetFilter(ctx: StateContext<TransportBreakdownsStateModel>, action: TransportBreakdownsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new TransportBreakdownsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
