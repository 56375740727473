import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { JobPositionListItem } from '../../models/business/job-positions/job-position-list-item.model';

export class DictionaryJobPositionsAction {
    static readonly type = '[DictionaryJobPositions Page] GetDictionaryJobPositions';
}

export class DictionaryJobPositionsSuccessAction {
    static readonly type = '[DictionaryJobPositions API] GetDictionaryJobPositions Success';

    constructor(public payload: JobPositionListItem[]) { }
}

export class DictionaryJobPositionsFailAction {
    static readonly type = '[DictionaryJobPositions API] GetDictionaryJobPositions Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryJobPositionsResetAction {
    static readonly type = '[DictionaryJobPositions Page] GetDictionaryJobPositions Reset';
}
