import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WorkingAreaService } from '../../services/working-area.service';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { WorkingAreaListGetFilter, WorkingAreaListGetFilterData } from '../../models/filters/working-area-list-get-filter.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

import { AnalyticsWorkingAreaFailAction, AnalyticsWorkingAreaSuccessAction } from './analytics-working-area.actions';
import { AnalyticsGetAction, AnalyticsResetAction } from './analytics.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

import { ExgParamsConfig } from '../../exg-params.config';

export interface AnalyticsWorkingAreaStateModel extends PaginationStateModel<WorkingAreaListItem> {
    uids: string[];
    entities: { [uid: string]: WorkingAreaListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: WorkingAreaListGetFilterData;
}

@State<AnalyticsWorkingAreaStateModel>({
    name: 'AnalyticsWorkingArea',
    defaults: { pending: false, uids: [], entities: {}, filter: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class AnalyticsWorkingAreaState extends PaginationStateBase {
    constructor(private workingAreaService: WorkingAreaService) {
        super();
    }

    @Action(AnalyticsGetAction) usersSetFilter(ctx: StateContext<AnalyticsWorkingAreaStateModel>, action: AnalyticsGetAction) {
        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            ctx.setState({ ...state, filter: filterData, retrieved: false, index: 0 });

            const filter = new WorkingAreaListGetFilter({ pageIndex: state.index, filterData });
            if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
                ctx.setState({ ...state, pending: false });
                return Promise.resolve();
            }

            return this.workingAreaService.getWorkingAreas(filter)
                .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsWorkingAreaSuccessAction(resp)), 0))
                .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsWorkingAreaFailAction(err)), 0));
        }
    }

    @Action(AnalyticsWorkingAreaSuccessAction) areasGetSuccess(ctx: StateContext<AnalyticsWorkingAreaStateModel>, action: AnalyticsWorkingAreaSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadFullList, ExgParamsConfig.pagination.workingAreaAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(AnalyticsWorkingAreaFailAction) areasGetFail(ctx: StateContext<AnalyticsWorkingAreaStateModel>, action: AnalyticsWorkingAreaFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsResetAction) areasGetReset(ctx: StateContext<AnalyticsWorkingAreaStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
