import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransportsService } from '../../services/transports.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Transport } from '../../models/business/transport/transport.model';

import { TransportAction, TransportFailAction, TransportResetAction, TransportSuccessAction } from './transport.actions';

export interface TransportStateModel {
    entity: Transport;
    pending: boolean;
    error: ErrorObject;
}

@State<TransportStateModel>({
    name: 'transport',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class TransportState {
    constructor(private transportsService: TransportsService) {}


    @Action(TransportAction) transportGet(ctx: StateContext<TransportStateModel>, action: TransportAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.transportsService.getTransport(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new TransportSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportFailAction(err)), 0));
    }

    @Action(TransportSuccessAction) transportGetSuccess(ctx: StateContext<TransportStateModel>, action: TransportSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(TransportFailAction) transportGetFail(ctx: StateContext<TransportStateModel>, action: TransportFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransportResetAction) transportGetReset(ctx: StateContext<TransportStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
