import { CreateOrUpdateDrillingRigTransferAggregationRequest } from '../../models/business/drilling-rig-transfer/create-or-update-drilling-rig-transfer-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigTransferCreateAction {
    static readonly type = '[DrillingRigTransfers Page] CreateDrillingRigTransfer';

    constructor(public payload: { request: CreateOrUpdateDrillingRigTransferAggregationRequest }) { }
}

export class DrillingRigTransferCreateSuccessAction {
    static readonly type = '[DrillingRigTransfer API] CreateDrillingRigTransfer Success';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigTransferCreateFailAction {
    static readonly type = '[DrillingRigTransfer API] CreateDrillingRigTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigTransferCreateResetAction {
    static readonly type = '[DrillingRigTransfers Page] CreateDrillingRigTransfer Reset';
}
