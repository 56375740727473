import { DictionaryEntryCreateOrUpdate } from '../../models/business/dictionaries/dictionary-entry-create-or-update.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class VehicleCategoriesUpdateAction {
    static readonly type = '[VehicleCategoriesUpdate Page] VehicleCategoriesUpdate';

    constructor(public payload: { request: DictionaryEntryCreateOrUpdate[] }) { }
}

export class VehicleCategoriesUpdateSuccessAction {
    static readonly type = '[VehicleCategoriesUpdate API] VehicleCategoriesUpdate Success';
}

export class VehicleCategoriesUpdateFailAction {
    static readonly type = '[VehicleCategoriesUpdate API] VehicleCategoriesUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleCategoriesUpdateResetAction {
    static readonly type = '[VehicleCategoriesUpdate Page] VehicleCategoriesUpdate Reset';
}
