import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { JobPositionListItem } from '../../models/business/job-positions/job-position-list-item.model';

import { DictionaryJobPositionsAction, DictionaryJobPositionsFailAction, DictionaryJobPositionsResetAction, DictionaryJobPositionsSuccessAction } from './dictionary-job-positions.actions';

export interface DictionaryJobPositionsStateModel {
    entities: JobPositionListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<DictionaryJobPositionsStateModel>({
    name: 'DictionaryJobPositions',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class DictionaryJobPositionsState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action([DictionaryJobPositionsAction]) JobPositionsGet(ctx: StateContext<DictionaryJobPositionsStateModel>, _: DictionaryJobPositionsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.dictionariesService.getJobPositions()
            .then(resp => setTimeout(() => ctx.dispatch(new DictionaryJobPositionsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryJobPositionsFailAction(err)), 0));
    }

    @Action(DictionaryJobPositionsSuccessAction) JobPositionsGetSuccess(ctx: StateContext<DictionaryJobPositionsStateModel>, action: DictionaryJobPositionsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(DictionaryJobPositionsFailAction) JobPositionsGetFail(ctx: StateContext<DictionaryJobPositionsStateModel>, action: DictionaryJobPositionsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryJobPositionsResetAction) JobPositionsGetReset(ctx: StateContext<DictionaryJobPositionsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
