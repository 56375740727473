import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { EmployeeWorkbookGetFilter } from '../../models/filters/employee-workbook-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { WorkbookExportAction, WorkbookExportFailAction, WorkbookExportResetAction, WorkbookExportSuccessAction } from './workbook-export.actions';

export interface WorkbookExportStateModel {
    loaded: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<WorkbookExportStateModel>({
    name: 'workbookExport',
    defaults: { pending: false, loaded: false, error: null }
})
@Injectable()
export class WorkbookExportState {
    constructor(private employeesService: EmployeesService) {}

    @Action(WorkbookExportAction) workbookExportGet(ctx: StateContext<WorkbookExportStateModel>, action: WorkbookExportAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new EmployeeWorkbookGetFilter({ filterData: action.payload.filter });
        return this.employeesService.getWorkbook(filter)
            .then(() => setTimeout(() => ctx.dispatch(new WorkbookExportSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new WorkbookExportFailAction(err)), 0));
    }

    @Action(WorkbookExportSuccessAction) workbookExportGetSuccess(ctx: StateContext<WorkbookExportStateModel>, _: WorkbookExportSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: true, error: null });
    }

    @Action(WorkbookExportFailAction) workbookExportGetFail(ctx: StateContext<WorkbookExportStateModel>, action: WorkbookExportFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(WorkbookExportResetAction) workbookExportGetReset(ctx: StateContext<WorkbookExportStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: false, error: null });
    }
}
