import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { YandexGeocodingDispatchers } from '../../store/yandex-geocoding/yandex-geocoding.dispatchers';
import { YandexGeocodingSelectors } from '../../store/yandex-geocoding/yandex-geocoding.selectors';

import { ComponentType } from '../../models/business/geocoding/component-type.enum';
import { Geocoding } from '../../models/business/geocoding/geocoding.model';


@Component({
    selector: 'rbc-yandex-geocoding',
    templateUrl: './rbc-yandex-geocoding.component.html',
    styleUrls: ['./rbc-yandex-geocoding.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RbcYandexGeocodingComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RbcYandexGeocodingComponent implements ControlValueAccessor {
    @Input() maxlength: number;
    @Input() placeholder: string;
    @Input() validate: boolean;
    @Input() controlsToValidate: FormControl[];
    @Input() countryCode: string;
    @Input() readonly: boolean;
    @Input() styleType: 'common' | 'inline' | 'empty' | 'readonly' = 'common';
    @Input() allowNull: boolean;
    @Input() displayKey = 'fullName';
    @Input() emptyDisplayValue = '-';
    @Input() parentPlaceId: string = null;
    @Input() level: ComponentType = null;
    @Input() fullNameLevelFrom: ComponentType = null;

    @Output() readonly selectionChange = new EventEmitter<Geocoding>();

    public value: any;
    public isDisabled: boolean;

    public places$ = this.geocodingSelectors.places$;

    private propagateChange: (_) => void;
    private propagateTouch: () => void;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private geocodingDispatchers: YandexGeocodingDispatchers,
        private geocodingSelectors: YandexGeocodingSelectors
    ) { }

    public addressDisplayValueFunction(item: Geocoding) {
        return `${item[this.displayKey]}`;
    }

    public onSearchPlace($event: string) {
        this.geocodingDispatchers.dispatchGeocodingAction($event);
    }

    public onSelectionChange($event: Geocoding) {
        this.value = $event;
        this.geocodingDispatchers.dispatchGeocodingResetAction();

        if (this.propagateTouch) {
            this.propagateTouch();
        }
        if (this.propagateChange) {
            this.propagateChange(this.value);
        }

        this.selectionChange.emit($event);
    }

    public writeValue(value: string) {
        this.value = value;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn) {
        this.propagateTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
