import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { LossOfProfitListItem } from '../../models/business/analytics/loss-of-profit-list-item.model';

export class AnalyticsDetailsLossOfProfitGetAction {
    static readonly type = '[AnalyticsDetailsLossOfProfit Page] GetAnalyticsDetailsLossOfProfit Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsLossOfProfitSuccessAction {
    static readonly type = '[AnalyticsDetailsLossOfProfit API] GetAnalyticsDetailsLossOfProfit Success';

    constructor(public payload: LossOfProfitListItem[]) { }
}

export class AnalyticsDetailsLossOfProfitFailAction {
    static readonly type = '[AnalyticsDetailsLossOfProfit API] GetAnalyticsDetailsLossOfProfit Fail';

    constructor(public payload: ErrorObject) { }
}
