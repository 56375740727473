import { CountryShiftsSummaryListGetFilterData } from '../../models/filters/country-shifts-summary-list-get-filter.model';
import { CountryShiftsSummaryListItem } from '../../models/business/shifts/country-shifts-summary-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WorkingAreaShiftsSummaryListItem } from '../../models/business/shifts/working-area-shifts-summary-list-item.model';

export class ShiftsCountriesAction {
    static readonly type = '[ShiftsCountries Page] GetShiftsCountries';
}

export class ShiftsCountriesSuccessAction {
    static readonly type = '[ShiftsCountries API] GetShiftsCountries Success';

    constructor(public payload: { list: CountryShiftsSummaryListItem[] }) { }
}

export class ShiftsWorkingAreasAction {
    static readonly type = '[ShiftsCountries Page] GetShiftsWorkingAreas';

    constructor(public payload: { countryCode: string }) { }
}

export class ShiftsWorkingAreasSuccessAction {
    static readonly type = '[ShiftsCountries API] GetShiftsWorkingAreas Success';

    constructor(public payload: { list: WorkingAreaShiftsSummaryListItem[], countryCode: string; }) { }
}

export class ShiftsCountriesFailAction {
    static readonly type = '[ShiftsCountries API] GetShiftsCountries Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftsCountriesResetAction {
    static readonly type = '[ShiftsCountries Page] GetShiftsCountries Reset';
}

export class ShiftsCountriesSetFilterAction {
    static readonly type = '[ShiftsCountries API] SetFilter';

    constructor(public payload: CountryShiftsSummaryListGetFilterData) { }
}

export class ShiftsCountriesExpandAction {
    static readonly type = '[ShiftsCountries Page] GetShiftsExpand';

    constructor(public payload: { code: string }) { }
}
