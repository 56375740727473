import { ModuleWithProviders, NgModule } from '@angular/core';

import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from '@eklipse/perfect-scrollbar';

import { HttpCancelService } from './services/common/http-cancel.service';
import { httpInterceptorProviders } from './services/common/http-interceptors';

import { SharedCommonModule } from './shared-common.module';

import { SettingsTokens } from './settings-tokens.config';
import { DefaultAuthSettings, ISharedSettingsConfig } from './shared-settings.config';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        SharedCommonModule
    ],
    exports: [
        SharedCommonModule
    ]
})
export class SharedModule {
    static forRoot(settings: ISharedSettingsConfig): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                HttpCancelService,
                httpInterceptorProviders,
                { provide: SettingsTokens.tokens.version, useValue: settings.version },
                { provide: SettingsTokens.tokens.environment, useValue: settings.environment },
                { provide: SettingsTokens.tokens.authSettings, useValue: settings.authSettings || DefaultAuthSettings },
                { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
            ]
        };
    }
}
