import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class EmployeeDeleteAction {
    static readonly type = '[My Employee Page] DeleteEmployee';

    constructor(public payload: { uid: string }) { }
}

export class EmployeeDeleteSuccessAction {
    static readonly type = '[My Employees API] DeleteEmployee Success';

    constructor(public payload: { uid: string }) { }
}

export class EmployeeDeleteFailAction {
    static readonly type = '[My Employees API] DeleteEmployee Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeDeleteResetAction {
    static readonly type = '[My Employee Page] DeleteEmployee Reset';
}
