import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeScheduleGetFilterData } from '../../models/filters/employee-schedule-get-filter.model';
import { EmployeeScheduleItem } from '../../models/business/employee/employee-schedule-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeScheduleState, EmployeeScheduleStateModel } from './employee-schedule.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeScheduleSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([EmployeeScheduleState], (state: EmployeeScheduleStateModel) => state.pending);

    static readonly getEmployees = createSelector([EmployeeScheduleState], (state: EmployeeScheduleStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([EmployeeScheduleState], (state: EmployeeScheduleStateModel) => state.retrieved);

    static readonly getError = createSelector([EmployeeScheduleState], (state: EmployeeScheduleStateModel) => state.error);

    static readonly getAllEmployeesLoaded = createSelector([EmployeeScheduleState], (state: EmployeeScheduleStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([EmployeeScheduleState], (state: EmployeeScheduleStateModel) => state.filter);

    static readonly getTotal = createSelector([EmployeeScheduleState], (state: EmployeeScheduleStateModel) => state.total);

    @Select(EmployeeScheduleSelectors.getPending) pending$: Observable<boolean>;

    @Select(EmployeeScheduleSelectors.getEmployees) employeeSchedule$: Observable<EmployeeScheduleItem[]>;

    @Select(EmployeeScheduleSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(EmployeeScheduleSelectors.getError) error$: Observable<ErrorObject>;

    @Select(EmployeeScheduleSelectors.getAllEmployeesLoaded) allEmployeeScheduleLoaded$: Observable<boolean>;

    @Select(EmployeeScheduleSelectors.getFilter) filter$: Observable<EmployeeScheduleGetFilterData>;

    @Select(EmployeeScheduleSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('EmployeeScheduleSelectors');
    }
}
