import { CreateOrUpdateEmployeeAggregationRequest } from '../../models/business/employee/create-or-update-employee-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class EmployeeCreateAction {
    static readonly type = '[Employees Page] CreateEmployee';

    constructor(public payload: { request: CreateOrUpdateEmployeeAggregationRequest }) { }
}

export class EmployeeCreateSuccessAction {
    static readonly type = '[Employee API] CreateEmployee Success';

    constructor(public payload: { uid: string }) { }
}

export class EmployeeCreateFailAction {
    static readonly type = '[Employee API] CreateEmployee Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeCreateResetAction {
    static readonly type = '[Employees Page] CreateEmployee Reset';
}
