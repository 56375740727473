<div class="container">
    <rbc-left-menu class="menu"
                   [menu]="headerMenu"
                   [profile]="profile$ | async"
                   [headerSettings]="headerSettings"
                   (logout)="onLogout()"></rbc-left-menu>
    <div class="content">
        <ng-content></ng-content>
    </div>
    <rbc-mobile-menu class="menu mobile"
                     [menu]="mobileMenu"
                     [profile]="profile$ | async"
                     (logout)="onLogout()"></rbc-mobile-menu>
</div>
