import { DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigListItem } from '../../models/business/drilling-rig/drilling-rig-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class DrillingRigsAction {
    static readonly type = '[DrillingRigs Page] GetDrillingRigs';

    constructor(public payload: PaginationRequest) { }
}

export class DrillingRigsSuccessAction {
    static readonly type = '[DrillingRigs API] GetDrillingRigs Success';

    constructor(public payload: { list: PaginationResult<DrillingRigListItem>, pagination: PaginationRequest }) { }
}

export class DrillingRigsFailAction {
    static readonly type = '[DrillingRigs API] GetDrillingRigs Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigsResetAction {
    static readonly type = '[DrillingRigs Page] GetDrillingRigs Reset';
}

export class DrillingRigsSetFilterAction {
    static readonly type = '[DrillingRigs API] SetFilter';

    constructor(public payload: DrillingRigListGetFilterData) { }
}
