import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';

import { AnalyticsDetailsGetAction, AnalyticsDetailsResetAction } from './analytics-details.actions';

export interface AnalyticsDetailsStateModel {
    filter: AnalyticGetFilterData;
}

@State<AnalyticsDetailsStateModel>({
    name: 'AnalyticsDetails',
    defaults: { filter: null }
})
@Injectable()
export class AnalyticsDetailsState {
    @Action(AnalyticsDetailsGetAction) AnalyticsDetailsGet(ctx: StateContext<AnalyticsDetailsStateModel>, action: AnalyticsDetailsGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, filter: action.payload });
    }

    @Action(AnalyticsDetailsResetAction) AnalyticsDetailsGetReset(ctx: StateContext<AnalyticsDetailsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, filter: null });
    }
}
