import { ActivityBalance } from '../../models/business/analytics/activity-balance.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsActivityBalanceSummarySuccessAction {
    static readonly type = '[AnalyticsActivityBalanceSummary API] GetAnalyticsActivityBalanceSummary Success';

    constructor(public payload: ActivityBalance) { }
}

export class AnalyticsActivityBalanceSummaryFailAction {
    static readonly type = '[AnalyticsActivityBalanceSummary API] GetAnalyticsActivityBalanceSummary Fail';

    constructor(public payload: ErrorObject) { }
}
