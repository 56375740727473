import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { DrillingRigCreateUpdateRequest } from '../../models/business/drilling-rig/drilling-rig-create-update-request.model';

export class DrillingRigCreateAction {
    static readonly type = '[DrillingRigs Page] CreateDrillingRig';

    constructor(public payload: { request: DrillingRigCreateUpdateRequest }) { }
}

export class DrillingRigCreateSuccessAction {
    static readonly type = '[DrillingRig API] CreateDrillingRig Success';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigCreateFailAction {
    static readonly type = '[DrillingRig API] CreateDrillingRig Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigCreateResetAction {
    static readonly type = '[DrillingRigs Page] CreateDrillingRig Reset';
}
