import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { VehicleCategoriesUpdateAction, VehicleCategoriesUpdateFailAction, VehicleCategoriesUpdateResetAction, VehicleCategoriesUpdateSuccessAction } from './vehicle-categories-update.actions';


export interface VehicleCategoriesUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<VehicleCategoriesUpdateStateModel>({
    name: 'VehicleCategoriesUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class VehicleCategoriesUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(VehicleCategoriesUpdateAction) async VehicleCategories(ctx: StateContext<VehicleCategoriesUpdateStateModel>, action: VehicleCategoriesUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateVehicleCategories(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new VehicleCategoriesUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VehicleCategoriesUpdateFailAction(err)), 0));
    }

    @Action(VehicleCategoriesUpdateSuccessAction) VehicleCategoriesSuccess(ctx: StateContext<VehicleCategoriesUpdateStateModel>, _: VehicleCategoriesUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(VehicleCategoriesUpdateFailAction) VehicleCategoriesFail(ctx: StateContext<VehicleCategoriesUpdateStateModel>, action: VehicleCategoriesUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(VehicleCategoriesUpdateResetAction) VehicleCategoriesReset(ctx: StateContext<VehicleCategoriesUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
