import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DictionaryVehicleCategoriesState, DictionaryVehicleCategoriesStateModel } from './dictionary-vehicle-categories.reducer';

import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

@Injectable({
    providedIn: 'root'
})
export class DictionaryVehicleCategoriesSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([DictionaryVehicleCategoriesState], (state: DictionaryVehicleCategoriesStateModel) => state.pending);

    static readonly getDictionaryVehicleCategories = createSelector([DictionaryVehicleCategoriesState], (state: DictionaryVehicleCategoriesStateModel) => state.entities);

    static readonly getError = createSelector([DictionaryVehicleCategoriesState], (state: DictionaryVehicleCategoriesStateModel) => state.error);

    @Select(DictionaryVehicleCategoriesSelectors.getPending) pending$: Observable<boolean>;

    @Select(DictionaryVehicleCategoriesSelectors.getDictionaryVehicleCategories) vehicleCategories$: Observable<DictionaryEntryListItem[]>;

    @Select(DictionaryVehicleCategoriesSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('DictionaryVehicleCategoriesSelectors');
    }
}
