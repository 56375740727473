import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ShiftsService } from '../../services/shifts.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { WorkingAreaShiftsSummaryListGetFilter, WorkingAreaShiftsSummaryListGetFilterData } from '../../models/filters/working-area-shifts-summary-list-get-filter.model';
import { WorkingAreaShiftsSummaryListItem } from '../../models/business/shifts/working-area-shifts-summary-list-item.model';

import { ShiftsAction, ShiftsFailAction, ShiftsResetAction, ShiftsSetFilterAction, ShiftsSuccessAction } from './shifts.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface ShiftsStateModel extends PaginationStateModel<WorkingAreaShiftsSummaryListItem> {
    uids: string[];
    entities: { [uid: string]: WorkingAreaShiftsSummaryListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: WorkingAreaShiftsSummaryListGetFilterData;
    total: number;
}

@State<ShiftsStateModel>({
    name: 'shifts',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class ShiftsState extends PaginationStateBase {
    constructor(private shiftsService: ShiftsService) {
        super();
    }

    @Action([ShiftsAction]) shiftsGet(ctx: StateContext<ShiftsStateModel>, action: ShiftsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new WorkingAreaShiftsSummaryListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.shiftsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.shiftsService.getShifts(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftsFailAction(err)), 0));
    }

    @Action(ShiftsSuccessAction) shiftsGetSuccess(ctx: StateContext<ShiftsStateModel>, action: ShiftsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.shiftsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(ShiftsFailAction) shiftsGetFail(ctx: StateContext<ShiftsStateModel>, action: ShiftsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftsResetAction) shiftsGetReset(ctx: StateContext<ShiftsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }

    @Action(ShiftsSetFilterAction) shiftsSetFilter(ctx: StateContext<ShiftsStateModel>, action: ShiftsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new ShiftsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
