import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WorkingAreaService } from '../../services/working-area.service';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { WorkingAreaListGetFilter } from '../../models/filters/working-area-list-get-filter.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

import { AnalyticsDetailsWorkingAreaAction, AnalyticsDetailsWorkingAreaFailAction, AnalyticsDetailsWorkingAreaResetAction, AnalyticsDetailsWorkingAreaSuccessAction } from './analytics-details-working-area.actions';
import { AnalyticsDetailsGetAction } from './analytics-details.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

import { ExgParamsConfig } from '../../exg-params.config';

export interface AnalyticsDetailsWorkingAreaStateModel extends PaginationStateModel<WorkingAreaListItem> {
    uids: string[];
    entities: { [uid: string]: WorkingAreaListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: AnalyticGetFilterData;
}

@State<AnalyticsDetailsWorkingAreaStateModel>({
    name: 'AnalyticsDetailsWorkingArea',
    defaults: { pending: false, uids: [], entities: {}, filter: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class AnalyticsDetailsWorkingAreaState extends PaginationStateBase {
    constructor(private workingAreaService: WorkingAreaService) {
        super();
    }

    @Action([AnalyticsDetailsWorkingAreaAction]) areasGet(ctx: StateContext<AnalyticsDetailsWorkingAreaStateModel>, action: AnalyticsDetailsWorkingAreaAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null, filter: action.payload });

        const filter = new WorkingAreaListGetFilter({ pageIndex: state.index, filterData: <any>{ ...action.payload } });
        if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.workingAreaService.getWorkingAreas(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsDetailsWorkingAreaSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsDetailsWorkingAreaFailAction(err)), 0));
    }

    @Action(AnalyticsDetailsWorkingAreaSuccessAction) areasGetSuccess(ctx: StateContext<AnalyticsDetailsWorkingAreaStateModel>, action: AnalyticsDetailsWorkingAreaSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadFullList, ExgParamsConfig.pagination.workingAreaAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(AnalyticsDetailsWorkingAreaFailAction) areasGetFail(ctx: StateContext<AnalyticsDetailsWorkingAreaStateModel>, action: AnalyticsDetailsWorkingAreaFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsDetailsWorkingAreaResetAction) areasGetReset(ctx: StateContext<AnalyticsDetailsWorkingAreaStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }

    @Action(AnalyticsDetailsGetAction) usersSetFilter(ctx: StateContext<AnalyticsDetailsWorkingAreaStateModel>, action: AnalyticsDetailsGetAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new AnalyticsDetailsWorkingAreaAction(filter)), 0);
        }
    }
}
