export enum ExgDialogMode {
    NoButtons,
    Ok,
    OkCancel,
    SaveCancel,
    YesNo,
    YesNoCancel,
    DeleteCancel,
    ConfirmCancel,
    Custom
}
