import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { WorkingTypeListItem } from '../models/business/working-type/working-type-list-item.model';
import { Vehicle } from '../models/business/vehicle/vehicle.model';

@Injectable({
    providedIn: 'root'
})
export class VehicleDictionaryService extends BaseSingletonService {

    private settings: {
        service: {
            getVehicleList: string;
        }
    };

    constructor(private http: HttpService) {
        super('VehicleDictionaryService');
        this.settings = {
            service: {
                getVehicleList: '/drilling/v1/vehicles',
            }
        };
    }

    public async getVehicleList(): Promise<PaginationResult<Vehicle>> {
        return this.http.get<PaginationResult<Vehicle>>(this.settings.service.getVehicleList);
    }
}
