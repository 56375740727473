import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeDeleteAction, EmployeeDeleteResetAction } from './employee-delete.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeeDeleteDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeeDeleteDispatchers');
    }

    public dispatchEmployeeDeleteAction(uid: string) {
        this.store.dispatch(new EmployeeDeleteAction({ uid }));
    }

    public dispatchEmployeeDeleteResetAction() {
        this.store.dispatch(new EmployeeDeleteResetAction());
    }
}
