import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigBreakdownsService } from '../../services/drilling-rig-breakdowns.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigBreakdownDeleteAction, DrillingRigBreakdownDeleteFailAction, DrillingRigBreakdownDeleteResetAction, DrillingRigBreakdownDeleteSuccessAction } from './drilling-rig-breakdown-delete.actions';

export interface DrillingRigBreakdownDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<DrillingRigBreakdownDeleteStateModel>({
    name: 'drillingRigBreakdownDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class DrillingRigBreakdownDeleteState {
    constructor(private drillingRigBreakdownsService: DrillingRigBreakdownsService) { }

    @Action(DrillingRigBreakdownDeleteAction) async drillingRigBreakdownDelete(ctx: StateContext<DrillingRigBreakdownDeleteStateModel>, action: DrillingRigBreakdownDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.drillingRigBreakdownsService.deleteDrillingRigBreakdown(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownDeleteFailAction(error)), 0));
    }

    @Action(DrillingRigBreakdownDeleteSuccessAction) drillingRigBreakdownDeleteSuccess(ctx: StateContext<DrillingRigBreakdownDeleteStateModel>, _: DrillingRigBreakdownDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(DrillingRigBreakdownDeleteFailAction) drillingRigBreakdownDeleteFail(ctx: StateContext<DrillingRigBreakdownDeleteStateModel>, action: DrillingRigBreakdownDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigBreakdownDeleteResetAction) drillingRigBreakdownDeleteReset(ctx: StateContext<DrillingRigBreakdownDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
