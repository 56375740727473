import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { BaseSingletonService } from './base-singleton.service';
import { LoggerService } from './logger.service';

@Injectable()
export class PusherService extends BaseSingletonService {
    constructor(private logger: LoggerService) {
        super('PusherService');
    }

    public init(apiUrl: string, hubs: { hubName: string, withAuth: boolean }[]) { return; }

    public subscribeToHub<T>(hubData: { hub: string, method: string }): Subject<T> {
        this.logger.logDebug(`[server side] stub method call (${hubData.hub},${hubData.method}). No real hub subscription.`);
        return new Subject();
    }

    public reconnect() { return; }

    public disconnect() { return; }
}
