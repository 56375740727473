import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigTransfersService } from '../../services/drilling-rig-transfers.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigTransferCreateAction, DrillingRigTransferCreateFailAction, DrillingRigTransferCreateResetAction, DrillingRigTransferCreateSuccessAction } from './drilling-rig-transfer-create.actions';

export interface DrillingRigTransferCreateStateModel {
    pending: boolean;
    created: { uid: string };
    error: ErrorObject;
}

@State<DrillingRigTransferCreateStateModel>({
    name: 'drillingRigTransferCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class DrillingRigTransferCreateState {
    constructor(private drillingRigTransfersService: DrillingRigTransfersService) { }

    @Action(DrillingRigTransferCreateAction) async drillingRigTransferCreate(ctx: StateContext<DrillingRigTransferCreateStateModel>, action: DrillingRigTransferCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.drillingRigTransfersService.createDrillingRigTransfer(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new DrillingRigTransferCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigTransferCreateFailAction(err)), 0));
    }

    @Action(DrillingRigTransferCreateSuccessAction) drillingRigTransferCreateSuccess(ctx: StateContext<DrillingRigTransferCreateStateModel>, action: DrillingRigTransferCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(DrillingRigTransferCreateFailAction) drillingRigTransferCreateFail(ctx: StateContext<DrillingRigTransferCreateStateModel>, action: DrillingRigTransferCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(DrillingRigTransferCreateResetAction) drillingRigTransferCreateReset(ctx: StateContext<DrillingRigTransferCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
