import { CreateOrUpdateVehicleTransferAggregationRequest } from '../../models/business/vehicle-transfer/create-or-update-vehicleTransfer-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class VehicleTransferCreateAction {
    static readonly type = '[VehicleTransfers Page] CreateVehicleTransfer';

    constructor(public payload: { request: CreateOrUpdateVehicleTransferAggregationRequest }) { }
}

export class VehicleTransferCreateSuccessAction {
    static readonly type = '[VehicleTransfer API] CreateVehicleTransfer Success';

    constructor(public payload: { uid: string }) { }
}

export class VehicleTransferCreateFailAction {
    static readonly type = '[VehicleTransfer API] CreateVehicleTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleTransferCreateResetAction {
    static readonly type = '[VehicleTransfers Page] CreateVehicleTransfer Reset';
}
