import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContractUpdateSuccessAction } from './contract-update.actions';
import { ContractAction, ContractFailAction, ContractResetAction, ContractSuccessAction } from './contract.actions';
import { Contract } from '../../models/business/contract/contract.model';
import { ContractsService } from '../../services/contracts.service';


export interface ContractStateModel {
    pending: boolean;
    entity: Contract;
    error: ErrorObject;
    retrieved: boolean;
}

@State<ContractStateModel>({
    name: 'contract',
    defaults: { pending: false, entity: null, error: null, retrieved: false }
})
@Injectable()
export class ContractState {
    constructor(private contractsService: ContractsService) { }

    @Action(ContractAction) async contract(ctx: StateContext<ContractStateModel>, action: ContractAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, entity: null, error: null });

        return this.contractsService.getContract(action.payload.uid)
            .then(res => setTimeout(() => ctx.dispatch(new ContractSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContractFailAction(err)), 0));
    }

    @Action(ContractSuccessAction) contractSuccess(ctx: StateContext<ContractStateModel>, action: ContractSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, retrieved: true, entity: action.payload, error: null });
    }

    @Action(ContractFailAction) contractFail(ctx: StateContext<ContractStateModel>, action: ContractFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, retrieved: true, error: action.payload });
    }

    @Action(ContractResetAction) contractReset(ctx: StateContext<ContractStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(ContractUpdateSuccessAction) contractUpdateSuccess(ctx: StateContext<ContractStateModel>, action: ContractUpdateSuccessAction) {
        const state = ctx.getState();
        const entity: any = { ...state.entity, ...action.payload.response };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
