import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ContractsService } from '../../services/contracts.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { ContractCreateAction, ContractCreateFailAction, ContractCreateResetAction, ContractCreateSuccessAction } from './contract-create.actions';
import { Contract } from '../../models/business/contract/contract.model';


export interface ContractCreateStateModel {
    pending: boolean;
    created: Contract;
    error: ErrorObject;
}

@State<ContractCreateStateModel>({
    name: 'contractCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class ContractCreateState {
    constructor(private contractsService: ContractsService) { }

    @Action(ContractCreateAction) async contractCreate(ctx: StateContext<ContractCreateStateModel>, action: ContractCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.contractsService.createContract(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new ContractCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ContractCreateFailAction(err)), 0));
    }

    @Action(ContractCreateSuccessAction) contractCreateSuccess(ctx: StateContext<ContractCreateStateModel>, action: ContractCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(ContractCreateFailAction) contractCreateFail(ctx: StateContext<ContractCreateStateModel>, action: ContractCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(ContractCreateResetAction) contractCreateReset(ctx: StateContext<ContractCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
