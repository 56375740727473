import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftsService } from '../../services/shifts.service';

import { WorkingAreaShiftsSummaryListGetFilter } from '../../models/filters/working-area-shifts-summary-list-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { ShiftsWorkbookExportAction, ShiftsWorkbookExportFailAction, ShiftsWorkbookExportResetAction, ShiftsWorkbookExportSuccessAction } from './shifts-workbook-export.actions';

export interface ShiftsWorkbookExportStateModel {
    loaded: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<ShiftsWorkbookExportStateModel>({
    name: 'shiftsWorkbookExport',
    defaults: { pending: false, loaded: false, error: null }
})
@Injectable()
export class ShiftsWorkbookExportState {
    constructor(private shiftsService: ShiftsService) {}

    @Action(ShiftsWorkbookExportAction) shiftsWorkbookExportGet(ctx: StateContext<ShiftsWorkbookExportStateModel>, action: ShiftsWorkbookExportAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new WorkingAreaShiftsSummaryListGetFilter({ filterData: action.payload.filter });
        return this.shiftsService.getShiftsWorkbook(filter)
            .then(() => setTimeout(() => ctx.dispatch(new ShiftsWorkbookExportSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftsWorkbookExportFailAction(err)), 0));
    }

    @Action(ShiftsWorkbookExportSuccessAction) shiftsWorkbookExportGetSuccess(ctx: StateContext<ShiftsWorkbookExportStateModel>, _: ShiftsWorkbookExportSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: true, error: null });
    }

    @Action(ShiftsWorkbookExportFailAction) shiftsWorkbookExportGetFail(ctx: StateContext<ShiftsWorkbookExportStateModel>, action: ShiftsWorkbookExportFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftsWorkbookExportResetAction) shiftsWorkbookExportGetReset(ctx: StateContext<ShiftsWorkbookExportStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: false, error: null });
    }
}
