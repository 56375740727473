import { EmployeeScheduleGetFilterData } from '../../models/filters/employee-schedule-get-filter.model';
import { EmployeeScheduleItem } from '../../models/business/employee/employee-schedule-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class EmployeeScheduleAction {
    static readonly type = '[EmployeeSchedule Page] GetEmployeeSchedule';

    constructor(public payload: PaginationRequest) { }
}

export class EmployeeScheduleSuccessAction {
    static readonly type = '[EmployeeSchedule API] GetEmployeeSchedule Success';

    constructor(public payload: { list: PaginationResult<EmployeeScheduleItem>, pagination: PaginationRequest }) { }
}

export class EmployeeScheduleFailAction {
    static readonly type = '[EmployeeSchedule API] GetEmployeeSchedule Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeScheduleResetAction {
    static readonly type = '[EmployeeSchedule Page] GetEmployeeSchedule Reset';
}

export class EmployeeScheduleSetFilterAction {
    static readonly type = '[EmployeeSchedule API] SetFilter';

    constructor(public payload: EmployeeScheduleGetFilterData) { }
}
