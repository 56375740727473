<mat-form-field [class.border-enable]="border" [appearance]="appearance" (mouseover)="hovered=true" (mouseleave)="hovered=false" (focusin)="focused=true" (focusout)="focused=false">
    <mat-label class="placeholder">{{placeholder}}</mat-label>

    <input #inputBegin matInput
           class="web" 
           [ngxTimepicker]="exgPickerFrom"
           [value]="internalValue.begin"
           [required]="!!required"
           [format]="24"
           autocomplete="off"
           [min]="minTimeValidate"
           [max]="maxTimeInternal"
           [disabled]="!!readonly"
           (timeChanged)="onBeginTimeChange($event)">

    <span class="wrapper" *ngIf="!!internalValue.begin && !!internalValue.end"> – </span>

    <input #inputEnd matInput
           class="web" 
           [ngxTimepicker]="exgPickerTo"
           [value]="internalValue.end"
           [required]="!!required"
           [format]="24"
           autocomplete="off"
           [min]="minTimeInternal"
           [max]="maxTimeValidate"
           [disabled]="!!readonly || !internalValue.begin"
           (timeChanged)="onEndTimeChange($event)">

    <ngx-material-timepicker #exgPickerFrom
                             [cancelBtnTmpl]="cancelBtnTmplBegin"
                             [format]="24"
                             [confirmBtnTmpl]="confirmBtnTmplBegin"
                             (closed)="onTimepickerDialogClose(true)"
                             (timeChanged)="onBeginTimeChange($event)"></ngx-material-timepicker>

    <ngx-material-timepicker #exgPickerTo
                             [cancelBtnTmpl]="cancelBtnTmpl"
                             [format]="24"
                             [confirmBtnTmpl]="confirmBtnTmpl"
                             (timeChanged)="onEndTimeChange($event)"></ngx-material-timepicker>

    <ng-template #cancelBtnTmplBegin>
        <exg-button class="cancel btn" styleType="outlined">{{'Cancel' | translate}}</exg-button>
    </ng-template>

    <ng-template #cancelBtnTmpl>
        <exg-button class="cancel btn" styleType="outlined">{{'Cancel' | translate}}</exg-button>
    </ng-template>

    <ng-template #confirmBtnTmplBegin>
        <exg-button class="btn" styleType="common">{{'Confirm' | translate}}</exg-button>
    </ng-template>

    <ng-template #confirmBtnTmpl>
        <exg-button class="btn" styleType="common">{{'Confirm' | translate}}</exg-button>
    </ng-template>

    <exg-button matSuffix class="time-show" styleType="icon" [tabindex]="-1" (btnClick)="openTimepicker()">
        <exg-icon class="show-icon" [ngClass]="{'show-icon__disabled': !iconColor}" [color]="iconColor" iconName="time-line"></exg-icon>
    </exg-button>

    <exg-validation-messages style="margin-left: auto;" [controls]="controlsToValidate" [validate]="validate" [showErrors]="focused || hovered"></exg-validation-messages>
</mat-form-field>

<input style="position: fixed; top: -5000px; left: -5000px" #elemToFocus />
