import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigTransfersService } from '../../services/drilling-rig-transfers.service';

import { DrillingRigTransfer } from '../../models/business/drilling-rig-transfer/drilling-rig-transfer.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigTransferUpdateSuccessAction } from './drilling-rig-transfer-update.actions';
import { DrillingRigTransferAction, DrillingRigTransferFailAction, DrillingRigTransferResetAction, DrillingRigTransferSuccessAction } from './drilling-rig-transfer.actions';

export interface DrillingRigTransferStateModel {
    entity: DrillingRigTransfer;
    pending: boolean;
    error: ErrorObject;
}

@State<DrillingRigTransferStateModel>({
    name: 'drillingRigTransfer',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class DrillingRigTransferState {
    constructor(private drillingRigTransfersService: DrillingRigTransfersService) {}


    @Action(DrillingRigTransferAction) drillingRigTransferGet(ctx: StateContext<DrillingRigTransferStateModel>, action: DrillingRigTransferAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.drillingRigTransfersService.getDrillingRigTransfer(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigTransferSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigTransferFailAction(err)), 0));
    }

    @Action(DrillingRigTransferSuccessAction) drillingRigTransferGetSuccess(ctx: StateContext<DrillingRigTransferStateModel>, action: DrillingRigTransferSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(DrillingRigTransferFailAction) drillingRigTransferGetFail(ctx: StateContext<DrillingRigTransferStateModel>, action: DrillingRigTransferFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigTransferResetAction) drillingRigTransferGetReset(ctx: StateContext<DrillingRigTransferStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(DrillingRigTransferUpdateSuccessAction) drillingRigTransferUpdateSuccessAction(ctx: StateContext<DrillingRigTransferStateModel>, action: DrillingRigTransferUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = <any>{ ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
