import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DictionaryVehicleTypesAction {
    static readonly type = '[DictionaryVehicleTypes Page] GetDictionaryVehicleTypes';
}

export class DictionaryVehicleTypesSuccessAction {
    static readonly type = '[DictionaryVehicleTypes API] GetDictionaryVehicleTypes Success';

    constructor(public payload: DictionaryEntryListItem[]) { }
}

export class DictionaryVehicleTypesFailAction {
    static readonly type = '[DictionaryVehicleTypes API] GetDictionaryVehicleTypes Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryVehicleTypesResetAction {
    static readonly type = '[DictionaryVehicleTypes Page] GetDictionaryVehicleTypes Reset';
}
