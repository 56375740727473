import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { RouteCreateOrUpdateRequest } from '../../models/business/route/route-create-or-update-request.model';
import { Route } from '../../models/business/route/route.model';

export class RouteUpdateAction {
    static readonly type = '[Route Page] UpdateRoute';

    constructor(public payload: { uid: string, request: RouteCreateOrUpdateRequest }) { }
}

export class RouteUpdateSuccessAction {
    static readonly type = '[Route API] UpdateRoute Success';

    constructor(public payload: { uid: string, request: Route }) { }
}

export class RouteUpdateFailAction {
    static readonly type = '[Route API] UpdateRoute Fail';

    constructor(public payload: ErrorObject) { }
}

export class RouteUpdateResetAction {
    static readonly type = '[Route Page] UpdateRoute Reset';
}
