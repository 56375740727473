import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigDeleteAction {
    static readonly type = '[My DrillingRig Page] DeleteDrillingRig';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigDeleteSuccessAction {
    static readonly type = '[My DrillingRigs API] DeleteDrillingRig Success';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigDeleteFailAction {
    static readonly type = '[My DrillingRigs API] DeleteDrillingRig Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigDeleteResetAction {
    static readonly type = '[My DrillingRig Page] DeleteDrillingRig Reset';
}
