import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { CompaniesListFilterData } from '../../models/filters/companies-list-filter.model';

import { CompaniesSearchAction, CompaniesSearchResetAction } from './companies-search.actions';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

@Injectable({
    providedIn: 'root'
})
export class CompaniesSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('CompaniesSearchDispatchers');
    }

    public dispatchCompaniesSearchAction(request: CompaniesListFilterData) {
        this.store.dispatch(new CompaniesSearchAction(request));
    }

    public dispatchCompaniesSearchResetAction() {
        this.store.dispatch(new CompaniesSearchResetAction());
    }
}
