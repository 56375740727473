import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigBreakdownsService } from '../../services/drilling-rig-breakdowns.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigBreakdownCreateAction, DrillingRigBreakdownCreateFailAction, DrillingRigBreakdownCreateResetAction, DrillingRigBreakdownCreateSuccessAction } from './drilling-rig-breakdown-create.actions';

export interface DrillingRigBreakdownCreateStateModel {
    pending: boolean;
    created: { uid: string };
    error: ErrorObject;
}

@State<DrillingRigBreakdownCreateStateModel>({
    name: 'drillingRigBreakdownCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class DrillingRigBreakdownCreateState {
    constructor(private drillingRigBreakdownsService: DrillingRigBreakdownsService) { }

    @Action(DrillingRigBreakdownCreateAction) async drillingRigBreakdownCreate(ctx: StateContext<DrillingRigBreakdownCreateStateModel>, action: DrillingRigBreakdownCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.drillingRigBreakdownsService.createDrillingRigBreakdown(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownCreateFailAction(err)), 0));
    }

    @Action(DrillingRigBreakdownCreateSuccessAction) drillingRigBreakdownCreateSuccess(ctx: StateContext<DrillingRigBreakdownCreateStateModel>, action: DrillingRigBreakdownCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(DrillingRigBreakdownCreateFailAction) drillingRigBreakdownCreateFail(ctx: StateContext<DrillingRigBreakdownCreateStateModel>, action: DrillingRigBreakdownCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(DrillingRigBreakdownCreateResetAction) drillingRigBreakdownCreateReset(ctx: StateContext<DrillingRigBreakdownCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
