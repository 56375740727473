import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransportDeleteAction {
    static readonly type = '[My Transport Page] DeleteTransport';

    constructor(public payload: { uid: string }) { }
}

export class TransportDeleteSuccessAction {
    static readonly type = '[My Transports API] DeleteTransport Success';

    constructor(public payload: { uid: string }) { }
}

export class TransportDeleteFailAction {
    static readonly type = '[My Transports API] DeleteTransport Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportDeleteResetAction {
    static readonly type = '[My Transport Page] DeleteTransport Reset';
}
