import { CreateOrUpdateEmployeeAggregationRequest } from '../../models/business/employee/create-or-update-employee-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class EmployeeUpdateAction {
    static readonly type = '[Employee Page] UpdateEmployee';

    constructor(public payload: { uid: string, request: CreateOrUpdateEmployeeAggregationRequest }) { }
}

export class EmployeeUpdateSuccessAction {
    static readonly type = '[Employee API] UpdateEmployee Success';

    constructor(public payload: { uid: string, request: CreateOrUpdateEmployeeAggregationRequest }) { }
}

export class EmployeeUpdateFailAction {
    static readonly type = '[Employee API] UpdateEmployee Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeUpdateResetAction {
    static readonly type = '[Employee Page] UpdateEmployee Reset';
}
