import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { filter } from 'rxjs/operators';

import { AppSelectors } from './burns-ui-framework/shared/store/app.selectors';
import { AuthService } from './burns-ui-framework/shared/services/business/auth.service';
import { BaseSingletonService } from './burns-ui-framework/shared/services/common/base-singleton.service';
import { DialogService } from './burns-ui-framework/shared/services/common/dialog.service';
import { ExgTranslateService } from './burns-ui-framework/shared/services/common/exg-translate.service';
import { LanguageService } from './burns-ui-framework/shared/services/common/language-service.service';
import { LogoutSelectors } from './burns-ui-framework/shared/store/logout/logout.selectors';
import { ProfileDispatchers } from './burns-ui-framework/shared/store/profile/profile.dispatchers';
import { RouterExtService } from './burns-ui-framework/shared/services/common/router-ext.service';
import { SelfEmployeeDispatchers } from './shared-rbc/store/user-profile/self-employee.dispatchers';
import { UiSelectors } from './burns-ui-framework/shared/store/ui/ui.selectors';

import { ExgCultureEnum } from './burns-ui-framework/shared/models/common/exg-culture.model';

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('exgDialogServiceContainer', { read: ViewContainerRef, static: true }) dialogDynamicComponentContainer: ViewContainerRef;

    public loggingOut$ = this.logoutSelectors.pending$;
    public culture$ = this.uiSelectors.culture$;

    public isLoading: boolean;

    constructor(private authService: AuthService,
                private platformLocation: PlatformLocation,
                private meta: Meta,
                private routerExtService: RouterExtService,
                private dialogService: DialogService,
                private translate: ExgTranslateService,
                private appSelectors: AppSelectors,
                private profileDispatchers: ProfileDispatchers,
                private employeeDispatchers: SelfEmployeeDispatchers,
                private languageService: LanguageService,
                private uiSelectors: UiSelectors,
                private logoutSelectors: LogoutSelectors) {
        this.translate.setDefaultLang(ExgCultureEnum.Russian);
        this.translate.apply();
        this.culture$.pipe(filter(x => !!x)).subscribe((culture) => {
            this.languageService.storeLanguage(culture);
            this.translate.apply();
        });
    }

    public ngOnInit() {
        BaseSingletonService.initialized = {};
        this.dialogService.init(this.dialogDynamicComponentContainer);

        this.initProfile();
        this.initOgMetaTags();
        this.routerExtService.init();
    }

    public ngOnDestroy() {
        // force clean singleton services initialization
        BaseSingletonService.initialized = {};
    }

    private initProfile() {
        if (this.authService.isLoggedIn()) {
            const userUid = this.authService.getUserId();
            this.profileDispatchers.dispatchProfileAction(userUid, 'authGuard');
        }
    }

    private initOgMetaTags() {
        this.appSelectors.getRoute().subscribe(event => this.setOpenGraphMetaTags(event.url));
    }

    private setOpenGraphMetaTags(url: string) {
        let origin = `${this.platformLocation.protocol}//${this.platformLocation.hostname}`;
        origin += this.platformLocation.port ? `:${this.platformLocation.port}` : '';

        this.meta.updateTag({ property: 'og:url', content: `${origin}${url}` });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
    }
}
