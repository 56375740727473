import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';

export class AnalyticsGetAction {
    static readonly type = '[Analytics Page] GetAnalytics Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsResetAction {
    static readonly type = '[Analytics Page] GetAnalytics Reset';
}
