import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WorkingAreaShiftsSummaryListGetFilterData } from '../../models/filters/working-area-shifts-summary-list-get-filter.model';
import { WorkingAreaShiftsSummaryListItem } from '../../models/business/shifts/working-area-shifts-summary-list-item.model';

export class ShiftsAction {
    static readonly type = '[Shifts Page] GetShifts';

    constructor(public payload: PaginationRequest) { }
}

export class ShiftsSuccessAction {
    static readonly type = '[Shifts API] GetShifts Success';

    constructor(public payload: { list: PaginationResult<WorkingAreaShiftsSummaryListItem>, pagination: PaginationRequest }) { }
}

export class ShiftsFailAction {
    static readonly type = '[Shifts API] GetShifts Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftsResetAction {
    static readonly type = '[Shifts Page] GetShifts Reset';
}

export class ShiftsSetFilterAction {
    static readonly type = '[Shifts API] SetFilter';

    constructor(public payload: WorkingAreaShiftsSummaryListGetFilterData) { }
}
