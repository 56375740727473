export class AnalyticGetFilter {
    public workingAreaUid?: string;
    public countryCode?: string;
    public drillingRigUid?: string;
    public dateFrom?: number;
    public dateTo?: number;

    constructor(args?: { filterData?: AnalyticGetFilterData }) {
        this.workingAreaUid = (args && args.filterData && args.filterData.workingAreaUid != null) ? args.filterData.workingAreaUid : null;
        this.countryCode = (args && args.filterData && args.filterData.countryCode != null) ? args.filterData.countryCode : null;
        this.drillingRigUid = (args && args.filterData && args.filterData.drillingRigUid != null) ? args.filterData.drillingRigUid : null;
        this.dateFrom = (args && args.filterData && args.filterData.dateFrom != null) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData && args.filterData.dateTo != null) ? args.filterData.dateTo : null;
    }
}

export class AnalyticGetFilterData {
    public workingAreaUid?: string;
    public countryCode?: string;
    public drillingRigUid?: string;
    public dateFrom?: number;
    public dateTo?: number;
}
