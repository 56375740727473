import { CreateOrUpdateTransportBreakdownAggregationRequest } from '../../models/business/transport-breakdown/create-or-update-transport-breakdown-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransportBreakdownUpdateAction {
    static readonly type = '[TransportBreakdown Page] UpdateTransportBreakdown';

    constructor(public payload: { uid: string, request: CreateOrUpdateTransportBreakdownAggregationRequest }) { }
}

export class TransportBreakdownUpdateSuccessAction {
    static readonly type = '[TransportBreakdown API] UpdateTransportBreakdown Success';

    constructor(public payload: { uid: string, request: CreateOrUpdateTransportBreakdownAggregationRequest }) { }
}

export class TransportBreakdownUpdateFailAction {
    static readonly type = '[TransportBreakdown API] UpdateTransportBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportBreakdownUpdateResetAction {
    static readonly type = '[TransportBreakdown Page] UpdateTransportBreakdown Reset';
}
