import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RoutesService } from '../../services/routes.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Route } from '../../models/business/route/route.model';

import { RouteUpdateSuccessAction } from './route-update.actions';
import { RouteAction, RouteFailAction, RouteResetAction, RouteSuccessAction } from './route.actions';

export interface RouteStateModel {
    entity: Route;
    pending: boolean;
    error: ErrorObject;
}

@State<RouteStateModel>({
    name: 'route',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class RouteState {
    constructor(private routesService: RoutesService) {}


    @Action(RouteAction) routeGet(ctx: StateContext<RouteStateModel>, action: RouteAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.routesService.getRoute(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new RouteSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RouteFailAction(err)), 0));
    }

    @Action(RouteSuccessAction) routeGetSuccess(ctx: StateContext<RouteStateModel>, action: RouteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(RouteFailAction) routeGetFail(ctx: StateContext<RouteStateModel>, action: RouteFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RouteResetAction) routeGetReset(ctx: StateContext<RouteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(RouteUpdateSuccessAction) routeUpdateSuccessAction(ctx: StateContext<RouteStateModel>, action: RouteUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = <any>{ ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
