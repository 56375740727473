import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportWorkbookExportAction, TransportWorkbookExportFailAction, TransportWorkbookExportResetAction, TransportWorkbookExportSuccessAction } from './transport-workbook-export.actions';
import { TransportWorkbookGetFilter } from '../../models/filters/transport-workbook-get-filter.model';
import { TransportsService } from '../../services/transports.service';

export interface TransportWorkbookExportStateModel {
    loaded: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<TransportWorkbookExportStateModel>({
    name: 'transportWorkbookExport',
    defaults: { pending: false, loaded: false, error: null }
})
@Injectable()
export class TransportWorkbookExportState {
    constructor(private transportService: TransportsService) {}

    @Action(TransportWorkbookExportAction) transportWorkbookExportGet(ctx: StateContext<TransportWorkbookExportStateModel>, action: TransportWorkbookExportAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new TransportWorkbookGetFilter({ filterData: action.payload.filter });
        return this.transportService.getWorkbook(filter)
            .then(() => setTimeout(() => ctx.dispatch(new TransportWorkbookExportSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportWorkbookExportFailAction(err)), 0));
    }

    @Action(TransportWorkbookExportSuccessAction) transportWorkbookExportGetSuccess(ctx: StateContext<TransportWorkbookExportStateModel>, _: TransportWorkbookExportSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: true, error: null });
    }

    @Action(TransportWorkbookExportFailAction) transportWorkbookExportGetFail(ctx: StateContext<TransportWorkbookExportStateModel>, action: TransportWorkbookExportFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransportWorkbookExportResetAction) transportWorkbookExportGetReset(ctx: StateContext<TransportWorkbookExportStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, loaded: false, error: null });
    }
}
