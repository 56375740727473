import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RoutesService } from '../../services/routes.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Route } from '../../models/business/route/route.model';

import { RouteCreateAction, RouteCreateFailAction, RouteCreateResetAction, RouteCreateSuccessAction } from './route-create.actions';

export interface RouteCreateStateModel {
    pending: boolean;
    created: Route;
    error: ErrorObject;
}

@State<RouteCreateStateModel>({
    name: 'routeCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class RouteCreateState {
    constructor(private routesService: RoutesService) { }

    @Action(RouteCreateAction) async routeCreate(ctx: StateContext<RouteCreateStateModel>, action: RouteCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.routesService.createRoute(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new RouteCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RouteCreateFailAction(err)), 0));
    }

    @Action(RouteCreateSuccessAction) routeCreateSuccess(ctx: StateContext<RouteCreateStateModel>, action: RouteCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(RouteCreateFailAction) routeCreateFail(ctx: StateContext<RouteCreateStateModel>, action: RouteCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(RouteCreateResetAction) routeCreateReset(ctx: StateContext<RouteCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
