import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryCasingTypesAction, DictionaryCasingTypesFailAction, DictionaryCasingTypesResetAction, DictionaryCasingTypesSuccessAction } from './dictionary-casing-types.actions';

export interface DictionaryCasingTypesStateModel {
    entities: DictionaryEntryListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<DictionaryCasingTypesStateModel>({
    name: 'DictionaryCasingTypes',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class DictionaryCasingTypesState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action([DictionaryCasingTypesAction]) casingTypesGet(ctx: StateContext<DictionaryCasingTypesStateModel>, _: DictionaryCasingTypesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.dictionariesService.getCasingTypes()
            .then(resp => setTimeout(() => ctx.dispatch(new DictionaryCasingTypesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryCasingTypesFailAction(err)), 0));
    }

    @Action(DictionaryCasingTypesSuccessAction) casingTypesGetSuccess(ctx: StateContext<DictionaryCasingTypesStateModel>, action: DictionaryCasingTypesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(DictionaryCasingTypesFailAction) casingTypesGetFail(ctx: StateContext<DictionaryCasingTypesStateModel>, action: DictionaryCasingTypesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryCasingTypesResetAction) casingTypesGetReset(ctx: StateContext<DictionaryCasingTypesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
