import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeWorkbookGetFilterData } from '../../models/filters/employee-workbook-get-filter.model';

import { WorkbookExportAction, WorkbookExportResetAction } from './workbook-export.actions';

@Injectable({
    providedIn: 'root'
})
export class WorkbookExportDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('WorkbookExportDispatchers');
    }

    public dispatchWorkbookExportAction(filter: EmployeeWorkbookGetFilterData) {
        this.store.dispatch(new WorkbookExportAction({ filter }));
    }

    public dispatchWorkbookExportResetAction() {
        this.store.dispatch(new WorkbookExportResetAction());
    }
}
