import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { Employee } from '../../models/business/employee/employee.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeAction, EmployeeFailAction, EmployeeResetAction, EmployeeSuccessAction } from './employee.actions';

export interface EmployeeStateModel {
    entity: Employee;
    pending: boolean;
    error: ErrorObject;
}

@State<EmployeeStateModel>({
    name: 'employee',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class EmployeeState {
    constructor(private employeesService: EmployeesService) {}


    @Action(EmployeeAction) employeeGet(ctx: StateContext<EmployeeStateModel>, action: EmployeeAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.employeesService.getEmploye(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new EmployeeSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeeFailAction(err)), 0));
    }

    @Action(EmployeeSuccessAction) employeeGetSuccess(ctx: StateContext<EmployeeStateModel>, action: EmployeeSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(EmployeeFailAction) employeeGetFail(ctx: StateContext<EmployeeStateModel>, action: EmployeeFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(EmployeeResetAction) employeeGetReset(ctx: StateContext<EmployeeStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
