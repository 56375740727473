import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { CasingTypesUpdateAction, CasingTypesUpdateFailAction, CasingTypesUpdateResetAction, CasingTypesUpdateSuccessAction } from './casing-types-update.actions';


export interface CasingTypesUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<CasingTypesUpdateStateModel>({
    name: 'CasingTypesUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class CasingTypesUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(CasingTypesUpdateAction) async casingTypes(ctx: StateContext<CasingTypesUpdateStateModel>, action: CasingTypesUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateCasingTypes(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new CasingTypesUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CasingTypesUpdateFailAction(err)), 0));
    }

    @Action(CasingTypesUpdateSuccessAction) casingTypesSuccess(ctx: StateContext<CasingTypesUpdateStateModel>, _: CasingTypesUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(CasingTypesUpdateFailAction) casingTypesFail(ctx: StateContext<CasingTypesUpdateStateModel>, action: CasingTypesUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CasingTypesUpdateResetAction) casingTypesReset(ctx: StateContext<CasingTypesUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
