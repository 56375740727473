import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MatTreeModule } from '@angular/material/tree';
import { SharedModule } from '../burns-ui-framework/shared/shared.module';

import { RbcEmployeeSchedulesListItemComponent } from './components/rbc-employee-schedules/rbc-employee-schedules-list-item/rbc-employee-schedules-list-item.component';
import { RbcEmployeeSchedulesComponent } from './components/rbc-employee-schedules/rbc-employee-schedules.component';
import { RbcErpRootComponent } from './components/rbc-erp-root/rbc-erp-root.component';
import { RbcFilterFieldComponent } from './components/rbc-filter-field/rbc-filter-field.component';
import { RbcLeftMenuComponent } from './components/rbc-left-menu/rbc-left-menu.component';
import { RbcMobileMenuComponent } from './components/rbc-mobile-menu/rbc-mobile-menu.component';
import { RbcPersonnelRightHeaderComponent } from './components/rbc-personnel-right-header/rbc-personnel-right-header.component';
import { RbcProgressBarComponent } from './components/rbc-progress-bar/rbc-progress-bar.component';
import { RbcTransportHeaderComponent } from './components/rbc-transport-header/rbc-transport-header.component';
import { RbcTransportRightHeaderComponent } from './components/rbc-transport-right-header/rbc-transport-right-header.component';

import { RbcFullNamePipe } from './pipes/rbc-full-name/rbc-full-name.pipe';
import { RbcNumberEmptyPipe } from './pipes/rbc-number-empty/rbc-number-empty.pipe';
import { RbcDrillingRigHeaderComponent } from './components/rbc-drilling-rig-header/rbc-drilling-rig-header.component';
import { RbcDrillingRigRightHeaderComponent } from './components/rbc-drilling-rig-right-header/rbc-drilling-rig-right-header.component';
import { RbcExportComponent } from './components/rbc-export/rbc-export.component';
import { RbcYandexGeocodingComponent } from './components/rbc-yandex-geocoding/rbc-yandex-geocoding.component';


@NgModule({
    imports: [
        SharedModule,
        MatProgressBarModule,
        MatTreeModule
    ],
    declarations: [
        RbcEmployeeSchedulesListItemComponent,
        RbcEmployeeSchedulesComponent,
        RbcFilterFieldComponent,
        RbcErpRootComponent,
        RbcLeftMenuComponent,
        RbcMobileMenuComponent,
        RbcPersonnelRightHeaderComponent,
        RbcTransportRightHeaderComponent,
        RbcDrillingRigRightHeaderComponent,
        RbcProgressBarComponent,
        RbcTransportHeaderComponent,
        RbcDrillingRigHeaderComponent,
        RbcExportComponent,
        RbcYandexGeocodingComponent,

        RbcFullNamePipe,
        RbcNumberEmptyPipe
    ],
    exports: [
        RbcEmployeeSchedulesListItemComponent,
        RbcEmployeeSchedulesComponent,
        RbcFilterFieldComponent,
        RbcErpRootComponent,
        RbcLeftMenuComponent,
        RbcMobileMenuComponent,
        RbcPersonnelRightHeaderComponent,
        RbcTransportRightHeaderComponent,
        RbcDrillingRigRightHeaderComponent,
        RbcTransportHeaderComponent,
        RbcDrillingRigHeaderComponent,
        RbcExportComponent,
        RbcProgressBarComponent,
        RbcYandexGeocodingComponent,

        RbcFullNamePipe,
        RbcNumberEmptyPipe
    ],
})
export class RbcModule {
    static forRoot(settingsProviders: { token: string, value: any }[]): ModuleWithProviders<RbcModule> {
        const providers: Provider[] = settingsProviders.map(setting => ({ provide: setting.token, useValue: setting.value }));

        return {
            ngModule: RbcModule,
            providers
        };
    }
}
