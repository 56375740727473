import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { FullnamePart } from '../models/business/suggestions/fullname-part.enum';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';
import { SuggestionFullName } from '../models/business/suggestions/suggestion-full-name.model';

import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class SuggestionService extends BaseSingletonService {

    private settings: {
        service: {
            getFullName: string;
        }
    };

    constructor(private http: HttpService) {
        super('SuggestionService');
        this.settings = {
            service: {
                getFullName: '/suggestions/v1/personal/fullnames?term={0}&parts={1}'
            }
        };
    }

    /**
     * Get full name suggestion
     */
    public async getFullName(term: string, parts: FullnamePart[]): Promise<SuggestionFullName[]> {
        const partsJoined = (parts || []).join(',');
        return this.http.get<{ list: SuggestionFullName[] }>(String.format(this.settings.service.getFullName, term, partsJoined))
            .then(resp => resp.list);
    }
}
