import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';
import { LanguageService } from '../../burns-ui-framework/shared/services/common/language-service.service';

import { Geocoding } from '../models/business/geocoding/geocoding.model';
import { String } from '../../burns-ui-framework/shared/utils/string';


@Injectable({
    providedIn: 'root'
})
export class YandexGeocodingService extends BaseSingletonService {

    private settings: {
        service: {
            getPlacesList: string;
        }
    };

    constructor(private http: HttpService, private languageService: LanguageService) {
        super('YandexGeocodingService');
        this.settings = {
            service: {
                getPlacesList: '/geocoding/v1/yandex/places?geocode={0}&culture={1}'
            }
        };
    }

    public async getPlacesList(geocode: string): Promise<Geocoding[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: Geocoding[] }>(String.format(this.settings.service.getPlacesList, geocode, culture))
            .then(res => {
                return res.list;
            });
    }
}
