import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WellListItem } from '../../models/business/shifts/well-list-item.model';

export class ShiftWellsAction {
    static readonly type = '[ShiftWells Page] GetShiftWells';

    constructor(public payload: { workingAreaUid: string }) { }
}

export class ShiftWellsSuccessAction {
    static readonly type = '[ShiftWells API] GetShiftWells Success';

    constructor(public payload: WellListItem[]) { }
}

export class ShiftWellsFailAction {
    static readonly type = '[ShiftWells API] GetShiftWells Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftWellsResetAction {
    static readonly type = '[ShiftWells Page] GetShiftWells Reset';
}
