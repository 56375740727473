import { DrillingRigTransfer } from '../../models/business/drilling-rig-transfer/drilling-rig-transfer.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigTransferAction {
    static readonly type = '[DrillingRigTransfer Page] GetDrillingRigTransfer';

    constructor(public payload: { uid: string}) { }
}

export class DrillingRigTransferSuccessAction {
    static readonly type = '[DrillingRigTransfer API] GetDrillingRigTransfer Success';

    constructor(public payload: DrillingRigTransfer) { }
}

export class DrillingRigTransferFailAction {
    static readonly type = '[DrillingRigTransfer API] GetDrillingRigTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigTransferResetAction {
    static readonly type = '[DrillingRigTransfer Page] GetDrillingRigTransfer Reset';
}
