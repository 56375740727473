<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="routing-tree">
    <mat-nested-tree-node *matTreeNodeDef="let node">
        <div class="mat-tree-node">
            <exg-button class="mat-tree-node_btn" styleType="texted" [class.active-exact]="exactMatch(node)" matTreeNodeToggle [routerLink]="[node.route]" routerLinkActive="active">
                <exg-icon class="mat-icon-rtl-mirror" *ngIf="!node.disableArrow" [class.expanded]="treeControl.isExpanded(node) && hasChild(null, node)" iconName="arrowleft"></exg-icon>
                <exg-icon *ngIf="node.icon" class="route-icon" [iconName]="node.icon"></exg-icon>
                <div class="node-title">{{node.name | translate}}</div>
            </exg-button>
        </div>

        <div class="childrens" [class.tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
</mat-tree>