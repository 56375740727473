import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { YandexGeocodingService } from '../../services/yandex-geocoding.service';

import { YandexGeocodingAction, YandexGeocodingFailAction, YandexGeocodingResetAction, YandexGeocodingSuccessAction } from './yandex-geocoding.actions';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Geocoding } from '../../models/business/geocoding/geocoding.model';

export interface YandexGeocodingStateModel {
    pending: boolean;
    entities: Geocoding[];
    retrieved: boolean;
    error: ErrorObject;
}

@State<YandexGeocodingStateModel>({
    name: 'yandexGeocoding',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class YandexGeocodingState {
    constructor(private geocodingService: YandexGeocodingService) { }

    @Action([YandexGeocodingAction]) async yandexGeocodingGet(ctx: StateContext<YandexGeocodingStateModel>, action: YandexGeocodingAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.geocodingService.getPlacesList(action.payload.geocode)
            .then(resp => setTimeout(() => ctx.dispatch(new YandexGeocodingSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new YandexGeocodingFailAction(err)), 0));
    }

    @Action(YandexGeocodingSuccessAction) yandexGeocodingGetSuccess(ctx: StateContext<YandexGeocodingStateModel>, action: YandexGeocodingSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(YandexGeocodingFailAction) yandexGeocodingGetFail(ctx: StateContext<YandexGeocodingStateModel>, action: YandexGeocodingFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(YandexGeocodingResetAction) yandexGeocodingGetReset(ctx: StateContext<YandexGeocodingStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
