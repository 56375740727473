import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { EmployeeScheduleItem } from '../../../models/business/employee/employee-schedule-item.model';
import { LinkedRoute } from '../../../models/business/employee/linked-route.model';

import { DateUtils } from '../../../../burns-ui-framework/shared/utils/date-utils';
import { RoutesUtils } from '../../../../shared-rbc/utils/routes.utils';

export class LinkedRouteExtended extends LinkedRoute {
    public widthLength: number;
}

export class LinkedRouteExtended2 extends LinkedRouteExtended {
    public startOffset: number;
}

@Component({
    selector: 'rbc-employee-schedules-list-item',
    templateUrl: './rbc-employee-schedules-list-item.component.html',
    styleUrls: ['./rbc-employee-schedules-list-item.component.scss', '../rbc-employee-schedules.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcEmployeeSchedulesListItemComponent implements OnChanges {
    @Input() scheduleItem: EmployeeScheduleItem;
    @Input() monthsToDisplay: string[];
    @Input() baseWidthPx: number;

    @Output() readonly openEmployeeRoute = new EventEmitter<{ uid: string, routeUid: string }>();

    public scheduleDateItems: LinkedRouteExtended2[];

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.scheduleItem || changes.monthsToDisplay) && this.scheduleItem && this.monthsToDisplay) {
            this.scheduleDateItems = RoutesUtils.addMoqRoutes(this.scheduleItem.routes).map(route => {
                const startPoint = DateUtils.compareDates(DateUtils.convertEpocToString(route.startDate), this.monthsToDisplay[0])
                    ? this.monthsToDisplay[0]
                    : DateUtils.convertEpocToString(route.startDate);

                return {
                    ...route,
                    isActual: !!route.workingArea && !!route.workingArea.uid,
                    widthLength: DateUtils.getDateDifference(startPoint, DateUtils.convertEpocToString(route.endDate), 'months', true),
                    startOffset: DateUtils.getDateDifference(this.monthsToDisplay[0], startPoint, 'months', true)
                };
            });
        }
    }

    public trackByScheduleDateItem(_, item: LinkedRouteExtended) {
        return item?.uid;
    }

    public getWidth() {
        return this.baseWidthPx * this.monthsToDisplay.length;
    }

    public onEmployeeRouteClick(scheduleDateItem: LinkedRouteExtended) {
        this.openEmployeeRoute.emit({ uid: this.scheduleItem.uid, routeUid: scheduleDateItem.uid });
    }
}
