import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class WorkingTypeSearchAction {
    static readonly type = '[WorkingTypeSearch Page] GetWorkingAreaSearch';
}

export class WorkingTypeSearchSuccessAction {
    static readonly type = '[WorkingTypeSearch API] GetWorkingAreaSearch Success';

    constructor(public payload: DictionaryEntryListItem[]) { }
}

export class WorkingTypeSearchFailAction {
    static readonly type = '[WorkingTypeSearch API] GetWorkingAreaSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class WorkingTypeSearchResetAction {
    static readonly type = '[WorkingTypeSearch Page] GetWorkingAreaSearch Reset';
}
