<section class="hedaer">
    <div class="tabs">
        <exg-button styleType="custom" color="primary" class="back-btn" (btnClick)="onBackClick()">
            <exg-icon class="back-icon" color="primary" iconName="arrowleft"></exg-icon>
            <div class="back-title">{{ 'Back' | translate }}</div>
        </exg-button>
        <exg-button class="tab_btn form" styleType="custom" [routerLink]="formPath" [class.active]="routingData.isForm">
            <exg-icon class="btn-icon tab_btn-icon" iconName="personnel"></exg-icon>
            {{'Form' | translate}}
        </exg-button>
        <exg-button  [exgDisabled]="!employee" class="tab_btn schedule" styleType="custom" [routerLink]="schedulePath" [class.active]="routingData.schedule" [class.mobile-form]="routingData.isForm">
            <exg-icon class="btn-icon tab_btn-icon" iconName="time-line"></exg-icon>
            {{'Work schedule' | translate}}
        </exg-button>
        <exg-button  [exgDisabled]="!employee" class="tab_btn" styleType="custom" [routerLink]="routePath" [class.active]="routingData.rotues">
            <exg-icon class="btn-icon tab_btn-icon" iconName="route"></exg-icon>
            {{'Routes' | translate}}
        </exg-button>
    </div>
    <div class="date-range"  [exgDisabled]="!employee" [class.form-page]="routingData.isForm">
        <exg-datepicker-range class="dates"
                              styleType="empty"
                              [border]="false"
                              [value]="datepickerData"
                              [minDate]="minCalendarDate"
                              placeholder="{{'Time period' | translate}}"
                              (valueChange)="onDatesRangeChanged($event)"></exg-datepicker-range>
    </div>
</section>