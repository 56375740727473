import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class RouteDeleteAction {
    static readonly type = '[My Route Page] DeleteRoute';

    constructor(public payload: { uid: string }) { }
}

export class RouteDeleteSuccessAction {
    static readonly type = '[My Routes API] DeleteRoute Success';

    constructor(public payload: { uid: string }) { }
}

export class RouteDeleteFailAction {
    static readonly type = '[My Routes API] DeleteRoute Fail';

    constructor(public payload: ErrorObject) { }
}

export class RouteDeleteResetAction {
    static readonly type = '[My Route Page] DeleteRoute Reset';
}
