import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { DrillingRigListGetFilter, DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigListItem } from '../../models/business/drilling-rig/drilling-rig-list-item.model';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { DrillingRigsSearchAction, DrillingRigsSearchFailAction, DrillingRigsSearchResetAction, DrillingRigsSearchSuccessAction } from './drilling-rigs-search.actions';

import { ExgParamsConfig } from '../../exg-params.config';

export interface DrillingRigsSearchStateModel extends PaginationStateModel<DrillingRigListItem> {
    uids: string[];
    entities: { [uid: string]: DrillingRigListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: DrillingRigListGetFilterData;
    total: number;
}

@State<DrillingRigsSearchStateModel>({
    name: 'DrillingRigsSearch',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class DrillingRigsSearchState extends PaginationStateBase {
    constructor(private drillingRigsService: DrillingRigsService) {
        super();
    }

    @Action([DrillingRigsSearchAction]) DrillingRigsSearchGet(ctx: StateContext<DrillingRigsSearchStateModel>, action: DrillingRigsSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new DrillingRigListGetFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.drillingRigsService.getDrillingRigList(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigsSearchSuccessAction({ list: resp, pagination: PaginationRequest.ReloadFullList })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigsSearchFailAction(err)), 0));
    }

    @Action(DrillingRigsSearchSuccessAction) DrillingRigsSearchGetSuccess(ctx: StateContext<DrillingRigsSearchStateModel>, action: DrillingRigsSearchSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.drillingRigsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(DrillingRigsSearchFailAction) DrillingRigsSearchGetFail(ctx: StateContext<DrillingRigsSearchStateModel>, action: DrillingRigsSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigsSearchResetAction) DrillingRigsSearchGetReset(ctx: StateContext<DrillingRigsSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }
}
