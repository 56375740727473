import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { CreateOrUpdateDrillingRigTransferAggregationRequest } from '../models/business/drilling-rig-transfer/create-or-update-drilling-rig-transfer-aggregation-request.model';
import { DrillingRigTransferListGetFilter } from '../models/filters/drilling-rig-transfer-list-get-filter.model';
import { DrillingRigTransferListItem } from '../models/business/drilling-rig-transfer/drilling-rig-transfer-list-item.model';
import { DrillingRigTransfer } from '../models/business/drilling-rig-transfer/drilling-rig-transfer.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';


@Injectable({
    providedIn: 'root'
})
export class DrillingRigTransfersService extends BaseSingletonService {

    private settings: {
        service: {
            getDrillingRigTransfer: string;
            getDrillingRigTransfers: string;
            createDrillingRigTransfer: string;
            updateDrillingRigTransfer: string;
            deleteDrillingRigTransfer: string;
        }
    };

    constructor(private http: HttpService) {
        super('DrillingRigTransfersService');
        this.settings = {
            service: {
                getDrillingRigTransfer: '/drilling/v1/drilling-rig-transfers/{0}',
                getDrillingRigTransfers: '/drilling/v1/drilling-rig-transfers?term={0}&drillingRigUid={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                createDrillingRigTransfer: '/drilling/v1/drilling-rig-transfers',
                updateDrillingRigTransfer: '/drilling/v1/drilling-rig-transfers/{0}',
                deleteDrillingRigTransfer: '/drilling/v1/drilling-rig-transfers/{0}'
            }
        };
    }

    public async getDrillingRigTransfer(uid: string): Promise<DrillingRigTransfer> {
        return this.http.get<{ data: DrillingRigTransfer }>(String.format(this.settings.service.getDrillingRigTransfer, uid))
            .then(res => res.data);
    }

    public async getDrillingRigTransfers(filter: DrillingRigTransferListGetFilter): Promise<PaginationResult<DrillingRigTransferListItem>> {
        return this.http.get<PaginationResult<DrillingRigTransferListItem>>(String.format(this.settings.service.getDrillingRigTransfers, filter.term, filter.drillingRigUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createDrillingRigTransfer(request: CreateOrUpdateDrillingRigTransferAggregationRequest): Promise<{ uid: string }> {
        return this.http.post<{ data: string }>(String.format(this.settings.service.createDrillingRigTransfer), request)
            .then(res => ({ uid: res.data }));
    }

    public async updateDrillingRigTransfer(uid: string, request: CreateOrUpdateDrillingRigTransferAggregationRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateDrillingRigTransfer, uid), request);
    }

    public async deleteDrillingRigTransfer(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteDrillingRigTransfer, uid));
    }
}
