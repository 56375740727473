import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WorkingAreaListGetFilterData } from '../../models/filters/working-area-list-get-filter.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

export class WorkingAreaSearchAction {
    static readonly type = '[WorkingAreaSearch Page] GetWorkingAreaSearch';

    constructor(public payload: WorkingAreaListGetFilterData) { }
}

export class WorkingAreaSearchSuccessAction {
    static readonly type = '[WorkingAreaSearch API] GetWorkingAreaSearch Success';

    constructor(public payload: PaginationResult<WorkingAreaListItem>) { }
}

export class WorkingAreaSearchFailAction {
    static readonly type = '[WorkingAreaSearch API] GetWorkingAreaSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class WorkingAreaSearchResetAction {
    static readonly type = '[WorkingAreaSearch Page] GetWorkingAreaSearch Reset';
}
