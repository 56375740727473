import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeDeleteState, EmployeeDeleteStateModel } from './employee-delete.reducer';

@Injectable({
    providedIn: 'root'
})
export class EmployeeDeleteSelectors extends BaseSingletonService {
    static readonly getPendings = createSelector([EmployeeDeleteState], (state: EmployeeDeleteStateModel) => state.pending);

    static readonly getErrors = createSelector([EmployeeDeleteState], (state: EmployeeDeleteStateModel) => state.error);

    static readonly getDeleted = createSelector([EmployeeDeleteState], (state: EmployeeDeleteStateModel) => state.deleted);

    @Select(EmployeeDeleteSelectors.getPendings) pending$: Observable<boolean>;

    @Select(EmployeeDeleteSelectors.getErrors) error$: Observable<ErrorObject>;

    @Select(EmployeeDeleteSelectors.getDeleted) deleted$: Observable<boolean>;

    constructor() {
        super('EmployeeDeleteSelectors');
    }
}
