import { PaginationFilter } from '../../models/filters/pagination-filter.model';
import { PaginationRequest } from '../../models/common/pagination-request.model';
import { PaginationResult } from '../../models/common/pagination-result.model';

import { Utils } from '../../utils/utils';

export interface PaginationStateModel<T> {
    uids: string[];
    entities: { [uid: string]: T };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
}

export class PaginationStateBase {
    public applyFilterPagination<T>(paginationRequest: PaginationRequest, state: PaginationStateModel<T>, filter: PaginationFilter): boolean {
        switch (paginationRequest) {
            case PaginationRequest.ReloadFullList:
                filter.pageIndex = 0;
                filter.pageSize = 9999;
                break;
            case PaginationRequest.ReloadCurrentList:
                filter.pageIndex = 0;
                filter.pageSize = Math.max(state.uids.length, filter.pageSize);
                break;
            case PaginationRequest.LoadMore:
                if (state.retrieved) {
                    if (state.allItemsLoaded) {
                        return false;
                    }
                    filter.pageIndex = state.index + 1;
                }
                break;
        }
        return true;
    }

    public handlePaginationResults<T extends { uid: string }>(state: PaginationStateModel<T>, paginationResult: PaginationResult<T>, paginationRequest: PaginationRequest, originalPageSize: number, needToLog = false): { uids: string[], entities: { [uid: string]: T }, allItemsLoaded: boolean, total: number, index: number } {
        let uids: string[];
        let entities: { [uid: string]: T };
        if (paginationRequest !== PaginationRequest.LoadMore) {
            // ... overwrite result
            uids = paginationResult.list.map(p => p.uid);
            entities = paginationResult.list.reduce((items: { [uid: string]: T }, item: T) => ({ ...items, [item.uid]: item }), {});
        } else {
            // ... merge result
            const newIds = paginationResult.list.map(p => p.uid);
            uids = Utils.arrayUnique(state.uids.concat(newIds));
            const entitiesList = uids.map(uid => state.entities[uid] || paginationResult.list.find(ent => ent.uid === uid));
            entities = entitiesList.reduce((items: { [uid: string]: T }, item: T) => ({ ...items, [item.uid]: item }), {});
        }

        let index = state.index;
        if (paginationRequest === PaginationRequest.LoadMore) {
            index = state.index + 1;
        } else {
            if (uids.length > originalPageSize) {
                index = Math.floor(uids.length / originalPageSize) - 1;
            }
        }

        const allItemsLoaded = paginationResult.total === uids.length || !paginationResult.list[0] || ((index + 1) * originalPageSize > paginationResult.total);
        return { uids, entities, total: paginationResult.total, allItemsLoaded, index };
    }
}
