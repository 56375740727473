import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { EmployeeListGetFilter } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeesSearchAction, EmployeesSearchFailAction, EmployeesSearchResetAction, EmployeesSearchSuccessAction } from './employees-search.actions';

export interface EmployeesSearchStateModel {
    entity: EmployeeListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<EmployeesSearchStateModel>({
    name: 'EmployeesSearch',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class EmployeesSearchState {
    constructor(private employeesService: EmployeesService) {}

    @Action(EmployeesSearchAction) employeeGet(ctx: StateContext<EmployeesSearchStateModel>, action: EmployeesSearchAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new EmployeeListGetFilter({ pageIndex: 0, pageSize: 9999, filterData: { workingAreaUid: action.payload.workingAreaUid } });
        return this.employeesService.getEmployees(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new EmployeesSearchSuccessAction(resp.list)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeesSearchFailAction(err)), 0));
    }

    @Action(EmployeesSearchSuccessAction) employeeGetSuccess(ctx: StateContext<EmployeesSearchStateModel>, action: EmployeesSearchSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(EmployeesSearchFailAction) employeeGetFail(ctx: StateContext<EmployeesSearchStateModel>, action: EmployeesSearchFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(EmployeesSearchResetAction) employeeGetReset(ctx: StateContext<EmployeesSearchStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }
}
