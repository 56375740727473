import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { EmployeeListGetFilter } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { ShiftEmployeesAction, ShiftEmployeesFailAction, ShiftEmployeesResetAction, ShiftEmployeesSuccessAction } from './shift-employees.actions';

export interface ShiftEmployeesStateModel {
    entity: EmployeeListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<ShiftEmployeesStateModel>({
    name: 'ShiftEmployees',
    defaults: { pending: false, entity: [], error: null }
})
@Injectable()
export class ShiftEmployeesState {
    constructor(private employeesService: EmployeesService) {}

    @Action(ShiftEmployeesAction) employeeGet(ctx: StateContext<ShiftEmployeesStateModel>, action: ShiftEmployeesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new EmployeeListGetFilter({ pageIndex: 0, pageSize: 9999, filterData: { workingAreaUid: action.payload.workingAreaUid } });
        return this.employeesService.getEmployees(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftEmployeesSuccessAction(resp.list)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftEmployeesFailAction(err)), 0));
    }

    @Action(ShiftEmployeesSuccessAction) employeeGetSuccess(ctx: StateContext<ShiftEmployeesStateModel>, action: ShiftEmployeesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ShiftEmployeesFailAction) employeeGetFail(ctx: StateContext<ShiftEmployeesStateModel>, action: ShiftEmployeesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftEmployeesResetAction) employeeGetReset(ctx: StateContext<ShiftEmployeesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: [], error: null });
    }
}
