import { DrillingRigListWithTransfersGetFilterData } from '../../models/filters/drilling-rig-list-with-transfers-get-filter.model';
import { DrillingRigWithTransfers } from '../../models/business/drilling-rig/drilling-rig-with-transfers.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigsWithTransfersAction {
    static readonly type = '[DrillingRigsWithTransfers Page] GetDrillingRigsWithTransfers';

    constructor(public payload: DrillingRigListWithTransfersGetFilterData) { }
}

export class DrillingRigsWithTransfersSuccessAction {
    static readonly type = '[DrillingRigsWithTransfers API] GetDrillingRigsWithTransfers Success';

    constructor(public payload: { list: DrillingRigWithTransfers[] }) { }
}

export class DrillingRigsWithTransfersFailAction {
    static readonly type = '[DrillingRigsWithTransfers API] GetDrillingRigsWithTransfers Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigsWithTransfersResetAction {
    static readonly type = '[DrillingRigsWithTransfers Page] GetDrillingRigsWithTransfers Reset';
}
