import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigBreakdownDeleteAction {
    static readonly type = '[My DrillingRigBreakdown Page] DeleteDrillingRigBreakdown';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigBreakdownDeleteSuccessAction {
    static readonly type = '[My DrillingRigBreakdowns API] DeleteDrillingRigBreakdown Success';

    constructor(public payload: { uid: string }) { }
}

export class DrillingRigBreakdownDeleteFailAction {
    static readonly type = '[My DrillingRigBreakdowns API] DeleteDrillingRigBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigBreakdownDeleteResetAction {
    static readonly type = '[My DrillingRigBreakdown Page] DeleteDrillingRigBreakdown Reset';
}
