import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { filter, takeUntil } from 'rxjs/operators';

import { ExgOnDestroy } from '../../../burns-ui-framework/shared/components/abstract/exg-on-destroy.component';

import { LogoutDispatchers } from '../../../burns-ui-framework/shared/store/logout/logout.dispatchers';
import { ProfileSelectors } from '../../../burns-ui-framework/shared/store/profile/profile.selectors';
import { UiSelectors } from '../../../burns-ui-framework/shared/store/ui/ui.selectors';

import { RoutingConfig } from '../../routing.config';

import { Group } from '../../models/business/user/user-group.enum';

@Component({
    selector: 'rbc-erp-root',
    templateUrl: './rbc-erp-root.component.html',
    styleUrls: ['./rbc-erp-root.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RbcErpRootComponent extends ExgOnDestroy {

    public showDialog: boolean;

    public profile$ = this.profileSelectors.profile$;

    public headerMenu = [
        { route: RoutingConfig.routes.objects.fullUrl, title: 'Objects', icon: 'objects' },
        { route: RoutingConfig.routes.companies.fullUrl, title: 'Clients', icon: 'clients' },
        { route: RoutingConfig.routes.personnel.fullUrl, title: 'Personnel', icon: 'personnel' },
        { route: RoutingConfig.routes.transport.fullUrl, title: 'Transport', icon: 'transport' },
        { route: RoutingConfig.routes.drillingRig.fullUrl, title: 'Drill Installations', icon: 'drill' },
        { route: RoutingConfig.routes.analytics.fullUrl, title: 'Analytics', icon: 'analytics' },
        { route: RoutingConfig.routes.settings.fullUrl, title: 'Settings', icon: 'settings', bottom: true },
    ];

    public mobileMenu = {
        bottom: [
            { route: RoutingConfig.routes.objects.fullUrl, title: 'Objects', icon: 'objects' },
            { route: RoutingConfig.routes.companies.fullUrl, title: 'Clients', icon: 'clients' },
            { route: RoutingConfig.routes.personnel.fullUrl, title: 'Personnel', icon: 'personnel' },
            { route: RoutingConfig.routes.transport.fullUrl, title: 'Transport', icon: 'transport' },
            { route: RoutingConfig.routes.drillingRig.fullUrl, title: 'Drill Installations', icon: 'drill' },
        ],
        dropdown: [
            { route: RoutingConfig.routes.analytics.fullUrl, title: 'Analytics', icon: 'analytics' },
            { route: RoutingConfig.routes.settings.fullUrl, title: 'Settings', icon: 'settings' },
            { id: 'logout', route: null, title: 'Logout', icon: 'exit' }
        ]
    };

    public restrictedRoutes = {
        [Group.User]: [RoutingConfig.routes.companies.fullUrl, RoutingConfig.routes.settings.fullUrl],
    };

    public headerSettings = {
        logoSquare: true
    };

    constructor(private uiSelectors: UiSelectors,
                private changere: ChangeDetectorRef,
                private logoutDispatchers: LogoutDispatchers,
                private profileSelectors: ProfileSelectors,
                private router: Router) {
        super();
        this.uiSelectors.showDialog$.pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
            setTimeout(() => {
                this.showDialog = res;
                this.changere.markForCheck();
            }, 0);
        });

        this.profile$.pipe(takeUntil(this.unsubscribe), filter(p => !!p)).subscribe((profile) => {
            if (!profile.user.groups.some(g => [Group.Administrator, Group.Superadmin, Group.Viewer].includes(+g))) {
                this.headerMenu = this.headerMenu.filter((v) => !this.restrictedRoutes[Group.User].includes(v.route));
                this.mobileMenu.dropdown = this.mobileMenu.dropdown.filter((v) => !this.restrictedRoutes[Group.User].includes(v.route));
            }
        });
    }

    public onLogout() {
        this.logoutDispatchers.dispatchLogoutAction();
        this.logoutDispatchers.dispatchFullResetAction();
        this.router.navigate(['/auth']);
    }

    protected afterDestroy() { /** no need */ }
}
