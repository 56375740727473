import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillDiametersUpdateAction, DrillDiametersUpdateFailAction, DrillDiametersUpdateResetAction, DrillDiametersUpdateSuccessAction } from './drill-diameters-update.actions';


export interface DrillDiametersUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<DrillDiametersUpdateStateModel>({
    name: 'DrillDiametersUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class DrillDiametersUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(DrillDiametersUpdateAction) async drillDiameters(ctx: StateContext<DrillDiametersUpdateStateModel>, action: DrillDiametersUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateDrillDiameters(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DrillDiametersUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillDiametersUpdateFailAction(err)), 0));
    }

    @Action(DrillDiametersUpdateSuccessAction) drillDiametersSuccess(ctx: StateContext<DrillDiametersUpdateStateModel>, _: DrillDiametersUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(DrillDiametersUpdateFailAction) drillDiametersFail(ctx: StateContext<DrillDiametersUpdateStateModel>, action: DrillDiametersUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillDiametersUpdateResetAction) drillDiametersReset(ctx: StateContext<DrillDiametersUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
