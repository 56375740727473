import { Group } from './group.enum';
import { KeyValueWithPrimaryFlag } from '../key-value-with-primary-flag.model';
import { LinkedDictionaryEntry } from '../shifts/linked-dictionary-entry.model';
import { LinkedWorkingArea } from './linked-working-area.model';

export class Employee {
    public uid: string;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public email: string;
    public phones: KeyValueWithPrimaryFlag<string, string>[];
    public group: Group;
    public workingArea: LinkedWorkingArea;
    public jobPosition: LinkedDictionaryEntry;
    public brigadeNumber: string;
    public personnelNumber: string;
    public passport: string;
    public address: string;
    public createDate: number;
    public updateDate: number;
}
