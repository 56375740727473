import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseSingletonService } from '../common/base-singleton.service';
import { LoggerService } from '../common/logger.service';
import { TokenService } from '../common/token.service';

import { AuthResponse } from '../../models/common/auth-response.model';
import { SettingsTokens } from '../../settings-tokens.config';

import { UrlUtils } from '../../utils/url-utils';

@Injectable({
    providedIn: 'root'
})
export class AuthRefreshService extends BaseSingletonService {

    private settings: { service: { refresh: string; } };

    constructor(@Inject(SettingsTokens.tokens.environment) private environment: any, private http: HttpClient, private logger: LoggerService, private tokenService: TokenService) {
        super('AuthRefreshService');
        this.settings = {
            service: {
                refresh: '/identity/v1/auth/refresh'
            }
        };
    }

    /**
     * Refresh auth token
     */
    public refresh(): Observable<boolean> {
        const apiUrl = UrlUtils.encodeUrl(this.environment.apiUrl + this.settings.service.refresh);
        const accessToken = this.tokenService.getAuthToken();
        const refreshToken = this.tokenService.getRefreshToken();
        const apiKey = this.environment.apiKey;
        const data = JSON.stringify({ accessToken, refreshToken, apiKey });
        return this.http.post<AuthResponse>(apiUrl, data, { observe: 'response' }).pipe(
            map(resp => resp.body),
            tap((resp: AuthResponse) => {
                this.logger.logDebug('Access token refreshed.');
                this.tokenService.setAuth(resp.accessToken, resp.refreshToken);
            }),
            map(() => true),
            catchError((err) => {
                this.logger.logDebug('Failed to refresh access token.');
                this.logger.logError(err);
                this.tokenService.resetAuthToken();
                throw err;
            })
        );
    }
}
