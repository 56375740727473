import { TransportBreakdown } from '../../models/business/transport-breakdown/transport-breakdown.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransportBreakdownAction {
    static readonly type = '[TransportBreakdown Page] GetTransportBreakdown';

    constructor(public payload: { uid: string}) { }
}

export class TransportBreakdownSuccessAction {
    static readonly type = '[TransportBreakdown API] GetTransportBreakdown Success';

    constructor(public payload: TransportBreakdown) { }
}

export class TransportBreakdownFailAction {
    static readonly type = '[TransportBreakdown API] GetTransportBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportBreakdownResetAction {
    static readonly type = '[TransportBreakdown Page] GetTransportBreakdown Reset';
}
