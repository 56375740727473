import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { ExgTranslateService } from '../../services/common/exg-translate.service';

import { DateUtils } from '../../utils/date-utils';

@Pipe({
    name: 'exgDate',
    pure: false,
})
export class ExgDatePipe implements PipeTransform {
    constructor(private translate: ExgTranslateService) {}

    public transform(value: any, pattern?: string, timeZone?: string, emptyDisplayValue?: string, locale?: string): string {
        if (+value) {
            value = timeZone
                ? DateUtils.convertEpocToString(DateUtils.getEpocWithTimeZoneOffset(value, timeZone))
                : DateUtils.convertEpocToString(value);
        }

        if (DateUtils.isDateTimeValid(value)) {
            const lang = locale || this.translate.getCurrentLang();
            return new DatePipe(lang).transform(value, pattern);
        }
        return emptyDisplayValue || '';
    }
}
