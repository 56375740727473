import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { ActivityListItem } from '../models/business/shifts/activity-list-item.model';
import { DictionaryEntryCreateOrUpdate } from '../models/business/dictionaries/dictionary-entry-create-or-update.model';
import { DictionaryEntryListItem } from '../models/business/dictionaries/dictionary-entry-list-item.model';
import { JobPositionCreateOrUpdateRequest } from '../models/business/job-positions/job-position-create-or-update-request.model';
import { JobPositionListItem } from '../models/business/job-positions/job-position-list-item.model';

import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class DictionariesService extends BaseSingletonService {

    private settings: {
        service: {
            getWorkingTypes: string;
            getVehicleCategories: string,
            getVehicleTypes: string;
            getDrillingRigs: string;
            getCasingTypes: string;
            getDrillingDiameters: string;
            getActivities: string;
            getJobPositions: string;

            updateWorkingType: string;
            updateVehicleCategories: string;
            updateVehicleTypes: string;
            updateDrillDiameters: string;
            updateCasingTypes: string;
            updateDrillingRigs: string;
            updateActivities: string;
            updateJobPositions: string;
        }
    };

    constructor(private http: HttpService) {
        super('DictionariesService');
        this.settings = {
            service: {
                getWorkingTypes: '/drilling/v1/dictionaries/work-types',
                getVehicleCategories: '/drilling/v1/dictionaries/vehicle-categories',
                getVehicleTypes: '/drilling/v1/dictionaries/vehicle-types',
                getDrillingRigs: '/drilling/v1/dictionaries/drilling-rigs',
                getDrillingDiameters: '/drilling/v1/dictionaries/drill-diameters',
                getCasingTypes: '/drilling/v1/dictionaries/casing-types',
                getActivities: '/drilling/v1/dictionaries/activities',
                getJobPositions: '/drilling/v1/dictionaries/job-positions',

                updateWorkingType: '/drilling/v1/dictionaries/work-types',
                updateVehicleCategories: '/drilling/v1/dictionaries/vehicle-categories',
                updateVehicleTypes: '/drilling/v1/dictionaries/vehicle-types',
                updateDrillingRigs: '/drilling/v1/dictionaries/drilling-rigs',
                updateDrillDiameters: '/drilling/v1/dictionaries/drill-diameters',
                updateCasingTypes: '/drilling/v1/dictionaries/casing-types',
                updateActivities: '/drilling/v1/dictionaries/activities',
                updateJobPositions: '/drilling/v1/dictionaries/job-positions'
            }
        };
    }

    public async getWorkingTypes(): Promise<DictionaryEntryListItem[]> {
        return this.http.get<{ list: DictionaryEntryListItem[] }>(String.format(this.settings.service.getWorkingTypes))
            .then((res) => res.list);
    }

    public async getVehicleCategories(): Promise<DictionaryEntryListItem[]> {
        return this.http.get<{ list: DictionaryEntryListItem[] }>(String.format(this.settings.service.getVehicleCategories))
            .then((res) => res.list);
    }

    public async getVehicleTypes(): Promise<DictionaryEntryListItem[]> {
        return this.http.get<{ list: DictionaryEntryListItem[] }>(String.format(this.settings.service.getVehicleTypes))
            .then((res) => res.list);
    }

    public async getDrillingRigs(): Promise<DictionaryEntryListItem[]> {
        return this.http.get<{ list: DictionaryEntryListItem[] }>(String.format(this.settings.service.getDrillingRigs))
            .then((res) => res.list);
    }

    public async getDrillingDiameters(): Promise<DictionaryEntryListItem[]> {
        return this.http.get<{ list: DictionaryEntryListItem[] }>(String.format(this.settings.service.getDrillingDiameters))
            .then((res) => res.list);
    }

    public async getCasingTypes(): Promise<DictionaryEntryListItem[]> {
        return this.http.get<{ list: DictionaryEntryListItem[] }>(String.format(this.settings.service.getCasingTypes))
            .then((res) => res.list);
    }

    public async getActivities(): Promise<ActivityListItem[]> {
        return this.http.get<{ list: ActivityListItem[] }>(String.format(this.settings.service.getActivities))
            .then((res) => res.list);
    }

    public async getJobPositions(): Promise<JobPositionListItem[]> {
        return this.http.get<{ list: JobPositionListItem[] }>(String.format(this.settings.service.getJobPositions))
            .then((res) => res.list);
    }

    public async updateWorkTypes(req: DictionaryEntryCreateOrUpdate[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateWorkingType), req);
    }

    public async updateVehicleCategories(req: DictionaryEntryCreateOrUpdate[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateVehicleCategories), req);
    }

    public async updateVehicleTypes(req: DictionaryEntryCreateOrUpdate[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateVehicleTypes), req);
    }

    public async updateTransportDrillingRigs(req: DictionaryEntryCreateOrUpdate[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateDrillingRigs), req);
    }

    public async updateDrillDiameters(req: DictionaryEntryCreateOrUpdate[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateDrillDiameters), req);
    }

    public async updateActivities(req: DictionaryEntryCreateOrUpdate[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateActivities), req);
    }

    public async updateCasingTypes(req: DictionaryEntryCreateOrUpdate[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateCasingTypes), req);
    }

    public async updateJobPositions(req: JobPositionCreateOrUpdateRequest[]): Promise<boolean> {
        return this.http.post(String.format(this.settings.service.updateJobPositions), req);
    }
}
