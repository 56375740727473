import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsService } from '../../services/analytics.service';

import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilter, AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { AnalyticsDrillingRigRepairBalanceSummaryFailAction, AnalyticsDrillingRigRepairBalanceSummarySuccessAction } from './analytics-drilling-rig-repair-balance-summary.actions';
import { AnalyticsGetAction, AnalyticsResetAction } from './analytics.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface AnalyticsDrillingRigRepairBalanceSummaryStateModel {
    entities: ActivityHours[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: AnalyticGetFilterData;
}

@State<AnalyticsDrillingRigRepairBalanceSummaryStateModel>({
    name: 'AnalyticsDrillingRigRepairBalanceSummary',
    defaults: { pending: false, entities: [], retrieved: false, error: null, filter: {} }
})
@Injectable()
export class AnalyticsDrillingRigRepairBalanceSummaryState {
    constructor(private analyticsService: AnalyticsService) { }

    @Action(AnalyticsGetAction) analyticsSetFilter(ctx: StateContext<AnalyticsDrillingRigRepairBalanceSummaryStateModel>, action: AnalyticsGetAction) {
        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            ctx.setState({ ...state, filter: filterData, retrieved: false });

            const filter = new AnalyticGetFilter({ filterData });

            return this.analyticsService.getDrillingRigRepairBalanceSummary(filter)
                .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsDrillingRigRepairBalanceSummarySuccessAction(resp)), 0))
                .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsDrillingRigRepairBalanceSummaryFailAction(err)), 0));
        }
    }

    @Action(AnalyticsDrillingRigRepairBalanceSummarySuccessAction) lossOfProfitGetSuccess(ctx: StateContext<AnalyticsDrillingRigRepairBalanceSummaryStateModel>, action: AnalyticsDrillingRigRepairBalanceSummarySuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, retrieved: true, error: null });
    }

    @Action(AnalyticsDrillingRigRepairBalanceSummaryFailAction) lossOfProfitGetFail(ctx: StateContext<AnalyticsDrillingRigRepairBalanceSummaryStateModel>, action: AnalyticsDrillingRigRepairBalanceSummaryFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsResetAction) analyticsGetReset(ctx: StateContext<AnalyticsDrillingRigRepairBalanceSummaryStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
