import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigTransferListGetFilterData } from '../../models/filters/drilling-rig-transfer-list-get-filter.model';
import { DrillingRigTransferListItem } from '../../models/business/drilling-rig-transfer/drilling-rig-transfer-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigTransfersState, DrillingRigTransfersStateModel } from './drilling-rig-transfers.reducer';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigTransfersSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([DrillingRigTransfersState], (state: DrillingRigTransfersStateModel) => state.pending);

    static readonly getDrillingRigTransfers = createSelector([DrillingRigTransfersState], (state: DrillingRigTransfersStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([DrillingRigTransfersState], (state: DrillingRigTransfersStateModel) => state.retrieved);

    static readonly getError = createSelector([DrillingRigTransfersState], (state: DrillingRigTransfersStateModel) => state.error);

    static readonly getAllDrillingRigTransfersLoaded = createSelector([DrillingRigTransfersState], (state: DrillingRigTransfersStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([DrillingRigTransfersState], (state: DrillingRigTransfersStateModel) => state.filter);

    static readonly getTotal = createSelector([DrillingRigTransfersState], (state: DrillingRigTransfersStateModel) => state.total);

    @Select(DrillingRigTransfersSelectors.getPending) pending$: Observable<boolean>;

    @Select(DrillingRigTransfersSelectors.getDrillingRigTransfers) drillingRigTransfers$: Observable<DrillingRigTransferListItem[]>;

    @Select(DrillingRigTransfersSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(DrillingRigTransfersSelectors.getError) error$: Observable<ErrorObject>;

    @Select(DrillingRigTransfersSelectors.getAllDrillingRigTransfersLoaded) allDrillingRigTransfersLoaded$: Observable<boolean>;

    @Select(DrillingRigTransfersSelectors.getFilter) filter$: Observable<DrillingRigTransferListGetFilterData>;

    @Select(DrillingRigTransfersSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('DrillingRigTransfersSelectors');
    }
}
