import { states as baseState } from '../burns-ui-framework/shared/store/reducers';

import { ActivitiesUpdateState } from './store/activities/activities-update.reducer';
import { AnalyticsActivityBalanceSummaryState } from './store/analytics/analytics-activity-balance-summary.reducer';
import { AnalyticsActivityBalanceState } from './store/analytics/analytics-activity-balance.reducer';
import { AnalyticsCausedByContractorStoppageBalanceSummaryState } from './store/analytics/analytics-caused-by-contractor-stoppage-balance-summary.reducer';
import { AnalyticsCausedByCustomerStoppageBalanceSummaryState } from './store/analytics/analytics-caused-by-customer-stoppage-balance-summary.reducer';
import { AnalyticsDetailsActivityBalanceState } from './store/analytics-details/analytics-details-activity-balance.reducer';
import { AnalyticsDetailsCausedByContractorStoppageBalanceState } from './store/analytics-details/analytics-details-caused-by-contractor-stoppage-balance.reducer';
import { AnalyticsDetailsCausedByCustomerStoppageBalanceState } from './store/analytics-details/analytics-details-caused-by-customer-stoppage-balance.reducer';
import { AnalyticsDetailsDrillSpeedChangeDynamicState } from './store/analytics-details/analytics-details-drill-speed-change-dynamic.reducer';
import { AnalyticsDetailsDrillingRigRepairBalanceState } from './store/analytics-details/analytics-details-drilling-rig-repair-balance.reducer';
import { AnalyticsDetailsLossOfProfitState } from './store/analytics-details/analytics-details-loss-of-profit.reducer';
import { AnalyticsDetailsProductiveBalanceState } from './store/analytics-details/analytics-details-productive-balance.reducer';
import { AnalyticsDetailsWorkingAreaState } from './store/analytics-details/analytics-details-working-area.reducer';
import { AnalyticsDetailsState } from './store/analytics-details/analytics-details.reducer';
import { AnalyticsDrillSpeedChangeDynamicState } from './store/analytics/analytics-drill-speed-change-dynamic.reducer';
import { AnalyticsDrillingRigRepairBalanceSummaryState } from './store/analytics/analytics-drilling-rig-repair-balance-summary.reducer';
import { AnalyticsLossOfProfitState } from './store/analytics/analytics-loss-of-profit.reducer';
import { AnalyticsProductiveBalanceSummaryState } from './store/analytics/analytics-productive-balance-summary.reducer';
import { AnalyticsWorkbookExportState } from './store/analytics/analytics-workbook-export.reducer';
import { AnalyticsWorkingAreaState } from './store/analytics/analytics-working-area.reducer';
import { AnalyticsState } from './store/analytics/analytics.reducer';
import { CasingTypesUpdateState } from './store/casing-types/casing-types-update.reducer';
import { CompaniesSearchState } from './store/companies/companies-search.reducer';
import { CompaniesState } from './store/companies/companies.reducer';
import { CompanyContractsState } from './store/companies/company-contracts.reducer';
import { CompanyCreateState } from './store/companies/company-create.reducer';
import { CompanyDeleteState } from './store/companies/company-delete.reducer';
import { CompanyUpdateState } from './store/companies/company-update.reducer';
import { CompanyState } from './store/companies/company.reducer';
import { CompniesWorkingAreaState } from './store/companies/compnies-working-area.reducer';
import { ContractCreateState } from './store/contracts/contract-create.reducer';
import { ContractDeleteState } from './store/contracts/contract-delete.reducer';
import { ContractEditDocumentState } from './store/contracts/contract-edit-document.reducer';
import { ContractUpdateState } from './store/contracts/contract-update.reducer';
import { ContractState } from './store/contracts/contract.reducer';
import { DictionaryActivitiesState } from './store/dictionaries/dictionary-activities.reducer';
import { DictionaryCasingTypesState } from './store/dictionaries/dictionary-casing-types.reducer';
import { DictionaryDrillDiametersState } from './store/dictionaries/dictionary-drill-diameters.reducer';
import { DictionaryDrillingRigsState } from './store/dictionaries/dictionary-drilling-rigs.reducer';
import { DictionaryJobPositionsUpdateState } from './store/dictionaries/dictionary-job-positions-update.reducer';
import { DictionaryJobPositionsState } from './store/dictionaries/dictionary-job-positions.reducer';
import { DictionaryVehicleCategoriesState } from './store/dictionaries/dictionary-vehicle-categories.reducer';
import { DictionaryVehicleTypesUpdateState } from './store/dictionaries/dictionary-vehicle-types-update.reducer';
import { DictionaryVehicleTypesState } from './store/dictionaries/dictionary-vehicle-types.reducer';
import { DrillDiametersUpdateState } from './store/drill-diameters/drill-diameters-update.reducer';
import { DrillingRigBreakdownCreateState } from './store/drilling-rig-breakdowns/drilling-rig-breakdown-create.reducer';
import { DrillingRigBreakdownDeleteState } from './store/drilling-rig-breakdowns/drilling-rig-breakdown-delete.reducer';
import { DrillingRigBreakdownUpdateState } from './store/drilling-rig-breakdowns/drilling-rig-breakdown-update.reducer';
import { DrillingRigBreakdownState } from './store/drilling-rig-breakdowns/drilling-rig-breakdown.reducer';
import { DrillingRigBreakdownsState } from './store/drilling-rig-breakdowns/drilling-rig-breakdowns.reducer';
import { DrillingRigCreateState } from './store/drilling-rig/drilling-rig-create.reducer';
import { DrillingRigDeleteState } from './store/drilling-rig/drilling-rig-delete.reducer';
import { DrillingRigTransferCreateState } from './store/drilling-rig-transfers/drilling-rig-transfer-create.reducer';
import { DrillingRigTransferDeleteState } from './store/drilling-rig-transfers/drilling-rig-transfer-delete.reducer';
import { DrillingRigTransferUpdateState } from './store/drilling-rig-transfers/drilling-rig-transfer-update.reducer';
import { DrillingRigTransferState } from './store/drilling-rig-transfers/drilling-rig-transfer.reducer';
import { DrillingRigTransfersState } from './store/drilling-rig-transfers/drilling-rig-transfers.reducer';
import { DrillingRigUpdateState } from './store/drilling-rig/drilling-rig-update.reducer';
import { DrillingRigWorkbookExportState } from './store/drilling-rig/drilling-rig-workbook-export.reducer';
import { DrillingRigState } from './store/drilling-rig/drilling-rig.reducer';
import { DrillingRigsSearchState } from './store/drilling-rig/drilling-rigs-search.reducer';
import { DrillingRigsWithTransfersState } from './store/drilling-rig/drilling-rigs-with-transfers.reducer';
import { DrillingRigsState } from './store/drilling-rig/drilling-rigs.reducer';
import { EmployeeChangePasswordState } from './store/employee-change-password/employee-change-password.reducer';
import { EmployeeCreateState } from './store/employee/employee-create.reducer';
import { EmployeeDeleteState } from './store/employee/employee-delete.reducer';
import { EmployeeScheduleWorkingAreaState } from './store/employee/employee-schedule-working-area.reducer';
import { EmployeeScheduleState } from './store/employee/employee-schedule.reducer';
import { EmployeeUpdateState } from './store/employee/employee-update.reducer';
import { EmployeeState } from './store/employee/employee.reducer';
import { EmployeesSearchState } from './store/employee/employees-search.reducer';
import { EmployeesState } from './store/employee/employees.reducer';
import { FullNameSuggestionState } from './store/suggestions/fullname-suggestion.reducer';
import { RouteCreateState } from './store/route/route-create.reducer';
import { RouteDeleteState } from './store/route/route-delete.reducer';
import { RouteUpdateState } from './store/route/route-update.reducer';
import { RouteState } from './store/route/route.reducer';
import { RoutesAllState } from './store/route/routes-all.reducer';
import { RoutesState } from './store/route/routes.reducer';
import { SelfEmployeeState } from './store/user-profile/self-employee.reducer';
import { ShiftCreateState } from './store/shifts/shift-create.reducer';
import { ShiftDrillingRigsState } from './store/shift-resources/shift-drilling-rigs.reducer';
import { ShiftEmployeesState } from './store/shift-resources/shift-employees.reducer';
import { ShiftVehiclesState } from './store/shift-resources/shift-vehicles.reducer';
import { ShiftWellsState } from './store/shift-resources/shift-wells.reducer';
import { ShiftState } from './store/shifts/shift.reducer';
import { ShiftsCountriesState } from './store/shifts/shifts-countries.reducer';
import { ShiftsWorkbookExportState } from './store/shifts/shifts-workbook-export.reducer';
import { ShiftsState } from './store/shifts/shifts.reducer';
import { TransportBreakdownCreateState } from './store/transport-breakdowns/transport-breakdown-create.reducer';
import { TransportBreakdownDeleteState } from './store/transport-breakdowns/transport-breakdown-delete.reducer';
import { TransportBreakdownUpdateState } from './store/transport-breakdowns/transport-breakdown-update.reducer';
import { TransportBreakdownState } from './store/transport-breakdowns/transport-breakdown.reducer';
import { TransportBreakdownsState } from './store/transport-breakdowns/transport-breakdowns.reducer';
import { TransportCreateState } from './store/transport/transport-create.reducer';
import { TransportDeleteState } from './store/transport/transport-delete.reducer';
import { TransportDrillingRigsUpdateState } from './store/transport-drilling-rigs/transport-drilling-rigs-update.reducer';
import { TransportDrillingRigsState } from './store/transport-drilling-rigs/transport-drilling-rigs.reducer';
import { TransportUpdateState } from './store/transport/transport-update.reducer';
import { TransportWorkbookExportState } from './store/transport/transport-workbook-export.reducer';
import { TransportState } from './store/transport/transport.reducer';
import { TransportsState } from './store/transport/transports.reducer';
import { UsersState } from './store/users/users.reducer';
import { VehicleCategoriesUpdateState } from './store/dictionaries/vehicle-categories-update.reducer';
import { VehicleTransferCreateState } from './store/vehicle-transfers/vehicle-transfer-create.reducer';
import { VehicleTransferDeleteState } from './store/vehicle-transfers/vehicle-transfer-delete.reducer';
import { VehicleTransferUpdateState } from './store/vehicle-transfers/vehicle-transfer-update.reducer';
import { VehicleTransferState } from './store/vehicle-transfers/vehicle-transfer.reducer';
import { VehicleTransfersState } from './store/vehicle-transfers/vehicle-transfers.reducer';
import { VehicleDictionaryState } from './store/vehicles-dictionary/vehicles-dictionary.reducer';
import { TransportWorkReportState } from './store/transport/work-report.reducer';
import { WorkTypesUpdateState } from './store/work-types/work-types-update.reducer';
import { WorkbookExportState } from './store/employee/workbook-export.reducer';
import { WorkingAreaSearchState } from './store/working-area/working-area-search.reducer';
import { WorkingTypeState } from './store/dictionaries/working-type-search.reducer';
import { YandexGeocodingState } from './store/yandex-geocoding/yandex-geocoding.reducer';

export const states = [
    ...baseState,

    AnalyticsActivityBalanceSummaryState,
    AnalyticsActivityBalanceState,
    AnalyticsCausedByContractorStoppageBalanceSummaryState,
    AnalyticsCausedByCustomerStoppageBalanceSummaryState,
    AnalyticsDrillSpeedChangeDynamicState,
    AnalyticsDrillingRigRepairBalanceSummaryState,
    AnalyticsLossOfProfitState,
    AnalyticsProductiveBalanceSummaryState,
    AnalyticsWorkingAreaState,
    AnalyticsState,

    AnalyticsDetailsState,
    AnalyticsDetailsActivityBalanceState,
    AnalyticsDetailsCausedByContractorStoppageBalanceState,
    AnalyticsDetailsCausedByCustomerStoppageBalanceState,
    AnalyticsDetailsDrillSpeedChangeDynamicState,
    AnalyticsDetailsDrillingRigRepairBalanceState,
    AnalyticsDetailsLossOfProfitState,
    AnalyticsDetailsProductiveBalanceState,
    AnalyticsDetailsWorkingAreaState,
    AnalyticsWorkbookExportState,

    UsersState,
    CompaniesState,
    CompaniesSearchState,
    CompanyCreateState,
    CompniesWorkingAreaState,
    EmployeeCreateState,
    EmployeeDeleteState,
    EmployeeScheduleWorkingAreaState,
    EmployeeScheduleState,
    EmployeeUpdateState,
    EmployeeState,
    EmployeesState,
    EmployeesSearchState,
    EmployeeChangePasswordState,
    RouteCreateState,
    RouteDeleteState,
    RouteUpdateState,
    RouteState,
    RoutesAllState,
    RoutesState,
    WorkingAreaSearchState,
    WorkbookExportState,
    ContractState,
    ContractCreateState,
    ContractUpdateState,
    ContractEditDocumentState,
    CompanyContractsState,
    CompanyState,
    CompanyUpdateState,
    CompanyDeleteState,
    FullNameSuggestionState,
    WorkingTypeState,
    VehicleDictionaryState,
    ContractDeleteState,
    DictionaryActivitiesState,
    DictionaryCasingTypesState,
    DictionaryDrillDiametersState,
    DictionaryDrillingRigsState,
    DictionaryJobPositionsUpdateState,
    DictionaryJobPositionsState,
    DictionaryVehicleCategoriesState,
    DictionaryVehicleTypesUpdateState,
    DictionaryVehicleTypesState,
    TransportsState,
    TransportUpdateState,
    TransportCreateState,
    TransportDeleteState,
    TransportState,
    VehicleTransferState,
    VehicleTransfersState,
    VehicleTransferUpdateState,
    VehicleTransferCreateState,
    VehicleTransferDeleteState,
    ShiftDrillingRigsState,
    ShiftEmployeesState,
    ShiftVehiclesState,
    ShiftWellsState,
    ShiftCreateState,
    ShiftState,
    ShiftsCountriesState,
    ShiftsWorkbookExportState,
    ShiftsState,
    TransportBreakdownState,
    TransportBreakdownsState,
    TransportBreakdownUpdateState,
    TransportBreakdownCreateState,
    TransportBreakdownDeleteState,
    TransportWorkbookExportState,
    TransportWorkReportState,
    SelfEmployeeState,
    TransportDrillingRigsState,
    WorkTypesUpdateState,
    CasingTypesUpdateState,
    DrillDiametersUpdateState,
    TransportDrillingRigsUpdateState,
    VehicleCategoriesUpdateState,
    ActivitiesUpdateState,
    YandexGeocodingState,

    DrillingRigState,
    DrillingRigsState,
    DrillingRigsSearchState,
    DrillingRigsWithTransfersState,
    DrillingRigCreateState,
    DrillingRigUpdateState,
    DrillingRigDeleteState,

    DrillingRigTransfersState,
    DrillingRigTransferState,
    DrillingRigTransferUpdateState,
    DrillingRigTransferDeleteState,
    DrillingRigTransferCreateState,

    DrillingRigBreakdownState,
    DrillingRigBreakdownsState,
    DrillingRigBreakdownUpdateState,
    DrillingRigBreakdownDeleteState,
    DrillingRigBreakdownCreateState,
    DrillingRigWorkbookExportState,

];
