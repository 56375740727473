import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ActivityListItem } from '../../models/business/shifts/activity-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryActivitiesAction, DictionaryActivitiesFailAction, DictionaryActivitiesResetAction, DictionaryActivitiesSuccessAction } from './dictionary-activities.actions';

export interface DictionaryActivitiesStateModel {
    entities: ActivityListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<DictionaryActivitiesStateModel>({
    name: 'DictionaryActivities',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class DictionaryActivitiesState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action([DictionaryActivitiesAction]) activitiesGet(ctx: StateContext<DictionaryActivitiesStateModel>, _: DictionaryActivitiesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.dictionariesService.getActivities()
            .then(resp => setTimeout(() => ctx.dispatch(new DictionaryActivitiesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryActivitiesFailAction(err)), 0));
    }

    @Action(DictionaryActivitiesSuccessAction) activitiesGetSuccess(ctx: StateContext<DictionaryActivitiesStateModel>, action: DictionaryActivitiesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(DictionaryActivitiesFailAction) activitiesGetFail(ctx: StateContext<DictionaryActivitiesStateModel>, action: DictionaryActivitiesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryActivitiesResetAction) activitiesGetReset(ctx: StateContext<DictionaryActivitiesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
