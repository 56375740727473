import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';
import { TransportWorkReportListGetFilter } from '../models/filters/work-report-list-get-filter.model';
import { TransportWorkReportListItem } from '../models/business/work-report/work-report-list-item.model';

@Injectable({
    providedIn: 'root'
})
export class TransportWorkReportService extends BaseSingletonService {

    private settings: {
        service: {
            getTransportWorkReport: string;
        }
    };

    constructor(private http: HttpService) {
        super('TransportWorkReportService');
        this.settings = {
            service: {
                getTransportWorkReport: '/drilling/v1/vehicles/report?term={0}&termField={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}&dateFrom={6}&dateTo={7}',
            }
        };
    }

    public async getTransportWorkReport(filter: TransportWorkReportListGetFilter): Promise<PaginationResult<TransportWorkReportListItem>> {
        return this.http.get<PaginationResult<TransportWorkReportListItem>>(String.format(this.settings.service.getTransportWorkReport, filter.term, filter.termField, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.dateFrom, filter.dateTo))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }
}
