import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { RoutesService } from '../../services/routes.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { RouteListGetFilter, RouteListGetFilterData } from '../../models/filters/route-list-get-filter.model';
import { RouteListItem } from '../../models/business/route/route-list-item.model';

import { RoutesAction, RoutesFailAction, RoutesResetAction, RoutesSuccessAction } from './routes-all.actions';

import { ExgParamsConfig } from '../../exg-params.config';

export interface RoutesAllStateModel extends PaginationStateModel<RouteListItem> {
    uids: string[];
    entities: { [uid: string]: RouteListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: RouteListGetFilterData;
    total: number;
}

@State<RoutesAllStateModel>({
    name: 'routesAll',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {} }
})
@Injectable()
export class RoutesAllState extends PaginationStateBase {
    constructor(private routesService: RoutesService) {
        super();
    }

    @Action([RoutesAction]) routesGet(ctx: StateContext<RoutesAllStateModel>, action: RoutesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new RouteListGetFilter({ pageSize: ExgParamsConfig.pagination.routesAllListPageSize, filterData: { ...action.payload } });
        if (!this.applyFilterPagination(PaginationRequest.ReloadFullList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.routesService.getRoutes(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new RoutesSuccessAction({ list: resp, pagination: PaginationRequest.ReloadFullList })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new RoutesFailAction(err)), 0));
    }

    @Action(RoutesSuccessAction) routesGetSuccess(ctx: StateContext<RoutesAllStateModel>, action: RoutesSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.routesAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(RoutesFailAction) routesGetFail(ctx: StateContext<RoutesAllStateModel>, action: RoutesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RoutesResetAction) routesGetReset(ctx: StateContext<RoutesAllStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {} });
    }
}
