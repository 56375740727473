import { DrillingRigListGetFilterData } from '../../models/filters/drilling-rig-list-get-filter.model';
import { DrillingRigListItem } from '../../models/business/drilling-rig/drilling-rig-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class DrillingRigsSearchAction {
    static readonly type = '[DrillingRigsSearch Page] GetDrillingRigsSearch';

    constructor(public payload: DrillingRigListGetFilterData) { }
}

export class DrillingRigsSearchSuccessAction {
    static readonly type = '[DrillingRigsSearch API] GetDrillingRigsSearch Success';

    constructor(public payload: { list: PaginationResult<DrillingRigListItem>, pagination: PaginationRequest }) { }
}

export class DrillingRigsSearchFailAction {
    static readonly type = '[DrillingRigsSearch API] GetDrillingRigsSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigsSearchResetAction {
    static readonly type = '[DrillingRigsSearch Page] GetDrillingRigsSearch Reset';
}
