import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticsService } from '../../services/analytics.service';

import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilter, AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { DateActivityHours } from '../../models/business/analytics/date-activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { AnalyticsDetailsCausedByCustomerStoppageBalanceFailAction, AnalyticsDetailsCausedByCustomerStoppageBalanceGetAction, AnalyticsDetailsCausedByCustomerStoppageBalanceSuccessAction } from './analytics-details-caused-by-customer-stoppage-balance.actions';
import { AnalyticsDetailsResetAction } from './analytics-details.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';

export interface AnalyticsDetailsCausedByCustomerStoppageBalanceStateModel {
    list: DateActivityHours[];
    summary: ActivityHours[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
    filter: AnalyticGetFilterData;
}

@State<AnalyticsDetailsCausedByCustomerStoppageBalanceStateModel>({
    name: 'AnalyticsDetailsCausedByCustomerStoppageBalance',
    defaults: { pending: false, list: [], summary: null, retrieved: false, error: null, filter: {} }
})
@Injectable()
export class AnalyticsDetailsCausedByCustomerStoppageBalanceState {
    constructor(private analyticsService: AnalyticsService) { }

    @Action(AnalyticsDetailsCausedByCustomerStoppageBalanceGetAction) analyticsSetFilter(ctx: StateContext<AnalyticsDetailsCausedByCustomerStoppageBalanceStateModel>, action: AnalyticsDetailsCausedByCustomerStoppageBalanceGetAction) {
        const state = ctx.getState();
        const filterData = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filterData)) {
            ctx.setState({ ...state, filter: filterData, retrieved: false });

            const filter = new AnalyticGetFilter({ filterData });

            return Promise.all([
                this.analyticsService.getCausedByCustomerStoppageBalance(filter),
                this.analyticsService.getCausedByCustomerStoppageBalanceSummary(filter)
            ])
            .then(resp => setTimeout(() => ctx.dispatch(new AnalyticsDetailsCausedByCustomerStoppageBalanceSuccessAction({ summary: resp[1], list: resp[0] })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AnalyticsDetailsCausedByCustomerStoppageBalanceFailAction(err)), 0));
        }
    }

    @Action(AnalyticsDetailsCausedByCustomerStoppageBalanceSuccessAction) lossOfProfitGetSuccess(ctx: StateContext<AnalyticsDetailsCausedByCustomerStoppageBalanceStateModel>, action: AnalyticsDetailsCausedByCustomerStoppageBalanceSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, list: action.payload.list, summary: action.payload.summary, retrieved: true, error: null });
    }

    @Action(AnalyticsDetailsCausedByCustomerStoppageBalanceFailAction) lossOfProfitGetFail(ctx: StateContext<AnalyticsDetailsCausedByCustomerStoppageBalanceStateModel>, action: AnalyticsDetailsCausedByCustomerStoppageBalanceFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AnalyticsDetailsResetAction) analyticsGetReset(ctx: StateContext<AnalyticsDetailsCausedByCustomerStoppageBalanceStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false,  list: [], summary: null, retrieved: false, error: null });
    }
}
