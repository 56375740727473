import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { DrillingRigListWithTransfersGetFilter } from '../../models/filters/drilling-rig-list-with-transfers-get-filter.model';
import { DrillingRigWithTransfers } from '../../models/business/drilling-rig/drilling-rig-with-transfers.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigsWithTransfersAction, DrillingRigsWithTransfersFailAction, DrillingRigsWithTransfersResetAction, DrillingRigsWithTransfersSuccessAction } from './drilling-rigs-with-transfers.actions';

export interface DrillingRigsWithTransfersStateModel {
    entities: DrillingRigWithTransfers[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<DrillingRigsWithTransfersStateModel>({
    name: 'DrillingRigsWithTransfers',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class DrillingRigsWithTransfersState {
    constructor(private drillingRigsService: DrillingRigsService) {
    }

    @Action([DrillingRigsWithTransfersAction]) DrillingRigsWithTransfersGet(ctx: StateContext<DrillingRigsWithTransfersStateModel>, action: DrillingRigsWithTransfersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new DrillingRigListWithTransfersGetFilter({ filterData: action.payload });
        return this.drillingRigsService.getDrillingRigsWithTransfers(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigsWithTransfersSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigsWithTransfersFailAction(err)), 0));
    }

    @Action(DrillingRigsWithTransfersSuccessAction) DrillingRigsWithTransfersGetSuccess(ctx: StateContext<DrillingRigsWithTransfersStateModel>, action: DrillingRigsWithTransfersSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(DrillingRigsWithTransfersFailAction) DrillingRigsWithTransfersGetFail(ctx: StateContext<DrillingRigsWithTransfersStateModel>, action: DrillingRigsWithTransfersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigsWithTransfersResetAction) DrillingRigsWithTransfersGetReset(ctx: StateContext<DrillingRigsWithTransfersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }
}
