import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { TransportBreakdownsService } from '../../services/transport-breakdowns.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportBreakdownCreateAction, TransportBreakdownCreateFailAction, TransportBreakdownCreateResetAction, TransportBreakdownCreateSuccessAction } from './transport-breakdown-create.actions';

export interface TransportBreakdownCreateStateModel {
    pending: boolean;
    created: { uid: string };
    error: ErrorObject;
}

@State<TransportBreakdownCreateStateModel>({
    name: 'transportBreakdownCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class TransportBreakdownCreateState {
    constructor(private transportBreakdownsService: TransportBreakdownsService) { }

    @Action(TransportBreakdownCreateAction) async transportBreakdownCreate(ctx: StateContext<TransportBreakdownCreateStateModel>, action: TransportBreakdownCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.transportBreakdownsService.createTransportBreakdown(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new TransportBreakdownCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportBreakdownCreateFailAction(err)), 0));
    }

    @Action(TransportBreakdownCreateSuccessAction) transportBreakdownCreateSuccess(ctx: StateContext<TransportBreakdownCreateStateModel>, action: TransportBreakdownCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(TransportBreakdownCreateFailAction) transportBreakdownCreateFail(ctx: StateContext<TransportBreakdownCreateStateModel>, action: TransportBreakdownCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(TransportBreakdownCreateResetAction) transportBreakdownCreateReset(ctx: StateContext<TransportBreakdownCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
