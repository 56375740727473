import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';

export class AnalyticsDetailsGetAction {
    static readonly type = '[AnalyticsDetails Page] GetAnalyticsDetails Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsResetAction {
    static readonly type = '[AnalyticsDetails Page] GetAnalyticsDetails Reset';
}
