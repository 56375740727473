import { VehicleTransfer } from '../../models/business/vehicle-transfer/vehicle-transfer.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class VehicleTransferAction {
    static readonly type = '[VehicleTransfer Page] GetVehicleTransfer';

    constructor(public payload: { uid: string}) { }
}

export class VehicleTransferSuccessAction {
    static readonly type = '[VehicleTransfer API] GetVehicleTransfer Success';

    constructor(public payload: VehicleTransfer) { }
}

export class VehicleTransferFailAction {
    static readonly type = '[VehicleTransfer API] GetVehicleTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class VehicleTransferResetAction {
    static readonly type = '[VehicleTransfer Page] GetVehicleTransfer Reset';
}
