import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class ShiftEmployeesAction {
    static readonly type = '[ShiftEmployees Page] GetShiftEmployees';

    constructor(public payload: { workingAreaUid: string }) { }
}

export class ShiftEmployeesSuccessAction {
    static readonly type = '[ShiftEmployees API] GetShiftEmployees Success';

    constructor(public payload: EmployeeListItem[]) { }
}

export class ShiftEmployeesFailAction {
    static readonly type = '[ShiftEmployees API] GetShiftEmployees Fail';

    constructor(public payload: ErrorObject) { }
}

export class ShiftEmployeesResetAction {
    static readonly type = '[ShiftEmployees Page] GetShiftEmployees Reset';
}
