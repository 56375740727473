import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { DictionaryEntryCreateOrUpdate } from '../../models/business/dictionaries/dictionary-entry-create-or-update.model';

export class DrillDiametersUpdateAction {
    static readonly type = '[DrillDiametersUpdate Page] DrillDiametersUpdate';

    constructor(public payload: { request: DictionaryEntryCreateOrUpdate[] }) { }
}

export class DrillDiametersUpdateSuccessAction {
    static readonly type = '[DrillDiametersUpdate API] DrillDiametersUpdate Success';
}

export class DrillDiametersUpdateFailAction {
    static readonly type = '[DrillDiametersUpdate API] DrillDiametersUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillDiametersUpdateResetAction {
    static readonly type = '[DrillDiametersUpdate Page] DrillDiametersUpdate Reset';
}
