import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { ActivitiesUpdateAction, ActivitiesUpdateFailAction, ActivitiesUpdateResetAction, ActivitiesUpdateSuccessAction } from './activities-update.actions';


export interface ActivitiesUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<ActivitiesUpdateStateModel>({
    name: 'ActivitiesUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class ActivitiesUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(ActivitiesUpdateAction) async activities(ctx: StateContext<ActivitiesUpdateStateModel>, action: ActivitiesUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateActivities(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new ActivitiesUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ActivitiesUpdateFailAction(err)), 0));
    }

    @Action(ActivitiesUpdateSuccessAction) activitiesSuccess(ctx: StateContext<ActivitiesUpdateStateModel>, _: ActivitiesUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(ActivitiesUpdateFailAction) activitiesFail(ctx: StateContext<ActivitiesUpdateStateModel>, action: ActivitiesUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ActivitiesUpdateResetAction) activitiesReset(ctx: StateContext<ActivitiesUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
