import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { CreateOrUpdateDrillingRigBreakdownAggregationRequest } from '../models/business/drilling-rig-breakdown/create-or-update-drilling-rig-breakdown-aggregation-request.model';
import { DrillingRigBreakdownListGetFilter } from '../models/filters/drilling-rig-breakdown-list-get-filter.model';
import { DrillingRigBreakdownListItem } from '../models/business/drilling-rig-breakdown/drilling-rig-breakdown-list-item.model';
import { DrillingRigBreakdown } from '../models/business/drilling-rig-breakdown/drilling-rig-breakdown.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../burns-ui-framework/shared/utils/string';


@Injectable({
    providedIn: 'root'
})
export class DrillingRigBreakdownsService extends BaseSingletonService {

    private settings: {
        service: {
            getDrillingRigBreakdown: string;
            getDrillingRigBreakdowns: string;
            createDrillingRigBreakdown: string;
            updateDrillingRigBreakdown: string;
            deleteDrillingRigBreakdown: string;
        }
    };

    constructor(private http: HttpService) {
        super('DrillingRigBreakdownsService');
        this.settings = {
            service: {
                getDrillingRigBreakdown: '/drilling/v1/drilling-rig-breakdowns/{0}',
                getDrillingRigBreakdowns: '/drilling/v1/drilling-rig-breakdowns?term={0}&drillingRigUid={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                createDrillingRigBreakdown: '/drilling/v1/drilling-rig-breakdowns',
                updateDrillingRigBreakdown: '/drilling/v1/drilling-rig-breakdowns/{0}',
                deleteDrillingRigBreakdown: '/drilling/v1/drilling-rig-breakdowns/{0}'
            }
        };
    }

    public async getDrillingRigBreakdown(uid: string): Promise<DrillingRigBreakdown> {
        return this.http.get<{ data: DrillingRigBreakdown }>(String.format(this.settings.service.getDrillingRigBreakdown, uid))
            .then(res => res.data);
    }

    public async getDrillingRigBreakdowns(filter: DrillingRigBreakdownListGetFilter): Promise<PaginationResult<DrillingRigBreakdownListItem>> {
        return this.http.get<PaginationResult<DrillingRigBreakdownListItem>>(String.format(this.settings.service.getDrillingRigBreakdowns, filter.term, filter.drillingRigUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createDrillingRigBreakdown(request: CreateOrUpdateDrillingRigBreakdownAggregationRequest): Promise<{ uid: string }> {
        return this.http.post<{ data: string }>(String.format(this.settings.service.createDrillingRigBreakdown), request)
            .then(res => ({ uid: res.data }));
    }

    public async updateDrillingRigBreakdown(uid: string, request: CreateOrUpdateDrillingRigBreakdownAggregationRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateDrillingRigBreakdown, uid), request);
    }

    public async deleteDrillingRigBreakdown(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteDrillingRigBreakdown, uid));
    }
}
