import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { Employee } from '../../models/business/employee/employee.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { SelfEmployeeState, SelfEmployeeStateModel } from './self-employee.reducer';

@Injectable({
    providedIn: 'root'
})
export class SelfEmployeeSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([SelfEmployeeState], (state: SelfEmployeeStateModel) => state.pending);

    static readonly getReadonly = createSelector([SelfEmployeeState], (state: SelfEmployeeStateModel) => state.readonly);

    static readonly getUser = createSelector([SelfEmployeeState], (state: SelfEmployeeStateModel) => state.user);

    static readonly getViewer = createSelector([SelfEmployeeState], (state: SelfEmployeeStateModel) => state.viewer);

    static readonly getEmployee = createSelector([SelfEmployeeState], (state: SelfEmployeeStateModel) => state.entity);

    static readonly getError = createSelector([SelfEmployeeState], (state: SelfEmployeeStateModel) => state.error);

    @Select(SelfEmployeeSelectors.getPending) pending$: Observable<boolean>;

    @Select(SelfEmployeeSelectors.getReadonly) readonly$: Observable<boolean>;

    @Select(SelfEmployeeSelectors.getUser) user$: Observable<boolean>;

    @Select(SelfEmployeeSelectors.getViewer) viewer$: Observable<boolean>;

    @Select(SelfEmployeeSelectors.getEmployee) employee$: Observable<Employee>;

    @Select(SelfEmployeeSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('SelfEmployeeSelectors');
    }
}
