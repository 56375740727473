import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { CompaniesListFilter } from '../models/filters/companies-list-filter.model';
import { CompanyListItem } from '../models/business/company/company-list-item.model';
import { Company } from '../models/business/company/company.model';
import { CompanyCreateOrUpdateRequest } from '../models/business/company/create-or-update-company-aggregation-request.model';
import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService extends BaseSingletonService {

    private settings: {
        service: {
            getCompany: string;
            getCompanyList: string;
            getStatistic: string;
            createCompany: string;
            updateCompany: string;
            deleteCompany: string;

            getCompanyFeed: string;
        }
    };

    constructor(private http: HttpService) {
        super('CompaniesService');
        this.settings = {
            service: {
                getCompany: '/drilling/v1/companies/{0}',
                getCompanyList: '/drilling/v1/companies?term={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                getStatistic: '/drilling/v1/companies/statistic?dateFrom={0}&dateTo={1}&groupBy={2}&period={3}&countryCodes={4}&citiesIds={5}&responsibleUsersUids={6}',
                createCompany: '/drilling/v1/companies',
                updateCompany: '/drilling/v1/companies/{0}',
                deleteCompany: '/drilling/v1/companies/{0}',
                getCompanyFeed: '/drilling/v1/companies/{0}/feed?term={1}&userUid={2}&types={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}'
            }
        };
    }

    public async getCompany(uid: string): Promise<Company> {
        return this.http.get<{ data: Company }>(String.format(this.settings.service.getCompany, uid))
            .then(res => res.data);
    }

    public async getCompanyList(filter: CompaniesListFilter): Promise<PaginationResult<CompanyListItem>> {
        return this.http.get<PaginationResult<CompanyListItem>>(String.format(this.settings.service.getCompanyList, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createCompany(request: CompanyCreateOrUpdateRequest): Promise<Company> {
        return this.http.post<{ data: Company }>(String.format(this.settings.service.createCompany), request)
            .then(res => res.data);
    }

    public async updateCompany(uid: string, request: CompanyCreateOrUpdateRequest): Promise<Company> {
        return this.http.put(String.format(this.settings.service.updateCompany, uid), request);
    }

    public async deleteCompany(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteCompany, uid));
    }
}
