import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigCreateState, DrillingRigCreateStateModel } from './drilling-rig-create.reducer';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigCreateSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([DrillingRigCreateState], (state: DrillingRigCreateStateModel) => state.pending);

    static readonly getUpdated = createSelector([DrillingRigCreateState], (state: DrillingRigCreateStateModel) => state.created);

    static readonly getError = createSelector([DrillingRigCreateState], (state: DrillingRigCreateStateModel) => state.error);

    @Select(DrillingRigCreateSelectors.getPending) pending$: Observable<boolean>;

    @Select(DrillingRigCreateSelectors.getUpdated) created$: Observable<{ uid: string }>;

    @Select(DrillingRigCreateSelectors.getError) error$: Observable<ErrorObject>;

    constructor() {
        super('DrillingRigCreateSelectors');
    }
}
