import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { CompanyCreateAction, CompanyCreateFailAction, CompanyCreateResetAction, CompanyCreateSuccessAction } from './company-create.actions';

import { CompaniesService } from '../../services/companies.service';
import { Company } from '../../models/business/company/company.model';


export interface CompanyCreateStateModel {
    pending: boolean;
    created: Company;
    error: ErrorObject;
}

@State<CompanyCreateStateModel>({
    name: 'companyCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class CompanyCreateState {
    constructor(private companiesService: CompaniesService) { }

    @Action(CompanyCreateAction) async companyCreate(ctx: StateContext<CompanyCreateStateModel>, action: CompanyCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.companiesService.createCompany({ ...action.payload })
            .then(res => setTimeout(() => ctx.dispatch(new CompanyCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CompanyCreateFailAction(err)), 0));
    }

    @Action(CompanyCreateSuccessAction) companyCreateSuccess(ctx: StateContext<CompanyCreateStateModel>, action: CompanyCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(CompanyCreateFailAction) companyCreateFail(ctx: StateContext<CompanyCreateStateModel>, action: CompanyCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(CompanyCreateResetAction) companyCreateReset(ctx: StateContext<CompanyCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
