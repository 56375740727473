import { getLocaleFirstDayOfWeek } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ExgTranslateService } from '../../../services/common/exg-translate.service';

import { DateUtils } from '../../../utils/date-utils';

@Component({
    selector: 'exg-datepicker-range',
    templateUrl: './exg-datepicker-range.component.html',
    styleUrls: ['./exg-datepicker-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => ExgDatepickerRangeComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExgDatepickerRangeComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

    @Input() value: { begin: number, end: number }; // epoch date format
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Input() placeholder: string;
    @Input() minDate: number;
    @Input() maxDate: number;

    @Input() controlsToValidate: (FormControl | FormGroup | FormArray)[];
    @Input() validate: boolean;
    @Input() border = true;
    /**
     * @deprecated should not use
     */
    @Input() appearance: 'fill' | 'outline' = 'fill';
    @Input() styleType: 'common' | 'empty' | 'readonly' = 'common';
    @Input() changeLanguage: string;

    @Output() readonly valueChange = new EventEmitter<{ begin: number, end: number }>();

    @ViewChild('elemToFocus', { static: true }) elemToFocus: ElementRef<HTMLInputElement>;

    @HostBinding('class.exg-datepicker-range-style-common') exgStyleTypeCommon: boolean;
    @HostBinding('class.exg-datepicker-range-style-empty') exgStyleTypeEmpty: boolean;
    @HostBinding('class.exg-datepicker-range-style-readonly') exgStyleTypeReadonly: boolean;

    public internalValue: { begin: string, end: string } = { begin: null, end: null };
    public internalMinDate: Date;
    public internalMaxDate: Date;
    public focused: boolean;
    public hovered: boolean;
    public invalid: boolean;

    private unsubscribe = new Subject();
    private propagateChange: (_) => { /* no need */ };

    constructor(private adapter: DateAdapter<any>, private changeDetectorRef: ChangeDetectorRef, private translate: ExgTranslateService) {
        this.adapter.setLocale(this.translate.getCurrentLang());
        this.adapter.getFirstDayOfWeek = () => { return getLocaleFirstDayOfWeek(this.translate.getCurrentLang()); };
    }

    public ngOnInit() {
        this.applyHostClasses();

        if (this.controlsToValidate) {
            merge(...this.controlsToValidate.map(c => c.valueChanges)).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                if (this.validate) {
                    this.setInvalidState();
                }
            });
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            this.setInternalValue();
        }

        if (changes.minDate || changes.maxDate) {
            const min = DateUtils.convertEpocToString(this.minDate);
            const max = DateUtils.convertEpocToString(this.maxDate);
            this.internalMinDate = min ? new Date(min) : null;
            this.internalMaxDate = max ? new Date(max) : null;
        }

        if (changes.validate) {
            this.setInvalidState();
        }

        if (changes.changeLanguage) {
            this.adapter.setLocale(this.translate.getCurrentLang());
            this.adapter.getFirstDayOfWeek = () => { return getLocaleFirstDayOfWeek(this.translate.getCurrentLang()); };
        }

        if (changes.styleType || changes.ngClass || changes.placeholder) {
            this.applyHostClasses();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    public writeValue(value: { begin: number, end: number }) {
        this.value = value;
        this.setInternalValue();
    }

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(_) { /* no need */ }

    public onStartChanges($event) {
        if ($event.value) {
            const internalBegin = DateUtils.convertDateToString($event.value);
            this.internalValue = { begin: internalBegin, end: this.internalValue.end };
            const valueEpocBegin = DateUtils.convertStringToEpoc(internalBegin);
            this.value = { begin: valueEpocBegin, end: this.value ? this.value.end : null };
        } else {
            this.value = { begin: null, end: this.value ? this.value.end : null };
        }

        if (!this.value.begin && !this.value.end) {
            if (this.propagateChange) {
                this.propagateChange(this.value);
            }

            setTimeout(() => this.elemToFocus.nativeElement.focus(), 0); // remove focus from trigger button since it looks ugly
            this.valueChange.emit(this.value);
        }
    }

    public onEndChanges($event) {
        if ($event.value) {
            const internalEnd = DateUtils.convertDateToString($event.value);
            this.internalValue = { begin: this.internalValue.begin, end: internalEnd };
            const valueEpocEnd = DateUtils.convertStringToEpoc(internalEnd);
            this.value = { begin: this.value ? this.value.begin : null, end: valueEpocEnd };
        } else {
            this.value = { begin: this.value ? this.value.begin : null, end: null };
        }

        if ($event.value || (!this.value.begin && !this.value.end)) {
            if (this.propagateChange) {
                this.propagateChange(this.value);
            }

            setTimeout(() => this.elemToFocus.nativeElement.focus(), 0); // remove focus from trigger button since it looks ugly
            this.valueChange.emit(this.value);
        }
    }

    private setInternalValue() {
        if (this.value) {
            const internalBegin = DateUtils.convertEpocToString(this.value.begin);
            const internalEnd = DateUtils.convertEpocToString(this.value.end);
            this.internalValue = { begin: internalBegin, end: internalEnd };
            this.changeDetectorRef.markForCheck();
        }
    }

    private setInvalidState() {
        this.invalid = this.validate && this.controlsToValidate && this.controlsToValidate.some(c => c.invalid);
    }

    private applyHostClasses() {
        this.exgStyleTypeCommon = this.styleType === 'common' || !this.styleType;
        this.exgStyleTypeEmpty = this.styleType === 'empty';
        this.exgStyleTypeReadonly = this.styleType === 'readonly';
        this.appearance =  this.styleType === 'common' || !this.styleType ? 'outline' : 'fill';
    }
}
