import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { DictionaryEntryCreateOrUpdate } from '../../models/business/dictionaries/dictionary-entry-create-or-update.model';

export class ActivitiesUpdateAction {
    static readonly type = '[ActivitiesUpdate Page] ActivitiesUpdate';

    constructor(public payload: { request: DictionaryEntryCreateOrUpdate[] }) { }
}

export class ActivitiesUpdateSuccessAction {
    static readonly type = '[ActivitiesUpdate API] ActivitiesUpdate Success';
}

export class ActivitiesUpdateFailAction {
    static readonly type = '[ActivitiesUpdate API] ActivitiesUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class ActivitiesUpdateResetAction {
    static readonly type = '[ActivitiesUpdate Page] ActivitiesUpdate Reset';
}
