import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { DrillingRigBreakdownsService } from '../../services/drilling-rig-breakdowns.service';

import { DrillingRigBreakdownListGetFilter, DrillingRigBreakdownListGetFilterData } from '../../models/filters/drilling-rig-breakdown-list-get-filter.model';
import { DrillingRigBreakdownListItem } from '../../models/business/drilling-rig-breakdown/drilling-rig-breakdown-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { DrillingRigBreakdownsAction, DrillingRigBreakdownsFailAction, DrillingRigBreakdownsResetAction, DrillingRigBreakdownsSetFilterAction, DrillingRigBreakdownsSuccessAction } from './drilling-rig-breakdowns.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface DrillingRigBreakdownsStateModel extends PaginationStateModel<DrillingRigBreakdownListItem> {
    uids: string[];
    entities: { [uid: string]: DrillingRigBreakdownListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: DrillingRigBreakdownListGetFilterData;
    total: number;
}

@State<DrillingRigBreakdownsStateModel>({
    name: 'drillingRigBreakdowns',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {drillingRigUid: null} }
})
@Injectable()
export class DrillingRigBreakdownsState extends PaginationStateBase {
    constructor(private drillingRigBreakdownsService: DrillingRigBreakdownsService) {
        super();
    }

    @Action([DrillingRigBreakdownsAction]) drillingRigBreakdownsGet(ctx: StateContext<DrillingRigBreakdownsStateModel>, action: DrillingRigBreakdownsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new DrillingRigBreakdownListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.drillingRigBreakdownsAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.drillingRigBreakdownsService.getDrillingRigBreakdowns(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownsSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownsFailAction(err)), 0));
    }

    @Action(DrillingRigBreakdownsSuccessAction) drillingRigBreakdownsGetSuccess(ctx: StateContext<DrillingRigBreakdownsStateModel>, action: DrillingRigBreakdownsSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.drillingRigBreakdownsAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(DrillingRigBreakdownsFailAction) drillingRigBreakdownsGetFail(ctx: StateContext<DrillingRigBreakdownsStateModel>, action: DrillingRigBreakdownsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigBreakdownsResetAction) drillingRigBreakdownsGetReset(ctx: StateContext<DrillingRigBreakdownsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {drillingRigUid: null} });
    }

    @Action(DrillingRigBreakdownsSetFilterAction) drillingRigBreakdownsSetFilter(ctx: StateContext<DrillingRigBreakdownsStateModel>, action: DrillingRigBreakdownsSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new DrillingRigBreakdownsAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
