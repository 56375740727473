import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { EmployeesService } from '../../services/employees.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { EmployeeChangePasswordAction, EmployeeChangePasswordFailAction, EmployeeChangePasswordResetAction, EmployeeChangePasswordSuccessAction } from './employee-change-password.actions';

export interface EmployeeChangePasswordStateModel {
    pending: boolean;
    changed: boolean;
    error: ErrorObject;
}

@State<EmployeeChangePasswordStateModel>({
    name: 'EmployeeChangePassword',
    defaults: { pending: false, changed: false, error: null }
})
@Injectable()
export class EmployeeChangePasswordState {
    constructor(private employeesService: EmployeesService) { }

    @Action(EmployeeChangePasswordAction) EmployeeChangePassword(ctx: StateContext<EmployeeChangePasswordStateModel>, action: EmployeeChangePasswordAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, changed: false, error: null });

        this.employeesService.changePassword(action.payload.userUid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new EmployeeChangePasswordSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeeChangePasswordFailAction(err)), 0));
    }

    @Action(EmployeeChangePasswordSuccessAction) EmployeeChangePasswordSuccess(ctx: StateContext<EmployeeChangePasswordStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, changed: true, error: null });
    }

    @Action(EmployeeChangePasswordFailAction) EmployeeChangePasswordFail(ctx: StateContext<EmployeeChangePasswordStateModel>, action: EmployeeChangePasswordFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, changed: false, error: action.payload });
    }

    @Action(EmployeeChangePasswordResetAction) EmployeeChangePasswordReset(ctx: StateContext<EmployeeChangePasswordStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, changed: false, error: null });
    }
}
