import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

export class AnalyticsDetailsWorkingAreaAction {
    static readonly type = '[AnalyticsDetailsWorkingArea Page] GetAnalyticsDetailsWorkingArea';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsWorkingAreaSuccessAction {
    static readonly type = '[AnalyticsDetailsWorkingArea API] GetAnalyticsDetailsWorkingArea Success';

    constructor(public payload: PaginationResult<WorkingAreaListItem>) { }
}

export class AnalyticsDetailsWorkingAreaFailAction {
    static readonly type = '[AnalyticsDetailsWorkingArea API] GetAnalyticsDetailsWorkingArea Fail';

    constructor(public payload: ErrorObject) { }
}

export class AnalyticsDetailsWorkingAreaResetAction {
    static readonly type = '[AnalyticsDetailsWorkingArea Page] GetAnalyticsDetailsWorkingArea Reset';
}
