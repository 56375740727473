import { TransportCreateUpdateRequest } from '../../models/business/transport/transport-create-update-request.model';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransportUpdateAction {
    static readonly type = '[Transport Page] UpdateTransport';

    constructor(public payload: { uid: string, request: TransportCreateUpdateRequest }) { }
}

export class TransportUpdateSuccessAction {
    static readonly type = '[Transport API] UpdateTransport Success';

    constructor(public payload: { uid: string, request: TransportCreateUpdateRequest }) { }
}

export class TransportUpdateFailAction {
    static readonly type = '[Transport API] UpdateTransport Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportUpdateResetAction {
    static readonly type = '[Transport Page] UpdateTransport Reset';
}
