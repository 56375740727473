import { DrillingRigBreakdown } from '../../models/business/drilling-rig-breakdown/drilling-rig-breakdown.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigBreakdownAction {
    static readonly type = '[DrillingRigBreakdown Page] GetDrillingRigBreakdown';

    constructor(public payload: { uid: string}) { }
}

export class DrillingRigBreakdownSuccessAction {
    static readonly type = '[DrillingRigBreakdown API] GetDrillingRigBreakdown Success';

    constructor(public payload: DrillingRigBreakdown) { }
}

export class DrillingRigBreakdownFailAction {
    static readonly type = '[DrillingRigBreakdown API] GetDrillingRigBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigBreakdownResetAction {
    static readonly type = '[DrillingRigBreakdown Page] GetDrillingRigBreakdown Reset';
}
