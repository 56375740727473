import { CreateOrUpdateDrillingRigTransferAggregationRequest } from '../../models/business/drilling-rig-transfer/create-or-update-drilling-rig-transfer-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigTransferUpdateAction {
    static readonly type = '[DrillingRigTransfer Page] UpdateDrillingRigTransfer';

    constructor(public payload: { uid: string, request: CreateOrUpdateDrillingRigTransferAggregationRequest }) { }
}

export class DrillingRigTransferUpdateSuccessAction {
    static readonly type = '[DrillingRigTransfer API] UpdateDrillingRigTransfer Success';

    constructor(public payload: { uid: string, request: CreateOrUpdateDrillingRigTransferAggregationRequest }) { }
}

export class DrillingRigTransferUpdateFailAction {
    static readonly type = '[DrillingRigTransfer API] UpdateDrillingRigTransfer Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigTransferUpdateResetAction {
    static readonly type = '[DrillingRigTransfer Page] UpdateDrillingRigTransfer Reset';
}
