import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigDeleteState, DrillingRigDeleteStateModel } from './drilling-rig-delete.reducer';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigDeleteSelectors extends BaseSingletonService {
    static readonly getPendings = createSelector([DrillingRigDeleteState], (state: DrillingRigDeleteStateModel) => state.pending);

    static readonly getErrors = createSelector([DrillingRigDeleteState], (state: DrillingRigDeleteStateModel) => state.error);

    static readonly getDeleted = createSelector([DrillingRigDeleteState], (state: DrillingRigDeleteStateModel) => state.deleted);

    @Select(DrillingRigDeleteSelectors.getPendings) pending$: Observable<boolean>;

    @Select(DrillingRigDeleteSelectors.getErrors) error$: Observable<ErrorObject>;

    @Select(DrillingRigDeleteSelectors.getDeleted) deleted$: Observable<boolean>;

    constructor() {
        super('DrillingRigDeleteSelectors');
    }
}
