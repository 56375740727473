import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { VehicleTransfersService } from '../../services/vehicle-transfers.service';

import { VehicleTransfer } from '../../models/business/vehicle-transfer/vehicle-transfer.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { VehicleTransferUpdateSuccessAction } from './vehicle-transfer-update.actions';
import { VehicleTransferAction, VehicleTransferFailAction, VehicleTransferResetAction, VehicleTransferSuccessAction } from './vehicle-transfer.actions';

export interface VehicleTransferStateModel {
    entity: VehicleTransfer;
    pending: boolean;
    error: ErrorObject;
}

@State<VehicleTransferStateModel>({
    name: 'vehicleTransfer',
    defaults: { pending: false, entity: null, error: null }
})
@Injectable()
export class VehicleTransferState {
    constructor(private vehicleTransfersService: VehicleTransfersService) {}


    @Action(VehicleTransferAction) vehicleTransferGet(ctx: StateContext<VehicleTransferStateModel>, action: VehicleTransferAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.vehicleTransfersService.getVehicleTransfer(action.payload.uid)
            .then(resp => setTimeout(() => ctx.dispatch(new VehicleTransferSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VehicleTransferFailAction(err)), 0));
    }

    @Action(VehicleTransferSuccessAction) vehicleTransferGetSuccess(ctx: StateContext<VehicleTransferStateModel>, action: VehicleTransferSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(VehicleTransferFailAction) vehicleTransferGetFail(ctx: StateContext<VehicleTransferStateModel>, action: VehicleTransferFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(VehicleTransferResetAction) vehicleTransferGetReset(ctx: StateContext<VehicleTransferStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, error: null });
    }

    @Action(VehicleTransferUpdateSuccessAction) vehicleTransferUpdateSuccessAction(ctx: StateContext<VehicleTransferStateModel>, action: VehicleTransferUpdateSuccessAction) {
        const state = ctx.getState();
        const entity = <any>{ ...state.entity, ...action.payload.request };
        ctx.setState({ ...state, pending: false, entity, error: null });
    }
}
