import { CreateOrUpdateDrillingRigBreakdownAggregationRequest } from '../../models/business/drilling-rig-breakdown/create-or-update-drilling-rig-breakdown-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DrillingRigBreakdownUpdateAction {
    static readonly type = '[DrillingRigBreakdown Page] UpdateDrillingRigBreakdown';

    constructor(public payload: { uid: string, request: CreateOrUpdateDrillingRigBreakdownAggregationRequest }) { }
}

export class DrillingRigBreakdownUpdateSuccessAction {
    static readonly type = '[DrillingRigBreakdown API] UpdateDrillingRigBreakdown Success';

    constructor(public payload: { uid: string, request: CreateOrUpdateDrillingRigBreakdownAggregationRequest }) { }
}

export class DrillingRigBreakdownUpdateFailAction {
    static readonly type = '[DrillingRigBreakdown API] UpdateDrillingRigBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigBreakdownUpdateResetAction {
    static readonly type = '[DrillingRigBreakdown Page] UpdateDrillingRigBreakdown Reset';
}
