import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { DictionaryEntryCreateOrUpdate } from '../../models/business/dictionaries/dictionary-entry-create-or-update.model';

export class CasingTypesUpdateAction {
    static readonly type = '[CasingTypesUpdate Page] CasingTypesUpdate';

    constructor(public payload: { request: DictionaryEntryCreateOrUpdate[] }) { }
}

export class CasingTypesUpdateSuccessAction {
    static readonly type = '[CasingTypesUpdate API] CasingTypesUpdate Success';
}

export class CasingTypesUpdateFailAction {
    static readonly type = '[CasingTypesUpdate API] CasingTypesUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class CasingTypesUpdateResetAction {
    static readonly type = '[CasingTypesUpdate Page] CasingTypesUpdate Reset';
}
