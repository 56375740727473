import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigsService } from '../../services/drilling-rigs.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigCreateAction, DrillingRigCreateFailAction, DrillingRigCreateResetAction, DrillingRigCreateSuccessAction } from './drilling-rig-create.actions';

export interface DrillingRigCreateStateModel {
    pending: boolean;
    created: { uid: string };
    error: ErrorObject;
}

@State<DrillingRigCreateStateModel>({
    name: 'drillingRigCreate',
    defaults: { pending: false, created: null, error: null }
})
@Injectable()
export class DrillingRigCreateState {
    constructor(private drillingRigsService: DrillingRigsService) { }

    @Action(DrillingRigCreateAction) async drillingRigCreate(ctx: StateContext<DrillingRigCreateStateModel>, action: DrillingRigCreateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, created: null, error: null });

        return this.drillingRigsService.createDrillingRig(action.payload.request)
            .then(res => setTimeout(() => ctx.dispatch(new DrillingRigCreateSuccessAction(res)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigCreateFailAction(err)), 0));
    }

    @Action(DrillingRigCreateSuccessAction) drillingRigCreateSuccess(ctx: StateContext<DrillingRigCreateStateModel>, action: DrillingRigCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: action.payload, error: null });
    }

    @Action(DrillingRigCreateFailAction) drillingRigCreateFail(ctx: StateContext<DrillingRigCreateStateModel>, action: DrillingRigCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: action.payload });
    }

    @Action(DrillingRigCreateResetAction) drillingRigCreateReset(ctx: StateContext<DrillingRigCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: null, error: null });
    }
}
