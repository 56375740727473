export class DrillingRigListWithTransfersGetFilter {
    public workingAreaUid?: string;
    public dateFrom?: number;
    public dateTo?: number;

    constructor(args?: { filterData?: DrillingRigListWithTransfersGetFilterData }) {
        this.workingAreaUid = (args && args.filterData && args.filterData.workingAreaUid != null) ? args.filterData.workingAreaUid : null;
        this.dateFrom = (args && args.filterData && args.filterData.dateFrom != null) ? args.filterData.dateFrom : null;
        this.dateTo = (args && args.filterData && args.filterData.dateTo != null) ? args.filterData.dateTo : null;
    }
}

export class DrillingRigListWithTransfersGetFilterData {
    public workingAreaUid?: string;
    public dateFrom?: number;
    public dateTo?: number;
}
