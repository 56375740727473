import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { VehicleTransfersService } from '../../services/vehicle-transfers.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { VehicleTransferDeleteAction, VehicleTransferDeleteFailAction, VehicleTransferDeleteResetAction, VehicleTransferDeleteSuccessAction } from './vehicle-transfer-delete.actions';

export interface VehicleTransferDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<VehicleTransferDeleteStateModel>({
    name: 'vehicleTransferDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class VehicleTransferDeleteState {
    constructor(private vehicleTransfersService: VehicleTransfersService) { }

    @Action(VehicleTransferDeleteAction) async vehicleTransferDelete(ctx: StateContext<VehicleTransferDeleteStateModel>, action: VehicleTransferDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.vehicleTransfersService.deleteVehicleTransfer(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new VehicleTransferDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new VehicleTransferDeleteFailAction(error)), 0));
    }

    @Action(VehicleTransferDeleteSuccessAction) vehicleTransferDeleteSuccess(ctx: StateContext<VehicleTransferDeleteStateModel>, _: VehicleTransferDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(VehicleTransferDeleteFailAction) vehicleTransferDeleteFail(ctx: StateContext<VehicleTransferDeleteStateModel>, action: VehicleTransferDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(VehicleTransferDeleteResetAction) vehicleTransferDeleteReset(ctx: StateContext<VehicleTransferDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
