import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { TransportCreateUpdateRequest } from '../../models/business/transport/transport-create-update-request.model';

export class TransportCreateAction {
    static readonly type = '[Transports Page] CreateTransport';

    constructor(public payload: { request: TransportCreateUpdateRequest }) { }
}

export class TransportCreateSuccessAction {
    static readonly type = '[Transport API] CreateTransport Success';

    constructor(public payload: { uid: string }) { }
}

export class TransportCreateFailAction {
    static readonly type = '[Transport API] CreateTransport Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportCreateResetAction {
    static readonly type = '[Transports Page] CreateTransport Reset';
}
