import { EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class EmployeesAction {
    static readonly type = '[Employees Page] GetEmployees';

    constructor(public payload: PaginationRequest) { }
}

export class EmployeesSuccessAction {
    static readonly type = '[Employees API] GetEmployees Success';

    constructor(public payload: { list: PaginationResult<EmployeeListItem>, pagination: PaginationRequest }) { }
}

export class EmployeesFailAction {
    static readonly type = '[Employees API] GetEmployees Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeesResetAction {
    static readonly type = '[Employees Page] GetEmployees Reset';
}

export class EmployeesSetFilterAction {
    static readonly type = '[Employees API] SetFilter';

    constructor(public payload: EmployeeListGetFilterData) { }
}
