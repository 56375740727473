import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { DateActivityHours } from '../../models/business/analytics/date-activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsDetailsCausedByContractorStoppageBalanceGetAction {
    static readonly type = '[AnalyticsDetailsCausedByContractorStoppageBalance Page] GetAnalyticsDetailsCausedByContractorStoppageBalance Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsCausedByContractorStoppageBalanceSuccessAction {
    static readonly type = '[AnalyticsDetailsCausedByContractorStoppageBalance API] GetAnalyticsCausedByContractorStoppageBalance Success';

    constructor(public payload: { list: DateActivityHours[], summary: ActivityHours[] }) { }
}

export class AnalyticsDetailsCausedByContractorStoppageBalanceFailAction {
    static readonly type = '[AnalyticsDetailsCausedByContractorStoppageBalance API] GetAnalyticsCausedByContractorStoppageBalance Fail';

    constructor(public payload: ErrorObject) { }
}
