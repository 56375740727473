import { LinkedDictionaryEntry } from '../shifts/linked-dictionary-entry.model';
import { LinkedWorkingArea } from './linked-working-area.model';

export class LinkedRoute {
    public uid: string;
    public workingArea: LinkedWorkingArea;
    public brigadeNumber: string;
    public jobPosition: LinkedDictionaryEntry;
    public startDate: number;
    public endDate: number;
    public isNotRoute: boolean;
}
