import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { TransportWorkbookGetFilterData } from '../../models/filters/transport-workbook-get-filter.model';

export class TransportWorkbookExportAction {
    static readonly type = '[TransportWorkbookExport Page] GetTransportWorkbookExport';

    constructor(public payload: { filter: TransportWorkbookGetFilterData }) { }
}

export class TransportWorkbookExportSuccessAction {
    static readonly type = '[TransportWorkbookExport API] GetTransportWorkbookExport Success';
}

export class TransportWorkbookExportFailAction {
    static readonly type = '[TransportWorkbookExport API] GetTransportWorkbookExport Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportWorkbookExportResetAction {
    static readonly type = '[TransportWorkbookExport Page] GetTransportWorkbookExport Reset';
}
