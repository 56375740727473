import { ActivityListItem } from '../../models/business/shifts/activity-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class DictionaryActivitiesAction {
    static readonly type = '[DictionaryActivities Page] GetDictionaryActivities';
}

export class DictionaryActivitiesSuccessAction {
    static readonly type = '[DictionaryActivities API] GetDictionaryActivities Success';

    constructor(public payload: ActivityListItem[]) { }
}

export class DictionaryActivitiesFailAction {
    static readonly type = '[DictionaryActivities API] GetDictionaryActivities Fail';

    constructor(public payload: ErrorObject) { }
}

export class DictionaryActivitiesResetAction {
    static readonly type = '[DictionaryActivities Page] GetDictionaryActivities Reset';
}
