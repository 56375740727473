import { DrillingRigBreakdownListGetFilterData } from '../../models/filters/drilling-rig-breakdown-list-get-filter.model';
import { DrillingRigBreakdownListItem } from '../../models/business/drilling-rig-breakdown/drilling-rig-breakdown-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';

export class DrillingRigBreakdownsAction {
    static readonly type = '[DrillingRigBreakdowns Page] GetDrillingRigBreakdowns';

    constructor(public payload: PaginationRequest) { }
}

export class DrillingRigBreakdownsSuccessAction {
    static readonly type = '[DrillingRigBreakdowns API] GetDrillingRigBreakdowns Success';

    constructor(public payload: { list: PaginationResult<DrillingRigBreakdownListItem>, pagination: PaginationRequest }) { }
}

export class DrillingRigBreakdownsFailAction {
    static readonly type = '[DrillingRigBreakdowns API] GetDrillingRigBreakdowns Fail';

    constructor(public payload: ErrorObject) { }
}

export class DrillingRigBreakdownsResetAction {
    static readonly type = '[DrillingRigBreakdowns Page] GetDrillingRigBreakdowns Reset';
}

export class DrillingRigBreakdownsSetFilterAction {
    static readonly type = '[DrillingRigBreakdowns API] SetFilter';

    constructor(public payload: DrillingRigBreakdownListGetFilterData) { }
}
