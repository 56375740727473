import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryDrillDiametersAction, DictionaryDrillDiametersFailAction, DictionaryDrillDiametersResetAction, DictionaryDrillDiametersSuccessAction } from './dictionary-drill-diameters.actions';

export interface DictionaryDrillDiametersStateModel {
    entities: DictionaryEntryListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<DictionaryDrillDiametersStateModel>({
    name: 'DictionaryDrillDiameters',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class DictionaryDrillDiametersState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action([DictionaryDrillDiametersAction]) drillDiametersGet(ctx: StateContext<DictionaryDrillDiametersStateModel>, _: DictionaryDrillDiametersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.dictionariesService.getDrillingDiameters()
            .then(resp => setTimeout(() => ctx.dispatch(new DictionaryDrillDiametersSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryDrillDiametersFailAction(err)), 0));
    }

    @Action(DictionaryDrillDiametersSuccessAction) drillDiametersGetSuccess(ctx: StateContext<DictionaryDrillDiametersStateModel>, action: DictionaryDrillDiametersSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(DictionaryDrillDiametersFailAction) drillDiametersGetFail(ctx: StateContext<DictionaryDrillDiametersStateModel>, action: DictionaryDrillDiametersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryDrillDiametersResetAction) drillDiametersGetReset(ctx: StateContext<DictionaryDrillDiametersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
