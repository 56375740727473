import { Injectable } from '@angular/core';

import { createSelector, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { RouteListGetFilterData } from '../../models/filters/route-list-get-filter.model';
import { RouteListItem } from '../../models/business/route/route-list-item.model';

import { RoutesState, RoutesStateModel } from './routes.reducer';

@Injectable({
    providedIn: 'root'
})
export class RoutesSelectors extends BaseSingletonService {

    static readonly getPending = createSelector([RoutesState], (state: RoutesStateModel) => state.pending);

    static readonly getRoutes = createSelector([RoutesState], (state: RoutesStateModel) => state.uids.map(uid => state.entities[uid]));

    static readonly getRetrieved = createSelector([RoutesState], (state: RoutesStateModel) => state.retrieved);

    static readonly getError = createSelector([RoutesState], (state: RoutesStateModel) => state.error);

    static readonly getAllRoutesLoaded = createSelector([RoutesState], (state: RoutesStateModel) => state.allItemsLoaded);

    static readonly getFilter = createSelector([RoutesState], (state: RoutesStateModel) => state.filter);

    static readonly getTotal = createSelector([RoutesState], (state: RoutesStateModel) => state.total);

    @Select(RoutesSelectors.getPending) pending$: Observable<boolean>;

    @Select(RoutesSelectors.getRoutes) routes$: Observable<RouteListItem[]>;

    @Select(RoutesSelectors.getRetrieved) retrieved$: Observable<boolean>;

    @Select(RoutesSelectors.getError) error$: Observable<ErrorObject>;

    @Select(RoutesSelectors.getAllRoutesLoaded) allRoutesLoaded$: Observable<boolean>;

    @Select(RoutesSelectors.getFilter) filter$: Observable<RouteListGetFilterData>;

    @Select(RoutesSelectors.getTotal) total$: Observable<number>;

    constructor() {
        super('RoutesSelectors');
    }
}
