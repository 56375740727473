import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { RouteCreateOrUpdateRequest } from '../../models/business/route/route-create-or-update-request.model';
import { Route } from '../../models/business/route/route.model';

export class RouteCreateAction {
    static readonly type = '[Routes Page] CreateRoute';

    constructor(public payload: { request: RouteCreateOrUpdateRequest }) { }
}

export class RouteCreateSuccessAction {
    static readonly type = '[Route API] CreateRoute Success';

    constructor(public payload: Route) { }
}

export class RouteCreateFailAction {
    static readonly type = '[Route API] CreateRoute Fail';

    constructor(public payload: ErrorObject) { }
}

export class RouteCreateResetAction {
    static readonly type = '[Routes Page] CreateRoute Reset';
}
