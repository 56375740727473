import { ActivityHours } from '../../models/business/analytics/activity-hours.model';
import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { DateActivityHours } from '../../models/business/analytics/date-activity-hours.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsDetailsProductiveBalanceGetAction {
    static readonly type = '[AnalyticsDetailsProductiveBalance Page] GetAnalyticsDetailsProductiveBalance Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsProductiveBalanceSuccessAction {
    static readonly type = '[AnalyticsDetailsProductiveBalance API] GetAnalyticsProductiveBalance Success';

    constructor(public payload: { list: DateActivityHours[], summary: ActivityHours[] }) { }
}

export class AnalyticsDetailsProductiveBalanceFailAction {
    static readonly type = '[AnalyticsDetailsProductiveBalance API] GetAnalyticsProductiveBalance Fail';

    constructor(public payload: ErrorObject) { }
}
