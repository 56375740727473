import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';

import { AnalyticsGetAction, AnalyticsResetAction } from './analytics.actions';

export interface AnalyticsStateModel {
    entity: AnalyticGetFilterData;
}

@State<AnalyticsStateModel>({
    name: 'Analytics',
    defaults: { entity: {} }
})
@Injectable()
export class AnalyticsState {
    @Action(AnalyticsGetAction) AnalyticsGet(ctx: StateContext<AnalyticsStateModel>, action: AnalyticsGetAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, entity: action.payload });
    }

    @Action(AnalyticsResetAction) AnalyticsGetReset(ctx: StateContext<AnalyticsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, entity: {} });
    }
}
