import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { WorkingAreaListGetFilterData } from '../../models/filters/working-area-list-get-filter.model';

import { WorkingAreaSearchAction, WorkingAreaSearchResetAction } from './working-area-search.actions';

@Injectable({
    providedIn: 'root'
})
export class WorkingAreaSearchDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('WorkingAreaSearchDispatchers');
    }

    public dispatchWorkingAreaSearchAction(request: WorkingAreaListGetFilterData) {
        this.store.dispatch(new WorkingAreaSearchAction(request));
    }

    public dispatchWorkingAreaSearchResetAction() {
        this.store.dispatch(new WorkingAreaSearchResetAction());
    }
}
