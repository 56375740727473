import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryJobPositionsUpdateAction, DictionaryJobPositionsUpdateFailAction, DictionaryJobPositionsUpdateResetAction, DictionaryJobPositionsUpdateSuccessAction } from './dictionary-job-positions-update.actions';


export interface DictionaryJobPositionsUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<DictionaryJobPositionsUpdateStateModel>({
    name: 'DictionaryJobPositionsUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class DictionaryJobPositionsUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(DictionaryJobPositionsUpdateAction) async DictionaryJobPositions(ctx: StateContext<DictionaryJobPositionsUpdateStateModel>, action: DictionaryJobPositionsUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateJobPositions(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DictionaryJobPositionsUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryJobPositionsUpdateFailAction(err)), 0));
    }

    @Action(DictionaryJobPositionsUpdateSuccessAction) DictionaryJobPositionsSuccess(ctx: StateContext<DictionaryJobPositionsUpdateStateModel>, _: DictionaryJobPositionsUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(DictionaryJobPositionsUpdateFailAction) DictionaryJobPositionsFail(ctx: StateContext<DictionaryJobPositionsUpdateStateModel>, action: DictionaryJobPositionsUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryJobPositionsUpdateResetAction) DictionaryJobPositionsReset(ctx: StateContext<DictionaryJobPositionsUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
