import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { WorkingAreaService } from '../../services/working-area.service';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { WorkingAreaListGetFilter } from '../../models/filters/working-area-list-get-filter.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

import { EmployeeScheduleWorkingAreaAction, EmployeeScheduleWorkingAreaFailAction, EmployeeScheduleWorkingAreaResetAction, EmployeeScheduleWorkingAreaSuccessAction } from './employee-schedule-working-area.actions';

import { ExgParamsConfig } from '../../exg-params.config';

export interface EmployeeScheduleWorkingAreaStateModel extends PaginationStateModel<WorkingAreaListItem> {
    uids: string[];
    entities: { [uid: string]: WorkingAreaListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<EmployeeScheduleWorkingAreaStateModel>({
    name: 'EmployeeScheduleWorkingArea',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class EmployeeScheduleWorkingAreaState extends PaginationStateBase {
    constructor(private workingAreaService: WorkingAreaService) {
        super();
    }

    @Action([EmployeeScheduleWorkingAreaAction]) areasGet(ctx: StateContext<EmployeeScheduleWorkingAreaStateModel>, action: EmployeeScheduleWorkingAreaAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new WorkingAreaListGetFilter({ pageIndex: state.index, filterData: action.payload });
        if (!this.applyFilterPagination(PaginationRequest.ReloadCurrentList, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.workingAreaService.getWorkingAreas(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new EmployeeScheduleWorkingAreaSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new EmployeeScheduleWorkingAreaFailAction(err)), 0));
    }

    @Action(EmployeeScheduleWorkingAreaSuccessAction) areasGetSuccess(ctx: StateContext<EmployeeScheduleWorkingAreaStateModel>, action: EmployeeScheduleWorkingAreaSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.workingAreaAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(EmployeeScheduleWorkingAreaFailAction) areasGetFail(ctx: StateContext<EmployeeScheduleWorkingAreaStateModel>, action: EmployeeScheduleWorkingAreaFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(EmployeeScheduleWorkingAreaResetAction) areasGetReset(ctx: StateContext<EmployeeScheduleWorkingAreaStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
