import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { TransportDrillingRigsUpdateAction, TransportDrillingRigsUpdateFailAction, TransportDrillingRigsUpdateResetAction, TransportDrillingRigsUpdateSuccessAction } from './transport-drilling-rigs-update.actions';


export interface TransportDrillingRigsUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<TransportDrillingRigsUpdateStateModel>({
    name: 'TransportDrillingRigsUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class TransportDrillingRigsUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(TransportDrillingRigsUpdateAction) async transportTransportDrillingRigs(ctx: StateContext<TransportDrillingRigsUpdateStateModel>, action: TransportDrillingRigsUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateTransportDrillingRigs(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new TransportDrillingRigsUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new TransportDrillingRigsUpdateFailAction(err)), 0));
    }

    @Action(TransportDrillingRigsUpdateSuccessAction) transportTransportDrillingRigsSuccess(ctx: StateContext<TransportDrillingRigsUpdateStateModel>, _: TransportDrillingRigsUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(TransportDrillingRigsUpdateFailAction) transportTransportDrillingRigsFail(ctx: StateContext<TransportDrillingRigsUpdateStateModel>, action: TransportDrillingRigsUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(TransportDrillingRigsUpdateResetAction) transportTransportDrillingRigsReset(ctx: StateContext<TransportDrillingRigsUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
