import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { DictionaryEntryListItem } from '../../models/business/dictionaries/dictionary-entry-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryVehicleTypesAction, DictionaryVehicleTypesFailAction, DictionaryVehicleTypesResetAction, DictionaryVehicleTypesSuccessAction } from './dictionary-vehicle-types.actions';

export interface DictionaryVehicleTypesStateModel {
    entities: DictionaryEntryListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<DictionaryVehicleTypesStateModel>({
    name: 'DictionaryVehicleTypes',
    defaults: { pending: false, entities: null, error: null }
})
@Injectable()
export class DictionaryVehicleTypesState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action([DictionaryVehicleTypesAction]) VehicleTypesGet(ctx: StateContext<DictionaryVehicleTypesStateModel>, _: DictionaryVehicleTypesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.dictionariesService.getVehicleTypes()
            .then(resp => setTimeout(() => ctx.dispatch(new DictionaryVehicleTypesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryVehicleTypesFailAction(err)), 0));
    }

    @Action(DictionaryVehicleTypesSuccessAction) VehicleTypesGetSuccess(ctx: StateContext<DictionaryVehicleTypesStateModel>, action: DictionaryVehicleTypesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload, error: null });
    }

    @Action(DictionaryVehicleTypesFailAction) VehicleTypesGetFail(ctx: StateContext<DictionaryVehicleTypesStateModel>, action: DictionaryVehicleTypesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryVehicleTypesResetAction) VehicleTypesGetReset(ctx: StateContext<DictionaryVehicleTypesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: null, error: null });
    }
}
