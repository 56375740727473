import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DictionariesService } from '../../services/dictionaries.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DictionaryVehicleTypesUpdateAction, DictionaryVehicleTypesUpdateFailAction, DictionaryVehicleTypesUpdateResetAction, DictionaryVehicleTypesUpdateSuccessAction } from './dictionary-vehicle-types-update.actions';


export interface DictionaryVehicleTypesUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<DictionaryVehicleTypesUpdateStateModel>({
    name: 'DictionaryVehicleTypesUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class DictionaryVehicleTypesUpdateState {
    constructor(private dictionariesService: DictionariesService) { }

    @Action(DictionaryVehicleTypesUpdateAction) async DictionaryVehicleTypes(ctx: StateContext<DictionaryVehicleTypesUpdateStateModel>, action: DictionaryVehicleTypesUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.dictionariesService.updateVehicleTypes(action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DictionaryVehicleTypesUpdateSuccessAction()), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DictionaryVehicleTypesUpdateFailAction(err)), 0));
    }

    @Action(DictionaryVehicleTypesUpdateSuccessAction) DictionaryVehicleTypesSuccess(ctx: StateContext<DictionaryVehicleTypesUpdateStateModel>, _: DictionaryVehicleTypesUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(DictionaryVehicleTypesUpdateFailAction) DictionaryVehicleTypesFail(ctx: StateContext<DictionaryVehicleTypesUpdateStateModel>, action: DictionaryVehicleTypesUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DictionaryVehicleTypesUpdateResetAction) DictionaryVehicleTypesReset(ctx: StateContext<DictionaryVehicleTypesUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
