import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { DrillingRigAction, DrillingRigResetAction } from './drilling-rig.actions';

@Injectable({
    providedIn: 'root'
})
export class DrillingRigDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('DrillingRigDispatchers');
    }

    public dispatchDrillingRigAction(uid: string) {
        this.store.dispatch(new DrillingRigAction({ uid }));
    }

    public dispatchDrillingRigResetAction() {
        this.store.dispatch(new DrillingRigResetAction());
    }
}
