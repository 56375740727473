import { AnalyticsWorkbookGetFilterData } from '../../models/filters/analytics-workbook-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsWorkbookExportAction {
    static readonly type = '[AnalyticsWorkbookExport Page] GetAnalyticsWorkbookExport';

    constructor(public payload: { filter: AnalyticsWorkbookGetFilterData }) { }
}

export class AnalyticsWorkbookExportSuccessAction {
    static readonly type = '[AnalyticsWorkbookExport API] GetAnalyticsWorkbookExport Success';
}

export class AnalyticsWorkbookExportFailAction {
    static readonly type = '[AnalyticsWorkbookExport API] GetAnalyticsWorkbookExport Fail';

    constructor(public payload: ErrorObject) { }
}

export class AnalyticsWorkbookExportResetAction {
    static readonly type = '[AnalyticsWorkbookExport Page] GetAnalyticsWorkbookExport Reset';
}
