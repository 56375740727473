import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';

import { EmployeeListGetFilterData } from '../../models/filters/employee-list-get-filter.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { EmployeesAction, EmployeesResetAction, EmployeesSetFilterAction } from './employees.actions';

@Injectable({
    providedIn: 'root'
})
export class EmployeesDispatchers extends BaseSingletonService {

    constructor(private store: Store) {
        super('EmployeesDispatchers');
    }

    public dispatchEmployeesAction(request: PaginationRequest) {
        this.store.dispatch(new EmployeesAction(request));
    }

    public dispatchEmployeesResetAction() {
        this.store.dispatch(new EmployeesResetAction());
    }

    public dispatchEmployeesSetFilterAction(filterData: EmployeeListGetFilterData) {
        this.store.dispatch(new EmployeesSetFilterAction(filterData));
    }
}
