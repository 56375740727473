import { ActivityBalanceWithPeriod } from '../../models/business/analytics/activity-balance-with-period.model';
import { ActivityBalance } from '../../models/business/analytics/activity-balance.model';
import { AnalyticGetFilterData } from '../../models/filters/analytic-get-filter.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class AnalyticsDetailsActivityBalanceGetAction {
    static readonly type = '[AnalyticsDetailsActivityBalance Page] GetAnalyticsDetailsActivityBalance Get';

    constructor(public payload: AnalyticGetFilterData) { }
}

export class AnalyticsDetailsActivityBalanceSuccessAction {
    static readonly type = '[AnalyticsDetailsActivityBalance API] GetAnalyticsActivityBalance Success';

    constructor(public payload: { list: ActivityBalanceWithPeriod[], summary: ActivityBalance }) { }
}

export class AnalyticsDetailsActivityBalanceFailAction {
    static readonly type = '[AnalyticsDetailsActivityBalance API] GetAnalyticsActivityBalance Fail';

    constructor(public payload: ErrorObject) { }
}
