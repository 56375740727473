import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { VehicleDictionaryService } from '../../services/vehicle-dictionary.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { Vehicle } from '../../models/business/vehicle/vehicle.model';

import { VehicleDictionaryAction, VehicleDictionaryFailAction, VehicleDictionaryResetAction, VehicleDictionarySuccessAction } from './vehicles-dictionary.actions';

import { ExgParamsConfig } from '../../exg-params.config';

export interface VehicleDictionaryStateModel {
    uids: string[];
    entities: { [uid: string]: Vehicle };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
}

@State<VehicleDictionaryStateModel>({
    name: 'VehicleDictionary',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 }
})
@Injectable()
export class VehicleDictionaryState extends PaginationStateBase {
    constructor(private vehicleService: VehicleDictionaryService) {
        super();
    }

    @Action([VehicleDictionaryAction]) vehiclesGet(ctx: StateContext<VehicleDictionaryStateModel>, action: VehicleDictionaryAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.vehicleService.getVehicleList()
            .then(resp => setTimeout(() => ctx.dispatch(new VehicleDictionarySuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new VehicleDictionaryFailAction(err)), 0));
    }

    @Action(VehicleDictionarySuccessAction) vehiclesGetSuccess(ctx: StateContext<VehicleDictionaryStateModel>, action: VehicleDictionarySuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index } = this.handlePaginationResults(state, action.payload, PaginationRequest.ReloadCurrentList, ExgParamsConfig.pagination.defaultPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(VehicleDictionaryFailAction) vehiclesGetFail(ctx: StateContext<VehicleDictionaryStateModel>, action: VehicleDictionaryFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(VehicleDictionaryResetAction) vehiclesGetReset(ctx: StateContext<VehicleDictionaryStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0 });
    }
}
