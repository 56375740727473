import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { RoutesService } from '../../services/routes.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { RouteDeleteAction, RouteDeleteFailAction, RouteDeleteResetAction, RouteDeleteSuccessAction } from './route-delete.actions';

export interface RouteDeleteStateModel {
    pending: boolean;
    error: ErrorObject;
    deleted: boolean;
}

@State<RouteDeleteStateModel>({
    name: 'routeDelete',
    defaults: { pending: false, deleted: false, error: null }
})
@Injectable()
export class RouteDeleteState {
    constructor(private routesService: RoutesService) { }

    @Action(RouteDeleteAction) async routeDelete(ctx: StateContext<RouteDeleteStateModel>, action: RouteDeleteAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null, deleted: false });

        return this.routesService.deleteRoute(action.payload.uid)
            .then(() => setTimeout(() => ctx.dispatch(new RouteDeleteSuccessAction({ uid: action.payload.uid })), 0))
            .catch(error => setTimeout(() => ctx.dispatch(new RouteDeleteFailAction(error)), 0));
    }

    @Action(RouteDeleteSuccessAction) routeDeleteSuccess(ctx: StateContext<RouteDeleteStateModel>, _: RouteDeleteSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: true });
    }

    @Action(RouteDeleteFailAction) routeDeleteFail(ctx: StateContext<RouteDeleteStateModel>, action: RouteDeleteFailAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(RouteDeleteResetAction) routeDeleteReset(ctx: StateContext<RouteDeleteStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: null, deleted: false });
    }
}
