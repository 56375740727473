import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { DrillingRigBreakdownsService } from '../../services/drilling-rig-breakdowns.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

import { DrillingRigBreakdownUpdateAction, DrillingRigBreakdownUpdateFailAction, DrillingRigBreakdownUpdateResetAction, DrillingRigBreakdownUpdateSuccessAction } from './drilling-rig-breakdown-update.actions';

export interface DrillingRigBreakdownUpdateStateModel {
    pending: boolean;
    updated: boolean;
    error: ErrorObject;
}

@State<DrillingRigBreakdownUpdateStateModel>({
    name: 'drillingRigBreakdownUpdate',
    defaults: { pending: false, updated: false, error: null }
})
@Injectable()
export class DrillingRigBreakdownUpdateState {
    constructor(private drillingRigBreakdownsService: DrillingRigBreakdownsService) { }

    @Action(DrillingRigBreakdownUpdateAction) async drillingRigBreakdownUpdate(ctx: StateContext<DrillingRigBreakdownUpdateStateModel>, action: DrillingRigBreakdownUpdateAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, updated: false, error: null });

        return this.drillingRigBreakdownsService.updateDrillingRigBreakdown(action.payload.uid, action.payload.request)
            .then(() => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownUpdateSuccessAction(action.payload)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigBreakdownUpdateFailAction(err)), 0));
    }

    @Action(DrillingRigBreakdownUpdateSuccessAction) drillingRigBreakdownUpdateSuccess(ctx: StateContext<DrillingRigBreakdownUpdateStateModel>, _: DrillingRigBreakdownUpdateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: true, error: null });
    }

    @Action(DrillingRigBreakdownUpdateFailAction) drillingRigBreakdownUpdateFail(ctx: StateContext<DrillingRigBreakdownUpdateStateModel>, action: DrillingRigBreakdownUpdateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: action.payload });
    }

    @Action(DrillingRigBreakdownUpdateResetAction) drillingRigBreakdownUpdateReset(ctx: StateContext<DrillingRigBreakdownUpdateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, updated: false, error: null });
    }
}
