import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { PaginationStateBase, PaginationStateModel } from '../../../burns-ui-framework/shared/store/shared/pagination-state-base';

import { DrillingRigTransfersService } from '../../services/drilling-rig-transfers.service';

import { DrillingRigTransferListGetFilter, DrillingRigTransferListGetFilterData } from '../../models/filters/drilling-rig-transfer-list-get-filter.model';
import { DrillingRigTransferListItem } from '../../models/business/drilling-rig-transfer/drilling-rig-transfer-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';

import { DrillingRigTransfersAction, DrillingRigTransfersFailAction, DrillingRigTransfersResetAction, DrillingRigTransfersSetFilterAction, DrillingRigTransfersSuccessAction } from './drilling-rig-transfers.actions';

import { CompareUtils } from '../../../burns-ui-framework/shared/utils/compare-utils';
import { ExgParamsConfig } from '../../exg-params.config';

export interface DrillingRigTransfersStateModel extends PaginationStateModel<DrillingRigTransferListItem> {
    uids: string[];
    entities: { [uid: string]: DrillingRigTransferListItem };
    retrieved: boolean;
    allItemsLoaded: boolean;
    index: number;
    pending: boolean;
    error: ErrorObject;
    filter: DrillingRigTransferListGetFilterData;
    total: number;
}

@State<DrillingRigTransfersStateModel>({
    name: 'drillingRigTransfers',
    defaults: { pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, total: 0, filter: {drillingRigUid: null} }
})
@Injectable()
export class DrillingRigTransfersState extends PaginationStateBase {
    constructor(private drillingRigTransfersService: DrillingRigTransfersService) {
        super();
    }

    @Action([DrillingRigTransfersAction]) drillingRigTransfersGet(ctx: StateContext<DrillingRigTransfersStateModel>, action: DrillingRigTransfersAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        const filter = new DrillingRigTransferListGetFilter({ pageIndex: state.index, pageSize: ExgParamsConfig.pagination.drillingRigTransfersAllListPageSize, filterData: state.filter });
        if (!this.applyFilterPagination(action.payload, state, filter)) {
            ctx.setState({ ...state, pending: false });
            return Promise.resolve();
        }

        return this.drillingRigTransfersService.getDrillingRigTransfers(filter)
            .then(resp => setTimeout(() => ctx.dispatch(new DrillingRigTransfersSuccessAction({ list: resp, pagination: action.payload })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new DrillingRigTransfersFailAction(err)), 0));
    }

    @Action(DrillingRigTransfersSuccessAction) drillingRigTransfersGetSuccess(ctx: StateContext<DrillingRigTransfersStateModel>, action: DrillingRigTransfersSuccessAction) {
        const state = ctx.getState();
        const { uids, entities, allItemsLoaded, index, total } = this.handlePaginationResults(state, action.payload.list, action.payload.pagination, ExgParamsConfig.pagination.drillingRigTransfersAllListPageSize);
        ctx.setState({ ...state, pending: false, uids, entities, total, retrieved: true, error: null, allItemsLoaded, index });
    }

    @Action(DrillingRigTransfersFailAction) drillingRigTransfersGetFail(ctx: StateContext<DrillingRigTransfersStateModel>, action: DrillingRigTransfersFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(DrillingRigTransfersResetAction) drillingRigTransfersGetReset(ctx: StateContext<DrillingRigTransfersStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, uids: [], entities: {}, retrieved: false, error: null, allItemsLoaded: false, index: 0, filter: {drillingRigUid: null} });
    }

    @Action(DrillingRigTransfersSetFilterAction) drillingRigTransfersSetFilter(ctx: StateContext<DrillingRigTransfersStateModel>, action: DrillingRigTransfersSetFilterAction) {
        const state = ctx.getState();
        const filter = { ...state.filter, ...action.payload };
        if (!CompareUtils.isObjectsEqual(state.filter, filter)) {
            ctx.setState({ ...state, filter, retrieved: false, index: 0 });
            setTimeout(() => ctx.dispatch(new DrillingRigTransfersAction(PaginationRequest.ReloadCurrentList)), 0);
        }
    }
}
