import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { WorkingAreaListGetFilterData } from '../../models/filters/working-area-list-get-filter.model';
import { WorkingAreaListItem } from '../../models/business/working-area/working-area-list-item.model';

export class CompniesWorkingAreaAction {
    static readonly type = '[CompniesWorkingArea Page] GetCompniesWorkingArea';

    constructor(public payload: WorkingAreaListGetFilterData) { }
}

export class CompniesWorkingAreaSuccessAction {
    static readonly type = '[CompniesWorkingArea API] GetCompniesWorkingArea Success';

    constructor(public payload: PaginationResult<WorkingAreaListItem>) { }
}

export class CompniesWorkingAreaFailAction {
    static readonly type = '[CompniesWorkingArea API] GetCompniesWorkingArea Fail';

    constructor(public payload: ErrorObject) { }
}

export class CompniesWorkingAreaResetAction {
    static readonly type = '[CompniesWorkingArea Page] GetCompniesWorkingArea Reset';
}
