import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransportBreakdownDeleteAction {
    static readonly type = '[My TransportBreakdown Page] DeleteTransportBreakdown';

    constructor(public payload: { uid: string }) { }
}

export class TransportBreakdownDeleteSuccessAction {
    static readonly type = '[My TransportBreakdowns API] DeleteTransportBreakdown Success';

    constructor(public payload: { uid: string }) { }
}

export class TransportBreakdownDeleteFailAction {
    static readonly type = '[My TransportBreakdowns API] DeleteTransportBreakdown Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportBreakdownDeleteResetAction {
    static readonly type = '[My TransportBreakdown Page] DeleteTransportBreakdown Reset';
}
