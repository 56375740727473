import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DateUtils } from '../../../utils/date-utils';

@Component({
    selector: 'exg-timepicker-range',
    templateUrl: './exg-timepicker-range.component.html',
    styleUrls: ['./exg-timepicker-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // tslint:disable-next-line:no-forward-ref
            useExisting: forwardRef(() => ExgTimepickerRangeComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExgTimepickerRangeComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

    @Input() value: { begin: number, end: number }; // epoch time format
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Input() placeholder: string;

    @Input() minTime: number;
    @Input() maxTime: number;

    @Input() controlsToValidate: (FormControl | FormGroup | FormArray)[];
    @Input() validate: boolean;
    @Input() border = true;
    /**
     * @deprecated should not use
     */
    @Input() appearance: 'fill' | 'outline' = 'fill';
    @Input() styleType: 'common' | 'empty' = 'common';
    @Input() iconColor: 'primary' | 'accent' | 'warn' | string | undefined = 'primary';
    @Input() changeLanguage: boolean;
    @Input() minRangeSizeMinutes: number = 0;

    @Output() readonly valueChange = new EventEmitter<{ begin: number, end: number }>();

    @HostBinding('class.exg-timepicker-range-style-common') exgStyleTypeCommon: boolean;
    @HostBinding('class.exg-timepicker-range-style-empty') exgStyleTypeEmpty: boolean;

    @ViewChild('elemToFocus', { static: true }) elemToFocus: ElementRef<HTMLInputElement>;
    @ViewChild('inputBegin') inputBegin: ElementRef<HTMLInputElement>;
    @ViewChild('inputEnd') inputEnd: ElementRef<HTMLInputElement>;

    public minTimeInternal: string;
    public maxTimeInternal: string;
    public minTimeValidate: string;
    public maxTimeValidate: string;

    public internalValue: { begin: string, end: string } = { begin: null, end: null };
    public focused: boolean;
    public hovered: boolean;
    public invalid: boolean;

    private unsubscribe = new Subject();
    private propagateChange: (_) => { /* no need */ };

    private oneMinuteMilliseconds = 60 * 1000;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    public ngOnInit() {
        if (this.controlsToValidate) {
            merge(...this.controlsToValidate.map(c => c.valueChanges)).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                if (this.validate) {
                    this.setInvalidState();
                }
            });
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            this.setInternalValue();
        }

        if (changes.validate) {
            this.setInvalidState();
        }

        if (changes.styleType || changes.ngClass || changes.placeholder) {
            this.applyHostClasses();
        }

        if (changes.minTime && this.minTime > 0) {
            this.minTimeValidate = DateUtils.convertEpochTimeToString(this.minTime);
        }

        if (changes.maxTime && this.maxTime > 0) {
            this.maxTimeValidate = DateUtils.convertEpochTimeToString(this.maxTime);
        }

        if (changes.minRangeSizeMinutes && this.minRangeSizeMinutes) {
            this.setMinTimeInternalInterval(this.internalValue.begin);
            this.setMaxTimeInternalInterval(this.internalValue.end);
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next(true);
        this.unsubscribe.complete();
    }

    public writeValue(value: { begin: number, end: number }) {
        this.value = value;
        this.setInternalValue();
    }

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(_) { /* no need */ }

    public onTimepickerDialogClose(needToOpenSecond: boolean = false) {
        if (needToOpenSecond && !this.internalValue.end) {
            this.openTimepicker(true);
        }
    }

    public onBeginTimeChange(event: any) {
        if (event) {
            const internalBegin = event;
            this.internalValue = { begin: internalBegin, end: this.internalValue.end };
            const valueEpocBegin = DateUtils.convertStringTimeToEpoch(internalBegin);
            this.value = { begin: valueEpocBegin, end: this.value ? this.value.end : null };
        } else {
            this.value = { begin: null, end: this.value ? this.value.end : null };
        }

        if (!!this.value.begin && !!this.value.end) {
            if (this.propagateChange) {
                this.propagateChange(this.value);
            }

            this.valueChange.emit(this.value);
        }

        this.setMinTimeInternalInterval(event);
        setTimeout(() => this.elemToFocus.nativeElement.focus(), 0); // remove focus from trigger button since it looks ugly
    }

    public onEndTimeChange(event: any) {
        if (event) {
            const internalEnd = event;
            this.internalValue = { begin: this.internalValue.begin, end: internalEnd };
            const valueEpocEnd = DateUtils.convertStringTimeToEpoch(internalEnd);
            this.value = { begin: this.value ? this.value.begin : null, end: valueEpocEnd };
        } else {
            this.value = { begin: this.value ? this.value.begin : null, end: null };
        }

        if (!!this.value.begin && !!this.value.end) {
            if (this.propagateChange) {
                this.propagateChange(this.value);
            }

            this.valueChange.emit(this.value);
        }

        this.setMaxTimeInternalInterval(event);
        setTimeout(() => this.elemToFocus.nativeElement.focus(), 0); // remove focus from trigger button since it looks ugly
    }

    public openTimepicker(openEnd: boolean = false) {
        if (openEnd) {
            setTimeout(() => this.inputEnd.nativeElement.click(), 0);
            return;
        }

        if (!this.internalValue || !this.internalValue.begin) {
            setTimeout(() => this.inputBegin.nativeElement.click(), 0);
        } else {
            setTimeout(() => this.inputEnd.nativeElement.click(), 0);
        }
    }

    private setInternalValue() {
        if (this.value) {
            const internalBegin = DateUtils.convertEpochTimeToString(this.value.begin);
            const internalEnd = DateUtils.convertEpochTimeToString(this.value.end);
            this.internalValue = { begin: internalBegin, end: internalEnd };
            this.setMinTimeInternalInterval(internalBegin);
            this.setMaxTimeInternalInterval(internalEnd);
            this.changeDetectorRef.markForCheck();
        }
    }

    private setInvalidState() {
        this.invalid = this.validate && this.controlsToValidate && this.controlsToValidate.some(c => c.invalid);
    }

    private applyHostClasses() {
        this.exgStyleTypeCommon = this.styleType === 'common' || !this.styleType;
        this.exgStyleTypeEmpty = this.styleType === 'empty';
        this.appearance =  this.styleType === 'common' || !this.styleType ? 'outline' : 'fill';
    }

    private setMinTimeInternalInterval(internalBegin: string) {
        if (!internalBegin) {
            return;
        }

        if (this.minRangeSizeMinutes === 0) {
            this.minTimeInternal = internalBegin;
            return;
        }

        let epochTime = DateUtils.convertStringTimeToEpoch(internalBegin);
        epochTime += this.minRangeSizeMinutes * this.oneMinuteMilliseconds;
        this.minTimeInternal = DateUtils.convertEpochTimeToString(epochTime);
    }

    private setMaxTimeInternalInterval(internalEnd: string) {
        if (!internalEnd) {
            return;
        }

        if (this.minRangeSizeMinutes === 0) {
            this.maxTimeInternal = internalEnd;
            return;
        }

        let epochTime = DateUtils.convertStringTimeToEpoch(internalEnd);
        epochTime -= this.minRangeSizeMinutes * this.oneMinuteMilliseconds;
        this.maxTimeInternal = DateUtils.convertEpochTimeToString(epochTime);
    }
}
