import { Transport } from '../../models/business/transport/transport.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class TransportAction {
    static readonly type = '[Transport Page] GetTransport';

    constructor(public payload: { uid: string}) { }
}

export class TransportSuccessAction {
    static readonly type = '[Transport API] GetTransport Success';

    constructor(public payload: Transport) { }
}

export class TransportFailAction {
    static readonly type = '[Transport API] GetTransport Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportResetAction {
    static readonly type = '[Transport Page] GetTransport Reset';
}
