import { EmployeePasswordUpdateAggregationRequest } from '../../models/business/employee/employee-password-update-aggregation-request.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class EmployeeChangePasswordAction {
    static readonly type = '[Security Page] EmployeeChangePassword';

    constructor(public payload: { userUid: string, request: EmployeePasswordUpdateAggregationRequest }) { }
}

export class EmployeeChangePasswordSuccessAction {
    static readonly type = '[Auth API] EmployeeChangePassword Success';
}

export class EmployeeChangePasswordFailAction {
    static readonly type = '[Auth API] EmployeeChangePassword Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeeChangePasswordResetAction {
    static readonly type = '[Security Page] EmployeeChangePassword Reset';
}
