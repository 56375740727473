<mat-radio-group class="mgl-radio-group" [required]="!!required" [name]="name" [class.column]="direction === 'column'" (change)="onSelectionChange($event)">
    <mat-radio-button class="mgl-radio-button"
                      [value]="value"
                      [name]="name"
                      color="primary"
                      [checked]="compareFn(groupValue, value)">
        <ng-container *ngIf="!!displayValueTemplate" [ngTemplateOutlet]="displayValueTemplate" [ngTemplateOutletContext]="{$implicit: value}"></ng-container>
        <ng-container *ngIf="!displayValueTemplate">{{placeholder}}</ng-container>
    </mat-radio-button>
</mat-radio-group>
