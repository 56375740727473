import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { PaginationRequest } from '../../../burns-ui-framework/shared/models/common/pagination-request.model';
import { PaginationResult } from '../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { RouteListGetFilterData } from '../../models/filters/route-list-get-filter.model';
import { RouteListItem } from '../../models/business/route/route-list-item.model';

export class RoutesAction {
    static readonly type = '[Routes Page] GetRoutes';

    constructor(public payload: PaginationRequest) { }
}

export class RoutesSuccessAction {
    static readonly type = '[Routes API] GetRoutes Success';

    constructor(public payload: { list: PaginationResult<RouteListItem>, pagination: PaginationRequest }) { }
}

export class RoutesFailAction {
    static readonly type = '[Routes API] GetRoutes Fail';

    constructor(public payload: ErrorObject) { }
}

export class RoutesResetAction {
    static readonly type = '[Routes Page] GetRoutes Reset';
}

export class RoutesSetFilterAction {
    static readonly type = '[Routes API] SetFilter';

    constructor(public payload: RouteListGetFilterData) { }
}
