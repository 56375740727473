import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { ExgTranslateService } from '../../burns-ui-framework/shared/services/common/exg-translate.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { CreateOrUpdateEmployeeAggregationRequest } from '../models/business/employee/create-or-update-employee-aggregation-request.model';
import { EmployeeListGetFilter } from '../models/filters/employee-list-get-filter.model';
import { EmployeeListItem } from '../models/business/employee/employee-list-item.model';
import { EmployeePasswordUpdateAggregationRequest } from '../models/business/employee/employee-password-update-aggregation-request.model';
import { EmployeeScheduleGetFilter } from '../models/filters/employee-schedule-get-filter.model';
import { EmployeeScheduleItem } from '../models/business/employee/employee-schedule-item.model';
import { EmployeeWorkbookGetFilter } from '../models/filters/employee-workbook-get-filter.model';
import { Employee } from '../models/business/employee/employee.model';
import { PaginationResult } from '../../burns-ui-framework/shared/models/common/pagination-result.model';

import { DateUtils } from '../../burns-ui-framework/shared/utils/date-utils';
import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class EmployeesService extends BaseSingletonService {

    private settings: {
        service: {
            getEmploye: string;
            getEmployees: string;
            getSchedule: string;
            getWorkbook: string;
            createEmployee: string;
            updateEmployee: string;
            deleteEmployee: string;

            changePassword: string;
        }
    };

    constructor(private http: HttpService, private translate: ExgTranslateService) {
        super('EmployeesService');
        this.settings = {
            service: {
                getEmploye: '/personnel/v1/employees/{0}',
                getEmployees: '/personnel/v1/employees?term={0}&workingAreaUid={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}',
                getSchedule: '/personnel/v1/employees/schedule?term={0}&workingAreaUid={1}&dateFrom={2}&dateTo={3}&sortField={4}&sortOrder={5}&pageIndex={6}&pageSize={7}',
                getWorkbook: '/personnel/v1/employees/workbook?workingAreaUid={0}&DateFrom={1}&DateTo={2}',
                createEmployee: '/personnel-aggregation/v1/employees',
                updateEmployee: '/personnel-aggregation/v1/employees/{0}',
                deleteEmployee: '/personnel-aggregation/v1/employees/{0}',

                changePassword: '/personnel-aggregation/v1/employees/{0}/password',
            }
        };
    }

    public async getEmploye(uid: string): Promise<Employee> {
        return this.http.get<{ data: Employee }>(String.format(this.settings.service.getEmploye, uid))
            .then(res => res.data);
    }

    public async getEmployees(filter: EmployeeListGetFilter): Promise<PaginationResult<EmployeeListItem>> {
        return this.http.get<PaginationResult<EmployeeListItem>>(String.format(this.settings.service.getEmployees, filter.term, filter.workingAreaUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getSchedule(filter: EmployeeScheduleGetFilter): Promise<PaginationResult<EmployeeScheduleItem>> {
        return this.http.get<PaginationResult<EmployeeScheduleItem>>(String.format(this.settings.service.getSchedule, filter.term, filter.workingAreaUid, filter.dateFrom, filter.dateTo, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getWorkbook(filter: EmployeeWorkbookGetFilter): Promise<Blob> {
        const workbookName = `Табель Буровой участок - ${filter.workingArea ? filter.workingArea?.title : ''} - ${ DateUtils.formatEpocToString(filter.dateFrom, this.translate.getCurrentLang(), 'MMMM yy')}.xlsx`;
        return this.http.download(String.format(this.settings.service.getWorkbook, filter.workingArea?.uid, filter.dateFrom, filter.dateTo), workbookName);
    }

    public async createEmployee(request: CreateOrUpdateEmployeeAggregationRequest): Promise<{ uid: string }> {
        return this.http.post<{ data: string }>(String.format(this.settings.service.createEmployee), request)
            .then(res => ({ uid: res.data }));
    }

    public async updateEmployee(uid: string, request: CreateOrUpdateEmployeeAggregationRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateEmployee, uid), request);
    }

    public async deleteEmployee(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteEmployee, uid));
    }

    /**
     * Change empoyee password
     */
    public async changePassword(userUid: string, request: EmployeePasswordUpdateAggregationRequest): Promise<boolean> {
        return this.http.put<boolean>(String.format(this.settings.service.changePassword, userUid), request);
    }
}
