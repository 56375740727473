import { EmployeeListItem } from '../../models/business/employee/employee-list-item.model';
import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';

export class EmployeesSearchAction {
    static readonly type = '[EmployeesSearch Page] GetEmployeesSearch';

    constructor(public payload: { workingAreaUid: string }) { }
}

export class EmployeesSearchSuccessAction {
    static readonly type = '[EmployeesSearch API] GetEmployeesSearch Success';

    constructor(public payload: EmployeeListItem[]) { }
}

export class EmployeesSearchFailAction {
    static readonly type = '[EmployeesSearch API] GetEmployeesSearch Fail';

    constructor(public payload: ErrorObject) { }
}

export class EmployeesSearchResetAction {
    static readonly type = '[EmployeesSearch Page] GetEmployeesSearch Reset';
}
