import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { DictionaryEntryCreateOrUpdate } from '../../models/business/dictionaries/dictionary-entry-create-or-update.model';


export class TransportDrillingRigsUpdateAction {
    static readonly type = '[TransportDrillingRigsUpdate Page] TransportDrillingRigsUpdate';

    constructor(public payload: { request: DictionaryEntryCreateOrUpdate[] }) { }
}
export class TransportDrillingRigsUpdateSuccessAction {
    static readonly type = '[TransportDrillingRigsUpdate API] TransportDrillingRigsUpdate Success';
}

export class TransportDrillingRigsUpdateFailAction {
    static readonly type = '[TransportDrillingRigsUpdate API] TransportDrillingRigsUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class TransportDrillingRigsUpdateResetAction {
    static readonly type = '[TransportDrillingRigsUpdate Page] TransportDrillingRigsUpdate Reset';
}
