import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftResourcesService } from '../../services/shift-resources.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { VehicleListItem } from '../../models/business/shifts/vehicle-list-item.model';

import { ShiftVehiclesAction, ShiftVehiclesFailAction, ShiftVehiclesResetAction, ShiftVehiclesSuccessAction } from './shift-vehicles.actions';

export interface ShiftVehiclesStateModel {
    entity: VehicleListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<ShiftVehiclesStateModel>({
    name: 'ShiftVehicles',
    defaults: { pending: false, entity: [], error: null }
})
@Injectable()
export class ShiftVehiclesState {
    constructor(private shiftResourcesService: ShiftResourcesService) {}

    @Action(ShiftVehiclesAction) vehiclesGet(ctx: StateContext<ShiftVehiclesStateModel>, action: ShiftVehiclesAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.shiftResourcesService.getVehicles(action.payload.workingAreaUid)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftVehiclesSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftVehiclesFailAction(err)), 0));
    }

    @Action(ShiftVehiclesSuccessAction) vehiclesGetSuccess(ctx: StateContext<ShiftVehiclesStateModel>, action: ShiftVehiclesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ShiftVehiclesFailAction) vehiclesGetFail(ctx: StateContext<ShiftVehiclesStateModel>, action: ShiftVehiclesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftVehiclesResetAction) vehiclesGetReset(ctx: StateContext<ShiftVehiclesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: [], error: null });
    }
}
